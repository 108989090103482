import { deck_template } from "../deck";
import { batakCopy } from './batakCopy'
import {isKozRevealedOnTable, suitsInHandFunction} from '../games'

const deck = {...deck_template}
const suitsCopy = batakCopy.suits
const compCopy = batakCopy.validations;

export function isInvalid(cardId, table, hand) {
    const koz = table.koz;

    if(koz) {
        return kozValidations(cardId, table, hand)
    }
    return 'Bir sorun oluştu. Tekrar deneyin'
}

function kozValidations(cardId, table, hand) {
    const tableSuit = table.tableSuit;
    const kozRevealed = table.kozRevealed;
    const suitsInHand = suitsInHandFunction(hand)

    if (tableSuit) {
        // elinde yerdeki kart varsa, oynamak zorundasin
        if (suitsInHand.includes(tableSuit) && (deck[cardId].suit !== tableSuit)) {
            return `${suitsCopy[tableSuit]} ${compCopy.playX}`
        }

        //Koz atildiysa
        if(tableSuit === table.koz){
            let rank = 0;
            for (const seat in table.cards) {
                const cardFromTable = deck[table.cards[seat]];
                if (cardFromTable.suit === tableSuit && cardFromTable.rank > rank) {
                    rank = cardFromTable.rank
                }
            }
            for (const handCardId in hand) {
                if (deck[handCardId].suit === tableSuit && deck[cardId].rank < rank && deck[handCardId].rank > rank) {
                    return 'Yükseltmek zorunludur.'
                }
            }
        }
        else {
            //Yukseltmek mecburi koz atilmamissa
            if(!isKozRevealedOnTable(table.cards, table.koz)){
                let rank = 0;
                for (const seat in table.cards) {
                    const cardFromTable = deck[table.cards[seat]];
                    if (cardFromTable.suit === tableSuit && cardFromTable.rank > rank) {
                        rank = cardFromTable.rank
                    }
                }
                for (const handCardId in hand) {
                    if (deck[handCardId].suit === tableSuit && deck[cardId].rank < rank && deck[handCardId].rank > rank) {
                        return 'Yükseltmek zorunludur.'
                    }
                }
            }

            //Koz cakilacaksa kozu yukseltmek mecburi
            if(!suitsInHand.includes(tableSuit)) {
                let rank = 0;
                for (const seat in table.cards) {
                    const tableCardId = table.cards[seat];
                    const cardFromTable = deck[tableCardId];
                    if (cardFromTable.suit === table.koz && cardFromTable.rank > rank) {
                        rank = cardFromTable.rank
                    }
                }
                for (const handCardId in hand) {
                    if (deck[handCardId].suit === table.koz && deck[cardId].rank < rank && deck[handCardId].rank > rank) {
                        return 'Yerdeki kozu yükseltmek zorunludur.'
                    }
                }
            }

            // Elinde koz varsa ve yerdeki suit yoksa koz atmalisin
            if (!suitsInHand.includes(tableSuit) && suitsInHand.includes(table.koz) && deck[cardId].suit !== tableSuit && deck[cardId].suit !== table.koz) {
                // @ts-ignore
                return `${suitsCopy[table.koz]} ${compCopy.playX}`
            }
        }
    }
    else {
        if (deck[cardId].suit === table.koz && !kozRevealed && suitsInHand.length > 1) {
            return compCopy.notYetTrump
        }
    }
    return false
}