<template>
  <radial-progress-bar
    id="radial-progress"
    class="baseShadow"
    :diameter="diameter"
    :completed-steps="points"
    :total-steps=stepsForLevel
    innerStrokeColor="navy"
    startColor="#ff3a00"
    stopColor="darkorange"
  >
    <h1>{{ levelName }}</h1>
  </radial-progress-bar>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress'
import {ratedLevels} from "@/js/ratedInits"

export default {
  name: "UserBatakProgress",
  components: {
    RadialProgressBar
  },
  props: ["userMeta", "diameter"],
  computed: {
    levelName() {
      return ratedLevels[this.level]['name']
    },
    stepsForLevel(){
      return ratedLevels[this.level]['endPoints'] - ratedLevels[this.level]['startPoints']
    },
    points() {
      if (this.userMeta && this.userMeta.scoreAllTime.points) {
        return this.userMeta.scoreAllTime.points - ratedLevels[this.level]['startPoints']
      } else {
        return 0
      }
    },
    level() {
      if (this.userMeta && this.userMeta.level) {
        return this.userMeta.level
      } else {
        return 1
      }
    }
  }
}
</script>

<style scoped>
#radial-progress{
  display:flex;
  justify-content: center;
  align-content: center;
  align-self: center;
  margin-left: 10px;
  margin-right: 10px;
  
  padding: -20px;
  
  border-radius: 50%;
  
}

</style>