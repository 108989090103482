const firebaseConfig = {
  apiKey: "AIzaSyBEsGZJBFOauq9hssfbM5PlS1RHebhrbM0",
  authDomain: "kagitoyna-test.firebaseapp.com",
  databaseURL: "https://kagitoyna-test.firebaseio.com",
  projectId: "kagitoyna-test",
  storageBucket: "kagitoyna-test.appspot.com",
  messagingSenderId: "96093448343",
  appId: "1:96093448343:web:474be3bffe10dbc602a1d2",
  measurementId: "G-LVHF1BBVJG"
};

export default firebaseConfig;
