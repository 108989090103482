<template>
  <div id="container">
    <div class="Header">
      <h1 class="leaderBoardTitleMain">
        GEÇMİŞ SEZONLAR
      </h1>
      <div class="dropdown-root">
        <div class="dropdown-parent">
          <label for="seasons" class="label">Sezon</label>
          <select id="seasons" class="select-form"></select>
        </div>
        <div class="dropdown-parent">
          <label for="category" class="label">Kategori</label>
          <select id="category" class="select-form">
            <option value="points" selected>Kağıtoyna Puanı</option>
            <option value="winRate">Galibiyet Oranı</option>
            <option value="maxStreak">Üst Üste Galibiyet</option>
            <option value="bestTime">En Hızlı Galibiyet</option>
            <option value="maxScore">En Yüksek Puan</option>
            <option value="scorePerPlayed">Ortalama Puan</option>
          </select>
        </div>
      </div>
    </div>
    <div v-if="selectedLeaderboard">
      <competitors
        style="margin-bottom: 10px"
        v-show=true
        :list="selectedLeaderboard"
        id="topXMonthly">
      </competitors>
    </div>
  </div>
</template>

<script>

import app from '@/firebase.setup'
import Competitors from "@/components/leaderboard/Competitors"
import {formatTimeInSecToMinSec} from "@/js/utils";

const db = app.firestore()
// const db = getFirestore();

export default {
  components: {
    Competitors
  },
  data: () => ({
    board: null,
    stars: null,
    ranks: null,
    category: null,
    season: null
  }),
  async mounted() {
    this.addListenerToCategorySelector()
    this.category = document.getElementById("category").value;
    await this.getAndAddMonthsToSelector();
  },
  methods: {
    addListenerToCategorySelector: function() {
      const categorySelect = document.getElementById('category');
      categorySelect.addEventListener("change", this.selectCategory)
    },
    parseMonth: function (month) {
      const m = {
        '01': 'Ocak',
        '02': 'Şubat',
        '03': 'Mart',
        '04': 'Nisan',
        '05': 'Mayıs',
        '06': 'Haziran',
        '07': 'Temmuz',
        '08': 'Ağustos',
        '09': 'Eylül',
        '10': 'Ekim',
        '11': 'Kasım',
        '12': 'Aralık',
      }
      return month.substring(1,5) + ' ' + m[month.substring(5,7)]
    },
    getAndAddMonthsToSelector: async function () {
      const seasonSelect = document.getElementById('seasons');
      const monthsSnapshot =  await db.collection('batakRated').get()
      monthsSnapshot.forEach((docu) => {
        seasonSelect.options.add( new Option(this.parseMonth(docu.id), docu.id))
      });
      seasonSelect.getElementsByTagName('option')[seasonSelect.options.length-1].selected = 'selected'
      seasonSelect.addEventListener("change", this.selectSeason)
      await this.selectSeason()
    },

    selectCategory: async function (){
      this.category= document.getElementById("category").value;
      [this.board, this.stars, this.ranks] = await this.getUsers(db, this.season, this.category);
    },

    selectSeason: async function (){
      this.season = document.getElementById("seasons").value;
      [this.board, this.stars, this.ranks] = await this.getUsers(db, this.season, this.category);
    },

    getUsers: async function (db, season, category) {

      let order = 'desc'
      if(category === "bestTime" || category === "minNegatives"){
        order = 'asc'
      }

      const usersRef = db.collection('batakRated').doc(season).collection('users').orderBy(category, order).limit(5)

      const usersCol = await usersRef.get()
      const board = {}
      let i = 1;
      usersCol.forEach((docu) => {
        board[i] = docu.data()
        i += 1
      });

      // make a star object for awarded stars
      const stars = {1: '⭐', 2:'', 3:'', 4:'', 5:''}
      for(let i=2; i<6; i++){
        if(board[1][category] === board[i][category]){
          stars[i] = '⭐'
        }
        else {
          break
        }
      }

      // rank object if equal
      const ranks = {1:1, 2:2, 3:3, 4:4, 5:5}
      let benchmark = board[1][category]
      let benchmark_rank = 1
      for(let i=2; i<6; i++){
        if(benchmark === board[i][category]){
          ranks[i] = benchmark_rank
        }
        else {
          benchmark = board[i][category]
          benchmark_rank = i
        }
      }

      return [board, stars, ranks]
    },

    formatScore: function (score, playedGames, category){
      if (category === 'bestTime') {
        return formatTimeInSecToMinSec(score)
      }
      else if (category === 'winRate') {
        return score + '% (' + playedGames + ')'
      }
      else
      {
        return score
      }
    }
  },
  computed: {
    selectedLeaderboard(){
      if (this.board) {
        return [
          { "star": this.stars[1], "rank": this.ranks['1'], "name": this.board['1']['name'], "points": this.formatScore(this.board[1][this.category], this.board[1]['played'], this.category)},
          { "star": this.stars[2], "rank": this.ranks['2'], "name": this.board['2']['name'], "points": this.formatScore(this.board[2][this.category], this.board[2]['played'], this.category) },
          { "star": this.stars[3], "rank": this.ranks['3'], "name": this.board['3']['name'], "points": this.formatScore(this.board[3][this.category], this.board[3]['played'], this.category) },
          { "star": this.stars[4], "rank": this.ranks['4'], "name": this.board['4']['name'], "points": this.formatScore(this.board[4][this.category], this.board[4]['played'], this.category) },
          { "star": this.stars[5], "rank": this.ranks['5'], "name": this.board['5']['name'], "points": this.formatScore(this.board[5][this.category], this.board[5]['played'], this.category) }
        ]
      }
      else {
        return null
      }

    }
  }
}

</script>

<style lang="css" scoped>
  .leaderBoardTitleMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: default;
  width:auto;
  align-self: center;
  font-size: 18px;


  background-color: var(--mainColor);
  /*height: 40px;*/
  border-radius: 3px;
  line-height: 36px;
  text-align: center;
  color: var(--tertiaryColor);
  text-transform: uppercase;
  font-weight: 700;
  /*padding: 0 34px;*/
  /*box-shadow: 0 4px 16px 0 rgba(0, 0, 0, .3);*/

  outline: inherit;
}
  .Header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;

    width: 90%;
    z-index: 1;
    max-width: 625px;
    margin: 0 auto;
    position: relative;
    border-top: 3px solid var(--leader1);
    background-color: var(--leader2);

    border-top-left-radius: min(1vh, 5);
    border-top-right-radius: min(1vh, 5);

    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  }

  .dropdown-root {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    flex-direction: row
  }
  .dropdown-parent {
    display:flex;
    flex-direction: column;
    border: 2px var(--tertiaryColor) solid;
    margin: 5px;
    border-radius: 5px;
    width: 150px;
  }
  .select-form {
    background-color: var(--mainColor);
    font-weight: 400;
    outline: 2px var(--tertiaryColor) solid;
    border: none;
    border-radius: 3px;
    text-align-last: center;
  }
  .label {
    color: var(--tertiaryColor);
    font-size: 20px;
    font-weight: bold;
    margin-top: 2px;
    margin-bottom: 3px;
  }

  select {
    color: var(--tertiaryColor);
    font-size: 16px;
    padding-top: 2px;
    padding-bottom: 2px;
    cursor: pointer;
  }

  @media screen and (max-width: 380px) {
    .dropdown-root {
      flex-direction: column;
      align-items: center;
    }
  }
</style>