import { render, staticRenderFns } from "./Leaderboard.vue?vue&type=template&id=8382ceb4&scoped=true&"
import script from "./Leaderboard.vue?vue&type=script&lang=js&"
export * from "./Leaderboard.vue?vue&type=script&lang=js&"
import style0 from "./Leaderboard.vue?vue&type=style&index=0&id=8382ceb4&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8382ceb4",
  null
  
)

export default component.exports