import { deck_template } from "../deck";
import { v4 as uuid } from 'uuid';
import { namePool } from "../namePool";
import { dealAllCardsToPlayers } from "../utils";

import { kingCopy } from "./kingCopy";
import {nextSeat, suitsInHandFunction, mapRank2Id, orderHand, howMany, playedCards, playedCardsBySuit,
  playedCardsBySuitWOTable, orderSuitsByPlayed, orderSuitsByLength, normalDeck} from "../games";

const validationsCopy = kingCopy.validations;
const contractsCopy = kingCopy.contracts;
const deck = JSON.parse(JSON.stringify(deck_template));


export function createGame(user, gameType) {
  const id = uuid().substring(0, 5);
  const usersInGame = {'S': {'name': user.name, 'id': user.id, 'gameCategory': 'king' }}
  const gameCategory = user.gameCategory
  const round = 0;


  if (gameType === 'kingSingle') {
    usersInGame['W'] = addBot('W', id, 'kingSingle')
    usersInGame['N'] = addBot('N', id, 'kingSingle', [usersInGame['W'].name.substring(9)])
    usersInGame['E'] = addBot('E', id, 'kingSingle', [usersInGame['W'].name.substring(9), usersInGame['N'].name.substring(9)])
  }
  else if (gameType === 'kingRated') {
    usersInGame['W'] = addBot('W', id, 'kingRated')
    usersInGame['N'] = addBot('N', id, 'kingRated', [usersInGame['W'].name.substring(9)])
    usersInGame['E'] = addBot('E', id, 'kingRated', [usersInGame['W'].name.substring(9), usersInGame['N'].name.substring(9)])
  }

  const game = {
    id,
    round: round,
    usersInGame,
    table: {
      'tableSuit': null,
      'whoseTurn': 'S',
      'kozRevealed': false,
      'tableRound': 1,
      'gameOver': false,
      'cards': {},
      'roundEnd': false,
    }
  };

  const gameMeta = {
    id,
    gameType: gameType,
    gameCategory: gameCategory,
    createTs: {
      0: {
        'round': 0
      }
    },
    creator: user.name,
    owner: {
      'id': user.id,
      'name': user.name,
      'seat': 'S'
    },
    restartCounter: 0,
    scores: {
      'S': {'total': 0, 'round': 0, 'totalKoz': 0, 'totalCeza': 0},
      'W': {'total': 0, 'round': 0, 'totalKoz': 0, 'totalCeza': 0},
      'N': {'total': 0, 'round': 0, 'totalKoz': 0, 'totalCeza': 0},
      'E': {'total': 0, 'round': 0, 'totalKoz': 0, 'totalCeza': 0},
    },
    yazboz: {'remains': {
        'E': {'koz': 2, 'ceza': 3},
        'W': {'koz': 2, 'ceza': 3},
        'N': {'koz': 2, 'ceza': 3},
        'S': {'koz': 2, 'ceza': 3},
        'cezalar':{ 'rifki': 2, 'el': 2, 'kupa': 2, 'kiz': 2, 'erkek': 2, 'soniki': 2 }},
      'rifki': {'S': ['', ''], 'W': ['', ''], 'N': ['', ''], 'E': ['', '']},
      'el': {'S': ['', ''], 'W': ['', ''], 'N': ['', ''], 'E': ['', '']},
      'kiz': {'S': ['', ''], 'W': ['', ''], 'N': ['', ''], 'E': ['', '']},
      'erkek': {'S': ['', ''], 'W': ['', ''], 'N': ['', ''], 'E': ['', '']},
      'kupa': {'S': ['', ''], 'W': ['', ''], 'N': ['', ''], 'E': ['', '']},
      'soniki': {'S': ['', ''], 'W': ['', ''], 'N': ['', ''], 'E': ['', '']},
    }
  }

  const newUser = {
    'id': user.id,
    'name': user.name,
    'gameType': gameType,
    'gameCategory': gameCategory,
    'gameId': game.id,
    'seat': 'S'
  }
  const hands = dealAllCardsToPlayers()

  return [game, gameMeta, hands, newUser]
}

export function restartGame(game, gameMeta, user) {
  const gameId = game.id;
  const usersInGame = game.usersInGame;
  const gameType = user.gameType;
  const gameCategory = user.gameCategory;

  const createTs = gameMeta.createTs
  let restartCounter = gameMeta.restartCounter;

  let newGame = {
    'id': gameId,
    'usersInGame': usersInGame,
    round: 1,
    'dealer': user.seat,
    table: {
      'tableSuit': null,
      'whoseTurn': user.seat,
      'kozRevealed': false,
      'tableRound': 1,
      'gameOver': false,
      'cards': {},
      'roundEnd': false
    },
    'history': null
  };

  const newGameMeta = {
    'id': gameId,
    'gameType': gameType,
    'gameCategory': gameCategory,
    'creator': user.name,
    'owner': user,
    'restartCounter': 0,
    scores: {
      'S': {'total': 0, 'round': 0, 'totalKoz': 0, 'totalCeza': 0},
      'W': {'total': 0, 'round': 0, 'totalKoz': 0, 'totalCeza': 0},
      'N': {'total': 0, 'round': 0, 'totalKoz': 0, 'totalCeza': 0},
      'E': {'total': 0, 'round': 0, 'totalKoz': 0, 'totalCeza': 0},
    },
    yazboz: {
      'remains': {
        'E': {'koz': 2, 'ceza': 3},
        'W': {'koz': 2, 'ceza': 3},
        'N': {'koz': 2, 'ceza': 3},
        'S': {'koz': 2, 'ceza': 3},
        'cezalar': {'rifki': 2, 'el': 2, 'kupa': 2, 'kiz': 2, 'erkek': 2, 'soniki': 2}
      },
      'rifki': {'S': ['', ''], 'W': ['', ''], 'N': ['', ''], 'E': ['', '']},
      'el': {'S': ['', ''], 'W': ['', ''], 'N': ['', ''], 'E': ['', '']},
      'kiz': {'S': ['', ''], 'W': ['', ''], 'N': ['', ''], 'E': ['', '']},
      'erkek': {'S': ['', ''], 'W': ['', ''], 'N': ['', ''], 'E': ['', '']},
      'kupa': {'S': ['', ''], 'W': ['', ''], 'N': ['', ''], 'E': ['', '']},
      'soniki': {'S': ['', ''], 'W': ['', ''], 'N': ['', ''], 'E': ['', '']},
    }
  }

  const hands = dealAllCardsToPlayers()
  // [newGame, hands] = updateStateRoundEnd(newGame, newGameMeta);

  if(restartCounter) {
    restartCounter += 1
  }
  else {
    restartCounter = 1
  }
  newGameMeta['restartCounter'] = restartCounter;
  newGameMeta['createTs'] = createTs;

  return [newGame, newGameMeta, hands]
}

export function addBot(seat, gameId, gameType, namesExcluded = []) {
  const nameP = JSON.parse(JSON.stringify(namePool));
  if (!Date.now) {
    Date.now = function () {
      return new Date().getTime();
    }
  }
  let index = Math.floor(Math.random() * nameP.length);
  while (namesExcluded.includes(nameP[index])) {
    index = Math.floor(Math.random() * nameP.length);
  }
  const botName = '_bot' + JSON.stringify(Date.now()).substring(8) + nameP[index];

  return {
    'id': botName,
    'gameId': gameId,
    'gameCategory': 'king',
    'gameType': gameType,
    'name': botName,
    'seat': seat
  }
}


export function botPlay(seat, game, hand) {
  let nextCardId;
  if (game.table.koz) {
    nextCardId = botKoz(game, hand)
  } else if (game.table.ceza) {
    const ceza = game.table.ceza
    if (ceza === 'soniki') {
      nextCardId = botSonIki(game, hand)
    } else if (ceza === 'rifki') {
      nextCardId = botRifki(game, hand)
    } else if (ceza === 'el') {
      nextCardId = botEl(game, hand)
    } else if (ceza === 'kiz') {
      nextCardId = botKiz(game, hand)
    } else if (ceza === 'erkek') {
      nextCardId = botErkek(game, hand)
    } else if (ceza === 'kupa') {
      nextCardId = botKupa(game, hand)
    } else { // other cezas - random
      nextCardId = botRandom(game, hand)
    }
  } else {
    throw new Error("Neither koz nor ceza determined. Did you mean to call botChooseTrump()?");
  }
  if (!isInvalid(nextCardId, game, hand)) {
    return nextCardId
  } else {
    throw new Error("Bot chose an invalid card!"+game+hand);
  }
}


function botRandom(game, hand) {
  let cardId = null
  for (const id in hand) {
    if (!isInvalid(id, game, hand)) {
      cardId = id
      break
    }
  }
  if (cardId) {
    return cardId
  } else {
    throw new Error("No available play. Something is wrong.");
  }
}

function botKoz(game, hand) {
// tableSuit elinde varsa
  // 1 - son oyuncuysan bir yuksegini at
  // 2 - degilsen
  // 2.1 - oyundaki en yuksek kart elinde degilse bir yuksegini at
  // 2.2 - oyundaki en yuksek kart elindeyse onu at

  // tableSuit elinde yoksa
  // 1 - kazanacak en kucuk kozu at
  // 1.1 - kazanacak kart yoksa en kucugunu at
  // 2 - koz yoksa elindeki en kucuk rank li karti at

  // Ilk atansa
  // 1 - Olmuslari topla
  // 2 - Olmus yoksa en az suitin en kucugunu at (Koz haric)
  // 3 - KOzun en kucugunu at

  const tableSuit = game.table.tableSuit;
  const suitsInHand = suitsInHandFunction(hand)
  const seat = game.table.whoseTurn;
  let nextCardId;


  let pCards = playedCards(game)

  if (tableSuit) {
    if (suitsInHand.includes(tableSuit)) {
      if (Object.keys(game.table.cards).length === 3) {
        //en sonsan en kucukten baslayarak, ilk winner karti at
        const orderedHand = orderHand(hand, tableSuit)
        nextCardId = hand[orderedHand[0]]
        for (const myCardId of orderedHand) {
          if (isWinnerCard(seat, hand[myCardId], game.table)) {
            nextCardId = hand[myCardId]
            break
          }
        }
      } else { //en son degilsen
        const ripeForTableSuit = ripeCards(game, hand)
          .filter((cardId) => deck[cardId].suit === tableSuit)
        if (ripeForTableSuit.length > 0) {
          nextCardId = ripeForTableSuit[0]
        } else {
          const orderedHand = orderHand(hand, tableSuit)
          nextCardId = hand[orderedHand[0]]
          for (const myCardId of orderedHand) {
            if (isWinnerCard(seat, hand[myCardId], game.table)) {
              nextCardId = hand[myCardId]
              break
            }
          }
        }
      }
    } else { //elde tableSuit yoksa - koz cakilacak
      if (suitsInHand.includes(game.table.koz)) {
        const orderedHand = orderHand(hand, game.table.koz)
        //en kucukten baslayarak, ilk winner karti at
        for (const myCardId of orderedHand) {
          if (isWinnerCard(seat, myCardId, game.table)) {
            nextCardId = myCardId
            break
          }
        }
        // yoksa en kucuk koz
        if (!nextCardId) {
          nextCardId = orderHand(hand, game.table.koz)[0]
        }
      } else {
        // koz yoksa en kucuk kart
        nextCardId = orderHand(hand)[0]
      }
    }
  }
  else { //Eli belirleyen ise - Olmuslari topla, yoksa en kisa elin kucugunu at (koz olmayan), sonra kozun en kucugu
    nextCardId = ''
    if (ripeCards(game, hand).length > 0) {
      let ripeForTableSuit = ripeCards(game, hand)
      if (!game.table.kozRevealed) {
        ripeForTableSuit = ripeForTableSuit.filter(x => deck[x].suit !== game.table.koz)
      }
      for (const rcid of ripeForTableSuit) {
        if (howMany(pCards, 'suit', deck[rcid].suit) < 5) {
          nextCardId = rcid;
          break
        }
      }
    }
    const excludedSuits = ripeCards(game, hand).map(x => deck[x].suit).concat(game.table.koz)
    const possibleSuits = ['spades', 'hearts', 'clubs', 'diamonds'].filter(x => !excludedSuits.includes(x))

    if (nextCardId === '' && orderSuitsByLength(hand, possibleSuits).length > 0) {
      for (const s of orderSuitsByLength(hand, possibleSuits)) {
        if (howMany(pCards, 'suit', s) < 9) {
          nextCardId = orderHand(hand, s)[0]
          break
        }
      }
      if (nextCardId === '') {
        nextCardId = orderHand(hand, orderSuitsByLength(hand, possibleSuits)[0])[0]
      }
    } else if (nextCardId === '' && ripeCards(game, hand).length > 0) {
      if (ripeCards(game, hand).filter(x => deck[x].suit !== game.table.koz).length > 0) {
        nextCardId = ripeCards(game, hand).filter(x => deck[x].suit !== game.table.koz)[0]
      } else {
        nextCardId = ripeCards(game, hand).filter(x => deck[x].suit === game.table.koz)[0]
      }
    } else if (nextCardId === '') {
      nextCardId = orderHand(hand, game.table.koz)[0]
    }
  }
  if (nextCardId) {
    return nextCardId
  } else {
    throw new Error("No card is chosen in Koz.")
  }
}

function botKupa(game, hand) {
  if (!game.table.cards) {
    game.table.cards = {}
  }
  const tableCards = game.table.cards; // always initialize game.table.cards= {}
  const tableSuit = game.table.tableSuit;
  const tableSuitInHand = Object.keys(hand).filter(handCardId => deck[handCardId].suit === tableSuit).length > 0;
  const suitsInHand = suitsInHandFunction(hand)

  let pCards = playedCards(game)

  const unFinishedS = unFinishedSuits(game, hand)

  const nDeck = normalDeck(pCards)
  const nHand = normalHand(hand, nDeck)
  // const orderSuits = orderSuitsByPlayed(hand, game, unFinishedS).reverse();

  const pCardsBySuit = playedCardsBySuit(game)
  const pCardsBySuitWOTable = playedCardsBySuitWOTable(game)
  const kupaOnTable = Object.keys(tableCards).map(x => tableCards[x]).filter(id => deck[id].suit === 'hearts').length > 0;

  const bigOT = biggestOnTable(tableSuit, tableCards);
  const kupaRevealed = game.table.kozRevealed;

  let nextCard;
  // 1 - Ilk oynayan
  if (Object.keys(tableCards).length === 0) {
    if (kupaRevealed) { // 1.1 Kupa cikmis
      // 1.1.1 spam hearts if you have small hearts
      if (orderHand(hand, 'hearts').length > 0) {
        if (pCardsBySuit['hearts'].length < 6) {
          nextCard = Object.keys(nHand).filter(x => deck[x].suit === 'hearts').filter(x => nHand[x] === 4)[0]
          if (!nextCard) {
            nextCard = Object.keys(nHand).filter(x => deck[x].suit === 'hearts').filter(x => nHand[x] === 3)[0]
          }
          if (!nextCard) {
            nextCard = Object.keys(nHand).filter(x => deck[x].suit === 'hearts').filter(x => nHand[x] === 2)[0]
          }
        } else if (pCardsBySuit['hearts'].length < 10) {
          nextCard = Object.keys(nHand).filter(x => deck[x].suit === 'hearts').filter(x => nHand[x] === 3)[0]
          if (!nextCard) {
            nextCard = Object.keys(nHand).filter(x => deck[x].suit === 'hearts').filter(x => nHand[x] === 2)[0]
          }
        } else { // pCardsBySuit['hearts'].length >= 10
          nextCard = Object.keys(nHand).filter(x => deck[x].suit === 'hearts').filter(x => nHand[x] === 2)[0]
        }
      }
      // 1.1.2 <6 olanlardan en buyugu at, yoksa <10 3,2, yoksa 2
      if (!nextCard) {
        for (const s of orderSuitsByLength(hand, unFinishedS)) {
          if (pCardsBySuit[s].length < 6) {
            nextCard = orderHand(hand, s).pop()
          } else if (pCardsBySuit[s].length < 10) {
            nextCard = Object.keys(nHand).filter(x => deck[x].suit === s).filter(x => nHand[x] === 3)[0]
            if (!nextCard) {
              nextCard = Object.keys(nHand).filter(x => deck[x].suit === s).filter(x => nHand[x] === 2)[0]
            }
          } else {
            nextCard = Object.keys(nHand).filter(x => deck[x].suit === s).filter(x => nHand[x] === 2)[0]
          }
        }
      }
      if (!nextCard) { // 1.1.3 unFinished olanlardan en az oynananin eldeki en kucugu, kendi elindeki availbilitye gore
        const candidateSuits = ['hearts', 'spades', 'clubs', 'diamonds'].filter(x => unFinishedS.includes(x)).filter(x => suitsInHand.includes(x))
        const s = orderSuitsByAvailabilityInHandAndPlayed(hand, pCards, candidateSuits)[0]
        if (s) {
          nextCard = orderHand(hand, s)[0]
        }
      }
      if (!nextCard) { // 1.1.4 from all olanlardan en az oynananin eldeki en kucugu, kendi elindeki availbilitye gore
        const candidateSuits = ['hearts', 'spades', 'clubs', 'diamonds'].filter(x => suitsInHand.includes(x))
        const s = orderSuitsByAvailabilityInHandAndPlayed(hand, pCards, candidateSuits)[0]
        if (s) {
          nextCard = orderHand(hand, s)[0]
        }
      }
    } else { //kupa not Revealed
      // 1.2.1 <6 olanlardan en buyugu at, yoksa <10 3,2, yoksa 2
      for (const s of orderSuitsByLength(hand, unFinishedS).filter(x => x !== 'hearts')) {
        if (pCardsBySuit[s].length < 6) {
          nextCard = orderHand(hand, s).pop()
          break
        }
      }
      if (!nextCard) {
        for (const s of orderSuitsByLength(hand, unFinishedS).filter(x => x !== 'hearts')) {
          if (pCardsBySuit[s].length < 10) {
            nextCard = Object.keys(nHand).filter(x => deck[x].suit === s).filter(x => nHand[x] === 3)[0]
            if (nextCard) {
              break
            }
            nextCard = Object.keys(nHand).filter(x => deck[x].suit === s).filter(x => nHand[x] === 2)[0]
            if (nextCard) {
              break
            }
          }
        }
      }
      if (!nextCard) {
        for (const s of orderSuitsByLength(hand, unFinishedS).filter(x => x !== 'hearts')) {
          if (pCardsBySuit[s].length >= 10) {
            nextCard = Object.keys(nHand).filter(x => deck[x].suit === s).filter(x => nHand[x] === 2)[0]
            if (nextCard) {
              break
            }
          }
        }
      }

      if (!nextCard) { // 1.2.2 unFinished olanlardan en az oynananin eldeki en kucugu, kendi elindeki availbilitye gore
        const candidateSuits = ['spades', 'clubs', 'diamonds'].filter(x => unFinishedS.includes(x)).filter(x => suitsInHand.includes(x))

        const s = orderSuitsByAvailabilityInHandAndPlayed(hand, pCards, candidateSuits)[0]
        if (s) {
          nextCard = orderHand(hand, s)[0]
        }
      }
      if (!nextCard) { // 1.2.3 hepsinden  en az oynananin eldeki en kucugu, kendi elindeki availbilitye gore

        const candidateSuits = ['spades', 'clubs', 'diamonds'].filter(x => suitsInHand.includes(x))

        const s = orderSuitsByAvailabilityInHandAndPlayed(hand, pCards, candidateSuits)[0]
        if (s) {
          nextCard = orderHand(hand, s)[0]
        }
      }
      if (!nextCard) { // 1.2.4 Mecbur kupa at, en kucugu
        const s = orderSuitsByAvailabilityInHandAndPlayed(hand, pCards, ['hearts'])[0]
        if (s) {
          nextCard = orderHand(hand, s)[0]
        }
      }
    }
  } else { // 2 Ilk baslayan degilse
    if (tableSuitInHand) { // 2.1 tableSuit eldeyse
      if (kupaOnTable) { //2.1.1 Yerde kupa varsa altina gir, giremiyorsan en sonsa en yuksegi at, yoksa bir ustunu at
        nextCard = orderHand(hand, tableSuit).filter(x => deck[x].rank < deck[bigOT].rank).pop()
        if (!nextCard) {
          if (Object.keys(tableCards).length !== 3) {
            nextCard = orderHand(hand, tableSuit)[0]
          } else {
            nextCard = orderHand(hand, tableSuit).pop()
          }
        }
      } else { //2.1.2 Elde kupa yoksa, eldeki en kisadan cikan miktarina gore at
        if (pCardsBySuitWOTable[tableSuit].length < 6) {
          nextCard = orderHand(hand, tableSuit).pop()
        } else {
          nextCard = orderHand(hand, tableSuit).filter(x => deck[x].rank < deck[bigOT].rank).pop()
          if (!nextCard) {
            if (Object.keys(tableCards).length !== 3) {
              nextCard = orderHand(hand, tableSuit)[0]
            } else {
              nextCard = orderHand(hand, tableSuit).pop()
            }
          }
        }
      }
    } else { // 2.2 tableSuit elde yoksa
      if (Object.keys(hand).filter(x => deck[x].suit === 'hearts').length > 0) {
        nextCard = orderHand(hand, 'hearts').pop()
      } else if (ripeCards(game, hand).length > 0) {
        nextCard = ripeCards(game, hand).sort(x => deck[x].rank).pop()
      } else {
        // const unFinishedS = unFinishedSuits(game, hand)
        nextCard = biggestFromUnfinishedShortestSuit(hand, unFinishedS)
      }
    }
  }
  if (nextCard) {
    return nextCard
  } else {
    throw new Error("No card is chosen in Kupa Almaz.")
  }
}

function botErkek(game, hand) {
  if (!game.table.cards) {
    game.table.cards = {}
  }
  const tableCards = game.table.cards; // always initialize game.table.cards= {}
  const tableSuit = game.table.tableSuit;
  const tableSuitInHand = Object.keys(hand).filter(handCardId => deck[handCardId].suit === tableSuit).length > 0;

  let pCards = [];
  if (game.history && game.history.playedCards) {
    pCards = Object.keys(game.history.playedCards);
  }
  const unFinishedS = unFinishedSuits(game, hand)

  const kJNotFinished = ['spades', 'hearts', 'clubs', 'diamonds']
    .filter(x => !pCards.includes(mapRank2Id(x, 11)) || !pCards.includes(mapRank2Id(x, 13)))
  const jNotPlayed = ['spades', 'hearts', 'clubs', 'diamonds']
    .filter(x => !pCards.includes(mapRank2Id(x, 11)))
  const kJInHand = Object.keys(hand)
    .filter(x => deck[x].rank === 11 || deck[x].rank === 13)
  const kJNotInHand = ['spades', 'hearts', 'clubs', 'diamonds']
    .filter(x => !Object.keys(hand).includes(mapRank2Id(x, 11)) && !Object.keys(hand).includes(mapRank2Id(x, 13)))
  const kJNotInHandAndNotFinished = kJNotInHand.filter(x => kJNotFinished.includes(x))

  const handWOAK = {}
  for (const id of Object.keys(hand).filter(x => deck[x].rank !== 13 && deck[x].rank !== 14)) {
    if (deck[id].rank === 12) {
      if (pCards.includes(mapRank2Id(deck[id].suit, 11))) {
        handWOAK[id] = id
      }
    } else {
      handWOAK[id] = id
    }
  }

  const nDeck = normalDeck(pCards)
  const nHand = normalHand(hand, nDeck)
  // const orderSuits = orderSuitsByPlayed(hand, game, unFinishedS).reverse();

  const pCardsBySuit = playedCardsBySuit(game)
  const bigOT = biggestOnTable(tableSuit, tableCards);

  let nextCard;
  // 1 - Ilk oynayan
  if (Object.keys(tableCards).length === 0) {
    // 1.0 - Elde KJ olanlar nHand'de === 2 ise
    if (kJInHand.length > 0) {
      for (const kj of kJInHand) {
        if (nHand[kj] === 2) {
          nextCard = kj;
        }
      }
    }

    // 1.1 - Elde KJ olmayanlardan ve KJ bitmemis ise
    // 1.1.1 - (Elde AKQJ harici (ya da J cikmissa AK) - cikan/4) en fazla olan en buyukten baslayarak
    if (!nextCard && kJNotInHandAndNotFinished.length > 0) {
      const suit = orderSuitsByAvailabilityInHandAndPlayed(handWOAK, pCards, kJNotInHandAndNotFinished).pop()
      nextCard = Object.keys(handWOAK).filter(x => deck[x].suit === suit).sort((a, b) => deck[a].rank - deck[b].rank).pop()
    }
    if (!nextCard) { // 1.2 - 6 taneden az cikmislardan Q varsa ve J cikmissa at
      const candidateSuits = ['hearts', 'spades', 'clubs', 'diamonds'].filter(x => pCardsBySuit[x].length < 6).filter(x => unFinishedS.includes(x))
      if (Object.keys(nHand).filter(x => candidateSuits.includes(deck[x].suit)).filter(x => deck[x].rank === 12).length > 0) {
        for (const id of Object.keys(nHand).filter(x => candidateSuits.includes(deck[x].suit)).filter(x => deck[x].rank === 12)) {
          if (pCards.includes(mapRank2Id(deck[id].suit, 11))) {
            nextCard = id;
            break;
          }
        }
      }
    }
    if (!nextCard) { // 1.3 - 6 taneden az cikmislardan AKQJ haric en buyuk
      const candidateSuits = ['hearts', 'spades', 'clubs', 'diamonds'].filter(x => pCardsBySuit[x].length < 6).filter(x => unFinishedS.includes(x))
      nextCard = Object.keys(nHand)
        .filter(x => candidateSuits.includes(deck[x].suit)).filter(x => deck[x].rank < 11)
        .sort((a, b) => nHand[a] - nHand[b]).pop()
    }
    // 1.4 - 10 taneden az cikmissa bitmemislerden oyunda normalized en kucuk ikinci (KJ degilse), sonra en kucuk
    if (!nextCard) {
      const candidateS = Object.keys(pCardsBySuit).filter(x => pCardsBySuit[x].length < 10).map(x => unFinishedS.includes(x))
      nextCard = Object.keys(nHand)
        .filter(x => candidateS.includes(deck[x].suit))
        .filter(x => deck[x].rank !== 11 && deck[x].rank !== 13)
        .filter(x => nHand[x] === 3)
        .sort((a, b) => pCardsBySuit[deck[a].suit].length - pCardsBySuit[deck[b].suit].length)[0]
    }
    if (!nextCard) {
      const candidateS = Object.keys(pCardsBySuit).filter(x => pCardsBySuit[x].length < 10).map(x => unFinishedS.includes(x))
      nextCard = Object.keys(nHand)
        .filter(x => candidateS.includes(deck[x].suit))
        .filter(x => nHand[x] === 2)
        .sort((a, b) => pCardsBySuit[deck[a].suit].length - pCardsBySuit[deck[b].suit].length)[0]
    }
    if (!nextCard) { 	// 1.5 - tum bitmemislerden Normalize en kucuk olani (K J olmayan)
      nextCard = Object.keys(nHand)
        .filter(x => unFinishedS.includes(deck[x].suit))
        .filter(x => deck[x].rank !== 11 && deck[x].rank !== 13)
        .sort((a, b) => nHand[a] - nHand[b])[0]
    }
    if (!nextCard) { 	// 1.6 - hepsinden normalize en kucuk (KJ olmayan)
      nextCard = Object.keys(nHand)
        .filter(x => deck[x].rank !== 11 && deck[x].rank !== 13)
        .sort((a, b) => nHand[a] - nHand[b])[0]
    }
    if (!nextCard) { // 1.7 - hepsinden normalize en kucuk
      nextCard = Object.keys(nHand)
        .sort((a, b) => nHand[a] - nHand[b])[0]
    }
  } else { // Ilk degilse
    if (tableSuitInHand) {
      // 2.1.0 - K icin, yerde A varsa, J icin yerde AKQ varsa yedir
      if (deck[bigOT].rank === 14 && Object.keys(hand).includes(mapRank2Id(deck[bigOT].suit, 13))) {
        nextCard = mapRank2Id(deck[bigOT].suit, 13)
      }
      if ((deck[bigOT].rank === 14 || deck[bigOT].rank === 13 || deck[bigOT].rank === 12) && Object.keys(hand).includes(mapRank2Id(deck[bigOT].suit, 11))) {
        nextCard = mapRank2Id(deck[bigOT].suit, 11)
      }
      if (Object.keys(tableCards).length !== 3) {
        nextCard = Object.keys(hand).filter(x => deck[x].suit === tableSuit && deck[x].rank < deck[bigOT].rank)
          .sort((a, b) => deck[a].rank - deck[b].rank).pop()
        // 2.1.1.2 - En kucuk (KJ haric)
        if (!nextCard) {
          nextCard = Object.keys(hand).filter(x => deck[x].suit === tableSuit && (deck[x].rank !== 11 || deck[x].rank !== 13))
            .sort((a, b) => deck[a].rank - deck[b].rank)[0]
        }
      } else { // 2.1.2 - En sonsa
        // 2.1.2.1 - Buyugun altina gir, en buyuk
        nextCard = Object.keys(hand).filter(x => deck[x].suit === tableSuit && deck[x].rank < deck[bigOT].rank)
          .sort((a, b) => deck[a].rank - deck[b].rank).pop()
        // 2.1.2.2 - EN yuksekle al (Q haric)
        if (!nextCard) {
          nextCard = Object.keys(hand).filter(x => deck[x].suit === tableSuit && (deck[x].rank !== 11 || deck[x].rank !== 13))
            .sort((a, b) => deck[a].rank - deck[b].rank).pop()
        }
      }
      // 2.1.2.3 - ilk J ile al
      if (!nextCard) {
        nextCard = mapRank2Id(deck[bigOT].suit, 11)
      }
      // 2.1.2.3 - then K ile al
      if (!nextCard) {
        nextCard = mapRank2Id(deck[bigOT].suit, 13)
      }
    } else { // no tableSuit
      // 2.2 - Yoksa
      // 2.2.1 - K lilerden en kisa olaninkinden
      nextCard = Object.keys(hand).filter(x => deck[x].rank === 13)
        .sort((a, b) => pCardsBySuit[deck[a].suit].length - pCardsBySuit[deck[b].suit].length)[0]
      // 2.2.2 - J lilerden en kisa olaninkinden
      if (!nextCard) {
        nextCard = Object.keys(hand).filter(x => deck[x].rank === 11)
          .sort((a, b) => pCardsBySuit[deck[a].suit].length - pCardsBySuit[deck[b].suit].length)[0]
      }
      // 2.2.3 - KJ bitmemislerden A
      if (!nextCard) {
        nextCard = Object.keys(hand)
          .filter(x => kJNotFinished.includes(deck[x].suit))
          .filter(x => deck[x].rank === 14)
          .sort((a, b) => pCardsBySuit[deck[a].suit].length - pCardsBySuit[deck[b].suit].length)[0]
      }
      // 2.2.4 - J cikmamislardan Q
      if (!nextCard) {
        nextCard = Object.keys(hand)
          .filter(x => jNotPlayed.includes(deck[x].suit))
          .filter(x => deck[x].rank === 12)
          .sort((a, b) => pCardsBySuit[deck[a].suit].length - pCardsBySuit[deck[b].suit].length)[0]
      }
      // 2.2.5 - Hepsinden AKQ (bitmemisse)
      if (!nextCard) {
        nextCard = Object.keys(hand)
          .filter(x => deck[x].rank === 14)
          .sort((a, b) => pCardsBySuit[deck[a].suit].length - pCardsBySuit[deck[b].suit].length)[0]
      }
      if (!nextCard) {
        nextCard = Object.keys(hand)
          .filter(x => deck[x].rank === 13)
          .sort((a, b) => pCardsBySuit[deck[a].suit].length - pCardsBySuit[deck[b].suit].length)[0]
      }
      if (!nextCard) {
        nextCard = Object.keys(hand)
          .filter(x => deck[x].rank === 12)
          .sort((a, b) => pCardsBySuit[deck[a].suit].length - pCardsBySuit[deck[b].suit].length)[0]
      }
      // 2.2.6 - 8den fazla cikmislardan en kucuk normalized iki haric buyukten baslayarak, bitmemislerden
      if (!nextCard) {
        const candSuits = Object.keys(pCardsBySuit)
          .filter(x => pCardsBySuit[x].length >= 8 && pCardsBySuit[x].length < 11)
          .map(x => unFinishedS.includes(x))
        nextCard = Object.keys(hand)
          .filter(x => candSuits.includes(deck[x].suit))
          .filter(x => nHand[x] > 3)
          .sort((a, b) => nHand[a] - nHand[b]).pop()
      }
      // 2.2.7 - 10dan fazla cikmislardan en kucuk normalized haric buyukten baslayarak
      if (!nextCard) {
        const candSuits = Object.keys(pCardsBySuit).filter(x => pCardsBySuit[x].length > 10).map(x => unFinishedS.includes(x))
        nextCard = Object.keys(hand)
          .filter(x => candSuits.includes(deck[x].suit))
          .filter(x => nHand[x] > 2)
          .sort((a, b) => nHand[a] - nHand[b]).pop()
      }
      // 2.2.8 - Hepsinden en kucuk normalized haric en buyuk
      if (!nextCard) {
        const candSuits = Object.keys(pCardsBySuit).map(x => unFinishedS.includes(x))
        nextCard = Object.keys(hand)
          .filter(x => candSuits.includes(deck[x].suit))
          .filter(x => nHand[x] > 2)
          .sort((a, b) => nHand[a] - nHand[b]).pop()
      }
      // 2.2.9 - Hepsinden en buyuk normalized
      if (!nextCard) {
        nextCard = Object.keys(hand)
          .sort((a, b) => nHand[a] - nHand[b]).pop()
      }
    }

  }
  if (nextCard) {
    return nextCard
  } else {
    throw new Error("No card is chosen in Erkek Almaz")
  }
}

function botKiz(game, hand) {
  // 1 - Ilk oynayan
  // 1.0 - Elde Q olanlar nHand'de === 2 ise
  // 1.1 - Elde Q olmayanlardan ve Q atilmamis
  // 1.1.1 - (Elde AK harici - cikan/4) en fazla olan en buyukten baslayarak
  // 1.2 - 6 taneden az cikmislardan AKQ haric en buyuk
  // 1.3 - 10 taneden az cikmissa bitmemislerden oyunda en normalized kucuk ikinci, sonra en kucuk
  // 1.4 - tum bitmemislerden Normalize en kucuk olani (Q olmayan)
  // 1.5 - hepsinden normalize en kucuk (Q olmayan)
  // 1.6 - hepsinden normalize en kucuk
  // 2 - Ilk oynayan degilse
  // 2.1.0 - En buyuk AK ve elde Q varsa, Q at
  // 2.1 - Varsa
  // 2.1.1 - En son degilse
  // 2.1.1.1 - Buyugun altina gir, en buyuk
  // 2.1.1.2 - En kucuk
  // 2.1.2 - En sonsa
  // 2.1.2.1 - Buyugun altina gir, en buyuk
  // 2.1.2.2 - EN yuksekle al (Q haric)
  // 2.1.2.3 - Q ile al
  // 2.2 - Yoksa
  // 2.2.1 - Kizlardan en kisa olani
  // 2.2.2 - Kiz cikmamislardan AK
  // 2.2.3 - Hepsinden AK
  // 2.2.4 - 8den fazla cikmislardan en kucuk normalized iki haric buyukten baslayarak
  // 2.2.5 - 10dan fazla cikmislardan en kucuk normalized haric buyukten baslayarak
  // 2.2.6 - Hepsinden en kucuk normalized haric en buyuk
  // 2.2.7 - Hepsinden en buyuk normalized

  if (!game.table.cards) {
    game.table.cards = {}
  }
  const tableCards = game.table.cards; // always initialize game.table.cards= {}
  const tableSuit = game.table.tableSuit;
  const tableSuitInHand = Object.keys(hand).filter(handCardId => deck[handCardId].suit === tableSuit).length > 0;

  let pCards = playedCards(game)

  const unFinishedS = unFinishedSuits(game, hand)

  const qNotPlayed = ['spades', 'hearts', 'clubs', 'diamonds'].filter(x => !pCards.includes(mapRank2Id(x, 12)))
  const qInHand = ['spades', 'hearts', 'clubs', 'diamonds'].filter(x => Object.keys(hand).includes(mapRank2Id(x, 12)))
  const qNotInHand = ['spades', 'hearts', 'clubs', 'diamonds'].filter(x => !Object.keys(hand).includes(mapRank2Id(x, 12)))

  const qNotInHandAndNotPlayed = qNotInHand.filter(x => qNotPlayed.includes(x))
  const handWOAK = {}
  for (const id of Object.keys(hand).filter(x => deck[x].rank !== 13 && deck[x].rank !== 14)) {
    handWOAK[id] = id
  }

  const nDeck = normalDeck(pCards)
  const nHand = normalHand(hand, nDeck)
  // const orderSuits = orderSuitsByPlayed(hand, game, unFinishedS).reverse();

  const pCardsBySuit = playedCardsBySuit(game)
  const bigOT = biggestOnTable(tableSuit, tableCards);

  let nextCard;
  if (Object.keys(tableCards).length === 0) {
    // 1 - Ilk oynayan
    // 1.0
    if (qInHand.length > 0) {
      for (const id of qInHand) {
        if (nHand[id] === 2) {
          nextCard = id
        }
      }
    }
    // 1.1 - Elde Q olmayanlardan ve Q atilmamis
    // 1.1.1 - (Elde AK harici - cikan/4) en fazla olan en buyukten baslayarak // 1.2 - 6 taneden az cikmislardan AKQ haric en buyuk
    if (!nextCard && qNotInHandAndNotPlayed.length > 0) {
      const suit = orderSuitsByAvailabilityInHandAndPlayed(handWOAK, pCards, qNotInHandAndNotPlayed).pop()
      nextCard = Object.keys(handWOAK).filter(x => deck[x].suit === suit).sort((a, b) => deck[a].rank - deck[b].rank).pop()
    }
    if (!nextCard) { // 1.2 - 6 taneden az cikmislardan AKQ haric en buyuk
      const candidateSuits = ['hearts', 'spades', 'clubs', 'diamonds'].filter(x => pCardsBySuit[x].length < 6).filter(x => unFinishedS.includes(x))
      nextCard = Object.keys(nHand)
        .filter(x => candidateSuits.includes(deck[x].suit)).filter(x => deck[x].rank < 12).sort((a, b) => nHand[a] - nHand[b]).pop()
      if (!nextCard) { 	// 1.3 - 10 taneden az cikmissa bitmemislerden oyunda en normalized kucuk ikinci, sonra en kucuk
        const candidateS = Object.keys(pCardsBySuit).filter(x => pCardsBySuit[x].length < 10).map(x => unFinishedS.includes(x))
        nextCard = Object.keys(nHand)
          .filter(x => candidateS.includes(deck[x].suit))
          .filter(x => nHand[x] === 3)
          .sort((a, b) => pCardsBySuit[deck[a].suit].length - pCardsBySuit[deck[b].suit].length)[0]
      }
      if (!nextCard) {
        const candidateS = Object.keys(pCardsBySuit).filter(x => pCardsBySuit[x].length < 10).map(x => unFinishedS.includes(x))
        nextCard = Object.keys(nHand)
          .filter(x => candidateS.includes(deck[x].suit))
          .filter(x => nHand[x] === 2)
          .sort((a, b) => pCardsBySuit[deck[a].suit].length - pCardsBySuit[deck[b].suit].length)[0]
      }
      if (!nextCard) { 	// 1.4 - tum bitmemislerden Normalize en kucuk olani (Q olmayan)
        nextCard = Object.keys(nHand)
          .filter(x => unFinishedS.includes(deck[x].suit))
          .filter(x => deck[x].rank !== 12)
          .sort((a, b) => nHand[a] - nHand[b])[0]
      }
      if (!nextCard) { 	// 1.5 - hepsinden normalize en kucuk (Q olmayan)
        nextCard = Object.keys(nHand)
          .filter(x => deck[x].rank !== 12)
          .sort((a, b) => nHand[a] - nHand[b])[0]
      }
      if (!nextCard) { // 1.6 - hepsinden normalize en kucuk
        nextCard = Object.keys(nHand)
          .sort((a, b) => nHand[a] - nHand[b])[0]
      }
    }

  } else {
    if (tableSuitInHand) {
      // 2.1 - Varsa
      // 2.1.1 - En son degilse
      // 2.1.1.1 - Buyugun altina gir, en buyuk
      if (Object.keys(tableCards).length !== 3) {
        nextCard = Object.keys(hand).filter(x => deck[x].suit === tableSuit && deck[x].rank < deck[bigOT].rank)
          .sort((a, b) => deck[a].rank - deck[b].rank).pop()
        // 2.1.1.2 - En kucuk (Q haric)
        if (!nextCard) {
          nextCard = Object.keys(hand).filter(x => deck[x].suit === tableSuit && deck[x].rank !== 12)
            .sort((a, b) => deck[a].rank - deck[b].rank)[0]
        }
      } else { // 2.1.2 - En sonsa
        // 2.1.2.1 - Buyugun altina gir, en buyuk
        nextCard = Object.keys(hand).filter(x => deck[x].suit === tableSuit && deck[x].rank < deck[bigOT].rank)
          .sort((a, b) => deck[a].rank - deck[b].rank).pop()
        // 2.1.2.2 - EN yuksekle al (Q haric)
        if (!nextCard) {
          nextCard = Object.keys(hand).filter(x => deck[x].suit === tableSuit && deck[x].rank !== 12)
            .sort((a, b) => deck[a].rank - deck[b].rank).pop()
        }
      }

      //2.1.0 - En buyuk AK ve elde Q varsa, Q at
      if ([13, 14].includes(deck[bigOT].rank) && Object.keys(hand).includes(mapRank2Id(deck[bigOT].suit, 12))) {
        nextCard = mapRank2Id(deck[bigOT].suit, 12)
      }

      // 2.1.2.3 - Q ile al
      if (!nextCard) {
        nextCard = mapRank2Id(deck[bigOT].suit, 12)
      }
    } else {

      // 2.2 - Yoksa
      // 2.2.1 - Kizlardan en kisa olani
      nextCard = Object.keys(hand).filter(x => deck[x].rank === 12).sort((a, b) => pCardsBySuit[deck[a].suit].length - pCardsBySuit[deck[b].suit].length)[0]
      // 2.2.2 - Kiz cikmamislardan AK
      if (!nextCard) {
        nextCard = Object.keys(hand)
          .filter(x => qNotPlayed.includes(deck[x].suit))
          .filter(x => deck[x].rank === 14)
          .sort((a, b) => pCardsBySuit[deck[a].suit].length - pCardsBySuit[deck[b].suit].length)[0]
      }
      if (!nextCard) {
        nextCard = Object.keys(hand)
          .filter(x => qNotPlayed.includes(deck[x].suit))
          .filter(x => deck[x].rank === 13)
          .sort((a, b) => pCardsBySuit[deck[a].suit].length - pCardsBySuit[deck[b].suit].length)[0]
      }
      // 2.2.3 - Hepsinden AK
      if (!nextCard) {
        nextCard = Object.keys(hand)
          .filter(x => deck[x].rank === 14)
          .sort((a, b) => pCardsBySuit[deck[a].suit].length - pCardsBySuit[deck[b].suit].length)[0]
      }
      if (!nextCard) {
        nextCard = Object.keys(hand)
          .filter(x => deck[x].rank === 13)
          .sort((a, b) => pCardsBySuit[deck[a].suit].length - pCardsBySuit[deck[b].suit].length)[0]
      }
      // 2.2.4 - 8den fazla cikmislardan en kucuk normalized iki haric buyukten baslayarak, bitmemislerden
      if (!nextCard) {
        const candSuits = Object.keys(pCardsBySuit).filter(x => pCardsBySuit[x].length >= 8 && pCardsBySuit[x].length < 11).map(x => unFinishedS.includes(x))
        nextCard = Object.keys(hand)
          .filter(x => candSuits.includes(deck[x].suit))
          .filter(x => nHand[x] > 3)
          .sort((a, b) => nHand[a] - nHand[b]).pop()
      }
      // 2.2.5 - 10dan fazla cikmislardan en kucuk normalized haric buyukten baslayarak
      if (!nextCard) {
        const candSuits = Object.keys(pCardsBySuit).filter(x => pCardsBySuit[x].length > 10).map(x => unFinishedS.includes(x))
        nextCard = Object.keys(hand)
          .filter(x => candSuits.includes(deck[x].suit))
          .filter(x => nHand[x] > 2)
          .sort((a, b) => nHand[a] - nHand[b]).pop()
      }
      // 2.2.6 - Hepsinden en kucuk normalized haric en buyuk
      if (!nextCard) {
        const candSuits = Object.keys(pCardsBySuit).map(x => unFinishedS.includes(x))
        nextCard = Object.keys(hand)
          .filter(x => candSuits.includes(deck[x].suit))
          .filter(x => nHand[x] > 2)
          .sort((a, b) => nHand[a] - nHand[b]).pop()
      }
      // 2.2.7 - Hepsinden en buyuk normalized
      if (!nextCard) {
        nextCard = Object.keys(hand)
          .sort((a, b) => nHand[a] - nHand[b]).pop()
      }
    }
  }
  if (nextCard) {
    return nextCard
  } else {
    throw new Error("No card is chosen in Kiz Almaz")
  }
}

function botRifki(game, hand) {
  // 1- Ilk oynayan
  // 1.1-Kupa cikmamissa veya Rifki veya Kupa As eldeyse
  // 1.1.1 - En kisa olandan 6dan az ciktiysa en buyugunu at,
  // 1.1.2 - yoksa bir sonraki en kisaya gec, 6dan az ciktiysa en buyugunu at,
  // 1.1.3 - UnFinished olanlardan en kisa olanin kucugunu at
  // 1.1.4 - Kupanin en kucugunu
  // 1.1.5 - UnFinished olmayanlardan rank'e gore sirala at
  // 1.1.6 - Kupa As varsa at
  // 1.1.7 - Rifki varsa at
  // 1.2 - Kupa cikmissa ve As-Rifki baskasindaysa ve elde kupa varsa
  // 1.2.1 - Kupa durt buyukten baslayarak
  // 1.2.2 - Kupa yoksa 1.1.1, 1.1.2, 1.1.3, 1.1.4

  // 2 - IkiUcuncu oynayan
  // 2.1 - Tablesuit varsa
  // 2.1.1 - Ilk veya ikinci kez donuyorsa ve Rifki yerde yoksa en buyugunu at (As ve Rifki kupa haric)
  // 2.1.2 - Degilse en buyuk nonwinneri at
  // 2.2. - Tablesuit yoksa
  // 2.2.1 - Rifki varsa at
  // 2.2.2 - En buyuk kupadan baslayarak at
  // 2.2.3 - Olmus varsa at
  // 2.2.4 - En kisanin en buyugunu at

  // 3- Son oynayan
  // 3.1 - Tablesuit varsa
  // 3.1.1 - Rifki yerde yoksa En buyugunu at
  // 3.1.2 - Rifki varsa en kucugu at
  // 3.2 - Tablesuit yoksa
  // 3.2.1 - Kupa varsa EN buyukten baslayarak kupalari at
  // 3.2.2 - Olmuslardan buyuk rankliyi at
  // 3.2.3 - En kisanin en buyugunu at
  if (!game.table.cards) {
    game.table.cards = {}
  }
  const tableCards = game.table.cards; // always initialize game.table.cards= {}
  const tableSuit = game.table.tableSuit;
  const tableSuitInHand = Object.keys(hand).filter(handCardId => deck[handCardId].suit === game.table.tableSuit).length > 0;
  const kupaRevealed = game.table.kozRevealed;
  const rifkiOrAhInHand = Object.keys(hand).filter(handCardId => handCardId === '113' || handCardId === '114').length > 0;
  const rifkiInHand = Object.keys(hand).filter(handCardId => handCardId === '113').length > 0;
  const rifkiOnTable = Object.keys(tableCards).map(x => tableCards[x]).filter(id => id === '113').length > 0;
  const aceHOnTable = Object.keys(tableCards).map(x => tableCards[x]).filter(id => id === '114').length > 0;
  let pCards = playedCards(game)

  let nextCard;

  if (Object.keys(tableCards).length === 0) {
    if (!kupaRevealed || rifkiOrAhInHand) {
      const unFinishedS = unFinishedSuits(game, hand).filter(x => x !== 'hearts')

      for (const s of orderSuitsByLength(hand, unFinishedS)) {
        if (howMany(pCards, 'suit', s) < 6) {
          nextCard = orderHand(hand, s).pop()
          break
        }
      }
      if (!nextCard) {
        if (orderSuitsByLength(hand, unFinishedS).length > 0) {
          nextCard = orderHand(hand, orderSuitsByLength(hand, unFinishedS)[0])[0]
        } else if (kupaRevealed && orderHand(hand, 'hearts')) {
          if (orderHand(hand, 'hearts')[0] !== '114')
            nextCard = orderHand(hand, 'hearts')[0]
        }
        if (!nextCard) {
          if (orderSuitsByLength(hand, ['spades', 'clubs', 'diamonds']).length > 0) {
            nextCard = orderHand(hand, orderSuitsByLength(hand, ['spades', 'clubs', 'diamonds'])[0])[0]
          } else if (orderSuitsByLength(hand, ['hearts']).length > 0) {
            nextCard = orderHand(hand, 'hearts')[0]
          } else if (hand['114']) {
            nextCard = '114'
          } else if (hand['113']) {
            nextCard = '113'
          } else {
            throw new Error('Something is wrong in the code in rifki for no hearts played (or Rifki or ah in hand)')
          }
        }
      }
    } else {
      if (howMany(pCards, 'suit', 'hearts') > 0 && orderHand(hand, 'hearts').length > 0) {
        nextCard = orderHand(hand, 'hearts').pop()
      } else {
        const unFinishedS = unFinishedSuits(game, hand).filter(x => x !== 'hearts')
        for (const s of orderSuitsByLength(hand, unFinishedS)) {
          if (howMany(pCards, 'suit', s) < 6) {
            nextCard = orderHand(hand, s).pop()
          }
        }
        if (orderSuitsByLength(hand, unFinishedS).length > 0) {
          nextCard = orderHand(hand, orderSuitsByLength(hand, unFinishedS)[0])[0]
        } else if (orderSuitsByLength(hand, ['spades', 'clubs', 'diamonds']).length > 0) {

          nextCard = orderHand(hand, orderSuitsByLength(hand, ['spades', 'clubs', 'diamonds'])[0])[0]
        } else if (orderHand(hand, 'hearts').length > 0) {
          nextCard = orderHand(hand, 'hearts')[0]
        } else {
          throw new Error('Something is wrong in the code in rifki for hearts played (and no rifki or ah in hand thread)')
        }
      }
    }
  } else if (Object.keys(tableCards).length === 1 || Object.keys(tableCards).length === 2) {
    if (aceHOnTable && tableSuit === 'hearts' && orderHand(hand, tableSuit)[0]) {
      nextCard = orderHand(hand, tableSuit).pop()
    } else if (tableSuitInHand) {
      if (!rifkiOnTable) {
        if (howMany(pCards, 'suit', tableSuit) < 6) {
          const orderedKeys = orderHand(hand, tableSuit)

          let gts = orderedKeys.pop()
          while (gts) {
            if (gts === '113' || gts === '114') {
              gts = orderedKeys.pop()
            } else {
              nextCard = gts
              break
            }
          }
          if (!nextCard) {
            nextCard = orderHand(hand, tableSuit).pop()
          }
        } else if (biggestNonWinner(tableSuit, tableCards, hand) !== '') {
          nextCard = biggestNonWinner(tableSuit, tableCards, hand)
        } else {
          nextCard = orderHand(hand, tableSuit)[0]
        }
      } else {
        nextCard = orderHand(hand, tableSuit)[0]
      }
    } else {
      if (rifkiInHand) {
        nextCard = '113'
      } else if (rifkiOnTable) {
        if (orderHand(hand, 'hearts').length > 0) {
          nextCard = orderHand(hand, 'hearts')[0]
        } else {
          nextCard = orderHand(hand)[0]
        }
      } else if (howMany(Object.keys(hand), 'suit', 'hearts') > 0) {
        nextCard = orderHand(hand, 'hearts').pop()
      } else if (ripeCards(game, hand).length > 0) {
        nextCard = ripeCards(game, hand).sort(x => deck[x].rank).pop()
      } else {
        const unFinishedS = unFinishedSuits(game, hand)
        nextCard = biggestFromUnfinishedShortestSuit(hand, unFinishedS)
      }
    }
  } else {
    // 3- Son oynayan
    // 3.1 - Tablesuit varsa
    // 3.1.1 - Rifki yerde yoksa En buyugunu at
    // 3.1.2 - Rifki varsa en kucugu at
    // 3.2 - Tablesuit yoksa
    // 3.2.1 - Kupa varsa EN buyukten baslayarak kupalari at
    // 3.2.2 - Olmuslardan buyuk rankliyi at
    // 3.2.3 - En kisanin en buyugunu at
    if (tableSuitInHand) {
      if (aceHOnTable && tableSuit === 'hearts') {
        nextCard = orderHand(hand, tableSuit).pop()
      } else if (!rifkiOnTable) {
        nextCard = orderHand(hand, tableSuit).pop()
      } else {
        nextCard = orderHand(hand, tableSuit)[0]
      }
    } else {
      if (orderHand(hand, 'hearts').length > 0) {
        if (Object.keys(hand).includes('113')) {
          nextCard = '113'
        } else if (Object.keys(tableCards).map(x => tableCards[x]).includes('113')) {
          nextCard = orderHand(hand, 'hearts')[0]
        } else {
          nextCard = orderHand(hand, 'hearts').pop()
        }
      } else if (ripeCards(game, hand).length > 0) {
        nextCard = ripeCards(game, hand).sort(x => deck[x].rank).pop()
      } else {
        const unFinishedS = unFinishedSuits(game, hand)
        nextCard = biggestFromUnfinishedShortestSuit(hand, unFinishedS)
      }
    }
  }
  if (nextCard) {
    return nextCard
  } else {
    throw new Error("No card is chosen in Rifki")
  }
}

function botEl(game, hand) {
  // 1 - Ilk oynayan
  // 1.1 - Eli karsiya gecirecek en buyuk
  // 1.2 - Yoksa en uzunun en kucugu (unfinishedlerden ve cakilmiyorsa)
  // 1.3 - Cakilanlardan en uzunun kucugu

  // 2 - IkiUcuncu oynayan
  // 2.1 - Altina gir, en yuksek
  // 2.2 - Bir ustu
  // 2.3 - En kisanin en buyugu (smallestlar haric)
  // 2.4 - Smallestlar dahil en kisanin en buyugu (Unfinishedlerle)
  // 2.4 - Smallestlar dahil en kisanin en buyugu (Hepsi)

  // 3- Son oynayan
  // 3.1 - Altina gir, en yuksek
  // 3.2 - En buyukle al
  // 3.3 - En kisanin en buyugu (smallestlar haric)
  // 3.4 - Smallestlar dahil en kisanin en buyugu
  if (!game.table.cards) {
    game.table.cards = {}
  }
  const tableCards = game.table.cards; // always initialize game.table.cards= {}
  const tableSuit = game.table.tableSuit;
  const tableSuitInHand = Object.keys(hand).filter(handCardId => deck[handCardId].suit === tableSuit).length > 0;
  let pCards = playedCards(game)
  const unFinishedS = unFinishedSuits(game, hand)

  let nextCard;
  if (Object.keys(tableCards).length === 0) {
    const nDeck = normalDeck(pCards)
    const nHand = normalHand(hand, nDeck)
    const orderSuits = orderSuitsByPlayed(game, unFinishedS).reverse();

    const pCardsBySuit = playedCardsBySuit(game)

    let candidates = []


    for (const s of orderSuits) {
      if (Object.keys(nHand).filter(x => nHand[x] === 4).length > 0) {
        candidates = Object.keys(nHand).filter(x => nHand[x] === 4)
        if (candidates.filter(x => deck[x].suit === s && pCardsBySuit[deck[x].suit].length < 8).length > 0) {
          nextCard = candidates.filter(x => deck[x].suit === s)[0]
          break
        }
      } else if (Object.keys(nHand).filter(x => nHand[x] === 3).length > 0) {
        candidates = Object.keys(nHand).filter(x => nHand[x] === 3)
        if (candidates.filter(x => deck[x].suit === s && pCardsBySuit[deck[x].suit].length < 9).length > 0) {
          nextCard = candidates.filter(x => deck[x].suit === s)[0]
          break
        }
      } else if (Object.keys(nHand).filter(x => nHand[x] === 2).length > 0) {
        if (candidates.filter(x => deck[x].suit === s && pCardsBySuit[deck[x].suit].length < 12).length > 0) {
          nextCard = candidates.filter(x => deck[x].suit === s)[0]
          break
        }
      }
    }

    if (!nextCard && smallestFromLeastPlayed(hand, game, unFinishedS)) {
      nextCard = smallestFromLeastPlayed(hand, game, unFinishedS)
    }

    if (!nextCard && smallestFromUnfinishedLongestSuit(hand, unFinishedS)) {
      nextCard = smallestFromUnfinishedLongestSuit(hand, unFinishedS)
    }
    if (!nextCard && smallestFromUnfinishedLongestSuit(hand, ['spades', 'hearts', 'clubs', 'diamonds'])) {
      nextCard = smallestFromUnfinishedLongestSuit(hand, ['spades', 'hearts', 'clubs', 'diamonds'])
    }
    if (!nextCard) {
      throw new Error("Something is wrong in code here")
    }
  } else if (Object.keys(tableCards).length === 1 || Object.keys(tableCards).length === 2) {
    if (tableSuitInHand) {
      if (biggestNonWinner(tableSuit, tableCards, hand) !== '') {
        nextCard = biggestNonWinner(tableSuit, tableCards, hand)
      } else {
        nextCard = smallestWinner(tableSuit, tableCards, hand)
      }
    } else {
      const handWOSmallest = handWithoutSmallest(hand, pCards)
      if (biggestFromUnfinishedShortestSuit(handWOSmallest, unFinishedS) !== '') {
        nextCard = biggestFromUnfinishedShortestSuit(handWOSmallest, unFinishedS)
      } else if (biggestFromUnfinishedShortestSuit(handWOSmallest, ['spades', 'hearts', 'clubs', 'diamonds']) !== '') {
        nextCard = biggestFromUnfinishedShortestSuit(handWOSmallest, ['spades', 'hearts', 'clubs', 'diamonds'])
      } else if (biggestFromUnfinishedShortestSuit(hand, ['spades', 'hearts', 'clubs', 'diamonds']) !== '') {
        nextCard = biggestFromUnfinishedShortestSuit(hand, ['spades', 'hearts', 'clubs', 'diamonds'])
      } else {
        throw new Error('Somethings wrong here')
      }
    }
  } else {
    if (tableSuitInHand) {
      if (biggestNonWinner(tableSuit, tableCards, hand) !== '') {
        nextCard = biggestNonWinner(tableSuit, tableCards, hand)
      } else {
        nextCard = Object.keys(hand).filter(x => deck[x].suit === tableSuit)
          .sort((x, y) => deck[x].rank - deck[y].rank)
          .pop()
      }
    } else {
      const handWOSmallest = handWithoutSmallest(hand, pCards)
      if (biggestFromUnfinishedShortestSuit(handWOSmallest, unFinishedS) !== '') {
        nextCard = biggestFromUnfinishedShortestSuit(handWOSmallest, unFinishedS)
      } else if (biggestFromUnfinishedShortestSuit(handWOSmallest, ['spades', 'hearts', 'clubs', 'diamonds']) !== '') {
        nextCard = biggestFromUnfinishedShortestSuit(handWOSmallest, ['spades', 'hearts', 'clubs', 'diamonds'])
      } else if (biggestFromUnfinishedShortestSuit(hand, ['spades', 'hearts', 'clubs', 'diamonds']) !== '') {
        nextCard = biggestFromUnfinishedShortestSuit(hand, ['spades', 'hearts', 'clubs', 'diamonds'])
      } else {
        throw new Error('Somethings wrong here')
      }
    }
  }
  if (nextCard) {
    return nextCard
  } else {
    throw new Error("No card is chosen in Rifki")
  }
}

export function handWithoutSmallest(hand, pCards) {
  const handWOSmallest = {}
  for (const cardId in hand) {
    const suit = deck[cardId].suit
    for (let i = 2; i < deck[cardId].rank; i++) {
      if (!Object.keys(hand).includes(mapRank2Id(suit, i)) && !pCards.includes(mapRank2Id(suit, i))) {
        handWOSmallest[cardId] = cardId
        break
      }
    }
  }
  return handWOSmallest
}

// export function normalDeck(pCards) {
//   const nDeck = JSON.parse(JSON.stringify(deck_template));
//   for (const cardId of pCards) {
//     const suit = deck[cardId].suit
//     for (const suitCard of Object.keys(deck).filter(x => deck[x].suit === suit)) {
//       if (deck[suitCard].rank > deck[cardId].rank) {
//         nDeck[suitCard].rank -= 1
//       }
//     }
//   }
//   return nDeck
// }

export function normalHand(hand, nDeck) {
  const nHand = {}
  for (const cardId in hand) {
    nHand[cardId] = nDeck[cardId].rank
  }
  return nHand
}


function botSonIki(game, hand) {
  // 1- Son bes el degilse
  // 1.1- Ilk oynayansa
  // 1.1.1- Olmuslari topla
  // 1.1.2- Tek kalmayan (ve en suitsInPlaySmallInHand olmayan) suitlerden en kisa olanin buyugunu at
  // 1.1.3- (Once en suitsInPlaySmallInHand) Tek kalan suitlerden en buyuk id olani at
  // 1.2- Ilk degilse
  // 1.2.1- Tablesuit varsa En buyuk suiti at
  // 1.2.2- Yoksa olmuslardan at
  // 1.2.3- Tek kalmayan (ve en suitsInPlaySmallInHand) suitlerden en kisa olanin en buyugunu at
  // 1.2.4- Once Tek kalmayan sonra suitsInPlaySmallInHand
  // 2- Son bes el ise
  // 2.1- Ilk oynayansa
  // 2.1.1- Tek kalmayan (suitsInPlaySmallInHand olmayan) suitlerden en uzun olanin en kucugunu at
  // 2.1.2- (Once suitsInPlaySmallInHand en kucugu) Tek kalan suitlerden buyuk id olani at
  // 2.2- Ilk degilse
  // 2.2.1- TableSuit varsa En buyuk non-winner karti at
  // 2.2.1.1- Non-winner yoksa ve en son degilse en kucugunu at
  // 2.2.2.2- TableSuit yoksa Tek kalmayanlardan olmuslari at
  // 2.2.2.3- Olmus yoksa Tek kalmayanlardan en kisanin en buyugunu at
  // 2.2.2.1- Non-winner yoksa ve en sonsa en bugunu at
  // 2.2.2.2- Yoksa Olmus at
  // 2.2.2.3- Olmus da yoksa Tek kalmayanlardan en kisa olanin en buyugunu at
  // 2.2.2.4 - O da yoksa en buyuk idli olan Olmusu at

  const tableCards = game.table.cards;
  const tableSuit = game.table.tableSuit
  const tableRound = game.table.tableRound

  const unFinishedS = unFinishedSuits(game, hand)
  const suitsInPlaySmallInHand = suitsWithSmallestInHand(game, hand).filter(x => unFinishedS.includes(x))
  const unFinishedSButNotSmallestInHand = unFinishedS.filter(x => !suitsWithSmallestInHand(game, hand).includes(x))

  if (tableRound <= 8) {
    if (!tableCards || Object.keys(tableCards).length === 0) { // ilk oynayan
      const suitableRipeCards = ripeCards(game, hand)
        .filter(x => (unFinishedS.includes(deck[x].suit)) && (suitsInPlaySmallInHand.includes(deck[x].suit)))
      if (suitableRipeCards.length > 0) {
        return suitableRipeCards[0]
      } else if (biggestFromUnfinishedShortestSuit(hand, unFinishedSButNotSmallestInHand) !== '') {
        return biggestFromUnfinishedShortestSuit(hand, unFinishedSButNotSmallestInHand)
      } else if (suitsInPlaySmallInHand.length > 0) {
        const nextCard = Object.keys(hand).filter(x => deck[x].suit === suitsInPlaySmallInHand[0])
          .sort((x, y) => deck[x].rank - deck[y].rank)[0]
        if (nextCard) {
          return nextCard
        } else {
          throw new Error("Undefined returned.")
        }
      } else {
        const nextCard = Object.keys(hand)
          .sort((x, y) => deck[x].rank - deck[y].rank)[0]
        if (nextCard) {
          return nextCard
        } else {
          throw new Error("Undefined returned.")
        }
      }
    } else { // Ilk oynayan degilse
      const newCardId = Object.keys(hand).filter(x => deck[x].suit === tableSuit)
        .sort((x, y) => deck[x].rank - deck[y].rank).pop() // TableSuitten en buyuk
      if (newCardId) {
        return newCardId
      } else if (biggestFromUnfinishedShortestSuit(hand, unFinishedSButNotSmallestInHand) !== '') { // TableSuit yoksa
        return biggestFromUnfinishedShortestSuit(hand, unFinishedSButNotSmallestInHand)
      } else if (suitsInPlaySmallInHand.length > 0) { // Play from finished instead of suitsInPlaySmallInHand
        const nextCard = Object.keys(hand).filter(x => suitsInPlaySmallInHand.includes(deck[x].suit))
          .sort((x, y) => deck[x].rank - deck[y].rank)[0]
        if (nextCard) {
          return nextCard
        } else {
          throw new Error("Undefined returned.")
        }
      } else { // last resort: play from suitsInPlaySmallInHand
        const nextCard = Object.keys(hand).filter(x => suitsInPlaySmallInHand.includes(deck[x].suit))
          .sort((x, y) => deck[x].rank - deck[y].rank)[0]
        if (nextCard) {
          return nextCard
        } else {
          return Object.keys(hand).filter(x => suitsWithSmallestInHand(game, hand).includes(deck[x].suit))
            .sort((x, y) => deck[x].rank - deck[y].rank)[0]
        }
      }
    }
  } else { // tableRound > 8
    if (!tableCards || Object.keys(tableCards).length === 0) {
      if (suitsInPlaySmallInHand.length > 0) {
        const nextCard = Object.keys(hand).filter(x => deck[x].suit === suitsInPlaySmallInHand[0])
          .sort((x, y) => deck[x].rank - deck[y].rank)[0]
        if (nextCard) {
          return nextCard
        } else {
          throw new Error("Undefined returned.")
        }
      } else if (smallestFromUnfinishedShortestSuit(hand, unFinishedS)) {
        return smallestFromUnfinishedShortestSuit(hand, unFinishedS)
      } else {
        const nextCard = Object.keys(hand).sort((x, y) => deck[x].rank - deck[y].rank)[0]
        if (nextCard) {
          return nextCard
        } else {
          throw new Error("Undefined returned.")
        }
      }
    } else { // Ilk oynayan degilse
      const biggestNonW = biggestNonWinner(tableSuit, tableCards, hand)

      if (biggestNonW !== '') {
        return biggestNonW
      } else {
        if (Object.keys(tableCards).length < 3) {
          const smallestW = smallestWinner(tableSuit, tableCards, hand)

          if (smallestW !== '') {
            return smallestW
          } else {
            if (ripeCards(game, hand).filter(x => unFinishedSButNotSmallestInHand.includes(deck[x].suit)).length > 0) {
              const nextCard = ripeCards(game, hand)
                .filter(x => unFinishedSButNotSmallestInHand.includes(deck[x].suit))[0]
              if (nextCard) {
                return nextCard
              } else {
                throw new Error("Undefined returned.")
              }
            } else if (biggestFromUnfinishedShortestSuit(hand, unFinishedSButNotSmallestInHand) !== '') {
              return biggestFromUnfinishedShortestSuit(hand, unFinishedSButNotSmallestInHand)
            } else {
              const nextCard = Object.keys(hand).sort((x, y) => deck[x].rank - deck[y].rank).pop()
              if (nextCard) {
                return nextCard
              } else {
                throw new Error("Undefined returned.")
              }
            }
          }
        } else { // en son ise
          const biggestTableSuit = Object.keys(hand).filter(x => deck[x].suit === tableSuit).sort((x, y) => deck[x].rank - deck[y].rank).pop()
          if (biggestTableSuit) {
            return biggestTableSuit
          } else {
            if (ripeCards(game, hand).filter(x => unFinishedSButNotSmallestInHand.includes(deck[x].suit)).length > 0) {
              const nextCard = ripeCards(game, hand)
                .filter(x => unFinishedSButNotSmallestInHand.includes(deck[x].suit))[0]
              if (nextCard) {
                return nextCard
              } else {
                throw new Error("Undefined returned.")
              }
            } else if (biggestFromUnfinishedShortestSuit(hand, unFinishedSButNotSmallestInHand) !== '') {
              return biggestFromUnfinishedShortestSuit(hand, unFinishedSButNotSmallestInHand)
            } else {
              const nextCard = Object.keys(hand).sort((x, y) => deck[x].rank - deck[y].rank).pop()
              if (nextCard) {
                return nextCard
              } else {
                throw new Error("Undefined returned.")
              }
            }
          }
        }
      }
    }
  }
}

export function orderSuitsByAvailabilityInHandAndPlayed(handWOAK, pCards, qNotInHandAndNotPlayed) {
  const orderedSuits = {}
  for (const s of qNotInHandAndNotPlayed) {
    orderedSuits[s] = Object.keys(handWOAK).filter(x => deck[x].suit === s).length - pCards.filter(x => deck[x].suit === s).length / 4
  }
  return Object.keys(orderedSuits).sort((a, b) => orderedSuits[a] - orderedSuits[b])
}


function biggestFromUnfinishedShortestSuit(hand, unFinishedS) {
  if (orderSuitsByLength(hand, unFinishedS).length > 0) {
    const suit = orderSuitsByLength(hand, unFinishedS)[0];
    const orderedCardsOfSuit = Object.keys(hand)
      .filter(x => deck[x].suit === suit)
      .sort((x, y) => deck[x].rank - deck[y].rank)
    const nextCard = orderedCardsOfSuit.pop()
    if (nextCard) {
      return nextCard
    } else {
      return ''
    }
  } else {
    return ''
  }
}

function smallestFromLeastPlayed(hand, game, unFinishedS) {
  if (orderSuitsByPlayed(game, unFinishedS).length > 0) {
    const suit = orderSuitsByPlayed(game, unFinishedS)[0];
    const orderedCardsOfSuit = Object.keys(hand)
      .filter(x => deck[x].suit === suit)
      .sort((x, y) => deck[x].rank - deck[y].rank)
    return orderedCardsOfSuit[0]
  }
  return null
}

function smallestFromUnfinishedShortestSuit(hand, unFinishedS) {
  if (orderSuitsByLength(hand, unFinishedS).length > 0) {
    const suit = orderSuitsByLength(hand, unFinishedS)[0];
    const orderedCardsOfSuit = Object.keys(hand)
      .filter(x => deck[x].suit === suit)
      .sort((x, y) => deck[x].rank - deck[y].rank)
    return orderedCardsOfSuit[0]
  }
  return Object.keys(hand).filter(x => unFinishedS.includes(deck[x].suit))
    .sort((x, y) => parseInt(deck[x].id) - parseInt(deck[y].id))[0]
}

function smallestFromUnfinishedLongestSuit(hand, unFinishedS) {
  if (orderSuitsByLength(hand, unFinishedS).length > 0) {
    const suit = orderSuitsByLength(hand, unFinishedS)[0];
    const orderedCardsOfSuit = Object.keys(hand)
      .filter(x => deck[x].suit === suit)
      .sort((x, y) => deck[x].rank - deck[y].rank)
    return orderedCardsOfSuit[0]
  }
  return null
}

function biggestOnTable(tableSuit, tableCards) {
  return Object.keys(tableCards).filter(x => deck[tableCards[x]].suit === tableSuit)
    .sort((x, y) => deck[tableCards[x]].rank - deck[tableCards[y]].rank)
    .map(x => tableCards[x]).pop()
}

function biggestNonWinner(tableSuit, tableCards, hand) {
  const biggestOnT = Object.keys(tableCards).filter(x => deck[tableCards[x]].suit === tableSuit)
    .sort((x, y) => deck[tableCards[x]].rank - deck[tableCards[y]].rank)
    .map(x => tableCards[x]).pop()
  if (biggestOnT) {
    const newCard = Object.keys(hand).filter(x => deck[x].suit === tableSuit)
      .filter(x => deck[x].rank < deck[biggestOnT].rank)
      .sort((x, y) => deck[x].rank - deck[y].rank)
      .pop()
    if (newCard) {
      return newCard
    } else {
      return ''
    }
  } else {
    throw new Error("biggestNonWinner should not be called if there is no card on the table.")
  }

}

function smallestWinner(tableSuit, tableCards, hand) {
  const biggestOnT = Object.keys(tableCards).filter(x => deck[tableCards[x]].suit === tableSuit)
    .sort((x, y) => deck[tableCards[x]].rank - deck[tableCards[y]].rank)
    .map(x => tableCards[x]).pop()
  if (biggestOnT && Object.keys(hand).filter(x => deck[x].suit === tableSuit)
    .filter(x => deck[x].rank > deck[biggestOnT].rank)
    .sort((x, y) => deck[x].rank - deck[y].rank).length > 0) {
    return Object.keys(hand).filter(x => deck[x].suit === tableSuit)
      .filter(x => deck[x].rank > deck[biggestOnT].rank)
      .sort((x, y) => deck[x].rank - deck[y].rank)[0]
  } else {
    return ''
  }
}



function unFinishedSuits(game, hand) {
  const unFinishedS = []
  let pCards = [];
  if (game.history && game.history.playedCards) {
    pCards = Object.keys(game.history.playedCards);
  }
  for (let i = 14; i > 1; i--) {
    if (!pCards.includes(mapRank2Id('spades', i)) && !hand[mapRank2Id('spades', i)]) {
      unFinishedS.push('spades')
      break
    }
  }
  for (let i = 14; i > 1; i--) {
    if (!pCards.includes(mapRank2Id('hearts', i)) && !hand[mapRank2Id('hearts', i)]) {
      unFinishedS.push('hearts')
      break
    }
  }
  for (let i = 14; i > 1; i--) {
    if (!pCards.includes(mapRank2Id('clubs', i)) && !hand[mapRank2Id('clubs', i)]) {
      unFinishedS.push('clubs')
      break
    }
  }
  for (let i = 14; i > 1; i--) {
    if (!pCards.includes(mapRank2Id('diamonds', i)) && !hand[mapRank2Id('diamonds', i)]) {
      unFinishedS.push('diamonds')
      break
    }
  }
  return unFinishedS
}

// This returns true if it is highly likely that suit cards in the hand are not winners
function isSmallestInHand(hand, pCards, suit) {
  let counter = 0;
  const biggestRank = Object.keys(hand).filter(x => deck[x].suit === suit).map(x => deck[x].rank).sort((x, y) => x - y).pop()
  if (!biggestRank) {
    return false
  }
  for (let i = 2; i < biggestRank; i++) {
    if (!pCards.includes(mapRank2Id(suit, i))) {
      if (hand[mapRank2Id(suit, i)]) {
        counter++
      } else {
        counter--
      }
    }
    if (counter < 0) {
      return false
    }
  }
  return true
}

function suitsWithSmallestInHand(game, hand) {
  const suitsWithSmallestInH = []
  const tableCards = game.table.cards;

  let pCards = [];
  if (game.history && game.history.playedCards) {
    pCards = Object.keys(game.history.playedCards);
  }

  if (tableCards && Object.keys(tableCards).length > 0) {
    pCards = pCards.concat(Object.keys(tableCards))
  }

  if (isSmallestInHand(hand, pCards, 'spades')) {
    suitsWithSmallestInH.push('spades')
  }
  if (isSmallestInHand(hand, pCards, 'hearts')) {
    suitsWithSmallestInH.push('hearts')
  }
  if (isSmallestInHand(hand, pCards, 'diamonds')) {
    suitsWithSmallestInH.push('diamonds')
  }
  if (isSmallestInHand(hand, pCards, 'clubs')) {
    suitsWithSmallestInH.push('clubs')
  }

  return suitsWithSmallestInH
}

function handBySuit(hand) {
  const res = {}
  const suitsInHand = suitsInHandFunction(hand)
  for (const suit of suitsInHand) {
    res[suit] = Object.keys(hand).filter(x => deck[x].suit === suit)
  }
  return res
}

//olmuslar
function ripeCards(game, hand) {
  const rcards = []
  let pCards = [];
  if (game.history && game.history.playedCards) {
    pCards = Object.keys(game.history.playedCards);
  }
  for (let i = 14; i > 1; i--) {
    if (!pCards.includes(mapRank2Id('spades', i))) {
      if (hand[mapRank2Id('spades', i)] && !isInvalid(hand[mapRank2Id('spades', i)], game, hand)) {
        rcards.push(hand[mapRank2Id('spades', i)])
      }
      break
    }
  }
  for (let i = 14; i > 1; i--) {
    if (!pCards.includes(mapRank2Id('diamonds', i))) {
      if (hand[mapRank2Id('diamonds', i)]) {
        rcards.push(hand[mapRank2Id('diamonds', i)])
      }
      break
    }
  }

  for (let i = 14; i > 1; i--) {
    if (!pCards.includes(mapRank2Id('clubs', i))) {
      if (hand[mapRank2Id('clubs', i)]) {
        rcards.push(hand[mapRank2Id('clubs', i)])
      }
      break
    }
  }
  for (let i = 14; i > 1; i--) {
    if (!pCards.includes(mapRank2Id('hearts', i))) {
      if (hand[mapRank2Id('hearts', i)]) {
        rcards.push(hand[mapRank2Id('hearts', i)])
      }
      break
    }
  }
  return rcards.sort((a, b) => parseInt(b) - parseInt(a))
}

function isWinnerCard(seat, mycard, table) {
  const cards = JSON.parse(JSON.stringify(table.cards));
  cards[seat] = mycard

  const testTable = {'cards': cards, 'tableSuit': table.tableSuit, 'koz': table.koz}
  return tableWinner(testTable) === seat;
}


export function tableWinner(table) {
  const tableCards = table['cards'];
  const tableSuit = table['tableSuit'];
  let trump = table.koz
  if (table.ceza) {
    trump = table.tableSuit
  }
  const weightedCards = {};
  for (const seat in tableCards) {
    if (deck[tableCards[seat]].suit === trump) {
      weightedCards[deck[tableCards[seat]].rank * 100] = seat;
    } else if (deck[tableCards[seat]].suit === tableSuit) {
      weightedCards[deck[tableCards[seat]].rank * 10] = seat;
    } else {
      weightedCards[deck[tableCards[seat]].rank] = seat;
    }
  }
  const max_index = Object.keys(weightedCards).reduce((a, b) => {
    if (parseInt(a) > parseInt(b)) {
      return a
    } else {
      return b
    }
  })
  return weightedCards[max_index]
}

export function buildHistory(game) {
  const history = game.history || {};
  const table = game.table;

  const lastTableCards = {
    'S': table.cards.S, 'W': table.cards.W, 'N': table.cards.N, 'E': table.cards.E
  }

  const KJ = history.kj || [];
  const Q = history.q || [];
  const hearts = history.hearts || [];
  const playedCards = history.playedCards || {};

  for (const s in lastTableCards) {
    const cardId = lastTableCards[s]
    if(table.ceza){
      if(deck[cardId].suit === 'hearts'){
        hearts.push(cardId)
      }
      if(deck[cardId].rank === 11 || deck[cardId].rank === 13){
        KJ.push(cardId)
      }
      if(deck[cardId].rank === 12){
        Q.push(cardId)
      }
    }
    playedCards[cardId] = table.tableRound
  }

  game.history = {'kj': uniq(KJ), 'q': uniq(Q), 'hearts': uniq(hearts), 'lastCards': lastTableCards, 'playedCards': playedCards, 'lastStarter': nextSeat(game.table.whoseTurn)}
  return game.history
}

export function isRoundFinished (hands, trump) {
  if(hands) {
    if (trump === 'kupa') {
      let totalRemaining = 0;
      for(const seat in hands) {
        totalRemaining += howMany(Object.keys(hands[seat]), 'suit', 'hearts')
      }
      return totalRemaining === 0
    } else if (trump === 'rifki') {
      let totalRemaining = 0;
      for(const seat in hands) {
        totalRemaining += howMany(Object.keys(hands[seat]), 'id', '113')
      }
      return totalRemaining === 0
    } else if (trump === 'erkek') {
      let totalRemaining = 0;
      for(const seat in hands) {
        totalRemaining += howMany(Object.keys(hands[seat]), 'rank', 11)
          + howMany(Object.keys(hands[seat]), 'rank', 13)
      }
      return totalRemaining === 0
    } else if (trump === 'kiz') {
      let totalRemaining = 0;
      for(const seat in hands) {
        totalRemaining += howMany(Object.keys(hands[seat]), 'rank', 12);
      }
      return totalRemaining === 0
    }
    else {
      return Object.keys(hands.S).length === 0
    }
  }
  else {
    return true
  }

}


function isInvalid(cardId, game, hand) {
  const ceza = game.table.ceza;
  const koz = game.table.koz;

  if (koz) {
    return kozValidations(cardId, game, hand)
  } else if (ceza) {
    if (ceza === 'rifki') {
      return rifkiValidations(cardId, game, hand)
    } else if (ceza === 'el') {
      return elValidations(cardId, game, hand)
    } else if (ceza === 'erkek') {
      return erkekValidations(cardId, game, hand)
    } else if (ceza === 'kiz') {
      return kizValidations(cardId, game, hand)
    } else if (ceza === 'kupa') {
      return kupaValidations(cardId, game, hand)
    } else if (ceza === 'soniki') {
      return sonikiValidations(cardId, game, hand)
    }
  }
  return false
}

function kozValidations(cardId,
                        game,
                        hand) {
  const tableSuit = game.table.tableSuit;
  const kozRevealed = game.table.kozRevealed;
  const koz = game.table.koz;

  const suitsInHand = suitsInHandFunction(hand)

  if (tableSuit) {
    // elinde yerdeki kart varsa, oynamak zorundasin
    if (suitsInHand.includes(tableSuit) && (deck[cardId].suit !== tableSuit)) {
      // @ts-ignore
      return `${contractsCopy[tableSuit]} ${validationsCopy.playX}`
    }

    //Koz oynaniyorsa kozu yukseltmek mecburi
    let rank = 0;
    for (const seat in game.table.cards) {
      const cardFromTable = deck[game.table.cards[seat]];
      if (cardFromTable.suit === koz && cardFromTable.rank > rank) {
        rank = cardFromTable.rank
      }
    }
    for (const handCardId in hand) {
      if (tableSuit === koz && deck[handCardId].suit === koz && deck[cardId].rank < rank && deck[handCardId].rank > rank) {
        return 'Koz elinde yerdeki kozu yükseltmek zorunludur.'
      }
    }

    //Koz cakilacaksa kozu yukseltmek mecburi
    for (const seat in game.table.cards) {
      const cardFromTable = deck[game.table.cards[seat]];
      if (cardFromTable.suit === koz && cardFromTable.rank > rank) {
        rank = cardFromTable.rank
      }
    }
    for (const handCardId in hand) {
      if (!suitsInHand.includes(tableSuit) && deck[handCardId].suit === koz && deck[cardId].rank < rank && deck[handCardId].rank > rank) {
        return 'Yerdeki kozu yükseltmek zorunludur.'
      }
    }

    // Elinde koz varsa ve yerdeki suit yoksa koz atmalisin
    if (!suitsInHand.includes(tableSuit) && suitsInHand.includes(koz) && deck[cardId].suit !== tableSuit && deck[cardId].suit !== koz) {
      // @ts-ignore
      return `${contractsCopy[koz]} ${validationsCopy.playX}`
    }

  } else {
    if (deck[cardId].suit === koz && !kozRevealed && suitsInHand.length > 1) {
      return `Bu elde henüz koz çıkmadı. Koz çıkamazsınız.`
    }
  }
  return false
}


function rifkiValidations(cardId, game, hand) {
  const tableSuit = game.table.tableSuit;
  const kozRevealed = game.table.kozRevealed;

  const suitsInHand = suitsInHandFunction(hand)

  if (tableSuit) {
    // elinde yerdeki kart varsa, oynamak zorundasin
    if (suitsInHand.includes(tableSuit) && (deck[cardId].suit !== tableSuit)) {
      // @ts-ignore
      return `${contractsCopy[tableSuit]} ${validationsCopy.playX}`
    }

    // Elinde rifki varsa ve yerdeki suit yoksa rifki atmalisin
    let isRifki = false;
    for (const handCardId in hand) {
      if (deck[handCardId].id === 113) {
        isRifki = true;
      }
    }

    if (deck[cardId].suit !== tableSuit && isRifki && deck[cardId].id !== 113) {
      return `Rıfkı ${validationsCopy.playX}`
    }
    // Kupa asi ciktiysa da Rifki oynamak zorunlu
    if (isRifki && deck[cardId].id !== 113) {
      for (const tableCardId of game.table.cards) {
        if (tableCardId === '114') {
          return `Rıfkı ${validationsCopy.playX}`
        }
      }
    }

    // Elinde kupa varsa ve yerdeki suit yoksa kupa atmalisin
    if (deck[cardId].suit !== tableSuit && deck[cardId].suit !== tableSuit && deck[cardId].suit !== 'hearts' && suitsInHand.includes('hearts')) {
      return `Kupa ${validationsCopy.playX}`
    }

  } else {
    if (deck[cardId].suit === 'hearts' && !kozRevealed && suitsInHand.length > 1) {
      return `Bu elde henüz kupa çıkmadı. Kupa çıkamazsınız.`
    }
  }
  return false
}

function kupaValidations(cardId, game, hand) {
  const tableSuit = game.table.tableSuit;
  const kozRevealed = game.table.kozRevealed;
  const suitsInHand = suitsInHandFunction(hand)

  if (tableSuit) {
    // elinde yerdeki suit varsa, oynamak zorundasin
    if (suitsInHand.includes(tableSuit) && (deck[cardId].suit !== tableSuit)) {
      // @ts-ignore
      return `${contractsCopy[tableSuit]}  ${validationsCopy.playX}`
    }

    // Elinde kupa varsa ve yerdeki suit yoksa kupa atmalisin
    if ('hearts' !== tableSuit && deck[cardId].suit !== 'hearts' && deck[cardId].suit !== tableSuit && suitsInHand.includes('hearts')) {
      return `Kupa ${validationsCopy.playX}`
    }

  } else {
    if (deck[cardId].suit === 'hearts' && !kozRevealed && suitsInHand.length > 1) {
      return `Bu elde henüz kupa çıkmadı. Kupa çıkamazsınız.`
    }
  }
  return false
}

function erkekValidations(cardId, game, hand) {
  const tableSuit = game.table.tableSuit;
  const suitsInHand = suitsInHandFunction(hand)

  if (tableSuit) {
    // elinde yerdeki suit varsa, oynamak zorundasin
    if (suitsInHand.includes(tableSuit) && (deck[cardId].suit !== tableSuit)) {
      // @ts-ignore
      return `${contractsCopy[tableSuit]} ${validationsCopy.playX}`
    }

    //Yerde eldeki erkekten buyuk kart varsa, erkegi atmak mecburi
    let rank = 20;
    let isErkek = false;
    for (const handCardId in hand) {
      if (deck[handCardId].rank === 11 || deck[handCardId].rank === 13) {
        isErkek = true
        if (deck[handCardId].suit === tableSuit && deck[handCardId].rank < rank) {
          rank = deck[handCardId].rank
        }
      }
    }
    for (const seat in game.table.cards) {
      const cardFromTable = deck[game.table.cards[seat]];
      if (deck[cardId].suit !== tableSuit && deck[cardId].rank !== 11 && deck[cardId].rank !== 13 && cardFromTable.rank > rank && cardFromTable.suit === tableSuit) {
        return 'Eldeki erkeği yedirmek zorunlu.'
      }
    }

    // Yerdeki suitten elinde yoksa ve erkek varsa, erkegi atmak zorundasin

    if (!suitsInHand.includes(tableSuit) && isErkek && deck[cardId].rank !== 11 && deck[cardId].rank !== 13) {
      return 'Eldeki erkeği yedirmek zorunlu.'
    }
  }
  return false
}

function kizValidations(cardId, game, hand) {
  const tableSuit = game.table.tableSuit;

  const suitsInHand = suitsInHandFunction(hand)
  if (tableSuit) {
    // elinde yerdeki suitten varsa oynamak zorundasin
    if (suitsInHand.includes(tableSuit) && (deck[cardId].suit !== tableSuit)) {
      // @ts-ignore
      return `${contractsCopy[tableSuit]} ${validationsCopy.playX}`
    }

    //Yerde eldeki kizdan buyuk kart varsa, kizi atmak mecburi
    let rank = 20;
    let isKiz = false;
    for (const handCardId in hand) {
      if (deck[handCardId].rank === 12) {
        isKiz = true
        if (deck[handCardId].suit === tableSuit && deck[handCardId].rank < rank) {
          rank = 12
        }
      }
    }
    for (const seat in game.table.cards) {
      const cardFromTable = deck[game.table.cards[seat]];
      if (deck[cardId].suit !== tableSuit && deck[cardId].rank !== 12 && cardFromTable.rank > rank && cardFromTable.suit === tableSuit) {
        return 'Eldeki kızı yedirmek zorunlu.'
      }
    }

    // Yerdeki suitten elinde yoksa ve kiz varsa, kizi atmak zorundasin
    if (deck[cardId].rank !== 12 && !suitsInHand.includes(tableSuit) && isKiz) {
      return 'Eldeki kızı yedirmek zorunludur.'
    }
  }
  return false
}

function elValidations(cardId, game, hand) {
  const suitsInHand = suitsInHandFunction(hand)

  const tableSuit = game.table.tableSuit;
  if (tableSuit) {
    // elinde yerdeki kart varsa, oynamak zorundasin
    if (suitsInHand.includes(tableSuit) && (deck[cardId].suit !== tableSuit)) {
      // @ts-ignore
      return `${contractsCopy[tableSuit]} ${validationsCopy.playX}`
    }
  }
  return false
}

function sonikiValidations(cardId, game, hand) {
  const suitsInHand = suitsInHandFunction(hand)

  const tableSuit = game.table.tableSuit;
  if (tableSuit) {
    // elinde yerdeki kart varsa, oynamak zorundasin
    if (suitsInHand.includes(tableSuit) && (deck[cardId].suit !== tableSuit)) {
      // @ts-ignore
      return `${contractsCopy[tableSuit]} ${validationsCopy.playX}`
    }
  }
  return false
}

export function scoreHand(hand) {
  const score = {
    'soniki': -1,
    'el': -1,
    'rifki': -1,
    'kiz': -1,
    'erkek': -1,
    'kupa': -1,

    'hearts': -1,
    'spades': -1,
    'diamonds': -1,
    'clubs': -1
  }
  const featureScores = {}

  featureScores['23'] = (score23(hand) - 4) / 4;
  featureScores['AK'] = (scoreAK(hand) - 16) / 16;
  featureScores['AvgValue'] = (scoreAverageValue(hand) - 8) / 4;
  featureScores['Kupa'] = (scoreKupa(hand) - 2.5) / 2.5;
  featureScores['NoKJ'] = (scoreNoKJ(hand) + 0.5) / 0.5;
  featureScores['NoQ'] = (scoreNoQ(hand) + 0.5) / 0.5;
  featureScores['NoRifki'] = (scoreNoRifki(hand) + 0.5) / 0.5;
  featureScores['Rifki'] = (scoreRifki(hand) - 0.5) / 0.5;
  featureScores['Koz'] = (scoreKoz(hand) - 5.5) / 4.5;
  featureScores['SuitLength'] = (scoreSuitLength(hand) - 18) / 18;


  // last elements are added/substracted to distribute chosen trumps in proportion
  // @ts-ignore
  score[suitForKoz(hand)] = 0.4 * featureScores['Koz'] + 0.4 * featureScores['SuitLength'] + 0.2 * featureScores['AvgValue'] + 0.6;
  score['soniki'] = 0.6 * featureScores['23'] - 0.4 * featureScores['AvgValue'] + 0.17;
  score['el'] = 0.25 * featureScores['23'] - 0.25 * featureScores['AK'] - 0.5 * featureScores['AvgValue'] - 0.09;
  score['rifki'] = 0.4 * featureScores['Rifki'] + 0.4 * featureScores['NoRifki'] - 0.2 * featureScores['AvgValue'] - 0.02;
  score['kiz'] = 0.7 * featureScores['NoQ'] - 0.3 * featureScores['AvgValue'] - 0.64;
  score['erkek'] = 0.7 * featureScores['NoKJ'] - 0.3 * featureScores['AvgValue'] - 0.63;
  score['kupa'] = 0.7 * featureScores['Kupa'] - 0.3 * featureScores['AvgValue'] - 0.62;

  return [score, featureScores]

  function suitForKoz(handd) {
    const handByS = handBySuit(handd);
    const avgRankPerSuit = {};
    for (const suit in handByS) {
      avgRankPerSuit[suit] = handByS[suit].length + avgRank(handByS[suit]) * 0.1
    }
    const maxValueList = Object.keys(avgRankPerSuit).map(x => avgRankPerSuit[x])
    return Object.keys(avgRankPerSuit)
      .filter(x => avgRankPerSuit[x] === Math.max(...maxValueList))
  }

  function avgRank(listOfCards) {
    return (listOfCards.map(x => deck[x].rank).reduce((a, b) => a + b)) / listOfCards.length
  }

  function scoreKoz(handd) {
    const handByS = handBySuit(handd);
    const longestSuit_l = Object.keys(handByS)
      .sort((a, b) => handByS[a].length - handByS[b].length)
    const handLongest = handBySuit(handd)[longestSuit_l[longestSuit_l.length - 1]];

    if (handLongest.length > 3) {
      const highSuits = handLongest.filter(x => deck[x].rank === 14 || deck[x].rank === 13 || deck[x].rank === 12 || deck[x].rank === 11)
      if (highSuits.length > 2) {
        return 10
      } else if (highSuits.length > 1) {
        return 5
      } else if (highSuits.length === 1) {
        return 3
      } else {
        return 1
      }
    } else {
      return 0
    }
  }

  function scoreAK(handd) {
    return Object.keys(handd).filter(x => deck[x].rank === 14).length * 4 +
      Object.keys(handd).filter(x => deck[x].rank === 13).length * 3 +
      Object.keys(handd).filter(x => deck[x].rank === 13).length
  }

  function scoreSuitLength(handd) {
    let res = 0

    for (const suit in handBySuit(handd)) {
      res += Math.pow(Math.max(handBySuit(handd)[suit].length - 4, 0), 2)
    }

    return res
  }

  function score23(handd) {
    return Object.keys(handd).filter(x => [2, 3].includes(deck[x].rank)).length
  }

  function scoreRifki(handd) {
    const rifkiInHand = Object.keys(handd).filter(handCardId => handCardId === '113').length > 0;
    const len_hearts = Object.keys(handd).filter(x => deck[x].suit === 'hearts').length;
    if (rifkiInHand && len_hearts > 4) {
      return 1
    }
    return 0
  }

  function scoreNoRifki(handd) {
    const rifkiInHand = Object.keys(handd).filter(handCardId => handCardId === '113').length > 0;
    const len_hearts = Object.keys(handd).filter(x => deck[x].suit === 'hearts').length;
    if (rifkiInHand && len_hearts < 4) {
      return -1
    }
    return 0
  }

  function scoreNoQ(handd) {
    const q = Object.keys(handd).filter(x => deck[x].rank === 12)
    const handByS = handBySuit(handd)
    if (q.filter(x => handByS[deck[x].suit].length < 4).length > 0) {
      return -1
    }
    return 0
  }

  function scoreNoKJ(handd) {
    const q = Object.keys(handd).filter(x => deck[x].rank === 11 || deck[x].rank === 13)
    const handByS = handBySuit(handd)
    if (q.filter(x => handByS[deck[x].suit].length < 4).length > 0) {
      return -1
    }
    return 0
  }

  function scoreAverageValue(handd) {
    return Object.keys(handd).map(x => deck[x].rank).reduce((a, b) => a + b) / 13
  }

  function scoreKupa(handd) {
    const hearts = handBySuit(handd)['hearts']
    if (hearts) {
      const hearts_under_5 = hearts.map(x => deck[x].rank)
        .filter(x => x === 2 || x === 3 || x === 4)
      const hearts_under_8 = hearts.map(x => deck[x].rank)
        .filter(x => x === 2 || x === 3 || x === 4
          || x === 5 || x === 6 || x === 7)
      const hearts_under_11 = hearts.map(x => deck[x].rank)
        .filter(x => x === 2 || x === 3 || x === 4
          || x === 5 || x === 6 || x === 7
          || x === 8 || x === 9 || x === 10)

      if (hearts_under_5.length > 0) {
        if (hearts_under_8.length > 1) {
          if (hearts_under_11.length > 2) {
            return 5
          }
          return 2
        }
      }
      return 0
    } else {
      return 0
    }
  }
}

export function botChooseTrump(game, gameMeta, hand) {
  const seat = game.table.whoseTurn;
  const newgame = JSON.parse(JSON.stringify(game))
  const newGameMeta = JSON.parse(JSON.stringify(gameMeta))
  if (game.round < 5) {
    const trumpList = chooseRuleBased(hand).filter(x => !['hearts', 'spades', 'clubs', 'diamonds'].includes(x));
    let trump = trumpList[0]
    let i = 0
    while (!isTrumpAvailable(trump, seat, gameMeta.yazboz.remains)) {
      i += 1
      trump = trumpList[i]
    }
    newgame.table.ceza = trump
    newGameMeta.yazboz.remains.cezalar[trump] -= 1;
    newGameMeta.yazboz.remains[seat].ceza -= 1;

  } else {
    const trumpList = chooseRuleBased(hand);
    let trump = trumpList[0]
    let i = 0
    while (!isTrumpAvailable(trump, seat, gameMeta.yazboz.remains)) {
      i += 1
      trump = trumpList[i]
    }

    if (['spades', 'hearts', 'clubs', 'diamonds'].includes(trump)) {
      newgame.table.koz = trump
      newGameMeta.yazboz.remains[seat].koz -= 1;
    } else {
      newgame.table.ceza = trump
      newGameMeta.yazboz.remains.cezalar[trump] -= 1;
      newGameMeta.yazboz.remains[seat].ceza -= 1;
    }
  }
  newgame.table.cards = {}
  return [newgame, newGameMeta]
}

function isTrumpAvailable(trump, seat, remains) {
  if (!['hearts', 'spades', 'clubs', 'diamonds'].includes(trump)) {
    if (remains[seat].ceza === 0) {
      return false
    } else {
      return remains.cezalar[trump] > 0;
    }
  } else {
    return remains[seat].koz !== 0;
  }
}


export function shuffle(array) {
  let currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export function chooseRuleBased(hand) {
  const score = scoreHand(hand)[0];
  return shuffle(Object.keys(score))
    .sort((a, b) => score[b] - score[a])
}

export function updateYazBoz(game, gameMeta) {
  const newGameMeta = JSON.parse(JSON.stringify(gameMeta))

  const yazboz = {...newGameMeta.yazboz}

  // Update Scores according to the last round's ceza or koz
  let trump_pts = 50
  if (game.table.koz) {
    newGameMeta.scores.E.totalKoz += gameMeta.scores.E.round * trump_pts;
    newGameMeta.scores.S.totalKoz += gameMeta.scores.S.round * trump_pts;
    newGameMeta.scores.W.totalKoz += gameMeta.scores.W.round * trump_pts;
    newGameMeta.scores.N.totalKoz += gameMeta.scores.N.round * trump_pts;
  } else if (game.table.ceza) {
    if (game.table.ceza === 'rifki') {
      trump_pts = 320
    } else if (game.table.ceza === 'el') {
      trump_pts = 50
    } else if (game.table.ceza === 'kupa') {
      trump_pts = 30
    } else if (game.table.ceza === 'erkek') {
      trump_pts = 60
    } else if (game.table.ceza === 'kiz') {
      trump_pts = 100
    } else if (game.table.ceza === 'soniki') {
      trump_pts = 180
    }

    let slot = 1
    if (yazboz[game.table.ceza].E[0] === '') {
      slot = 0
    }
    yazboz[game.table.ceza].E[slot] = gameMeta.scores.E.round * trump_pts;
    yazboz[game.table.ceza].S[slot] = gameMeta.scores.S.round * trump_pts;
    yazboz[game.table.ceza].W[slot] = gameMeta.scores.W.round * trump_pts;
    yazboz[game.table.ceza].N[slot] = gameMeta.scores.N.round * trump_pts;
    newGameMeta.yazboz = yazboz

    newGameMeta.scores.E.totalCeza += gameMeta.scores.E.round * trump_pts;
    newGameMeta.scores.S.totalCeza += gameMeta.scores.S.round * trump_pts;
    newGameMeta.scores.W.totalCeza += gameMeta.scores.W.round * trump_pts;
    newGameMeta.scores.N.totalCeza += gameMeta.scores.N.round * trump_pts;
  }

  newGameMeta.scores.E.round = 0;
  newGameMeta.scores.S.round = 0;
  newGameMeta.scores.W.round = 0;
  newGameMeta.scores.N.round = 0;

  return newGameMeta
}

function uniq(a) {
  let seen = {};
  return a.filter(function(item) {
    return Object.prototype.hasOwnProperty.call(seen, item) ? false : (seen[item] = true);
  });
}

export function updateStateRoundEnd(game) {
  const newGame = JSON.parse(JSON.stringify(game))
  const table = JSON.parse(JSON.stringify(game.table))
  // const newReporting = JSON.parse(JSON.stringify(reporting))

  newGame.dealer = nextSeat(game.dealer)
  newGame.round = game.round + 1
  table.whoseTurn = newGame.dealer
  table.ceza = null
  table.koz = null
  table.roundEnd = false
  table.cards = {}
  table.kozRevealed = false
  table.tableRound = 1
  table.tableSuit = null
  newGame.table = table
  newGame.history = null

  const newHands = dealAllCardsToPlayers()

  return [newGame, newHands]
}


