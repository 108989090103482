<template>
  <div id="table-panel">
    <div id="nameW" v-if="usersInGame[relDir.W]" style="z-index: 120">
      <div id="nameWBorder" class="player-area">
        <b v-if="table.whoseTurn !== this.relDir.W" class="playername text">
          {{ screenNames[this.relDir.W] }}</b>
        <b v-else class="playername text nameTurn">
          {{ screenNames[this.relDir.W] }}</b>
        <div style="display: flex; flex-direction: row">
          <div v-if="ihaleci === 'W'" class="player-points" style="margin-bottom: 5px; margin-left: 5px">
            <span class="points">{{ scores[this.relDir.W]['round'] }}/{{ contracts.W }}</span>
          </div>
          <div v-else class="player-points" style="margin-bottom: 5px; margin-left: 5px">
            <span class="points">{{ scores[this.relDir.W]['round'] }}</span>
          </div>
          <div v-if="ihaleci === 'W'" style="font-size:30px; margin-left: 5px">
            <div v-if="ihaleci === 'W' && contracts && scores[this.relDir.W]['round'] >= contracts.W" >
              👍
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="inner-L">
      <transition-group
        name="card-appear-L"
        mode="out-in"
        class="player-area-cards"
        style="justify-content: flex-end;"
        v-on:leave="leave('W')"
      >
        <vue-playing-card :style="cssVars"
                          v-if="!!table && !!tableCardsDisplay && !!tableCardsDisplay[relDir.W]"
                          :width="cardWidth"
                          :signature="this.deck[tableCardsDisplay[relDir.W]].name"
                          :key="this.deck[tableCardsDisplay[relDir.W]].id"
                          class="playing-card">
        </vue-playing-card>
      </transition-group>
    </div>
    <div id="inner-C-T">
      <transition-group
        name="card-appear-T"
        mode="out-in"
        class="player-area-cards"
        v-on:leave="leave('N')"
      >
        <vue-playing-card :style="cssVars"
                          v-if="!!table && !!tableCardsDisplay && !!tableCardsDisplay[relDir.N]"
                          :width="cardWidth"
                          :signature=this.deck[tableCardsDisplay[relDir.N]].name
                          :key="this.deck[tableCardsDisplay[relDir.N]].id"
                          class="playing-card">
        </vue-playing-card>
      </transition-group>
    </div>
    <div id="inner-C-B" v-if="table">

      <transition-group
        name="card-appear-B"
        mode="out-in"
        class="player-area-cards"
        v-on:leave="leave('S')"
      >
        <vue-playing-card :style="cssVars"
                          v-if="table && tableCardsDisplay && tableCardsDisplay[relDir.S]"
                          :width="cardWidth"
                          :signature=this.deck[tableCardsDisplay[relDir.S]].name
                          :key="this.deck[tableCardsDisplay[relDir.S]].id"
                          class="playing-card">
        </vue-playing-card>
      </transition-group>
    </div>
    <div id="inner-R">
      <transition-group
        name="card-appear-R"
        mode="out-in"
        class="player-area-cards"
        style="justify-content: flex-start"
        v-on:leave="leave('E')"
      >
        <vue-playing-card :style="cssVars"
                          v-if="table && tableCardsDisplay && tableCardsDisplay[relDir.E]"
                          :width="cardWidth"
                          :signature=this.deck[tableCardsDisplay[relDir.E]].name
                          :key="this.deck[tableCardsDisplay[relDir.E]].id"
                          class="playing-card">
        </vue-playing-card>
      </transition-group>
    </div>
    
    <div id="nameE" v-if="usersInGame[relDir.E]" style="z-index: 120">
      <div id="nameEBorder" class="player-area">
        <b v-if="table.whoseTurn !== this.relDir.E" class="playername text">
          {{ screenNames[this.relDir.E] }}</b>
        <b v-else class="playername text nameTurn">
          {{ screenNames[this.relDir.E] }}</b>
        <div style="display: flex; flex-direction: row">
          <div v-if="ihaleci==='E'" style="font-size:30px; margin-right: 5px">
            <div v-if="ihaleci === 'E' && contracts && scores[this.relDir.E]['round'] >= contracts.E" >
              👍
            </div>
          </div>
          <div v-if="ihaleci === 'E'" class="player-points" style="margin-bottom: 5px; margin-right: 5px">
            <span class="points">{{ scores[this.relDir.E]['round'] }}/{{ contracts.E }}</span>
          </div>
          <div v-else class="player-points" style="margin-bottom: 5px; margin-right: 5px">
            <span class="points">{{ scores[this.relDir.E]['round'] }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { ihaleCopy } from '@/js/ihale/ihaleCopy'
import { deck_template } from "@/js/deck";

export default {
  name: "IhaleTablePanel",
  data: () => ({
    deck: null,
    inMotion: {'S': false, 'E': false, 'N': false, 'W': false},
    leaveDuration: '250'
  }),
  props: ["showChosenTrump", "table", "usersInGame", "scores", "user", "cardWidth", "relDir", "screenNames", "contracts", "ihaleci"],
  methods: {
    leave(seat) {
      this.inMotion[seat] = true
      setTimeout(() => {
        this.inMotion[seat] = false;
      }, this.leaveDuration);
    },
  },
  created() {
    this.compCopy = ihaleCopy.TablePanel;
    this.deck = { ...deck_template }
  },
  watch: {
    showChosenTrump: function () {
      if (!this.showChosenTrump) {
        this.inMotion['S'] = true
      }
      setTimeout(() => {
        this.inMotion['S'] = false;
      }, this.leaveDuration);
    },
  },
  computed: {
    tableCards() {
      return this.table.cards
    },
    tableCardsDisplay() {
      const tCards = {}
      if (this.tableCards && this.tableCards.N && this.inMotion && !this.inMotion['N']) {
        tCards['N'] = this.tableCards.N
      } else {
        tCards['N'] = null
      }
      if (this.tableCards && this.tableCards.S && this.inMotion && !this.inMotion['S']) {
        tCards['S'] = this.tableCards.S
      } else {
        tCards['S'] = null
      }
      if (this.tableCards && this.tableCards.E && this.inMotion && !this.inMotion['E']) {
        tCards['E'] = this.tableCards.E
      } else {
        tCards['E'] = null
      }
      if (this.tableCards && this.tableCards.W && this.inMotion && !this.inMotion['W']) {
        tCards['W'] = this.tableCards.W
      } else {
        tCards['W'] = null
      }
      return tCards
    },
    cssVars() {
      let cssVs = {
        '--leave-duration': this.leaveDuration + 'ms',
        'z-index': '900',
        '--move-duration': '.3s',
        '--active-transition': 'all .5s cubic-bezier(.16, .11, .29, .96)' // cubic-bezier(1.0, 0.5, 0.8, 1.0) cubic-bezier(.16, .11, .29, .96)
      }
      
      if (this.tableWinner && this.tableWinner === this.relDir.S) {
        cssVs['--transition'] = 'translateY(300px)'
      } else if (this.tableWinner && this.tableWinner === this.relDir.N) {
        cssVs['--transition'] = 'translateY(-300px)'
      } else if (this.tableWinner && this.tableWinner === this.relDir.E) {
        cssVs['--transition'] = 'translateX(300px)'
      } else if (this.tableWinner && this.tableWinner === this.relDir.W) {
        cssVs['--transition'] = 'translateX(-300px)'
      }
      
      return cssVs
    },
    tableWinner() {
      const tableCards = this.table['cards'];
      if (tableCards) {
        const tableSuit = this.table['tableSuit'];
        let trump = this.table.koz
        if (this.table.ceza) {
          trump = this.table.tableSuit
        }
        const weightedCards = {};
        for (const seat in tableCards) {
          if (tableCards[seat]) {
            if (this.deck[tableCards[seat]].suit === trump) {
              weightedCards[this.deck[tableCards[seat]].rank * 100] = seat;
            } else if (this.deck[tableCards[seat]].suit === tableSuit) {
              weightedCards[this.deck[tableCards[seat]].rank * 10] = seat;
            } else {
              weightedCards[this.deck[tableCards[seat]].rank] = seat;
            }
          }
        }
        const max_index = Object.keys(weightedCards).reduce((a, b) => {
          if (parseInt(a) > parseInt(b)) {
            return a
          } else {
            return b
          }
        })
        
        return weightedCards[max_index]
      } else {
        return null
      }
    }
  }
  
}
</script>

<style scoped>

#table-panel {
  display: grid;
  grid-template-columns: calc(30% ) calc(40% ) calc(30% );
  grid-template-rows: 100px calc(50% - 100px) calc(50% - 50px) 50px;
  grid-template-areas:
        "nameW   tableCT  nameE"
        "tableL  tableCT  tableR"
        "tableL  tableCB  tableR"
        ".      tableCB  .";
  
  flex: 1 0;
  /*min-height: min(350px, 44vh);*/
  
  border: 2px solid rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  outline: inherit;
  
}

#table-panel #nameW {
  grid-area: nameW;
  
  display: flex;
  flex-direction: row;
  
  justify-content: flex-start;
  align-items: stretch;
  
  z-index: 0;
  
  outline: inherit;
}

#nameWBorder {
  display: flex;
  
  flex: 1 0;
  
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  
  outline: inherit;
}

#table-panel #nameE {
  grid-area: nameE;
  
  display: flex;
  flex-direction: row;
  
  justify-content: flex-end;
  align-items: stretch;
  
  z-index: 0;
  
  outline: inherit;
}

#nameEBorder {
  outline: inherit;
  display: flex;
  flex: 1 0;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}


#inner-L {
  grid-area: tableL;
  display: flex;
  /*flex: 1 1 auto;*/
  flex: 0 0 30%;
  
  justify-content: flex-end;
  align-items: center;
  
  z-index: 100;
  
  outline: inherit;
  
  /*border: 2px solid rgba(0,0,0,0.06);*/
  /*border-radius: 5px;*/
}

#inner-R {
  grid-area: tableR;
  display: flex;
  /*flex: 1 1 auto;*/
  flex: 0 0 30%;
  
  justify-content: flex-start;
  align-items: center;
  
  z-index: 100;
  
  outline: inherit;
  
  /*border: 2px solid rgba(0,0,0,0.06);*/
  /*border-radius: 5px;*/
}

#inner-C-T {
  grid-area: tableCT;
  display: flex;
  flex-direction: column;
  /*flex: 1 1 auto;*/
  flex: 0 0 100%;
  
  min-height: 100%;
  max-height: 100%;
  
  justify-content: center;
  align-items: center;
  
  outline: inherit;
  
  /*border: 2px solid rgba(0,0,0,0.06);*/
  /*border-radius: 5px;*/
}

#inner-C-B {
  grid-area: tableCB;
  display: flex;
  /*flex: 1 1 auto;*/
  flex-direction: column;
  flex: 0 0 100%;
  
  min-height: 100%;
  max-height: 100%;
  
  justify-content: center;
  align-items: center;
  
  outline: inherit;
  
  /*border: 2px blue solid;*/
  /*border-radius: 5px;*/
}

.chair {
  /*flex: 0 0 10px;*/
  width: 10px;
  height: auto;
  
  filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.2));
  
  outline: inherit;
  
}

.player-area-cards {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;

  outline: inherit;
  
}

.card-appear-T-enter-active, .card-appear-T-leave-active {
  /*position: relative;*/
  transition: var(--active-transition);
  z-index: 1000;
}

.card-appear-T-leave-to {
  opacity: 0;
  z-index: 1000;
  transform: var(--transition);
  transition: var(--leave-duration);
}

.card-appear-T-enter {
  transform: translateY(-100%) rotate(200deg);
  z-index: 1000;
}

.card-appear-T-move {
  transition: transform var(--move-duration);
  z-index: 1000;
}

.card-appear-B-enter-active, .card-appear-B-leave-active {
  /*position: absolute;*/
  transition: var(--active-transition);
  z-index: 1000;
}

.card-appear-B-leave-to {
  /*position: relative;*/
  opacity: 0;
  z-index: 1000;
  transform: var(--transition);
  transition: var(--leave-duration);
}

.card-appear-B-enter {
  transform: translateY(100%) rotate(200deg);
  z-index: 1000;
}

.card-appear-B-move {
  transition: transform var(--move-duration);
  z-index: 1000;
}

.card-appear-L-enter-active, .card-appear-L-leave-active {
  /*position: absolute;*/
  transition: var(--active-transition);
  z-index: 1000;
}

.card-appear-L-leave-to {
  opacity: 0;
  z-index: 1000;
  transform: var(--transition);
  transition: var(--leave-duration);
}

.card-appear-L-enter {
  transform: translateX(-100%) rotate(200deg);
  z-index: 1000;
}

.card-appear-L-move {
  transition: transform var(--move-duration);
  z-index: 1000;
}

.card-appear-R-enter-active, .card-appear-R-leave-active {
  /*position: absolute;*/
  transition: var(--active-transition);
  z-index: 1000;
}

.card-appear-R-leave-to {
  opacity: 0;
  z-index: 1000;
  transform: var(--transition);
  transition: var(--leave-duration);
}

.card-appear-R-enter {
  transform: translateX(100%) rotate(200deg);
  z-index: 1000;
}

.card-appear-R-move {
  transition: transform var(--move-duration);
  z-index: 1000;
}

.showChosenTrump-enter-active, .showChosenTrump-leave-active {
  /*position: absolute;*/
  transition: opacity 1s;
}

.showChosenTrump-enter, .showChosenTrump-leave-to {
  opacity: 0;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  flex: 1 0;
  
  font-size: 20px;
  color: var(--tertiaryColor);
  
  background-color: var(--mainColor);
  
  margin: 5px;
  padding: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  
  outline: inherit;
}

.trumpHeader {
  margin: 3px;
  outline: inherit;
}

.points {
  /*border: 2px darkblue solid;*/
  margin: 5px;
  font-size: 25px;

  /*font-size: 1.75rem;*/
  color: var(--tertiaryColor);
  /*text-shadow: 1px 1px 8px rgba(0, 0, 0, 1);*/
  cursor: default;
  outline: inherit;
}

.player-points {
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 50px;
  height: 2rem;
  /*padding: 0.1rem;*/
  /*margin: 5px;*/
  /*padding: 5px;*/
  background: var(--mainColor);
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  /*position: absolute;*/
  z-index: 120;
  /*left: -30px;*/
  text-align: center;
  flex: 0 1 0;
  /*align-self: flex-start;*/
  /*margin: 3px;*/
  /*border: 3px hidden red;*/

  outline: inherit;
}

@media (max-width:450px){
  .points {
    /*border: 2px darkblue solid;*/
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 20px;

    /*font-size: 1.75rem;*/
    color: var(--tertiaryColor);
    /*text-shadow: 1px 1px 8px rgba(0, 0, 0, 1);*/
    cursor: default;
    outline: inherit;
  }
  .player-points {
    display: flex;
    justify-content: center;
    align-items: center;

    width: max(5vw, 55px);

    height: 1.5rem;
    /*padding: 0.1rem;*/
    /*margin: 5px;*/
    /*padding: 5px;*/
    background: var(--mainColor);
    border-radius: 10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    /*position: absolute;*/
    z-index: 120;
    /*left: -30px;*/
    text-align: center;
    flex: 0 1 auto;
    /*align-self: flex-start;*/
    /*margin: 3px;*/
    /*border: 3px hidden red;*/

    outline: inherit;
  }
}

</style>
