<template>
  <div id="container">
    <div class="Header">
      <h1 class="leaderBoardTitleMain" @click="openHOFInfo">
        Onur Listesi
      </h1>
    </div>
    <div v-if="hallOfFame">
      <competitors
        style="margin-bottom: 10px"
        v-show=true
        :list="hallOfFame"
        id="topXMonthly">
      </competitors>
    </div>
  </div>
</template>

<script>

import app from '@/firebase.setup'
import Competitors from "@/components/leaderboard/Competitors"

const db = app.firestore()

export default {
  components: {
    Competitors
  },
  data: () => ({
    users: null,
    ranks: null
  }),
  async created() {
    [this.users, this.ranks] = await this.getChamps(db)
  },
  methods: {
    openHOFInfo() {
      this.$emit('openHOFInfo')
    },
    getChamps: async function (db){
      const champsRef = db.collection('kingRatedChamps').orderBy('star', 'desc')
      const champsSnapshot = await champsRef.get()

      const board = {}
      const ranks = []
      let i = 1;
      champsSnapshot.forEach((doc) => {
        board[i] = doc.data()
        ranks[i] = i
        i += 1
      });

      // rank object if equal
      let benchmark = board[1]["star"]
      let benchmark_rank = 1
      for(let j=2; j<i; j++){
        if(benchmark === board[j]['star']){
          ranks[j] = benchmark_rank
        }
        else {
          benchmark = board[j]['star']
          benchmark_rank = j
        }
      }
      return [board, ranks]
    }
  },
  computed: {
    hallOfFame(){
      if (this.users) {
        return Object.keys(this.users).map(id => ({"rank": this.ranks[id], "name":this.users[id]['name'], "points": this.users[id]['star']+" ⭐"}))
      }
      else {
        return null
      }

    }
  }
}

</script>

<style lang="css" scoped>
.leaderBoardTitleMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  width:auto;
  align-self: center;
  font-size: 18px;


  background-color: var(--mainColor);
  /*height: 40px;*/
  border-radius: 3px;
  line-height: 36px;
  text-align: center;
  color: var(--tertiaryColor);
  text-transform: uppercase;
  font-weight: 700;
  /*padding: 0 34px;*/
  /*box-shadow: 0 4px 16px 0 rgba(0, 0, 0, .3);*/

  outline: inherit;
}
.Header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  width: 90%;
  z-index: 1;
  max-width: 625px;
  margin: 0 auto;
  position: relative;
  border-top: 3px solid var(--leader1);
  background-color: var(--leader2);

  border-top-left-radius: min(1vh, 5);
  border-top-right-radius: min(1vh, 5);

  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}
</style>