import { render, staticRenderFns } from "./Batak.vue?vue&type=template&id=412c47aa&scoped=true&"
import script from "./Batak.vue?vue&type=script&lang=js&"
export * from "./Batak.vue?vue&type=script&lang=js&"
import style0 from "./Batak.vue?vue&type=style&index=0&id=412c47aa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "412c47aa",
  null
  
)

export default component.exports