import {deck_template} from "./deck";

const turnDirection = {
  'N': 'E',
  'E': 'S',
  'S': 'W',
  'W': 'N',
};

export function nextSeat(seat){
  return turnDirection[seat]
}

export function suitsInHandFunction(hand) {
  const deck = {...deck_template}
  const suitsInHand = []
  for (const cardId in hand) {
    if (!suitsInHand.includes(deck[cardId].suit)) {
      suitsInHand.push(deck[cardId].suit)
    }
  }
  return suitsInHand
}

export function orderHand(hand, suit = '', algo = 'least') {
  const deck = {...deck_template}
  let sort_fn
  if (algo === 'least') {
    sort_fn = (a, b) => {
      return deck[a].rank - deck[b].rank;
    }
  } else {
    sort_fn = (a, b) => {
      return deck[b].rank - deck[a].rank;
    }
  }
  let suit_list = []
  if (suit === '') {
    suit_list = ['hearts', 'spades', 'clubs', 'diamonds']
  } else {
    // @ts-ignore
    suit_list = [suit]
  }

  if (suit_list[0]) {
    return Object.keys(hand).sort(sort_fn).filter(cardId => suit_list.includes(deck[cardId].suit))
  } else {
    return []
  }
}


export function howMany(cards, attributeName, attributeValue) {
  const deck = {...deck_template}
  if (cards.length === 0) {
    return 0
  } else {
    const frs = cards.map((key) => {
      if (deck[key][attributeName] === attributeValue) {
        return 1
      } else {
        return 0
      }
    })
    return frs.reduce((a, b) => {
      return a + b
    }, 0)
  }
}

export function isKozRevealed(table) {
  //koz cikti mi?
  const deck = {...deck_template}
  let kozRevealed = table.kozRevealed
  const cards = table.cards

  if (table.koz) {
    for (const s in cards) {
      if (deck[cards[s]].suit === table.koz) {
        kozRevealed = true
      }
    }
  } else if (table.ceza === 'rifki' || table.ceza === 'kupa') {
    for (const s in cards) {
      if (deck[cards[s]].suit === 'hearts') {
        kozRevealed = true
      }
    }
  }
  return kozRevealed
}

export function isKozRevealedOnTable(cards, koz) {
  for (const seat in cards){
    if(deck_template[cards[seat]].suit === koz) {
      return true
    }
  }
  return false
}

export function handWithoutSmallest(hand, pCards) {
  const handWOSmallest = {}
  const deck = {...deck_template}
  for (const cardId in hand) {
    const suit = deck[cardId].suit
    for (let i = 2; i < deck[cardId].rank; i++) {
      if (!Object.keys(hand).includes(mapRank2Id(suit, i)) && !pCards.includes(mapRank2Id(suit, i))) {
        handWOSmallest[cardId] = cardId
        break
      }
    }
  }
  return handWOSmallest
}

export function normalDeck(pCards) { // played cards are removed such bigger cards for the suit have one less rank
  const deck = {...deck_template}
  const nDeck = JSON.parse(JSON.stringify(deck_template));
  for (const cardId of pCards) {
    const suit = deck[cardId].suit
    for (const suitCard of Object.keys(deck).filter(x => deck[x].suit === suit)) {
      if (deck[suitCard].rank > deck[cardId].rank) {
        nDeck[suitCard].rank -= 1
      }
    }
  }
  return nDeck
}

export function normalHand(hand, nDeck) {
  const nHand = {}
  for (const cardId in hand) {
    nHand[cardId] = nDeck[cardId].rank
  }
  return nHand
}

export function playedCards(game) {
  let pCards = [];
  if (game.history && game.history.playedCards) {
    pCards = Object.keys(game.history.playedCards);
  }
  return pCards
}

export function playedCardsBySuit(game) {
  const deck = {...deck_template}
  let pCards = playedCards(game)
  if (game.table['cards']) {
    for (const s in game.table.cards) {
      pCards.push(game.table.cards[s]);
    }
  }

  return {
    'spades': pCards.filter(x => deck[x].suit === 'spades'),
    'hearts': pCards.filter(x => deck[x].suit === 'hearts'),
    'clubs': pCards.filter(x => deck[x].suit === 'clubs'),
    'diamonds': pCards.filter(x => deck[x].suit === 'diamonds')
  }
}

export function playedCardsBySuitWOTable(game) {
  const deck = {...deck_template}
  let pCards = [];
  if (game.history && game.history.playedCards) {
    pCards = Object.keys(game.history.playedCards);
  }

  return {
    'spades': pCards.filter(x => deck[x].suit === 'spades'),
    'hearts': pCards.filter(x => deck[x].suit === 'hearts'),
    'clubs': pCards.filter(x => deck[x].suit === 'clubs'),
    'diamonds': pCards.filter(x => deck[x].suit === 'diamonds')
  }
}

export function orderSuitsByPlayed(game, unFinishedS = ['spades', 'hearts', 'clubs', 'diamonds']) {
  const pCBySuit = playedCardsBySuit(game)
  return Object.keys(pCBySuit).filter(x => unFinishedS.includes(x))
    .sort((a, b) => pCBySuit[a] - pCBySuit[b])
}

export function orderSuitsByLength(hand, unFinishedS = ['spades', 'hearts', 'clubs', 'diamonds']) {
  const deck = {...deck_template}
  const suits = []
  if (hand && unFinishedS.includes('spades')) {
    suits.push({'suit': 'spades', 'length': Object.keys(hand).filter(x => deck[x].suit === 'spades').length})
  }
  if (hand && unFinishedS.includes('hearts')) {
    suits.push({'suit': 'hearts', 'length': Object.keys(hand).filter(x => deck[x].suit === 'hearts').length})
  }
  if (hand && unFinishedS.includes('diamonds')) {
    suits.push({'suit': 'diamonds', 'length': Object.keys(hand).filter(x => deck[x].suit === 'diamonds').length})
  }
  if (hand && unFinishedS.includes('clubs')) {
    suits.push({'suit': 'clubs', 'length': Object.keys(hand).filter(x => deck[x].suit === 'clubs').length})
  }
  return suits.filter(x => x['length'] > 0).sort((x, y) => x['length'] - y['length']).map(x => x['suit'])
}

export function mapRank2Id(suit, rank) {
  if (suit === 'hearts') {
    let rank_str = rank.toString()
    if (rank_str.length === 1) {
      rank_str = '0' + rank_str
    }
    return '1' + rank_str
  } else if (suit === 'clubs') {
    let rank_str = rank.toString()
    if (rank_str.length === 1) {
      rank_str = '0' + rank_str
    }
    return '2' + rank_str
  } else if (suit === 'diamonds') {
    let rank_str = rank.toString()
    if (rank_str.length === 1) {
      rank_str = '0' + rank_str
    }
    return '3' + rank_str
  } else if (suit === 'spades') {
    let rank_str = rank.toString()
    if (rank_str.length === 1) {
      rank_str = '0' + rank_str
    }
    return '4' + rank_str
  } else {
    return ''
  }
}

export function getInstalledGames(configPath) {
  // const path = require('path');
  // const absPath = path.resolve(__dirname, configPath)
  const envVars = getConfig(configPath)
  return envVars["games"]
}

export function getConfig(configPath) {
  return require(configPath);
}

// function getEnvVars(gamesPath){
//   const fs = require('fs');
//   const path = require('path');
//   const absPath = path.resolve(__dirname, gamesPath)
//
//   const text = fs.readFileSync(absPath, "utf-8");
//   const textByLine = text.split("\n")
//
//   const envVars = {}
//   for(const line of textByLine){
//     envVars[line.split('=')[0].trim()] = line.split('=')[1].trim()
//   }
//   return envVars
// }


// export function prettyPrint(hand, seat, round) {
//   let res = round+seat+ ': ♠️ ' + Object.keys(hand).filter(x => deck_template[x].suit === 'spades').map(x => deck_template[x].name.substring(0,1)) + ' '
//   res += '♥️️️ ' + Object.keys(hand).filter(x => deck_template[x].suit === 'hearts').map(x => deck_template[x].name.substring(0,1)) + ' '
//   res += '♣️️️️ ' + Object.keys(hand).filter(x => deck_template[x].suit === 'clubs').map(x => deck_template[x].name.substring(0,1)) + ' '
//   res += '♦️️ ' + Object.keys(hand).filter(x => deck_template[x].suit === 'diamonds').map(x => deck_template[x].name.substring(0,1))
//   console.log(res)
// }