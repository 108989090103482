<template>
  <div class="CompetitorList">
    <row
      v-for="player in list"
      :key=player.id
      :data="player"
      class="Player animated"
      stagger="100"
      enter-active-class="animated flipInX"
      leave-active-class="animated fadeOutLeft"
    >
    </row>
  </div>
</template>

<script>
// import app from '../firebase.setup'
import Row from "@/components/leaderboard/Row";

export default {
  name: 'Competitors',
  components: {
    Row
  },
  data: () => ({}),
  props: ['list'],
  created() {
  },
  methods: {}
}
</script>


<style lang="scss" scoped>

#container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Header, .Player {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

hr {
  outline: 0;
  border: 0;
  height: 0;
  margin: 0;
}

hr.pad2 {
  padding: 1% 0;
}

hr.pad5 {
  padding: 2.5% 0;
}

hr.pad10 {
  padding: 5% 0;
}

nav {
  width: 100%;
  background-color: #333333;
}

nav img {
  display: block;
  width: 150px;
  margin: 0 auto;
  padding: 33px 0;
}

.CompetitorList {
  width: 90%;
  max-width: 625px;
  display: block;
  margin: 0 auto;
}

.Player {
  width: 100%;
  margin: 1px 0;
  display: flex;
  align-items: center;
}

.Player .Player__rank {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 68px;
  width: 68px;
  flex-basis: 68px;
  background-color: #0A96C8;
}

.Player .Player__rank h5 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #FFFFFF;
  margin: 0;
  padding: 0;
}

.Player .Player__name {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #333333;
  margin: 0;
  padding: 0 0 0 19px;
  flex: 1;
}

.Player .Player__reps {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #333333;
  margin: 0;
  padding: 0 19px 0 0;
  flex: 1;
  text-align: right;
  align-self: flex-right;
}

::selection {
  background-color: #F5617A;
  color: #fff;
}

::-moz-selection {
  background-color: #F5617A;
  color: #fff;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder { /* Firefox 18- */
  text-align: center;
}

::-moz-placeholder { /* Firefox 19+ */
  text-align: center;
}

:-ms-input-placeholder {
  text-align: center;
}
</style>
