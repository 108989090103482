export const appCopy = {
    'months': {
        "1": "Ocak",
        "2": "Şubat",
        "3": "Mart",
        "4": "Nisan",
        "5": "Mayıs",
        "6": "Haziran",
        "7": "Temmuz",
        "8": "Ağustos",
        "9": "Eylül",
        "10": "Ekim",
        "11": "Kasım",
        "12": "Aralık"
    },
    'App': {
        'defaultName': 'Sen',
        'roundCopy': '. Tur',
        'change': 'Değİştİr',
        'ok': 'Tamam',
        'cancel': 'Geri Dön',
        'quitWarning': 'Önceki oyununuzu terk edip linkteki oyuna gireceksiniz. Kabul ediyor musunuz?',
        'noMoreCommonPenaltyAlert': ' kalmadı. Başka bir ceza seçin.',
        'noMorePlayerPenaltyAlert': 'Koz seçin. Ceza hakkınız kalmadı.',
        'noMorePlayerTrumpAlert': 'Ceza seçin. Koz hakkınız kalmadı.'
    },
    'ModalUserProfile': {
        'placeholderUsername': 'Kullanıcı Adı',
        'placeholderPassword': 'Şifre',
        'placeholderPasswordConfirm': 'Şifre Tekrar',
        'placeholderEmail': 'E-posta',
        'back': 'Geri',
        'signOutCopy': 'Çıkış',
        'signIn': 'Giriş',
        'signInTitle': 'Giriş Yap',
        'signUpTitle': 'Kayıt Ol',
        'newUserTitle': 'Yeni Hesap Oluştur',
        'enter': 'Devam',
        'or': 'veya',
        'newUserCopy': 'Ücretsiz üye ol',
        'oldUserCopy': 'Hesabınız varsa buradan giriş yapın.',
        'createCopy': 'Yeni hesap oluşturun.',
        'signInWithGoogle': 'Google ile devam et',
        'renameUser': 'Kullanıcı adını değiştir',
        'quitWarning': 'Siteden çıkmanız oyunu da terk etmenize neden olur. Siteden çıkmak ve oyunu terk etmek istediğinizden emin misiniz?',
        'signInAdvantage': 'Siteye giriş yapın, oyununuz bulutta kayıtlı kalsın.',
        'userNameMissingAlert': 'Kullanıcı adı girmelisiniz.',
        'passwordMissingAlert': 'Şifre girmelisiniz.',
        'emailMissingAlert': 'E-posta adresinizi girmelisiniz.',
        'badlyFormatedEmailAlert': 'Geçersiz bir E-posta adresi girdiniz.',
        'emailAlreadyInUseAlert': 'Bu E-posta adresi zaten kullanımdadır.',
        'weakPasswordAlert': 'Şifre en az 6 haneli olmalı.',
        'noPasswordMatchAlert': 'Aynı şifreyi tekrarlayınız.',
        'genericErrorAlert': 'Lütfen aşağıdaki hata kodunu kopyalayarak king@kagitoyna.com adresine gönderin. ',
        'userNotFoundAlert': 'Bu E-posta adresi sistemimizde kayıtlı değil.'
    },
    'ModalMenu': {
        'gameCode': 'Masa kodu:',
        'gameHost': 'Masa sahibi:',
        'quitGame': 'Masayı terk et',
        'restartGame': 'Baştan başlat',
        'renameUser': 'Kullanıcı adını değiştir',
        'howToPlayKing': 'King nasıl oynanır?',
        'contactUs': 'İletişime geç',
        'followUs': 'Takip et',
        'restart': 'Oyunu baştan başlatmak istediğinizden emin misiniz?',
        'restartOnlyHost': 'Yalnızca masayı açan oyuncu oyunu baştan başlatabilir.',
        'quitAlert': 'Oyunu terk etmek istediğinizden emin misiniz?'
    },
    'ModalUserName': {
        'userName': 'Kullanıcı Adı',
        'back': 'Geri',
        'takeSeat': 'Otur',
        'userNameLengthAlert': 'Kullanıcı adınız en fazla on karakterden oluşabilir.',
        'userNameFirstLetterAlert': 'Kullanıcı adınızın ilk karakteri rakam ya da harf olmalıdır.',
        'userAlreadyExists': 'Seçtiğiniz kullanıcı adı kullanımda. Başka bir ad seçiniz.',
        'nameTooShort': 'Kullanıcı adınız en az üç haneli olmalıdır.'
    }
}