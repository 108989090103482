<template>
  <div id="number-pad">
    <div id="no0" class="number-key" @click="chosenNumberHands(0)">El Almaz</div>
    <div id="no13" class="number-key" @click="chosenNumberHands(13)">13</div>
    <div id="no12" class="number-key" @click="chosenNumberHands(12)">12</div>
    <div id="no11" class="number-key" @click="chosenNumberHands(11)">11</div>
    <div id="no10" class="number-key" @click="chosenNumberHands(10)">10</div>
    <div id="no9" class="number-key" @click="chosenNumberHands(9)">9</div>
    <div id="no8" class="number-key" @click="chosenNumberHands(8)">8</div>
    <div id="no7" class="number-key" @click="chosenNumberHands(7)">7</div>
    <div id="no6" class="number-key" @click="chosenNumberHands(6)">6</div>
    <div id="no5" class="number-key" @click="chosenNumberHands(5)">5</div>
    <div id="no4" class="number-key" @click="chosenNumberHands(4)">4</div>
    <div id="no3" class="number-key" @click="chosenNumberHands(3)">3</div>
    <div id="no2" class="number-key" @click="chosenNumberHands(2)">2</div>
    <div id="no1" class="number-key" @click="chosenNumberHands(1)">1</div>
  </div>
</template>

<script>

export default {
  name: 'NumberPad',
  props: [],
  data: () => ({
    chosenNumber: null
  }),
  created() {
  },
  computed: {
  },
  methods: {
    chosenNumberHands(num){
      this.$emit('chosenNumber', num);
    }
  },
};
</script>

<style scoped>

#number-pad {
  display: grid;
  grid-template-columns: calc(25% ) calc(25% ) calc(25% ) calc(25% );
  grid-template-rows: calc(25% ) calc(25% ) calc(25% ) calc(25% );
  grid-template-areas:
            "no10     no11     no12  no13"
            "no6     no7    no8  no9"
            "no2     no3     no4   no5"
            "no0     no0     no1   no1";
  flex: 1 0;

  outline: inherit;
}

#no0 {
  font-size: 3vmin;
}

.number-key {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 2px;
  padding: 10px;

  cursor: pointer;

  background-color: #24da7e7f;
  /*background-color: var(--mainColor)+'7f';*/
  text-align: center;

  font-size: 4vmin;

  border-radius: 5px;
}

.number-key:hover {
  background-color: #24da7e;
}

#number-pad #no0 {
  grid-area: no0;
}
#number-pad #no1 {
  grid-area: no1;
}
#number-pad #no2 {
  grid-area: no2;
}
#number-pad #no3 {
  grid-area: no3;
}
#number-pad #no4 {
  grid-area: no4;
}
#number-pad #no5 {
  grid-area: no5;
}
#number-pad #no6 {
  grid-area: no6;
}
#number-pad #no7 {
  grid-area: no7;
}
#number-pad #no8 {
  grid-area: no8;
}
#number-pad #no9 {
  grid-area: no9;
}
#number-pad #no10 {
  grid-area: no10;
}
#number-pad #no11 {
  grid-area: no11;
}
#number-pad #no12 {
  grid-area: no12;
}
#number-pad #no13 {
  grid-area: no13;
}



</style>
