import { deck_template } from "./deck";

const deck = JSON.parse(JSON.stringify(deck_template));

export function sleep(milliseconds) {
  let start = new Date().getTime();
  for (let i = 0; i < 1e7; i++) {
    if ((new Date().getTime() - start) > milliseconds){
      break;
    }
  }
}

export function pad0(num, num0=2) {
    let str = num.toString()
    const p0 = num0 - str.length;
    if (p0 < 0) {
        console.log('string is longer than targeted length');
        throw new Error;
    }
    for (let i=0; i < p0; i++) {
        str = '0'+str;
    }
    return str
}

export function getTimestamp() {
	const now = new Date();
	const dd = now.getDate();
	const mm = now.getMonth()+1;
	const yyyy = now.getFullYear();
	const hours =now.getHours();
	const minutes =now.getMinutes();
	const seconds =now.getSeconds();
	return yyyy.toString()+pad0(mm)+pad0(dd)+pad0(hours)+pad0(minutes)+pad0(seconds)
}

export function isBot(seat, game) {
	if(game.usersInGame && game.usersInGame[seat]) {
		const userName = game.usersInGame[seat].name;
		return userName.substring(0, 4) === '_bot';
	}
	else {
		return false
	}
}

export function dealAllCardsToPlayers() {
	const myDeck = JSON.parse(JSON.stringify(deck))
	const players = {'S':'S', 'E':'E', 'N':'N', 'W':'W'}
	const card_per_player = Math.floor(Object.keys(myDeck).length / Object.keys(players).length);

	const hands = {};
	for(const k in players) {
		const hand = {};
		for (let i = 0; i < card_per_player; i++) {
			const v = drawCardFromDeck(myDeck)
			hand[v.id] = v.id
		}
		hands[k] = hand
	}
	return hands
}

export function dealAllCardsToPlayersHigherThan10(){
	let hands;
	let valid_hands = true
	while(valid_hands){
		hands = dealAllCardsToPlayers();
		valid_hands = anyHandNotHigherThan10(hands)
	}
	return hands
}

export function anyHandNotHigherThan10(hands){
	let isValid = 0;
	for(let hand in hands) {
		isValid = 0;
		for(const card in hands[hand]){
			if(deck[hands[hand][card]]['rank'] > 10){
				isValid = 1
			}
		}
		if (isValid === 0) {
			break
		}
	}
	if(isValid === 0) {
		return true
	}
	return false
}

function drawCardFromDeck(myDeck) {
    const keys = Object.keys(myDeck)
    const randomKey = keys[getRandomInt(0, keys.length - 1)]
    const card = myDeck[randomKey]
    delete myDeck[randomKey]
    return card;
}

function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export async function delay(ms) {
	return new Promise(resolve => {
        setTimeout(resolve, ms);
	});
}

function convertToISODate(s) {
	let t;
	if(s.length === 14) {
		t = s.substring(0,4)+'-' + s.substring(4,6) + '-' + s.substring(6,8) +
			'T'+ s.substring(8,10)+':'+ s.substring(10,12) + ':' + s.substring(12,14);
	}
	else {
		t = s.substring(0,4)+'-' + s.substring(4,6) + '-' + s.substring(6,8) +
			'T'+ s.substring(8,10)+':' + s.substring(10,12) + ':00';
	}
	return t
}

export function getSecondsSinceEpoch(s) {
	return Date.parse(convertToISODate(s))/1000
}

export function formatTimeInSecToMinSec(s) {
	return Math.floor(s/60) +  '\'' + s%60 + '\'\''
}

export function getInstalledGames() {
	const games = process.env.VUE_APP_GAMES
	return games.split('|')
}