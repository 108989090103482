<template>
  <div id="invite-panel">
    <modal-user-name
      :user="user"
      :seat="seat"
      v-show="formOpen"
      @renameUser="chooseSeat"
      @modalUserNameClose="closeLogin"
    >
    </modal-user-name>
    <div id="top-area">
      <div v-if="!usersInGame['N']"
           style=" display: flex; flex: 1 0; justify-content: center; align-items: center; flex-direction: column; margin-top: 5px">
        <div>
          <i class="fa-duotone fa-chair-office fa-6x chair"></i>
        </div>
        <div v-if="user.seat" class="addbot" @click="addBot('N')">{{ compCopy.addBot }}</div>
        <div v-if="!user.seat" class="addbot" @click="seat='N'; formOpen = true;">{{ compCopy.sit }}</div>
      </div>
      <div v-else>
        <b class="text" style="font-size: 30px">{{ screenNames['N'] }}</b>
      </div>
    </div>
    <div id="left-area">
      <div v-if="!usersInGame['W']"
           style="display:flex; flex-direction: row; justify-content: flex-end; align-items: center; outline: inherit; ">
        <!--                <img class="chair" src="../assets/chair.png" alt="chair">-->
        <!--                <i class="fad fa-chair-office fa-6x chair"></i>-->
        <i class="fa-duotone fa-chair-office fa-6x chair" style="margin-left: 5px"></i>
        <!--                <i class="fal fa-robot"></i>-->
        <div v-if="user.seat" class="addbot" @click="addBot('W')">{{ compCopy.addBot }}</div>
        <div v-if="!user.seat" class="addbot" @click="seat='W'; formOpen = true;">{{ compCopy.sit }}</div>
      </div>
      
      <div v-else>
        <b class="text" style="font-size: 25px; margin-left:50px">{{ screenNames['W'] }}</b>
      </div>
    </div>
    <div id="center-area-top">
      <div id="center-area-top-inner">
        <div class="text"
             style="display: flex; margin: 10px; justify-content: center; font-size: 1.4rem; outline: inherit;">
          {{ compCopy.copyShare }}
        </div>
        <code id="inviteLink" v-clipboard:copy=gameUrl v-clipboard:success="onCopy" v-clipboard:error="onError"
              class="pointer">
          <!--                <img src="../assets/icons8-copy-to-clipboard-100.png" height="15px" width="15px">-->
          <!--                    <i class="fal fa-copy"></i>-->
          <i class="fa-duotone fa-copy"></i>
          
          {{ gameUrlwoHttp }}
        </code>
        <!--                <div style="flex: 0 0;-->
        <!--                -->
        <!--                            height: 80px;-->
        <!--                            margin: 20px;-->
        <!--                            flex-direction: row;-->
        <!--                            display: flex;-->
        <!--                            flex-wrap: wrap;-->
        <!--                            justify-content: space-evenly;-->
        <!--                            align-items: center;-->
        <!--                            outline: inherit;">-->
        <!--                    <ShareNetwork-->
        <!--                            network="whatsapp"-->
        <!--                            class="hover"-->
        <!--                            :url=gameUrl-->
        <!--                            title="Kagitoyna'da bana katıl, King oynayalım."-->
        <!--                    >-->
        <!--                        <i class="fab fa-whatsapp fa-3x  pointer"-->
        <!--                           style=" filter:drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.2)); outline:inherit"></i>-->
        <!--    -->
        <!--    -->
        <!--                    </ShareNetwork>-->
        <!--                    <ShareNetwork-->
        <!--                            network="twitter"-->
        <!--                            class="hover"-->
        <!--                            :url=gameUrl-->
        <!--                            title="Kagitoyna'da bana katıl, King oynayalım."-->
        <!--                            :description=gameUrlMessage-->
        <!--                    >-->
        <!--                        <i class="fab fa-twitter fa-3x  pointer"-->
        <!--                           style=" filter:drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.2))"></i>-->
        <!--                    </ShareNetwork>-->
        <!--                    <ShareNetwork-->
        <!--                            network="facebook"-->
        <!--                            class="hover"-->
        <!--                            url="https://kagitoyna.com"-->
        <!--                            title="Kagitoyna'da bana katıl, King oynayalım."-->
        <!--                            :description=gameUrlMessage-->
        <!--                    >-->
        <!--                        <i class="fab fa-facebook-f fa-3x  pointer"-->
        <!--                           style=" filter:drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.2))"></i>-->
        <!--                    </ShareNetwork>-->
        <!--                    <ShareNetwork-->
        <!--                            network="telegram"-->
        <!--                            class="hover"-->
        <!--                            :url=gameUrl-->
        <!--                            title="Kagitoyna'da bana katıl, King oynayalım."-->
        <!--                            :description=gameUrlMessage-->
        <!--                    >-->
        <!--                        <i class="fab fa-telegram-plane fa-3x  pointer hover"-->
        <!--                           style=" filter:drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.2))"></i>-->
        <!--                    </ShareNetwork>-->
        <!--                </div>-->
        <div style=" font-size: 1.3rem; margin: 20px"> {{ compCopy.inviteCopy1 }}
          <br> {{ compCopy.inviteCopy2 }}
          <strong> {{ compCopy.inviteCopy3 }} </strong> {{ compCopy.inviteCopy4 }}
        </div>
      </div>
    </div>
    <div id="right-area">
      <div v-if="!usersInGame['E']"
           style="z-index:100; display:flex; flex-direction: row; justify-content: flex-end; align-content: center; align-items: center; outline: inherit;">
        <div v-if="user.seat" class="addbot" @click="addBot('E')">{{ compCopy.addBot }}</div>
        <div v-if="!user.seat" class="addbot" @click="seat='E'; formOpen = true;">{{ compCopy.sit }}</div>
        <!--                <img class="chair" src="../assets/chair.png" alt="chair">-->
        <!--                <i class="fad fa-chair-office fa-6x chair"></i>-->
        <i class="fa-duotone fa-chair-office fa-6x chair" style="margin-right: 5px"></i>
      </div>
      <div v-else>
        
        <b class="text" style="font-size: 25px; margin-right:50px">{{ screenNames['E'] }}</b>
      </div>
    </div>
    <div id="bottom-area">
      <div v-if="!usersInGame['S']"
           style="display: flex; flex: 1; justify-content: center; align-items: center; flex-direction: column; margin-top: 10px">
        <div v-if="user.seat" class="addbot" @click="addBot('S')">{{ compCopy.addBot }}</div>
        <div v-if="!user.seat" class="addbot" @click="seat='S'; formOpen = true;">{{ compCopy.sit }}</div>
      </div>
      <div v-else>
        <b class="text" style="font-size: 30px; margin-bottom: 20px">{{ this.screenNames['S'] }}</b>
      </div>
    </div>
  </div>

</template>

<script>
import app from "@/firebase.setup";
import ModalUserName from "@/components/games/king/user/ModalUserName";
import {kingCopy} from "@/js/king/kingCopy";

export default {
  name: "InvitePanel",
  props: ["gameMeta", "usersInGame", "user", "relDir", "screenNames"],
  data: () => ({
    username: "Sen",
    seat: '',
    formOpen: false
  }),
  created() {
    this.compCopy = kingCopy.InvitePanel
  },
  components: {
    ModalUserName
  },
  methods: {
    closeLogin() {
      this.formOpen = false;
      // this.resetForm();
    },
    onCopy: function () {
      this.$alert(this.compCopy.copyGameCodeAlert)
    }
    ,
    onError: function (e) {
      this.$alert('Failed to copy texts' + e.text)
    }
    ,
    howMany(cards, attributeName, attributeValue) {
      if (!cards) {
        return 0
      } else {
        return Object.keys(cards).map((key) => {
          if (cards[key][attributeName] === attributeValue) {
            return 1
          } else {
            return 0
          }
        }).reduce((a, b) => a + b)
      }
    },
    addBot(seat) {
      this.$emit('add-bot', seat)
    },
    async chooseSeat(data) {
      this.$emit('setIsLoading', true);
      await this.$emit('registerUser',
        {
          'id': this.user.id,
          'name': data.name,
          'seat': data.seat,
          'gameId': this.user.gameId,
          'gameType': 'kingFriendly',
          'gameCategory': 'king'
        })
      
      let addUser = app.functions().httpsCallable('kingFriendlyAddUser');
      await addUser({'seat': data.seat, 'gameType': 'kingFriendly', 'gameId': this.user.gameId, 'name': data.name});
      
      this.closeLogin()
      this.$emit('setIsLoading', false);
      return 'all good'
    },
  },
  computed: {
    gameUrl: function () {
      return process.env.VUE_APP_URL + '/' + this.user.gameId
    },
    gameUrlwoHttp: function () {
      const splitt = process.env.VUE_APP_URL.split('//')
      return splitt[splitt.length - 1] + '/' + this.user.gameId
    },
    gameUrlMessage: function () {
      return "Kagitoyna'da King oynar mısın? " + process.env.VUE_APP_URL + '/' + this.user.gameId
    },
  }
}


</script>

<style scoped>

#invite-panel {
  display: grid;
  grid-template-columns: 100px calc(50% - 100px) calc(50% - 100px) 100px;
  grid-template-rows: 280px  calc(33% - 80px) calc(33% - 100px) calc(34% - 100px);
  grid-template-areas:
        " tableCT   tableCT tableCT  tableCT "
        ".          tableT tableT  . "
        "tableL     tableL tableR  tableR"
        "tableB     tableB tableB  tableB";
  flex: 1 0;
  outline: inherit;
}


#invite-panel #top-area {
  grid-area: tableT;
  
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;
  
  flex: 1 0;
  
  z-index: 210;
  outline: inherit;
}

#invite-panel #bottom-area {
  grid-area: tableB;
  
  display: flex;
  flex-direction: column;
  
  justify-content: flex-start;
  align-items: center;
  
  flex: 1 0;
  
  z-index: 200;
  
  outline: inherit;
}

#invite-panel #left-area {
  grid-area: tableL;
  
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: flex-start;
  
  flex: 1 0;
  
  z-index: 200;
  
  outline: inherit;
}

#invite-panel #center-area-top {
  grid-area: tableCT;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  flex: 1 1;
  
  z-index: 200;
  outline: inherit;
}

#center-area-top-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
  /*border: 2px dashed black;*/
  padding: 20px;
  margin-top: 10px;
  
  flex: 0 0 auto;
  
  z-index: 200;
  outline: inherit;
}

#invite-panel #center-area-bottom {
  grid-area: tableCB;
  
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
  
  flex: 1 1;
  
  z-index: 200;
  
  outline: inherit;
}


#invite-panel #right-area {
  grid-area: tableR;
  
  display: flex;
  flex-direction: column;
  flex: 1 0;
  
  justify-content: center;
  align-items: flex-end;
  
  z-index: 200;
  
  outline: inherit;
  
}

#inviteLink {
  z-index: 200;
  font-size: 1.8rem;
  border: 1px dashed darkblue;
  align-self: center;
  padding: 10px;
  margin: 2px;
  background-color: var(--fifthColor);
  align-items: center;
  align-content: center;
  justify-content: center;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.8);
  
  -webkit-transition: background-color 2s ease-out;
  -moz-transition: background-color 2s ease-out;
  -o-transition: background-color 2s ease-out;
  transition: background-color 2s ease-out;
}

#inviteLink:hover {
  background: var(--mainColor);
}

#copyClipboard {
  width: 48px;
  height: 48px;
  
  background-image: url('../../../assets/icons8-copy-to-clipboard-100.png');
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer
}

.fa-whatsapp {
  color: #24CD63;
  
  outline: inherit;
}

.fa-telegram-plane {
  color: #2CA0DB;
  outline: inherit;
}

.fa-facebook-messenger {
  color: #3A5895;
  outline: inherit;
}

.fa-facebook-f {
  color: #3A5895;
  outline: inherit;
}

.fa-twitter {
  color: #1C9DEB;
  outline: inherit;
}

.addbot {
  /*border: 1px solid red;*/
  
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 5px;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
  /*min-height: 25px;*/
  
  background-color: var(--mainColor);
  /*height: 40px;*/
  border-radius: 20px;
  line-height: 36px;
  text-align: center;
  color: var(--tertiaryColor);
  text-transform: uppercase;
  font-size: 0.875em;
  font-weight: 700;
  /*padding: 0 34px;*/
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, .5);
  
  outline: inherit;
}

.chair {
  /*flex: 0 0 10px;*/
  z-index: 90;
  width: 100px;
  height: auto;
  filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.2));
  outline: inherit;
}

</style>
