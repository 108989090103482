export const ratedLevels = {
  '1': {
    'name': 'Çaylak',
    'startPoints': 0,
    'endPoints': 5000
  },
  '2': {
    'name': 'Amatör',
    'startPoints': 5000,
    'endPoints': 15000
  },
  '3': {
    'name': 'Çırak',
    'startPoints': 15000,
    'endPoints': 30000
  },
  '4': {
    'name': 'Yancı',
    'startPoints': 30000,
    'endPoints': 50000
  },
  '5': {
    'name': 'Oyuncu',
    'startPoints': 50000,
    'endPoints': 100000
  },
  '6': {
    'name': 'Uzman',
    'startPoints': 100000,
    'endPoints': 175000
  },
  '7': {
    'name': 'Erbab',
    'startPoints': 175000,
    'endPoints': 250000
  },
  '8': {
    'name': 'Profesyonel',
    'startPoints': 250000,
    'endPoints': 500000
  },
  '9': {
    'name': 'Profesör',
    'startPoints': 500000,
    'endPoints': 1000000
  },
  '10': {
    'name': 'Maestro',
    'startPoints': 1000000,
    'endPoints': 10000000
  }
};

export const initScores = {
  'kingRated': {
    'played': 0,
    'winRate': 0,
    'wins': 0,
    'losses': 0,
    'quits':0,
    'points': 0,
    'bestTime': 59 * 60,
    'streak':0,
    'maxStreak':0,

    'maxPositives': 0,
    'minNegatives': 2000,
    'maxScore': 0,
    'scoreSum': 0,
    'playedSinceSPP': 0,
    'scorePerPlayed': 0
  },
  'batakRated': {
    'played': 0,
    'winRate': 0,
    'wins': 0,
    'losses': 0,
    'quits':0,
    'points': 0,
    'bestTime': 59 * 60,
    'streak':0,
    'maxStreak':0,

    'maxPositives': 0,
    'minNegatives': 2000,
    'maxScore': 0,
    'scoreSum': 0,
    'playedSinceSPP': 0,
    'scorePerPlayed': 0
  },
  'ihaleRated': {
    'played': 0,
    'winRate': 0,
    'wins': 0,
    'losses': 0,
    'quits':0,
    'points': 0,
    'bestTime': 59 * 60,
    'streak':0,
    'maxStreak':0,

    'maxPositives': 0,
    'minNegatives': 2000,
    'maxScore': 0,
    'scoreSum': 0,
    'playedSinceSPP': 0,
    'scorePerPlayed': 0
  }
};

export const gameAttributes = {
  'kingRated': {
    'round': 20
  },
  'batakRated': {
    'round': 11
  },
  'ihaleRated': {
    'round': 11
  },
}
