<template>
  <div id="koz-ceza-panel" v-if="usersInGame[relDir.W] && yazboz">
    <div id="nameW">
      <div id="nameWBorder" class="player-area">
        <b v-if="game && game.table.whoseTurn !== this.relDir.W" class="playername text" :class="{'nameTurnStaticRed': (this.game.dealer === this.relDir.W)}">
          {{ screenNames[this.relDir.W] }}</b>
        <b v-else class="playername text nameTurn">
          {{ screenNames[this.relDir.W] }}</b>
        <div v-if = contracts.W class="player-points"  style="margin-bottom: 5px; margin-left: 5px; align-self: flex-start">
          <span class="points">0/{{contracts.W}}</span>
        </div>
      </div>
    </div>
    
    <div id="rules-summary">
      <div style="display: flex; flex-direction: column; align-items: flex-start">
        <div class="info">
          ✅️ <b>Koz Maça ♠️</b>
        </div>
        <div class="info">
          👎 <b>Yan {{yanBatar}} batar</b>
        </div>
        <div class="info">
          ⏱️ <b>{{numberOfRounds}} Tur</b>
        </div>
      </div>
    </div>
    
    <div id="choose-numbers-panel" v-if="this.hand">
      <div id="ceza-panel">
        <div class="takes-how-many" style="cursor: default;"> {{whoseTurnName}} EL SÖYLÜYOR </div>
        <NumberPad v-if="whoseTurn === this.relDir.S"
          @chosenNumber="numberChosen"
        ></NumberPad>
        <div v-else-if="whoseTurn === this.relDir.W" style="font-size:5rem;width:100%;text-align:center; cursor: default">
          👈
        </div>
        <div v-if="whoseTurn === this.relDir.N" style="font-size:5rem;width:100%;text-align:center; cursor: default">
          👆
        </div>
        <div v-if="whoseTurn === this.relDir.E" style="font-size:5rem;width:100%;text-align:center; cursor: default">
          👉
        </div>
      </div>
    </div>
    
    <div id="nameE" v-if="usersInGame[relDir.E]">
      <div id="nameEBorder" class="player-area" >
          <b v-if="game && game.table.whoseTurn !== this.relDir.E" class="playername text" :class="{'nameTurnStaticRed': (this.game.dealer === this.relDir.E)}">
            {{ screenNames[this.relDir.E] }}</b>
          <b v-else class="playername text nameTurn">
            {{ screenNames[this.relDir.E] }}</b>
          <div v-if = contracts.E class="player-points" style="margin-bottom: 5px; margin-right: 5px; align-self: flex-end">
            <span class="points">0/{{contracts.E}}</span>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import {batakCopy} from "@/js/batak/batakCopy";
import NumberPad from "@/components/games/batak/NumberPad";

export default {
  name: "BatakHandNumberChooser",
  components: {NumberPad},
  props: ["gameMeta", "round", "usersInGame", "game", "yazboz", "user", "hand", "relDir", "screenNames", "firstMover",
    "yanBatar", "numberOfRounds"],
  data: () => ({
  }),
  created() {
    this.compCopy = batakCopy.HandNumberChooser;
  },
  methods: {
    numberChosen(num) {
      this.$emit('numberChosen', this.whoseTurn, num)
    },
  },
  computed: {
    contracts: function(){
      if (this.game && this.game.contracts) {
        return this.game.contracts
      } else {
        return null
      }
    },
    whoseTurn: function () {
      if (this.game && this.game.table && this.game.table.whoseTurn) {
        return this.game.table.whoseTurn
      } else {
        return this.firstMover
      }
    },
    whoseTurnName: function () {
      if (this.whoseTurn && this.game){
        const name = this.game.usersInGame[this.whoseTurn]['name']
        if (name[0] === '_') {
          return name.substring(9)
        }
        else {
          return name
        }
      }
      else {
        return null
      }
    }
  }
}
</script>

<style scoped>
.takes-how-many {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin: 5px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: default;
  /*width: 60px;*/
  /*height: 40px;*/
  /*min-height: 25px;*/
  
  background-color: var(--mainColor);
  /*height: 40px;*/
  border-radius: 20px;
  line-height: 36px;
  text-align: center;
  color: var(--tertiaryColor);
  text-transform: uppercase;
  font-size: 0.875em;
  font-weight: 700;
  /*padding: 0 34px;*/
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, .3);
  
  outline: inherit;
}

#koz-ceza-panel {
  display: grid;
  grid-template-columns: calc(33% ) calc(34% ) calc(33% );
  grid-template-rows: 100px calc(100% - 100px);
  grid-template-areas:
            "nameW      rules-summary       nameE"
            "choose-numbers-panel     choose-numbers-panel     choose-numbers-panel";
  flex: 0 0 100%;
  
  border: 2px solid rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  outline: inherit;
}


#koz-ceza-panel #nameW {
  grid-area: nameW;
  
  display: flex;
  flex-direction: row;
  /*flex: 1 0;*/
  
  justify-content: flex-start;
  align-items: stretch;
  
  
  z-index: 200;
  
  outline: inherit;
}

#nameWBorder {
  display: flex;
  flex: 1 0;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  
  outline: inherit;
}

#koz-ceza-panel #nameE {
  grid-area: nameE;
  
  display: flex;
  flex-direction: row;
  
  justify-content: flex-end;
  align-items: stretch;
  
  z-index: 200;
  
  outline: inherit;
}

#nameEBorder {
  outline: inherit;
  display: flex;
  flex: 1 0;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

#rules-summary {
  grid-area: rules-summary;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  outline: inherit;
}

#choose-numbers-panel {
  grid-area: choose-numbers-panel;
  
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
  
  padding: 5px;
  
  outline: inherit;
}

#ceza-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*align-content: stretch;*/
  align-items: center;
  
  margin-top: 5px;
  
  flex: 1 1 auto;
  
  outline: inherit;
}

.points {
  /*border: 2px darkblue solid;*/
  margin: 5px;
  font-size: 25px;

  /*font-size: 1.75rem;*/
  color: var(--tertiaryColor);
  /*text-shadow: 1px 1px 8px rgba(0, 0, 0, 1);*/
  cursor: default;

  outline: inherit;
}

.player-points {
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 50px;
  height: 2rem;
  /*padding: 0.1rem;*/
  /*margin: 5px;*/
  /*padding: 5px;*/
  background: var(--mainColor);
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  /*position: absolute;*/
  z-index: 120;
  /*left: -30px;*/
  text-align: center;
  flex: 0 1 0;
  /*align-self: flex-start;*/
  /*margin: 3px;*/
  /*border: 3px hidden red;*/

  outline: inherit;
}

.info {
  cursor: default;
}

@media (max-width:450px){
  .info {
    cursor: default;
    font-size: 12px;
  }
  .points {
    /*border: 2px darkblue solid;*/
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 20px;

    /*font-size: 1.75rem;*/
    color: var(--tertiaryColor);
    /*text-shadow: 1px 1px 8px rgba(0, 0, 0, 1);*/
    cursor: default;
    outline: inherit;
  }
  .player-points {
    display: flex;
    justify-content: center;
    align-items: center;

    width: max(5vw, 55px);

    height: 1.5rem;
    /*padding: 0.1rem;*/
    /*margin: 5px;*/
    /*padding: 5px;*/
    background: var(--mainColor);
    border-radius: 10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    /*position: absolute;*/
    z-index: 120;
    /*left: -30px;*/
    text-align: center;
    flex: 0 1 auto;
    /*align-self: flex-start;*/
    /*margin: 3px;*/
    /*border: 3px hidden red;*/

    outline: inherit;
  }
}

</style>
