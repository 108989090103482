import { deck_template } from "../deck";
import { v4 as uuid } from 'uuid';
import { namePool } from "../namePool";
import { dealAllCardsToPlayersHigherThan10 } from "../utils";
import {nextSeat, suitsInHandFunction, mapRank2Id, orderHand, howMany, playedCards, } from "../games"; //prettyPrint
import {isInvalid} from "./validations"

const deck = JSON.parse(JSON.stringify(deck_template));

export const ratedLevels = {
  '1': {
    'name': 'Çaylak',
    'startPoints': 0,
    'endPoints': 5000
  },
  '2': {
    'name': 'Amatör',
    'startPoints': 5000,
    'endPoints': 15000
  },
  '3': {
    'name': 'Çırak',
    'startPoints': 15000,
    'endPoints': 30000
  },
  '4': {
    'name': 'Yancı',
    'startPoints': 30000,
    'endPoints': 50000
  },
  '5': {
    'name': 'Oyuncu',
    'startPoints': 50000,
    'endPoints': 100000
  },
  '6': {
    'name': 'Uzman',
    'startPoints': 100000,
    'endPoints': 175000
  },
  '7': {
    'name': 'Erbab',
    'startPoints': 175000,
    'endPoints': 250000
  },
  '8': {
    'name': 'Profesyonel',
    'startPoints': 250000,
    'endPoints': 500000
  },
  '9': {
    'name': 'Profesör',
    'startPoints': 500000,
    'endPoints': 1000000
  },
  '10': {
    'name': 'Maestro',
    'startPoints': 1000000,
    'endPoints': 10000000
  }
};

export function createGame(user, gameType) {
  const id = uuid().substring(0, 5);
  const usersInGame = {'S': {'name': user.name, 'id': user.id, 'gameCategory': 'ihale' }}
  const gameCategory = user.gameCategory
  const round = 0;


  if (gameType === 'ihaleSingle') {
    usersInGame['W'] = addBot('W', id, 'ihaleSingle')
    usersInGame['N'] = addBot('N', id, 'ihaleSingle', [usersInGame['W'].name.substring(9)])
    usersInGame['E'] = addBot('E', id, 'ihaleSingle', [usersInGame['W'].name.substring(9), usersInGame['N'].name.substring(9)])
  }
  else if (gameType === 'ihaleRated') {
    usersInGame['W'] = addBot('W', id, 'ihaleRated')
    usersInGame['N'] = addBot('N', id, 'ihaleRated', [usersInGame['W'].name.substring(9)])
    usersInGame['E'] = addBot('E', id, 'ihaleRated', [usersInGame['W'].name.substring(9), usersInGame['N'].name.substring(9)])
  }

  const game = {
    id,
    round: round,
    dealer: 'S',
    usersInGame,
    table: {
      'tableSuit': null,
      'whoseTurn': 'S',
      'kozRevealed': false,
      'tableRound': 1,
      'gameOver': false,
      'cards': {},
      'roundEnd': false,
    },
    'contracts': {
      'S': null,
      'W': null,
      'N': null,
      'E': null
    }
  };

  const gameMeta = {
    id,
    gameType: gameType,
    gameCategory: gameCategory,
    createTs: {
      0: {
        'round': 0
      }
    },
    creator: user.name,
    owner: {
      'id': user.id,
      'name': user.name,
      'seat': 'S'
    },
    restartCounter: 0,
    scores: {
      'S': {'total': 0, 'round': 0, 'totalKoz': 0, 'totalCeza': 0},
      'W': {'total': 0, 'round': 0, 'totalKoz': 0, 'totalCeza': 0},
      'N': {'total': 0, 'round': 0, 'totalKoz': 0, 'totalCeza': 0},
      'E': {'total': 0, 'round': 0, 'totalKoz': 0, 'totalCeza': 0},
    },
    yazboz: {
    }
  }

  const newUser = {
    'id': user.id,
    'name': user.name,
    'gameType': gameType,
    'gameCategory': gameCategory,
    'gameId': game.id,
    'seat': 'S'
  }
  const hands = dealAllCardsToPlayersHigherThan10()

  return [game, gameMeta, hands, newUser]
}

export function restartGame(game, gameMeta, user) {
  const gameId = game.id;
  const usersInGame = game.usersInGame;
  const gameType = user.gameType;
  const gameCategory = user.gameCategory;

  const createTs = gameMeta.createTs
  let restartCounter = gameMeta.restartCounter;

  let newGame = {
    'id': gameId,
    'usersInGame': usersInGame,
    round: 1,
    'dealer': user.seat,
    table: {
      'tableSuit': null,
      'whoseTurn': user.seat,
      'kozRevealed': false,
      'tableRound': 1,
      'gameOver': false,
      'cards': {},
      'roundEnd': false,
    },
    'contracts': {
      'S': null,
      'W': null,
      'N': null,
      'E': null
    },
    'history': null
  };

  const newGameMeta = {
    'id': gameId,
    'gameType': gameType,
    'gameCategory': gameCategory,
    'creator': user.name,
    'owner': user,
    'restartCounter': 0,
    scores: {
      'S': {'total': 0, 'round': 0, 'totalKoz': 0, 'totalCeza': 0},
      'W': {'total': 0, 'round': 0, 'totalKoz': 0, 'totalCeza': 0},
      'N': {'total': 0, 'round': 0, 'totalKoz': 0, 'totalCeza': 0},
      'E': {'total': 0, 'round': 0, 'totalKoz': 0, 'totalCeza': 0},
    },
    yazboz: {
    }
  }

  const hands = dealAllCardsToPlayersHigherThan10()
  // [newGame, hands] = updateStateRoundEnd(newGame, newGameMeta);

  if(restartCounter) {
    restartCounter += 1
  }
  else {
    restartCounter = 1
  }
  newGameMeta['restartCounter'] = restartCounter;
  newGameMeta['createTs'] = createTs;

  return [newGame, newGameMeta, hands]
}

export function addBot(seat, gameId, gameType, namesExcluded = []) {
  const nameP = JSON.parse(JSON.stringify(namePool));
  if (!Date.now) {
    Date.now = function () {
      return new Date().getTime();
    }
  }
  let index = Math.floor(Math.random() * nameP.length);
  while (namesExcluded.includes(nameP[index])) {
    index = Math.floor(Math.random() * nameP.length);
  }
  const botName = '_bot' + JSON.stringify(Date.now()).substring(8) + nameP[index];

  return {
    'id': botName,
    'gameId': gameId,
    'gameCategory': 'ihale',
    'gameType': gameType,
    'name': botName,
    'seat': seat
  }
}

export function botPlay(seat, game, hand) {
  return botKoz(game, hand)
}

export function botKoz2(game, hand) {
  const tableSuit = game.table.tableSuit;

  if (tableSuit) {
    if (Object.keys(game.table.cards).length === 3) {
      return play_4_2(game, hand)
    } else { //en son degilsen
      return [play_2_3(game, hand), 0]
    }
  }
  else { //Eli belirleyen ise - Olmuslari topla, yoksa en kisa elin kucugunu at (koz olmayan), sonra kozun en kucugu
    return [play_1(game, hand), 0]
  }
}


function botKoz(game, hand) {
  const tableSuit = game.table.tableSuit;

  if (tableSuit) {
    if (Object.keys(game.table.cards).length === 3) {
      return play_4(game, hand)
    } else { //en son degilsen
      return play_2_3(game, hand)
    }
  }
  else { //Eli belirleyen ise - Olmuslari topla, yoksa en kisa elin kucugunu at (koz olmayan), sonra kozun en kucugu
    return play_1(game, hand)
  }
}

function play_1(game, hand) {
  let pCards = playedCards(game)

  if (ripeCards(game, hand).length > 0) {
    let ripeForTableSuit = ripeCards(game, hand)
    if (!game.table.kozRevealed) {
      ripeForTableSuit = ripeForTableSuit.filter(x => deck[x].suit !== game.table.koz)
    }
    for (const rcid of ripeForTableSuit) { // don't play if less than 5 cards are played for this suit
      if (howMany(pCards, 'suit', deck[rcid].suit) < 5) {
        return rcid;
      }
    }
  }
  const excludedSuits = ripeCards(game, hand).map(x => deck[x].suit).concat(game.table.koz)
  const possibleSuits = ['spades', 'hearts', 'clubs', 'diamonds'].filter(x => !excludedSuits.includes(x))

  if (orderSuitsByLength(hand, possibleSuits).length > 0) {
    for (const s of orderSuitsByLength(hand, possibleSuits)) {
      if (howMany(pCards, 'suit', s) < 9) {
        return orderHand(hand, s)[0]
      }
    }
    return orderHand(hand, orderSuitsByLength(hand, possibleSuits)[0])[0]
  }
  else if (ripeCards(game, hand).length > 0) {
    if (ripeCards(game, hand).filter(x => deck[x].suit !== game.table.koz).length > 0) {
      return ripeCards(game, hand).filter(x => deck[x].suit !== game.table.koz)[0]
    } else {
      return ripeCards(game, hand).filter(x => deck[x].suit === game.table.koz)[0]
    }
  }
  else {
    return orderHand(hand, game.table.koz)[0]
  }
}

function play_4(game, hand){
  const seat = game.table.whoseTurn
  const tableSuit = game.table.tableSuit
  const suitsInHand = suitsInHandFunction(hand)
  const orderedHand = orderHand(hand, tableSuit)

  if (suitsInHand.includes(tableSuit)) { // elde varsa en kucukten baslayarak, ilk winner karti at
    for (const myCardId of orderedHand) {
      if (isWinnerCard(seat, hand[myCardId], game.table)) {
        return hand[myCardId]
      }
    }
    return hand[orderedHand[0]]
  }
  else { // elde yoksa kozlardan en kucukten baslayarak ilk winneri at
    if (suitsInHand.includes(game.table.koz)){
      const orderedHand = orderHand(hand, game.table.koz)
      for (const myCardId of orderedHand) {
        if (isWinnerCard(seat, myCardId, game.table)) {
          return myCardId
        }
      }
      // yoksa en kucuk koz
      return orderHand(hand, game.table.koz)[0]
    }
    else { // koz da yoksa en kucuk karti at
      return orderHand(hand)[0]
    }
  }
}

function play_4_2(game, hand){
  const seat = game.table.whoseTurn
  const tableSuit = game.table.tableSuit
  const suitsInHand = suitsInHandFunction(hand)
  const orderedHand = orderHand(hand, tableSuit)

  if (suitsInHand.includes(tableSuit)) { // elde varsa en kucukten baslayarak, ilk winner karti at
    for (const myCardId of orderedHand) {
      if (isWinnerCard(seat, hand[myCardId], game.table)) {
        return [hand[myCardId],0]
      }
    }
    return [hand[orderedHand[0]], 0]
  }
  else { // elde yoksa en kucuk kozu atmaya calis. eger yerde koz varsa altini atinca invalid olmasi gerekit
    if (suitsInHand.includes(game.table.koz)){
      const orderedHand = orderHand(hand, game.table.koz)

      let highestCard = 0
      for (const seat in game.table.cards) {
        const cardIdTable = game.table.cards[seat]
        let highestRank;
        if(!highestCard) {
          highestRank = 0
        }
        else {
          highestRank = deck[highestCard].rank
        }
        if (deck[cardIdTable].suit === game.table.koz && deck[cardIdTable].rank > highestRank){
          highestCard = cardIdTable
        }
      }

      if(highestCard && deck[orderedHand[0]].rank < deck[highestCard].rank &&  deck[orderedHand[orderedHand.length-1]].rank > deck[highestCard].rank) {
        return [orderedHand[0], 1]
      }

      // for (const myCardId of orderedHand) {
      //   if (isWinnerCard(seat, myCardId, game.table)) {
      //     return myCardId
      //   }
      // }
      // yoksa en kucuk koz
      return [orderedHand[0], 0]
    }
    else { // koz da yoksa en kucuk karti at
      return [orderHand(hand)[0], 0]
    }
  }
}

function play_2_3(game, hand){
  const seat = game.table.whoseTurn
  const tableSuit = game.table.tableSuit;
  const suitsInHand = suitsInHandFunction(hand);

  if (suitsInHand.includes(tableSuit)) {
    const ripeForTableSuit = ripeCards(game, hand).filter((cardId) => deck[cardId].suit === tableSuit)
    if (ripeForTableSuit.length > 0) {
      return ripeForTableSuit[0]
    } else {
      const orderedSuitsInHand = orderHand(hand, tableSuit)
      for (const myCardId of orderedSuitsInHand) { // Yukseltmek mecbur
        if (isWinnerCard(seat, hand[myCardId], game.table)) {
          return hand[myCardId]
        }
      }
      return orderedSuitsInHand[0]
    }
  }
  else {// elde yoksa kozlardan en kucukten baslayarak ilk winneri at
    if (suitsInHand.includes(game.table.koz)){
      const orderedHand = orderHand(hand, game.table.koz)
      for (const myCardId of orderedHand) {
        if (isWinnerCard(seat, myCardId, game.table)) {
          return myCardId
        }
      }
      // yoksa en kucuk koz
      return orderHand(hand, game.table.koz)[0]
    }
    else { // koz da yoksa en kucuk karti at
      return orderHand(hand)[0]
    }
  }
}

export function tableWinner(table) {
  const deck = {...deck_template}
  const tableCards = table['cards'];
  const tableSuit = table['tableSuit'];
  let trump = table.koz
  if (table.ceza) {
    trump = table.tableSuit
  }
  const weightedCards = {};
  for (const seat in tableCards) {
    if (deck[tableCards[seat]].suit === trump) {
      weightedCards[deck[tableCards[seat]].rank * 100] = seat;
    } else if (deck[tableCards[seat]].suit === tableSuit) {
      weightedCards[deck[tableCards[seat]].rank * 10] = seat;
    } else {
      weightedCards[deck[tableCards[seat]].rank] = seat;
    }
  }
  const max_index = Object.keys(weightedCards).reduce((a, b) => {
    if (parseInt(a) > parseInt(b)) {
      return a
    } else {
      return b
    }
  })
  return weightedCards[max_index]
}

function isWinnerCard(seat, mycard, table) {
  const cards = JSON.parse(JSON.stringify(table.cards));
  cards[seat] = mycard

  const testTable = {'cards': cards, 'tableSuit': table.tableSuit, 'koz': table.koz}
  return tableWinner(testTable) === seat;
}

function orderSuitsByLength(hand, unFinishedS = ['spades', 'hearts', 'clubs', 'diamonds']) {
  const suits = []
  if (hand && unFinishedS.includes('spades')) {
    suits.push({'suit': 'spades', 'length': Object.keys(hand).filter(x => deck[x].suit === 'spades').length})
  }
  if (hand && unFinishedS.includes('hearts')) {
    suits.push({'suit': 'hearts', 'length': Object.keys(hand).filter(x => deck[x].suit === 'hearts').length})
  }
  if (hand && unFinishedS.includes('diamonds')) {
    suits.push({'suit': 'diamonds', 'length': Object.keys(hand).filter(x => deck[x].suit === 'diamonds').length})
  }
  if (hand && unFinishedS.includes('clubs')) {
    suits.push({'suit': 'clubs', 'length': Object.keys(hand).filter(x => deck[x].suit === 'clubs').length})
  }
  return suits.filter(x => x['length'] > 0).sort((x, y) => x['length'] - y['length']).map(x => x['suit'])
}

//olmuslar
export function ripeCards(game, hand) {
  const rcards = []
  let pCards = playedCards(game)
  for (let i = 14; i > 1; i--) {
    if (!pCards.includes(mapRank2Id('spades', i))) {
      if (hand[mapRank2Id('spades', i)] && !isInvalid(hand[mapRank2Id('spades', i)], game.table, hand)) {
        rcards.push(hand[mapRank2Id('spades', i)])
      }
      break
    }
  }
  for (let i = 14; i > 1; i--) {
    if (!pCards.includes(mapRank2Id('diamonds', i))) {
      if (hand[mapRank2Id('diamonds', i)] && !isInvalid(hand[mapRank2Id('diamonds', i)], game.table, hand)) {
        rcards.push(hand[mapRank2Id('diamonds', i)])
      }
      break
    }
  }

  for (let i = 14; i > 1; i--) {
    if (!pCards.includes(mapRank2Id('clubs', i))) {
      if (hand[mapRank2Id('clubs', i)] && !isInvalid(hand[mapRank2Id('clubs', i)], game.table, hand)) {
        rcards.push(hand[mapRank2Id('clubs', i)])
      }
      break
    }
  }
  for (let i = 14; i > 1; i--) {
    if (!pCards.includes(mapRank2Id('hearts', i))) {
      if (hand[mapRank2Id('hearts', i)] && !isInvalid(hand[mapRank2Id('hearts', i)], game.table, hand)) {
        rcards.push(hand[mapRank2Id('hearts', i)])
      }
      break
    }
  }
  return rcards.sort((a, b) => parseInt(b) - parseInt(a))
}

function weights(suitCards){
  if(suitCards.length < 2){
    return [1, 0, 0]
  }
  else if(suitCards.length === 2){
    return [1, 0.6, 0]
  }
  else if(suitCards.length === 3){
    return [1, 0.6, 0.2]
  }
  else if(suitCards.length === 4){
    return [1, 0.5, 0]
  }
  else if(suitCards.length === 5){
    return [1, 0.4, 0]
  }
  else if(suitCards.length === 6){
    return [1, 0.3, 0]
  }
  else {
    return [1, 0, 0]
  }
}

export function botChooseNumber(game, hand) {
  const highestBid = Math.max(...Object.keys(game.contracts).map(x => game.contracts[x]))
  const seat = game.table.whoseTurn;
  const newGame = JSON.parse(JSON.stringify(game))

  const bid = scoreHandForAllSuits(hand)[0]
  if (bid > highestBid && bid >= 5) {
    newGame.contracts[seat] = bid
  }
  else {
    newGame.contracts[seat] = 0
  }

  let whoseTurnCandidate = nextSeat(seat)
  for (let i=0; i<3; i++) {
    if (game.contracts[whoseTurnCandidate] === 0) {
      whoseTurnCandidate = nextSeat(whoseTurnCandidate)
    } else {
      break
    }
  }
  newGame.table.whoseTurn = whoseTurnCandidate

  if(contractsNotMade(newGame)) {
    newGame.table.whoseTurn = contractsNotMade(newGame)
  }

  // prettyPrint(hand, seat, game.round)
  return newGame
}

export function botChooseTrump(game, gameMeta, hand) {
  const newgame = JSON.parse(JSON.stringify(game))
  const newGameMeta = JSON.parse(JSON.stringify(gameMeta))

  newgame.table.koz = scoreHandForAllSuits(hand)[1]
  newgame.table.cards = {}
  return [newgame, newGameMeta]
}

function scoreHandForAllSuits(hand){
  const suits = {'spades':0, 'hearts':0, 'clubs':0, 'diamonds':0}

  for (const suit in suits){
    suits[suit] = scoreHand(hand, suit)
  }

  const highest = Math.max(suits['spades'], suits['hearts'], suits['diamonds'], suits['clubs'])
  return [Math.round(highest), Object.keys(suits).filter(x => suits[x] === highest)[0]]
}

function scoreHand(hand, trump='spades') {
  let spades;
  let hearts;
  let clubs;
  let diamonds;
  if(trump === 'hearts') {
    spades = Object.keys(hand).filter(x => deck[x].suit === 'hearts')
    hearts = Object.keys(hand).filter(x => deck[x].suit === 'spades')
    clubs = Object.keys(hand).filter(x => deck[x].suit === 'clubs')
    diamonds = Object.keys(hand).filter(x => deck[x].suit === 'diamonds')
  }
  else if(trump === 'clubs'){
    spades = Object.keys(hand).filter(x => deck[x].suit === 'clubs')
    hearts = Object.keys(hand).filter(x => deck[x].suit === 'hearts')
    clubs = Object.keys(hand).filter(x => deck[x].suit === 'spades')
    diamonds = Object.keys(hand).filter(x => deck[x].suit === 'diamonds')
  }
  else if(trump === 'diamonds'){
    spades = Object.keys(hand).filter(x => deck[x].suit === 'diamonds')
    hearts = Object.keys(hand).filter(x => deck[x].suit === 'hearts')
    clubs = Object.keys(hand).filter(x => deck[x].suit === 'clubs')
    diamonds = Object.keys(hand).filter(x => deck[x].suit === 'spades')
  }
  else {
    spades = Object.keys(hand).filter(x => deck[x].suit === 'spades')
    hearts = Object.keys(hand).filter(x => deck[x].suit === 'hearts')
    clubs = Object.keys(hand).filter(x => deck[x].suit === 'clubs')
    diamonds = Object.keys(hand).filter(x => deck[x].suit === 'diamonds')
  }

  let num = 0;
  // Spades
  // Koz sayisi - 3
  let numKoz = spades.length

  if (numKoz > 3) {
    num += numKoz - 3.5
  }
  let w = weights(spades)
  if (spades.filter(x => deck[x].rank === 14).length) {
    num += 1
    w[0] = 0
    if (spades.filter(x => deck[x].rank === 13).length){
      num += 1
      w[1] = 0
      if (spades.filter(x => deck[x].rank === 12).length){
        num += 1
        w[2] = 0
      }
    }
  }
  numKoz -= num
  if(numKoz > 0 && Object.keys(hearts).length < 3) {
    num += Math.min((3 - Object.keys(hearts).length)*0.4, numKoz)
    numKoz -= Math.min((3 - Object.keys(hearts).length), numKoz)
  }
  if(numKoz > 0 && Object.keys(clubs).length < 3) {
    num += Math.min((3 - Object.keys(clubs).length)*0.4, numKoz)
    numKoz -= Math.min((3 - Object.keys(clubs).length), numKoz)
  }
  if(numKoz > 0 && Object.keys(diamonds).length < 3) {
    num += Math.min((3 - Object.keys(diamonds).length < 3)*0.4, numKoz)
  }


  if(spades) {
    num += w[0] * spades.filter(x => deck[x].rank === 14).length
    num += w[1] * spades.filter(x => deck[x].rank === 13).length
    num += w[2] * spades.filter(x => deck[x].rank === 12).length
  }
  //Hearts
  w = weights(hearts)
  if(hearts){
    num += w[0] * hearts.filter(x => deck[x].rank === 14).length
    num += w[1] * hearts.filter(x => deck[x].rank === 13).length
    num += w[2] * hearts.filter(x => deck[x].rank === 12).length
  }

  //Clubs
  w = weights(clubs)
  if(clubs){
    num += w[0] * clubs.filter(x => deck[x].rank === 14).length
    num += w[1] * clubs.filter(x => deck[x].rank === 13).length
    num += w[2] * clubs.filter(x => deck[x].rank === 12).length
  }

  //Diamonds
  w = weights(diamonds)
  if(diamonds){
    num += w[0] * diamonds.filter(x => deck[x].rank === 14).length
    num += w[1] * diamonds.filter(x => deck[x].rank === 13).length
    num += w[2] * diamonds.filter(x => deck[x].rank === 12).length
  }

  return num
}

export function contractsNotMade(game) {
  const contracts = game.contracts;
  let whoseTurn = game.table.whoseTurn;
  const seatsInC = seatsInContract(contracts)
  if (seatsInC.length === 0){
    console.log('came here')
    return false
  }
  else if (seatsInC.length === 1){
    if (!contracts[seatsInC[0]]){
      return seatsInC[0]
    }
    else {
      return false
    }
  }
  else {
      return whoseTurn
  }
}

function seatsInContract(contracts) {
  const seatsInC = []
  if (contracts.S === null || contracts.S > 0 ){
    seatsInC.push('S')
  }
  if (contracts.W === null || contracts.W > 0){
    seatsInC.push('W')
  }
  if (contracts.N === null || contracts.N > 0){
    seatsInC.push('N')
  }
  if (contracts.E === null || contracts.E > 0){
    seatsInC.push('E')
  }
  return seatsInC
}

function isPlayerThrough(game, gameMeta, ihaleci, seat) {
  const contracts = game.contracts

  if(seat === ihaleci) {
    return contracts[seat] <= gameMeta.scores[seat].round;
  }
  else {
    return gameMeta.scores[seat].round !== 0;
  }
}

export function updateYazBoz(game, gameMeta, ihaleci) {
  const newGameMeta = JSON.parse(JSON.stringify(gameMeta))
  let contracts = game.contracts

  let s, e, w, n;
  if (isPlayerThrough(game, gameMeta, ihaleci, 'S')){
    s = gameMeta.scores.S.round;
    newGameMeta.scores.S.totalKoz += s ;
    newGameMeta.scores.S.total += s ;
  }
  else {
    s = -contracts[ihaleci]
    newGameMeta.scores.S.totalCeza -= s ;
    newGameMeta.scores.S.total += s ;
  }

  if (isPlayerThrough(game, gameMeta, ihaleci, 'W')){
    w = gameMeta.scores.W.round;
    newGameMeta.scores.W.totalKoz += w ;
    newGameMeta.scores.W.total += w ;
  }
  else {
    w = -contracts[ihaleci]
    newGameMeta.scores.W.totalCeza -= w ;
    newGameMeta.scores.W.total += w ;
  }

  if (isPlayerThrough(game, gameMeta, ihaleci, 'E')){
    e = gameMeta.scores.E.round;
    newGameMeta.scores.E.totalKoz += e ;
    newGameMeta.scores.E.total += e ;
  }
  else {
    e = -contracts[ihaleci]
    newGameMeta.scores.E.totalCeza -= e ;
    newGameMeta.scores.E.total += e ;
  }


  if (isPlayerThrough(game, gameMeta, ihaleci, 'N')){
    n = gameMeta.scores.N.round;
    newGameMeta.scores.N.totalKoz += n ;
    newGameMeta.scores.N.total += n ;
  }
  else {
    n = -contracts[ihaleci]
    newGameMeta.scores.N.totalCeza -= n ;
    newGameMeta.scores.N.total += n ;
  }

  newGameMeta.yazboz[game.round] = {'S': s, 'W':w, 'E':e, 'N':n}

  newGameMeta.scores.E.round = 0;
  newGameMeta.scores.S.round = 0;
  newGameMeta.scores.W.round = 0;
  newGameMeta.scores.N.round = 0;

  return newGameMeta
}

export function updateStateRoundEnd(game) {
  const newGame = JSON.parse(JSON.stringify(game))
  const table = JSON.parse(JSON.stringify(game.table))
  // const newReporting = JSON.parse(JSON.stringify(reporting))

  newGame.dealer = nextSeat(game.dealer)
  newGame.round = game.round + 1
  table.whoseTurn = newGame.dealer
  table.koz = null
  table.roundEnd = false
  table.cards = {}
  table.kozRevealed = false
  table.tableRound = 1
  table.tableSuit = null
  newGame.table = table
  newGame.history = null

  newGame.contracts.E = null
  newGame.contracts.W = null
  newGame.contracts.N = null
  newGame.contracts.S = null

  const newHands = dealAllCardsToPlayersHigherThan10()

  return [newGame, newHands]
}

export function isRoundFinished (hands) {
  if(hands) {
    return Object.keys(hands.S).length === 0
  }
  else {
    return true
  }
}

export function buildHistory(game) {
  const history = game.history || {};
  const table = game.table;

  const lastTableCards = {
    'S': table.cards.S, 'W': table.cards.W, 'N': table.cards.N, 'E': table.cards.E
  }

  const playedCards = history.playedCards || {};

  for (const s in lastTableCards) {
    const cardId = lastTableCards[s]
    playedCards[cardId] = table.tableRound
  }

  game.history = {'lastCards': lastTableCards, 'playedCards': playedCards, 'lastStarter': nextSeat(game.table.whoseTurn)}
  return game.history
}

