<template>
  <div id="app-wrapper">
    <ModalUserProfile
      style="z-index: 1050"
      v-show="userProfileFlag"
      @close="closeUserProfileModal"
      @googleSignIn="googleSignInWrapper"
      @passwordSignUp="passwordSignUp"
      @passwordSignIn="passwordSignIn"
      @authLogout="authLogout"
      :userMeta="userMeta"
      :displayName="displayName"
      :authProvider="authProvider"
      :user="user"
      :emailVerified="emailVerified"
      :emailRegistered="email"
    />
    <div id="header">
        <div v-if="!this.gameType"
             style="display: flex; flex: 1 1 auto; justify-content: space-between; align-items: center;">
          <a :href=landing_page style="display:flex; flex: 1 0; margin-left: 10px; align-items: center">
            <img src="@/assets/ihale_logo.svg" alt="ihale_logo">
          </a>
          <a :href=landing_page style="display: flex; flex: 1 0; flex-direction: row; justify-content: center; justify-items: center;">
            <img src="@/assets/website_logo.svg" alt="website_logo"
                   style="display: flex; flex-direction: row; flex: 1 1; justify-content: center;">
          </a>
          <div style="display: flex; flex: 1 0; margin-right: 10px;">
            <span style="display: flex; flex-direction: row; flex: 1 1 30%; justify-content: flex-end;
            align-content: center; align-items: center;">
                <div
                  v-if="authProvider === 'anonymous' && !gameType"
                  class="btn-loginout"
                  @click="toggleUserProfile"
                >
                  <i class="fa-duotone fa-sign-in fa-2x"/>
                </div>
                <div class="authUser" v-else-if="!gameType && user && user.name" @click="toggleUserProfile">
                    {{ user.name.substring(0, 1) }}
                </div>
                <div class="authUser" v-else-if="!gameType && displayName !== this.appCopy.defaultName"
                     @click="toggleUserProfile">
                    {{ displayName.substring(0, 1) }}
                </div>
            </span>
          </div>
        </div>
        <div v-else id="header-inner">
          <span class="itemleft">
            <i class="fa-duotone fa-bars fa-2x pointer"
               v-if="user && gameType"
               aria-hidden="true"
               @click="toggleMenu"
               style="margin-left: 15px"
            />
          </span>
          <span class="itemcenter">
            <div style="display: flex; flex: 1 0; flex-direction: row; justify-content: center; justify-items: center;">
            <img src="@/assets/ihale_logo.svg" alt="website_logo"
                 style="display: flex; flex-direction: row; flex: 1 1; justify-content: center;">
            </div>
          </span>
          <span class="itemright">
            <div
              v-if="authProvider === 'anonymous' && !gameType"
              class="btn-loginout"
              @click="toggleUserProfile"
            >
              <i class="fa-duotone fa-sign-in fa-3x"/>
            </div>
            <div class="authUser"
                 v-else-if="authProvider !== 'anonymous' && user && user.name"
                 @click="toggleUserProfile">
              {{ user.name.substring(0, 1) }}
            </div>
            <div class="authUser"
                 v-else-if="authProvider !== 'anonymous' && displayName !== this.appCopy.defaultName"
                 @click="toggleUserProfile">
                {{ displayName.substring(0, 1) }}
            </div>
          </span>
        </div>
      </div>
    <div id="body">
      <loading
        :active.sync="isLoading"
        :is-full-page="false"
        loader="dots"
        color="#ffc107"
        :height=100
        :width=100
        style="z-index: 900"
      />
      <IhaleSingle
        v-if="this.gameType === 'ihaleSingle' || this.gameType === 'ihaleRated'"
        ref="ihaleSingle"
        :user="user"
        :menuOpen="menuOpen"
        :rel-dir="relDir"
        :touchDevice="touchDevice"

        @setIsLoading="setIsLoading"
        @openMenu="openMenu"
        @closeMenu="closeMenu"
        style="display: flex; flex: 1 0 auto; height: 100%"
      ></IhaleSingle>
      <IhaleLanding v-else
        :flashMessageFlag="flashMessageFlag"
        :user-meta="userMeta"
        :displayName="displayName"
        :emailVerified="emailVerified"
        :email="email"
        :user="user"
        :authProvider="authProvider"
        
        @singleGameStart="singleGameStart"
        @ratedGameStart="ratedGameStart"
        @friendlyGameStart="friendlyGameStart"
        @signIn="openUserProfileModal"
        @openUserProfile="openUserProfile"
        @flashMessageClose="flashMessageFlag = false"
        
      ></IhaleLanding>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';

import ModalUserProfile from '@/components/games/ihale/user/ModalUserProfile'
import IhaleLanding from "@/components/games/ihale/IhaleLanding";
import IhaleSingle from '@/components/games/ihale/IhaleSingle.vue'
// import IhaleRated from '@/components/games/ihale/IhaleRated.vue'

import compass from "@/js/compass"
import app from "@/firebase.setup";
import firebase from "firebase/app";
import {ihaleCopy} from "@/js/ihale/ihaleCopy";
import {gameCat} from "@/components/games/mixins/gameCat";


export default {
  name: "Ihale",
  mixins: [gameCat],
  components: {
    ModalUserProfile,
    Loading,
    IhaleLanding,
    IhaleSingle,
    // IhaleRated
  },
  props: ["user", "userMeta", "appURL", "displayName", "email", "emailVerified", "authProvider", "touchDevice"],
  data: () => ({
    userProfileFlag: false,
    menuOpen: false,

    gameCategory: 'ihale',

    flashMessageFlag: true,
    isLoading: false,
    
    landing_page: process.env.VUE_APP_LANDING,
    
    compass: compass
  }),
  methods: {

    setIsLoading(newState) {
      this.isLoading = newState
    },
    async registerUser(user) {
      await app.database().ref(`/users/${user.id}`).set(user);
    },
    async authLogout() {
      await app.auth().signOut();
      this.closeUserProfileModal()
    },
    async googleSignIn() {
      let googleProvider = new firebase.auth.GoogleAuthProvider();
      
      await app.auth().signInWithPopup(googleProvider)
        .then(function (result) {
          let token = result.credential.accessToken;
          let user = result.user;
          token.length
          user.length
          if (this.authUser.displayName) {
            const candidateUserName = this.authUser.displayName.trim().split(/\s+/)[0]
            this.$emit('setDisplayName', candidateUserName)
          }
        }
      ).catch(function (error) {
        console.error(error.code)
        console.error(error.message)
      });
      
      return 'all good'
    },
    async facebookSignin() {
      let facebookProvider = new firebase.auth.FacebookAuthProvider();
      
      await app.auth().signInWithPopup(facebookProvider)
        .then(function(result) {
          let token = result.credential.accessToken;
          let user = result.user;
        
          console.log(token)
          console.log(user)
         }).catch(function(error) {
            console.log(error.code);
            console.log(error.message);
         });
    },
    async passwordSignIn(email, password) {
      app.auth().signInWithEmailAndPassword(email, password)
      .then(() => {
      // .then((userCredential) => {
        // Signed in
        // let user = userCredential.user;
        // console.log('user signed in', user)
        // ...
      })
      .catch((error) => {
        if (error.code === 'auth/invalid-email') {
          this.$alert(this.userProfileCopy.badlyFormatedEmailAlert)
        }
        else if (error.code === 'auth/user-not-found') {
          this.$alert(this.userProfileCopy.userNotFoundAlert)
        }
        else {
          this.$alert(this.userProfileCopy.genericErrorAlert, error.code + ': ' + error.message)
        }
      });
    },
    async passwordSignUp(email, password, username) {
      await app.auth().createUserWithEmailAndPassword(email, password)
        .then((userCredential) => {
          
          let userrr = userCredential.user;
          
          app.database()
            .ref(`users/${userrr.uid}`)
            .set({'name': username, 'id': userrr.uid});
          
          let actionCodeSettings = {
            url: this.appURL
          };
          
          userrr.sendEmailVerification(actionCodeSettings).then(() => {
            console.log('email sent')
            // Email sent.
          }).catch((error) => {
            // An error happened.
            this.$alert(error.code + ': ' + error.message, this.userProfileCopy.genericErrorAlert)
          });
          
        }
      ).catch((error) => {
        console.log('error catched!!!!')
        if (error.code === 'auth/invalid-email') {
          this.$alert(this.userProfileCopy.badlyFormatedEmailAlert)
        }
        else if (error.code ===  'auth/email-already-in-use') {
          this.$alert(this.userProfileCopy.emailAlreadyInUseAlert)
        }
        else if (error.code === 'auth/weak-password') {
          this.$alert(this.userProfileCopy.weakPasswordAlert)
        }
        else {
          this.$alert(this.userProfileCopy.genericErrorAlert, error.code + ': ' + error.message)
        }
        
        console.error(error.code)
        console.error(error.message)
      });
      
      return 'all good'
    },
    async googleSignout() {
      await app.auth().signOut()
        .then(function () {
          console.warn('Signout Succesfull')
        }, function (error) {
          console.error('Signout Failed', error)
        });
    },
    openUserProfileModal() {
      this.userProfileFlag = true;
    },
    closeUserProfileModal() {
      this.userProfileFlag = false;
    },
    closeCompetitionInfo() {
      this.competitionInfoFlag = false;
    },
    toggleUserProfile(e) {
      e.preventDefault();
      e.stopPropagation();
      this.userProfileFlag = true;
    },
    toggleMenu(e) {
      e.preventDefault();
      e.stopPropagation();
      this.menuOpen = true;
    },
    openUserProfile() {
      this.userProfileFlag = true;
    },
    googleSignInWrapper() {
      this.userProfileFlag = false;
      this.googleSignIn()
    },
    openMenu: function () {
      this.menuOpen = true;
    },
    closeMenu: function() {
      this.menuOpen = false;
    },
    async singleGameStart() {
      await app.database()
        .ref(`users/${this.user.id}`)
        .set({'id': this.user.id, 'name': this.user.name, 'gameType': 'ihaleSingle', 'gameCategory': 'ihale'});
    },
    async ratedGameStart() {
      await app.database()
        .ref(`users/${this.user.id}`)
        .set({'id': this.user.id, 'name': this.user.name, 'gameType': 'ihaleRated', 'gameCategory': 'ihale'});
    },
    friendlyGameStart() {
      this.gameStartFlag = "ihaleFriendly";
      setTimeout(() => {
        this.gameStartFlag = false;
      }, 100)
    },
  },
  computed: {
    gameType() {
      if (this.user && this.user.gameType === "ihaleSingle") {
        return 'ihaleSingle'
      } else if (this.user && this.user.gameType === "ihaleRated"){
        return 'ihaleRated'
      } else if ((this.gameStartFlag === "ihaleFriendly") || (this.user && this.user.gameType === "ihaleFriendly")) {
        return 'ihaleFriendly'
      }
      return null
    },
    relDir: function () {
      if (this.user) {
        if (this.user.seat) {
          return this.compass[this.user.seat]
        } else {
          return this.compass['S']
        }
      } else {
        return null
      }
    }
  },
  created() {
    this.appCopy = ihaleCopy.App;
    this.userProfileCopy = ihaleCopy.ModalUserProfile;
  }
}
</script>

<style scoped>
#app-wrapper {
  align-items: stretch;
  
  justify-content: center;
  
  width: calc(min(600px, 100vw));
  min-height: 100vh;
  
  display: flex;
  flex-direction: column;
  flex: 1 1;
  
  outline: 0px solid red;
}

#header {
  display: flex;
  flex: 0 1 auto;
  height: 55px;
  
  position: fixed;
  top: 0;
  width: calc(min(600px, 100vw));
  -webkit-transform: translateZ(0);
  
  z-index: 950;
  
  justify-content: stretch;
  
  background-color: var(--mainColor);
  
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.8);
  /*border: 2px solid rgba(0,0,0,0.06);*/
  /*border-radius: 5px;*/
  outline: inherit;
}
</style>