import { render, staticRenderFns } from "./PreviousSeasons.vue?vue&type=template&id=76ef68cf&scoped=true&"
import script from "./PreviousSeasons.vue?vue&type=script&lang=js&"
export * from "./PreviousSeasons.vue?vue&type=script&lang=js&"
import style0 from "./PreviousSeasons.vue?vue&type=style&index=0&id=76ef68cf&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76ef68cf",
  null
  
)

export default component.exports