import { deck_template } from "../deck";
import { kingCopy } from './kingCopy'

const deck = {...deck_template}
const contractsCopy = kingCopy.contracts
const compCopy = kingCopy.validations;

export function isInvalid(cardId, table, hand) {
    // const deck = deckMixin.data().deck

    if (table.koz) {
        return kozValidations(cardId, table, hand)
    } else if (table.ceza) {
        if (table.ceza === 'rifki') {
            return rifkiValidations(cardId, table, hand)
        } else if (table.ceza === 'el') {
            return elValidations(cardId, table, hand)
        } else if (table.ceza === 'erkek') {
            return erkekValidations(cardId, table, hand)
        } else if (table.ceza === 'kiz') {
            return kizValidations(cardId, table, hand)
        } else if (table.ceza === 'kupa') {
            return kupaValidations(cardId, table, hand)
        } else if (table.ceza === 'soniki') {
            return sonikiValidations(cardId, table, hand)
        }
    }
    return false
}

function kozValidations(cardId, table, hand) {
    const tableSuit = table.tableSuit;
    const kozRevealed = table.kozRevealed;
    const suitsInHand = getSuitsInHand(hand)

    if (tableSuit) {
        // elinde yerdeki kart varsa, oynamak zorundasin
        if (suitsInHand.includes(tableSuit) && (deck[cardId].suit !== tableSuit)) {
            return `${contractsCopy[tableSuit]} ${compCopy.playX}`
        }

        //Koz oynaniyorsa kozu yukseltmek mecburi
        let rank = 0;
        for (const seat in table.cards) {
            const tableCardId = table.cards[seat];
            const cardFromTable = deck[tableCardId];
            if (cardFromTable.suit === table.koz && cardFromTable.rank > rank) {
                rank = cardFromTable.rank
            }
        }

        for (const handCardId in hand) {
            if (tableSuit === table.koz && deck[handCardId].suit === table.koz && deck[cardId].rank < rank && deck[handCardId].rank > rank) {
                return 'Koz elinde yerdeki kozu yükseltmek zorunludur.'
            }
        }

        //Koz cakilacaksa kozu yukseltmek mecburi
        for (const handCardId in hand) {
            if (!suitsInHand.includes(tableSuit) && deck[handCardId].suit === table.koz && deck[cardId].rank < rank && deck[handCardId].rank > rank) {
                return 'Yerdeki kozu yükseltmek zorunludur.'
            }
        }

        // Elinde koz varsa ve yerdeki suit yoksa koz atmalisin
        if (!suitsInHand.includes(tableSuit) && suitsInHand.includes(table.koz) && deck[cardId].suit !== tableSuit && deck[cardId].suit !== table.koz) {
            // @ts-ignore
            return `${contractsCopy[table.koz]} ${compCopy.playX}`
        }

    } else {
        if (deck[cardId].suit === table.koz && !kozRevealed && suitsInHand.length > 1) {
            return compCopy.notYetTrump
        }
    }
    return false
}

function rifkiValidations(cardId, table, hand) {
    const tableSuit = table.tableSuit;
    const suitsInHand = getSuitsInHand(hand)
    const kozRevealed = table.kozRevealed;
    if (tableSuit) {
        // elinde yerdeki kart varsa, oynamak zorundasin
        if (suitsInHand.includes(tableSuit) && (deck[cardId].suit !== tableSuit)) {
            return `${contractsCopy[tableSuit]} ${compCopy.playX}`
        }

        // Elinde rifki varsa ve yerdeki suit yoksa rifki atmalisin
        let isRifki = false;
        let isAH = false;
        for (const handCardId in hand) {
            if (handCardId === '113') {
                isRifki = true;
            }
            // if (hand[cardId].id === 114) {
            //     isAH = true;
            // }
        }
        if (deck[cardId].suit !== tableSuit && isRifki && cardId !== '113') {
            return compCopy.playBarbu
        }

        // Kupa asi ciktiysa da Rifki oynamak zorunlu
        if (isRifki && isAH && cardId !== '113') {
            return compCopy.playBarbu
        }

        // Elinde kupa varsa ve yerdeki suit yoksa kupa atmalisin
        if (deck[cardId].suit !== tableSuit && deck[cardId].suit !== tableSuit && deck[cardId].suit !== 'hearts' && suitsInHand.includes('hearts')) {
            return compCopy.playHearts
        }

    } else {
        if (deck[cardId].suit === 'hearts' && !kozRevealed && suitsInHand.length > 1) {
            return compCopy.notYetHearts
        }
    }
    return false
}

function kupaValidations(cardId, table, hand) {
    const tableSuit = table.tableSuit;
    const suitsInHand = getSuitsInHand(hand)
    const kozRevealed = table.kozRevealed;
    if (tableSuit) {
        // elinde yerdeki suit varsa, oynamak zorundasin
        if (suitsInHand.includes(tableSuit) && (deck[cardId].suit !== tableSuit)) {
            return `${contractsCopy[tableSuit]} ${compCopy.playX}`
        }

        // Elinde kupa varsa ve yerdeki suit yoksa kupa atmalisin
        if ('hearts' !== tableSuit && deck[cardId].suit !== 'hearts' && deck[cardId].suit !== tableSuit && suitsInHand.includes('hearts')) {
            return compCopy.playHearts
        }

    } else {
        if (deck[cardId].suit === 'hearts' && !kozRevealed && suitsInHand.length > 1) {
            return compCopy.notYetHearts
        }
    }
    return false
}

function erkekValidations(cardId, table, hand) {
    const tableSuit = table.tableSuit;
    const suitsInHand = getSuitsInHand(hand)
    if (tableSuit) {
        // elinde yerdeki suit varsa, oynamak zorundasin
        if (suitsInHand.includes(tableSuit) && (deck[cardId].suit !== tableSuit)) {
            return `${contractsCopy[tableSuit]} ${compCopy.playX}`
        }

        //Yerde eldeki erkekten buyuk kart varsa, erkegi atmak mecburi
        let rank = 20;
        let isErkek = false;
        for (const handCardId in hand) {
            if (deck[handCardId].rank === 11 || deck[handCardId].rank === 13) {
                isErkek = true
                if (deck[handCardId].suit === tableSuit && deck[handCardId].rank < rank) {
                    rank = deck[handCardId].rank
                }
            }
        }
        for (const seat in table.cards) {
            const cardFromTable = deck[table.cards[seat]];
            if (deck[cardId].suit !== tableSuit && cardFromTable.rank > rank && cardFromTable.suit === tableSuit) {
                return 'Eldeki erkeği yedirmek zorunlu.'
            }
        }

        // Yerdeki suitten elinde yoksa ve erkek varsa, erkegi atmak zorundasin

        if (!suitsInHand.includes(tableSuit) && isErkek && deck[cardId].rank !== 11 && deck[cardId].rank !== 13) {
            return 'Eldeki erkeği yedirmek zorunlu.'
        }
    }
    return false
}

function kizValidations(cardId, table, hand) {
    const tableSuit = table.tableSuit;
    const suitsInHand = getSuitsInHand(hand)
    if (tableSuit) {
        // elinde yerdeki suitten varsa oynamak zorundasin
        if (suitsInHand.includes(tableSuit) && (deck[cardId].suit !== tableSuit)) {
            return `${contractsCopy[tableSuit]} ${compCopy.playX}`
        }

        //Yerde eldeki kizdan buyuk kart varsa, kizi atmak mecburi
        let rank = 20;
        let isKiz = false;
        for (const handCardId in hand) {
            if (deck[handCardId].rank === 12) {
                isKiz = true
                if (deck[handCardId].suit === tableSuit && deck[handCardId].rank < rank) {
                    rank = deck[handCardId].rank
                }
            }
        }
        for (const seat in table.cards) {
            const tableCardId = table.cards[seat];
            const cardFromTable = deck[tableCardId];
            if (deck[cardId].suit !== tableSuit && cardFromTable.rank > rank && cardFromTable.suit === tableSuit) {
                return 'Eldeki kızı yedirmek zorunlu.'
            }
        }

        // Yerdeki suitten elinde yoksa ve kiz varsa, kizi atmak zorundasin
        if (!suitsInHand.includes(tableSuit) && isKiz && deck[cardId].rank !== 12) {
            return 'Eldeki kızı yedirmek zorunludur.'
        }
    }
    return false
}

function elValidations(cardId, table, hand) {
    const suitsInHand = getSuitsInHand(hand)
    const tableSuit = table.tableSuit;
    if (tableSuit) {
        // elinde yerdeki kart varsa, oynamak zorundasin
        if (suitsInHand.includes(tableSuit) && (deck[cardId].suit !== tableSuit)) {
            return `${contractsCopy[tableSuit]} ${compCopy.playX}`
        }
    }
    return false
}

function sonikiValidations(cardId, table, hand) {
    const suitsInHand = getSuitsInHand(hand)
    const tableSuit = table.tableSuit;
    if (tableSuit) {
        // elinde yerdeki kart varsa, oynamak zorundasin
        if (suitsInHand.includes(tableSuit) && (deck[cardId].suit !== tableSuit)) {
            return `${contractsCopy[tableSuit]} ${compCopy.playX}`
        }
    }
    return false
}

function getSuitsInHand(hand) {
    let suitsInHand = []
    for (const handCardId in hand) {
        if (!suitsInHand.includes(deck[handCardId].suit)) {
            suitsInHand.push(deck[handCardId].suit)
        }
    }
    return suitsInHand
}