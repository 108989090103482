<template>
  <transition name="lb-cycle">
    <div>
      <div class="Player__outer">
        <div class="Player__rank" :class="{'star': star}">
          <h5> {{ data.rank }}</h5>
        </div>
      </div>
      <h2 class="Player__name"> {{ data.name }} </h2>
      <div class="Player__outer" >
          <h4 class="Player__points"> {{ data.points }} </h4>
      </div>
    </div>
  </transition>
</template>

<script>
// import app from '../firebase.setup'

export default {
  name: 'Row',
  data: () => ({}),
  props: ['data'],
  created() {
  },
  computed: {
    star() {
      return !!this.data.star;
    }
  }
}
</script>


<style lang="scss" scoped>

#container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Header, .Athlete {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

hr {
  outline: 0;
  border: 0;
  height: 0;
  margin: 0;
}

hr.pad2 {
  padding: 1% 0;
}

hr.pad5 {
  padding: 2.5% 0;
}

hr.pad10 {
  padding: 5% 0;
}

nav {
  width: 100%;
  background-color: var(--leader1);
}

nav img {
  display: block;
  width: 150px;
  margin: 0 auto;
  padding: 33px 0;
}

.Header {
  width: 90%;
  z-index: 1;
  max-width: 625px;
  margin: 0 auto;
  position: relative;
  border-top: 3px solid var(--leader3);
  background-color: #FFFFFF;
}

.Header:nth-of-type(2) {
  border-top: 3px solid var(--leader3);
}

.Header .Header__title {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 24px;
  color: var(--leader1);
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  padding: 12px 0;
}

.Header .Header__image {
  width: 40px;
  height: auto;
  display: block;
  margin: 0 auto;
  padding: 0 0 23px 0;
}

.Header .Header__summary {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 115px;
}

.Header .Header__stat {
  height: auto;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.Header .Header__stat h3, .Header .Header__stat h4 {
  width: 100%;
  margin: 0;
  padding: 0;
  display: block;
  text-align: center;
  align-self: center;
}

.Header .Header__stat h3 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 24px;
  color: var(--leader1);
}

.Header .Header__stat h4 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: var(--leader1);
  text-transform: uppercase;
  margin-top: 5px;
  
}

.Player {
  width: 100%;
  margin: 1px 0;
  display: flex;
  align-items: center;
}

.Player__outer {
  width: 100px;
}

.Player__points {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 68px;
  width: auto;
  flex-basis: 68px;
  
  color: var(--leader1);
  margin: 0;
  text-align: right;
}


.Player__rank {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 68px;
  width: 68px;
  flex-basis: 68px;
  background-color: var(--leader4);
}

.Player__rank h5 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 25px;
  color: var(--secondaryColor);
  margin: 0;
  padding: 0;
}

.star h5 {
  color: var(--tertiaryColor);
  font-weight: 1000;
  font-size: 25px;
}

.Player__name {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: var(--leader1);
  margin: 0;
  //padding: 0 0 0 19px;
  flex: 1;
}


::selection {
  background-color: #F5617A;
  color: #fff;
}

::-moz-selection {
  background-color: #F5617A;
  color: #fff;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder { /* Firefox 18- */
  text-align: center;
}

::-moz-placeholder { /* Firefox 19+ */
  text-align: center;
}

:-ms-input-placeholder {
  text-align: center;
}



.lb-cycle-enter-active {
  transition: all .3s ease;
}
.lb-cycle-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.lb-cycle-enter, .lb-cycle-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.star {
  background-size: contain;
  background: var(--tertiaryColor) url('../../assets/star.png') center;
}
</style>
