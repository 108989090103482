<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click=close>
      <div class="modal"
           role="dialog"
           aria-labelledby="modalTitle"
           aria-describedby="modalDescription"
      >
        <div id="round-end">
          <span></span>
          <span></span>
          <span class="thumbs-up-down">
            <div v-if="thumbsUpDownGameEnd('N')">
              👍
            </div>
            <div v-else>
              👎🏾
            </div>
          </span>
          <span></span>
          <span></span>

          <span></span>
          <span></span>
          <span class="player-points-roundend  points-roundend">{{ scores[relDir.N].round }}/{{game.contracts[relDir.N]}} </span>
          <span></span>
          <span></span>

          <span class="thumbs-up-down">
            <div v-if="thumbsUpDownGameEnd('W')">
              👍
            </div>
            <div v-else>
              👎🏾
            </div>
          </span>
          <span class="player-points-roundend  points-roundend">{{ scores[relDir.W].round }}/{{game.contracts[relDir.W]}} </span>
          <span class="player-points-roundend points-roundend" style="background: violet">Tur {{ game.round }}</span>
          <span class="player-points-roundend  points-roundend">{{ scores[relDir.E].round }}/{{game.contracts[relDir.E]}} </span>
          <span class="thumbs-up-down">
            <div v-if="thumbsUpDownGameEnd('E')">
              👍
            </div>
            <div v-else>
              👎🏾
            </div>
          </span>

          <span></span>
          <span></span>
          <span class="player-points-roundend  points-roundend">{{ scores[relDir.S].round }}/{{game.contracts[relDir.S]}} </span>
          <span></span>
          <span></span>

          <span></span>
          <span></span>
          <span class="thumbs-up-down">
            <div v-if="thumbsUpDownGameEnd('S')">
              👍
            </div>
            <div v-else>
              👎🏾
            </div>
          </span>
          <span></span>
          <span></span>
        </div>
        <button
          type="button"
          class="btn-green"
          @click="close"
          aria-label="Close modal"
        >
          {{ compCopy.beginNewRound }}
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import {batakCopy} from "@/js/batak/batakCopy";

export default {
  name: 'ModalRoundEnd',
  props: ["game", "gameMeta", "relDir", "yanBatar"],
  methods: {
    thumbsUpDownGameEnd(seat) {
      if(this.contracts[seat] > 0){
        return this.contracts[seat] <= this.scores[this.relDir[seat]]['round']
            && this.scores[this.relDir[seat]]['round'] < this.contracts[seat] + this.yanBatar
      }
      else {
        return this.scores[this.relDir[seat]]['round'] === 0
      }
    },
    close() {
      this.$emit('close');
    },
  },
  created() {
    this.compCopy = batakCopy.ModalRoundEnd;
  },
  computed: {
    contracts: function() {
      return this.game.contracts
    },
    trump: function () {
      return this.game.table.koz || this.game.table.ceza
    },
    scores: function () {
      return this.gameMeta.scores
    },
    cardWName: function () {
      return this.deck[this.game.table.cards[this.relDir.W]].name
    },
    cardEName: function () {
      return this.deck[this.game.table.cards[this.relDir.E]].name
    },
    cardNName: function () {
      return this.deck[this.game.table.cards[this.relDir.N]].name
    },
    cardSName: function () {
      return this.deck[this.game.table.cards[this.relDir.S]].name
    },
  },
  // watch: {
  //     trumpUrl() {
  //         // document.documentElement.style
  //         //     .setProperty('--background-url', url('../assets/spades.png' )); //this.trumpUrl()
  //
  //     }
  // }
  
};
</script>

<style scoped>

#history div {
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;
  
  
  min-width: 70px;
  margin: 5px;
  
  outline: inherit;
}

#round-end {
  display: grid;
  grid-template-rows: [t]50px [tm]60px [m]60px [bm]60px [b]50px [rfinal];
  grid-template-columns: [l]55px [lc]80px [c]80px [rc]80px [rc] 55px [cfinal];

  justify-items: stretch;
  align-items: stretch;

  color: var(--tertiaryColor);
  
  margin: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  
  border-radius: 2px;
  
  outline: inherit;
  
}

#round-end span {
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;
  
  min-width: 0;
  margin: auto;
  
  outline: inherit;
  
}

.bg-rifki {
  background: url("../../../assets/rifki.png") center no-repeat;
  background-size: 35%;
}

.bg-soniki {
  background: url("../../../assets/soniki.png") center no-repeat;
  background-size: 35%;
}

.bg-kupa {
  background: url("../../../assets/kupaalmaz.png") center no-repeat;
  background-size: 35%;
}

.bg-el {
  background: url("../../../assets/elalmaz.png") center no-repeat;
  background-size: 35%;
}

.bg-erkek {
  background: url("../../../assets/erkekalmaz.png") center no-repeat;
  background-size: 35%;
}

.bg-kiz {
  background: url("../../../assets/kizalmaz.png") center no-repeat;
  background-size: 35%;
}

.bg-hearts {
  background: url("../../../assets/hearts.png") center no-repeat;
  background-size: 35%;
}

.bg-diamonds {
  background: url("../../../assets/diamond.png") center no-repeat;
  background-size: 35%;
}

.bg-clubs {
  background: url("../../../assets/clubs.png") center no-repeat;
  background-size: 35%;
}

.bg-spades {
  background: url("../../../assets/spades.png") center no-repeat;
  background-size: 35%;
}

.player-points-roundend {
  display: flex;
  /*justify-content: center;*/
  /*align-items: center;*/
  /*align-self: center;*/

  width: 70px;
  height: 3rem;
  background: var(--secondaryColor);
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  /*position: absolute;*/
  z-index: 120;
  /*left: -30px;*/
  text-align: center;
  flex: 0 1 0;
  /*align-self: flex-start;*/
  /*margin: 3px;*/
  /*border: 3px hidden red;*/

  outline: inherit;
}

.points-roundend {
  /*border: 2px darkblue solid;*/
  font-size: 1.5rem;
  color: var(--tertiaryColor);
  text-shadow: 1px 1px 8px rgba(0, 0, 0, 1);
  
  outline: inherit;
}


.modal-backdrop {
  position: fixed;
  z-index: 300;
  
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  
  outline: inherit;
  
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;
  
  /*position: absolute;*/
  /*top: calc(100% - 410px);*/
  
  border-radius: 2px;
  
  outline: inherit;
}

.modal-header,
.modal-footer {
  justify-content: center;
  align-items: center;
  padding: 15px;
  display: flex;
  color: var(--tertiaryColor);
  
  outline: inherit;
}


.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
  
  outline: inherit;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  
  outline: inherit;
}

.btn-close {
  border: none;
  font-size: 20px;
  padding: 20px;
  cursor: pointer;
  font-weight: bold;
  color: #4AAE9B;
  background: transparent;
}

.btn-green {
  color: black;
  background: var(--mainColor);
  border: 1px solid var(--tertiaryColor);
  border-radius: 2px;
  width: auto;
  align-self: center;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.6);
  margin-bottom: 6px;
  margin-top: 6px;
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease
}

.thumbs-up-down{
  display: flex; align-items: center; justify-content: flex-start; text-align: left; font-size:30px;width: 60px;
}
</style>
