const compass = {
    'S': {
        'N': 'N',
        'E': 'E',
        'S': 'S',
        'W': 'W'
    },
    'E': {
        'N': 'W',
        'E': 'N',
        'S': 'E',
        'W': 'S'
    },
    'N': {
        'N': 'S',
        'E': 'W',
        'S': 'N',
        'W': 'E'
    },
    'W': {
        'N': 'E',
        'E': 'S',
        'S': 'W',
        'W': 'N'
    }
}
export default compass;
