import VuePlayingCard from 'vue-playing-card';
import VueSimpleAlert from 'vue-simple-alert';
import ToggleButton from 'vue-js-toggle-button'

import Vue from 'vue'
import App from './App.vue'
import './firebase.setup';
import VueScrollTo from 'vue-scrollto'

import VueClipboard from 'vue-clipboard2'
// import VueSocialSharing from 'vue-social-sharing'
// import deckMixin from './mixins/deckMixin'
import VueCookies from 'vue-cookies'
import '../node_modules/animate.css/animate.css';

// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faSignature, faUserRobot, faUsers, faFlagCheckered , faTrophyAlt, faMedal, faChairOffice, faBars, faSignIn, faSignOut, faHistory, faCopy,
//     faFastBackward, faBookOpen, faCommentAltSmile, faPlay, faPause, faInfoCircle } from '@fortawesome/pro-duotone-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import { faFacebookSquare, faTwitterSquare, faInstagramSquare } from '@fortawesome/free-brands-svg-icons'
// import { faCircle as falCircle, faTriangle as falTriangle, faClipboardList} from '@fortawesome/pro-light-svg-icons'
// import { faHandPointUp, faHandPointLeft, faCircle as fasCircle, faTriangle as fasTriangle } from '@fortawesome/pro-solid-svg-icons'

import { kingCopy } from "@/js/king/kingCopy";

// TODO: Minify to only these icons

// library.add(faSignature, faHandPointUp, faHandPointLeft, faFacebookSquare, faTwitterSquare, faInstagramSquare, faClipboardList,
//     faUserRobot, faUsers, faFlagCheckered,faTrophyAlt, faMedal,
//     faChairOffice, faBars, faSignIn, faPlay, faPause, faInfoCircle,
//     faSignOut, faHistory, falCircle, fasCircle, falTriangle, fasTriangle, faCopy,
//     faFastBackward, faBookOpen, faCommentAltSmile )

// Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueCookies)
Vue.$cookies.config('3650d')

Vue.use(VuePlayingCard);
// Vue.use(VueSocialSharing);

Vue.use(VueScrollTo)
// You can also pass in the default options
Vue.use(VueScrollTo, {
    container: "body",
    duration: 500,
    easing: "ease",
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
})

const appCopy = kingCopy.App;

const options = {
    confirmButtonColor: '#24DA7E',
    cancelButtonColor: '#ff3a00',
    cancelButtonText: appCopy.cancel,
    confirmButtonText: appCopy.ok,
    customClass: {
        zIndex: 2000
    }
};

Vue.use(VueSimpleAlert, options);
Vue.use(VueClipboard);
Vue.use(ToggleButton);

Vue.config.productionTip = false

Vue.prototype.$log = function(message) {
    if (process.env.VUE_APP_ENV === 'dev') {
        console.info(message);
    }
}

// Vue.mixin(deckMixin)

require('events').EventEmitter.defaultMaxListeners = 50;

new Vue({
  render: h => h(App),
}).$mount('#app')
