<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click=close>
      <div id="menu" class="modal"
           role="dialog"
           aria-labelledby="modalTitle"
           aria-describedby="modalDescription"
           >
        <div style="display: flex; flex-direction: column; align-items: stretch;outline: inherit"
        >
           <div class="menu-section">
            <h1 class="authUser" style="align-self: center; font-size: 18px; width: auto"
                @click="close"
            >
              {{ leaderboardCopy.leaderboard }}
            </h1>
            <div style="text-align: left">{{compCopy.description}}</div>
            <div style="text-align: left">{{compCopy.description2}}</div>
          </div>
          <div class="menu-section">
            <div style="text-align: left">
              <div><b>{{ leaderboardCopy.leaderboardCategories.topXKO.name }}</b> </div>
              <div>{{ leaderboardCopy.leaderboardCategories.topXKO.description }} </div>
            </div>
          </div>
          <div class="menu-section">
            <div style="text-align: left">
              <div><b>{{ leaderboardCopy.leaderboardCategories.topXWinRate.name }}</b> </div>
              <div>{{ leaderboardCopy.leaderboardCategories.topXWinRate.description }} </div>
            </div>
          </div>
          <div class="menu-section">
            <div style="text-align: left">
              <div><b>{{ leaderboardCopy.leaderboardCategories.topXMaxStreak.name }}</b> </div>
              <div>{{ leaderboardCopy.leaderboardCategories.topXMaxStreak.description }} </div>
            </div>
          </div>
          <div class="menu-section">
            <div style="text-align: left">
              <div><b>{{ leaderboardCopy.leaderboardCategories.topXBestTime.name }}</b> </div>
              <div>{{ leaderboardCopy.leaderboardCategories.topXBestTime.description }} </div>
            </div>
          </div>
          <div class="menu-section">
            <div style="text-align: left">
              <div><b>{{ leaderboardCopy.leaderboardCategories.topXMaxScore.name }}</b> </div>
              <div>{{ leaderboardCopy.leaderboardCategories.topXMaxScore.description }} </div>
            </div>
          </div>
          <div class="menu-section">
            <div style="text-align: left">
              <div><b>{{ leaderboardCopy.leaderboardCategories.topXScorePerPlayed.name }}</b> </div>
              <div>{{ leaderboardCopy.leaderboardCategories.topXScorePerPlayed.description }} </div>
            </div>
          </div>
<!--          <div class="menu-section">-->
<!--            <div style="text-align: left">-->
<!--              <div><b>{{ leaderboardCopy.leaderboardCategories.topXMaxPositives.name }}</b> </div>-->
<!--              <div>{{ leaderboardCopy.leaderboardCategories.topXMaxPositives.description }} </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="menu-section">-->
<!--            <div style="text-align: left">-->
<!--              <div><b>{{ leaderboardCopy.leaderboardCategories.topXMinNegatives.name }}</b> </div>-->
<!--              <div>{{ leaderboardCopy.leaderboardCategories.topXMinNegatives.description }} </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

import { batakCopy } from "@/js/batak/batakCopy";

export default {
  name: 'ModalCompetitionInfo',
  props: [],
  data: () => ({
    modalUserFlag: false
  }),
  components: {
  },
  methods: {
    close() {
      this.$emit('close');
    }
  },
  created() {
    this.leaderboardCopy = batakCopy.Leaderboard;
    this.compCopy = batakCopy.ModalCompetitionInfo;
  }
};
</script>

<style lang="scss" scoped>
$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669F2;

.form--element {
    background-color: #fff;
    border: 2px solid #ECECEC;
    border-radius: 3px;
    font-size: 16px;
    line-height: 28px;
    padding: 0 4px;
    color: #3D3D3D;
    width: 70%;
    margin: -10px 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    outline: inherit;

    &:focus {
        border: 1px solid #FFC145;
        border-radius: 2px;
    }

    &:not(.texteare) {
        height: 30px;
    }

    &.textarea {
        height: 80px;
        resize: none;
    }
}

#menu {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  //align-items: stretch;
  height: 80vh;
  
  font-size: 20px;
  color: var(--tertiaryColor);
  
  margin: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  
  width: calc(min(95%, 550px));
  
  overflow-y: auto;
  
  
  -ms-overflow-style: auto; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  
  outline: inherit;
  
}

#menu-header {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  
  /*border: 1px solid var(--tertiaryColor);*/
  
  font-size: 20px;
  color: var(--tertiaryColor);
  
  margin: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  padding: 5px;
  
  background-color: var(--mainColor);
  
  outline: inherit;
  
}

.menu-section {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  
  justify-content: center;
  align-items: stretch;
  
  font-size: 20px;
  color: var(--tertiaryColor);
  
  margin: 5px;
  padding: 10px;
  /*margin-bottom: 20px;*/
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  
  outline: inherit;
}

#menu-system > div {
  margin: 5px;
  outline: inherit
}

.menu-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  border-radius: 2px;
  
  outline: inherit
}

.menu-button:hover {
  background-color: var(--secondaryColor);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  
  outline: inherit
}

.Header__stat {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -10px;
  margin-top: -10px;
  
  outline: inherit
}

.Header__stat h4 {
  text-align: left;
  
  outline: inherit
}

.Header__stat h3 {
  text-align: left;
  outline: inherit
}

//.modal-backdrop {
//  position: fixed;
//  z-index: 300;
//
//  top: 0;
//  bottom: 0;
//  left: 0;
//  right: 0;
//  background-color: rgba(0, 0, 0, 0.3);
//  display: flex;
//  justify-content: center;
//  align-items: center;
//
//  outline: inherit;
//
//}

//.modal {
//  display: flex;
//  flex-direction: column;
//
//  justify-content: center;
//  align-items: center;
//
//  /*background: #FFFFFF;*/
//  box-shadow: 2px 2px 20px 1px;
//  /*overflow-x: auto;*/
//
//  /*background-color: #24CD63;*/
//
//  height: 80vh;
//
//  border-radius: 2px;
//  outline: inherit;
//}


</style>
