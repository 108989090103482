import app from '@/firebase.setup'
import 'firebase/database';

export async function find_player_by_name(player_name) {
    const usersRef = await app.database().ref(`users`).once('value')
    const users = usersRef.val()

    const found_users = []
    for (const user_id in users) {
        if (users[user_id]['name'].toLowerCase() === player_name.toLowerCase()) {
            found_users.push(users[user_id])
        }
    }
    return found_users
}

