<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click=close>
      <div class="modal"
           role="dialog"
           aria-labelledby="modalTitle"
           aria-describedby="modalDescription"
      >
        <div class="trumpModal" v-if="trump==='rifki'">
          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
            <div class="header">
              <b class="trumpHeader text">{{ contractsCopy.rifki }}</b>
            </div>
            <div class="ceza-button" v-if="cezalar.rifki >= 0">
              <div class="rifki ceza-button-inner"></div>
              <div class="overlay" v-if="cezalar.rifki === 1"></div>
              <div class="overlay2" v-if="cezalar.rifki === 0"></div>
            </div>
          </div>
          <div class="section text">
            <p style="margin-left:5px; margin-right: 5px">{{ compCopy.noBarbu1 }}</p>
            <p style="margin-left:5px; margin-right: 5px">{{ compCopy.noBarbu2 }}</p>
            <p style="margin-left:5px; margin-right: 5px">{{ compCopy.noBarbu3 }}</p>
          </div>
          <div style="display: flex; justify-content: center" @click.stop>
            <div class="btn-sec" @click="confirmTrump('rifki')">{{ compCopy.choose }}</div>
          </div>
        </div>
        <div class="trumpModal" v-if="trump==='erkek'">
          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
            <div class="header">
              <b class="trumpHeader text">{{ contractsCopy.erkek }}</b>
            </div>
            <div class="ceza-button" v-if="cezalar.erkek >= 0">
              <div class="erkekalmaz ceza-button-inner"></div>
              <div class="overlay" v-if="cezalar.erkek === 1"></div>
              <div class="overlay2" v-if="cezalar.erkek === 0"></div>
            </div>
          </div>
          <div class="section text">
            <p style="margin-left:5px; margin-right: 5px">{{ compCopy.noMen1 }}</p>
            <p style="margin-left:5px; margin-right: 5px">{{ compCopy.noMen2 }}</p>
          </div>
          <div style="display: flex; justify-content: center" @click.stop>
            <div class="btn-sec" style="cursor: pointer;" @click="confirmTrump('erkek')">{{ compCopy.choose }}</div>
          </div>
        </div>
        <div class="trumpModal" v-if="trump==='kiz'">
          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
            <div class="header">
              <b class="trumpHeader text">{{ contractsCopy.kiz }}</b>
            </div>
            <div class="ceza-button" v-if="cezalar.kiz >= 0">
              <div class="kizalmaz ceza-button-inner"></div>
              <div class="overlay" v-if="cezalar.kiz === 1"></div>
              <div class="overlay2" v-if="cezalar.kiz === 0"></div>
            </div>
          </div>
          <div class="section text">
            <p style="margin-left:5px; margin-right: 5px">{{ compCopy.noQueens1 }}</p>
            <p style="margin-left:5px; margin-right: 5px">{{ compCopy.noQueens2 }}</p>
          </div>
          <div style="display: flex; justify-content: center" @click.stop>
            <div class="btn-sec" style="cursor: pointer;" @click="confirmTrump('kiz')">{{ compCopy.choose }}</div>
          </div>
        </div>
        <div class="trumpModal" v-if="trump==='soniki'">
          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
            <div class="header">
              <b class="trumpHeader text">{{ contractsCopy.soniki }}</b>
            </div>
            <div class="ceza-button" v-if="cezalar.soniki >= 0">
              <div class="soniki ceza-button-inner"></div>
              <div class="overlay" v-if="cezalar.soniki === 1"></div>
              <div class="overlay2" v-if="cezalar.soniki === 0"></div>
            </div>
          </div>
          <div class="section text">
            <p style="margin-left:5px; margin-right: 5px">{{ compCopy.noLastTwo }}</p>
          </div>
          <div style="display: flex; justify-content: center" @click.stop>
            <div class="btn-sec" style="cursor: pointer;" @click="confirmTrump('soniki')">{{ compCopy.choose }}</div>
          </div>
        </div>
        
        <div class="trumpModal" v-if="trump==='kupa'">
          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
            <div class="header">
              <b class="trumpHeader text">{{ contractsCopy.kupa }}</b>
            </div>
            <div class="ceza-button" v-if="cezalar.kupa >= 0">
              <div class="kupaalmaz ceza-button-inner"></div>
              <div class="overlay" v-if="cezalar.kupa === 1"></div>
              <div class="overlay2" v-if="cezalar.kupa === 0"></div>
            </div>
          </div>
          <div class="section text">
            <p style="margin-left:5px; margin-right: 5px">{{ compCopy.noHearts1 }}</p>
            <p style="margin-left:5px; margin-right: 5px">{{ compCopy.noHearts2 }}</p>
            <p style="margin-left:5px; margin-right: 5px">{{ compCopy.noHearts3 }}</p>
          </div>
          <div style="display: flex; justify-content: center" @click.stop>
            <div class="btn-sec" style="cursor: pointer;" @click="confirmTrump('kupa')">{{ compCopy.choose }}</div>
          </div>
        </div>
        <div class="trumpModal" v-if="trump==='el'">
          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
            <div class="header">
              <b class="trumpHeader text">{{ contractsCopy.el }}</b>
            </div>
            <div class="ceza-button" v-if="cezalar.el >= 0">
              <div class="elalmaz ceza-button-inner"></div>
              <div class="overlay" v-if="cezalar.el === 1"></div>
              <div class="overlay2" v-if="cezalar.el === 0"></div>
            </div>
          </div>
          <div class="section text">
            <p style="margin-left:5px; margin-right: 5px">{{ compCopy.noTricks }}</p>
          </div>
          <div style="display: flex; justify-content: center" @click.stop>
            <div class="btn-sec" style="cursor: pointer;" @click="confirmTrump('el')">{{ compCopy.choose }}</div>
          </div>
        </div>
        <div class="trumpModal" v-if="trump === 'spades'">
          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
            <div class="header">
              <b class="trumpHeader text">{{ contractsCopy.spades }}</b>
            </div>
            <div class="spades_button koz-button"></div>
          </div>
          <div class="section text">
            <p style="margin-left:5px; margin-right: 5px">{{ compCopy.koz1 }}</p>
            <p style="margin-left:5px; margin-right: 5px">{{ compCopy.koz2 }}</p>
            <p style="margin-left:5px; margin-right: 5px">{{ compCopy.koz3 }}</p>
            <p style="margin-left:5px; margin-right: 5px">{{ compCopy.koz4 }}</p>
          </div>
          <div style="display: flex; justify-content: center" @click.stop>
            <div class="btn-sec" style="cursor: pointer;" @click="confirmTrump('spades')">{{ compCopy.choose }}</div>
          </div>
        </div>
        <div class="trumpModal" v-if="trump === 'hearts'">
          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
            <div class="header">
              <b class="trumpHeader text">{{ contractsCopy.hearts }}</b>
            </div>
            <div class="hearts_button koz-button"></div>
          </div>
          <div class="section text">
            <p style="margin-left:5px; margin-right: 5px">{{ compCopy.koz1 }}</p>
            <p style="margin-left:5px; margin-right: 5px">{{ compCopy.koz2 }}</p>
            <p style="margin-left:5px; margin-right: 5px">{{ compCopy.koz3 }}</p>
            <p style="margin-left:5px; margin-right: 5px">{{ compCopy.koz4 }}</p>
          </div>
          <div style="display: flex; justify-content: center" @click.stop>
            <div class="btn-sec" style="cursor: pointer;" @click="confirmTrump('hearts')">{{ compCopy.choose }}</div>
          </div>
        </div>
        <div class="trumpModal" v-if="trump === 'clubs'">
          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
            <div class="header">
              <b class="trumpHeader text">{{ contractsCopy.clubs }}</b>
            </div>
            <div class="clubs_button koz-button"></div>
          </div>
          <div class="section text">
            <p style="margin-left:5px; margin-right: 5px">{{ compCopy.koz1 }}</p>
            <p style="margin-left:5px; margin-right: 5px">{{ compCopy.koz2 }}</p>
            <p style="margin-left:5px; margin-right: 5px">{{ compCopy.koz3 }}</p>
            <p style="margin-left:5px; margin-right: 5px">{{ compCopy.koz4 }}</p>
          </div>
          <div style="display: flex; justify-content: center" @click.stop>
            <div class="btn-sec" style="cursor: pointer;" @click="confirmTrump('clubs')">{{ compCopy.choose }}</div>
          </div>
        </div>
        <div class="trumpModal" v-if="trump === 'diamonds'">
          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
            <div class="header">
              <b class="trumpHeader text">{{ contractsCopy.diamonds }}</b>
            </div>
            <div class="diamonds_button koz-button"></div>
          </div>
          <div class="section text">
            <p style="margin-left:5px; margin-right: 5px">{{ compCopy.koz1 }}</p>
            <p style="margin-left:5px; margin-right: 5px">{{ compCopy.koz2 }}</p>
            <p style="margin-left:5px; margin-right: 5px">{{ compCopy.koz3 }}</p>
            <p style="margin-left:5px; margin-right: 5px">{{ compCopy.koz4 }}</p>
          </div>
          <div style="display: flex; justify-content: center" @click.stop>
            <div class="btn-sec" style="cursor: pointer;" @click="confirmTrump('diamonds')">{{ compCopy.choose }}</div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {kingCopy} from "@/js/king/kingCopy";

export default {
  name: 'ModalTrumpConfirm',
  props: ["trump", "cezalar"],
  methods: {
    close() {
      this.$emit('close');
    },
    confirmTrump(trump) {
      this.$emit('confirmTrump', trump);
    }
  },
  created() {
    this.compCopy = kingCopy.ModalTrumpConfirm;
    this.contractsCopy = kingCopy.contracts;
  }
};
</script>

<style scoped>
.btn-sec {
  /*border: 1px solid red;*/
  
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 5px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  width: 76px;
  /*min-height: 25px;*/
  
  background-color: var(--mainColor);
  /*height: 40px;*/
  border-radius: 20px;
  line-height: 36px;
  text-align: center;
  color: var(--tertiaryColor);
  text-transform: uppercase;
  font-size: 0.875em;
  font-weight: 700;
  /*padding: 0 34px;*/
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, .6);
  
  outline: inherit;
}

.trumpModal {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  flex: 1 0;
  
  font-size: 20px;
  color: var(--tertiaryColor);
  
  background-color: var(--mainColor);
  
  margin: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  
  outline: inherit;
  
}

.section {
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  margin-left: 15px;
  margin-right: 15px;
  
  font-size: 15px;
  color: var(--tertiaryColor);
  
  /*box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);*/
  border-radius: 2px;
  
  outline: inherit;
  
}

#history div {
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;
  
  
  min-width: 70px;
  margin: 5px;
  
  outline: inherit;
  
}


.modal-backdrop {
  position: fixed;
  z-index: 500;
  
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  
  outline: inherit;
  
}

.modal {
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;
  
  /*background: #FFFFFF;*/
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  margin: 5px;
  
  /*background-color: #24CD63;*/
  
  
  border-radius: 2px;
  
  outline: inherit;
}

.modal-header,
.modal-footer {
  justify-content: center;
  align-items: center;
  padding: 15px;
  display: flex;
  color: var(--tertiaryColor);
  
  outline: inherit;
}


.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
  
  outline: inherit;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  
  outline: inherit;
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease
}

.trumpHeader {
  margin: 3px;
  outline: inherit;
}

.ceza-button {
  display: flex;
  flex: 1 0 auto;
  justify-content: space-evenly;
  
  /*align-items: stretch;*/
  /*padding-bottom: 15vw;*/
  /*height: 15vw;*/
  width: 100px;
  height: 100px;
  
  /*border: 1px black solid;*/
  border-radius: 50%;
  
  /*max-width: 100px;*/
  /*max-height: 100px;*/
  
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.5);
  
  cursor: default;
  
  margin: 2px;
  overflow: hidden;
  
  /*clip-path: polygon(0 0, 0 100px, 100px 80px, 100px 0);*/
  position: relative;
  outline: inherit;
}

.ceza-button-inner {
  display: flex;
  height: 100%;
  width: 100%;
  
  border-radius: 50%;
  outline: inherit;
}


.overlay {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  left: 50%;
  background: rgba(255, 0, 0, 0.6);
  transform: skew(135deg);
  
  outline: inherit;
}

.overlay2 {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  left: 0;
  background: rgba(255, 0, 0, 0.6);
  transform: skew(0deg);
  
  outline: inherit;
}

.koz-button {
  height: 13vw;
  width: 13vw;
  
  border-radius: 5px;
  
  max-width: 100px;
  max-height: 100px;
  
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.5);
}

</style>
