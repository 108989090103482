<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click=close>
      <div class="modal"
           role="dialog"
           aria-labelledby="modalTitle"
           aria-describedby="modalDescription"
      >
        <div v-if="this.winners && this.gameOver && this.remains && this.screenNames">
          <h1 style="margin:15px">🏆 {{ winnersParsed }} {{ compCopy.xWon }} 🏆</h1>
        </div>
        <div id="yazboz-table" v-if="this.yazboz" class="grid">
          
          <span class="item-yazboz-header"><strong>{{ compCopy.yazBoz }}</strong></span>
          <span class="remains">
                        <span class="col">
                          <i class="fa-circle" :class="{'fa-solid': remains.S.ceza[0], 'fa-light': !remains.S.ceza[0] }"/>
                          <i class="fa-circle" :class="{'fa-solid': remains.S.ceza[1], 'fa-light': !remains.S.ceza[1] }"/>
                          <i class="fa-circle" :class="{'fa-solid': remains.S.ceza[2], 'fa-light': !remains.S.ceza[2] }"/>
                        </span>
                        <span class="col">
                          <i class="fa-triangle" :class="{'fa-solid': remains.S.koz[0], 'fa-light': !remains.S.koz[0] }" style="margin-right: 3px"/>
                          <i class="fa-triangle" :class="{'fa-solid': remains.S.koz[1], 'fa-light': !remains.S.koz[1] }"/>
                        </span>
                    </span>
          <span class="remains">
                        <span class="col">
                          <i class="fa-circle" :class="{'fa-solid': remains.W.ceza[0], 'fa-light': !remains.W.ceza[0] }"/>
                          <i class="fa-circle" :class="{'fa-solid': remains.W.ceza[1], 'fa-light': !remains.W.ceza[1] }"/>
                          <i class="fa-circle" :class="{'fa-solid': remains.W.ceza[2], 'fa-light': !remains.W.ceza[2] }"/>
                        </span>
                        <span class="col">
                          <i class="fa-triangle" :class="{'fa-solid': remains.W.koz[0], 'fa-light': !remains.W.koz[0] }" style="margin-right: 3px"/>
                          <i class="fa-triangle" :class="{'fa-solid': remains.W.koz[1], 'fa-light': !remains.W.koz[1] }"/>
                        </span>
                    </span>
          <span class="remains">
                        <span class="col">
                          <i class="fa-circle" :class="{'fa-solid': remains.N.ceza[0], 'fa-light': !remains.N.ceza[0] }"/>
                          <i class="fa-circle" :class="{'fa-solid': remains.N.ceza[1], 'fa-light': !remains.N.ceza[1] }"/>
                          <i class="fa-circle" :class="{'fa-solid': remains.N.ceza[2], 'fa-light': !remains.N.ceza[2] }"/>
                        </span>
                        <span class="col">
                          <i class="fa-triangle" :class="{'fa-solid': remains.N.koz[0], 'fa-light': !remains.N.koz[0] }" style="margin-right: 3px"/>
                          <i class="fa-triangle" :class="{'fa-solid': remains.N.koz[1], 'fa-light': !remains.N.koz[1] }"/>
                        </span>
                    </span>
          <span class="remains">
                        <span class="col">
                          <i class="fa-circle" :class="{'fa-solid': remains.E.ceza[0], 'fa-light': !remains.E.ceza[0] }"/>
                          <i class="fa-circle" :class="{'fa-solid': remains.E.ceza[1], 'fa-light': !remains.E.ceza[1] }"/>
                          <i class="fa-circle" :class="{'fa-solid': remains.E.ceza[2], 'fa-light': !remains.E.ceza[2] }"/>
                        </span>
                        <span class="col">
                          <i class="fa-triangle" :class="{'fa-solid': remains.E.koz[0], 'fa-light': !remains.E.koz[0] }" style="margin-right: 3px"/>
                          <i class="fa-triangle" :class="{'fa-solid': remains.E.koz[1], 'fa-light': !remains.E.koz[1] }"/>
                        </span>
                    </span>
          
          <span class="item-yazboz-header"></span>
          <span>{{ this.name_abbr().S }}</span>
          <span>{{ this.name_abbr().W }}</span>
          <span>{{ this.name_abbr().N }}</span>
          <span>{{ this.name_abbr().E }}</span>
          
          <span class="header"><div>{{ compCopy.barbu }}</div> <div>320</div></span>
          <span class="cezalar">{{ this.yazboz.rifki.S[0] }} {{ this.yazboz.rifki.S[1] }}</span>
          <span class="cezalar">{{ this.yazboz.rifki.W[0] }} {{ this.yazboz.rifki.W[1] }}</span>
          <span class="cezalar">{{ this.yazboz.rifki.N[0] }} {{ this.yazboz.rifki.N[1] }}</span>
          <span class="cezalar">{{ this.yazboz.rifki.E[0] }} {{ this.yazboz.rifki.E[1] }}</span>
          
          <span class="header"><span>{{ compCopy.noQueens }}</span> <div>100</div></span>
          <span class="cezalar">{{ this.yazboz.kiz.S[0] }} {{ this.yazboz.kiz.S[1] }}</span>
          <span class="cezalar">{{ this.yazboz.kiz.W[0] }} {{ this.yazboz.kiz.W[1] }}</span>
          <span class="cezalar">{{ this.yazboz.kiz.N[0] }} {{ this.yazboz.kiz.N[1] }}</span>
          <span class="cezalar">{{ this.yazboz.kiz.E[0] }} {{ this.yazboz.kiz.E[1] }}</span>
          
          <span class="header"> <span>{{ compCopy.noMen }}</span> <div>60</div></span>
          <span class="cezalar">{{ this.yazboz.erkek.S[0] }} {{ this.yazboz.erkek.S[1] }}</span>
          <span class="cezalar">{{ this.yazboz.erkek.W[0] }} {{ this.yazboz.erkek.W[1] }}</span>
          <span class="cezalar">{{ this.yazboz.erkek.N[0] }} {{ this.yazboz.erkek.N[1] }}</span>
          <span class="cezalar">{{ this.yazboz.erkek.E[0] }} {{ this.yazboz.erkek.E[1] }}</span>
          
          <span class="header"><span>{{ compCopy.noHearts }}</span> <div>30</div></span>
          <span class="cezalar">{{ this.yazboz.kupa.S[0] }} {{ this.yazboz.kupa.S[1] }}</span>
          <span class="cezalar">{{ this.yazboz.kupa.W[0] }} {{ this.yazboz.kupa.W[1] }}</span>
          <span class="cezalar">{{ this.yazboz.kupa.N[0] }} {{ this.yazboz.kupa.N[1] }}</span>
          <span class="cezalar">{{ this.yazboz.kupa.E[0] }} {{ this.yazboz.kupa.E[1] }}</span>
          
          <span class="header"><span>{{ compCopy.noTricks }}</span> <div>50</div></span>
          <span class="cezalar">{{ this.yazboz.el.S[0] }} {{ this.yazboz.el.S[1] }}</span>
          <span class="cezalar">{{ this.yazboz.el.W[0] }} {{ this.yazboz.el.W[1] }}</span>
          <span class="cezalar">{{ this.yazboz.el.N[0] }} {{ this.yazboz.el.N[1] }}</span>
          <span class="cezalar">{{ this.yazboz.el.E[0] }} {{ this.yazboz.el.E[1] }}</span>
          
          <span class="header"><span style="flex-wrap: nowrap"> {{ compCopy.noLastTwo }}</span> <div>180</div></span>
          <span class="cezalar">{{ this.yazboz.soniki.S[0] }} {{ this.yazboz.soniki.S[1] }}</span>
          <span class="cezalar">{{ this.yazboz.soniki.W[0] }} {{ this.yazboz.soniki.W[1] }}</span>
          <span class="cezalar">{{ this.yazboz.soniki.N[0] }} {{ this.yazboz.soniki.N[1] }}</span>
          <span class="cezalar">{{ this.yazboz.soniki.E[0] }} {{ this.yazboz.soniki.E[1] }}</span>
          
          <span class="toplamCeza">{{ compCopy.negatives }}</span>
          <span class="toplamCeza">{{ this.scores.S.totalCeza }}</span>
          <span class="toplamCeza">{{ this.scores.W.totalCeza }}</span>
          <span class="toplamCeza">{{ this.scores.N.totalCeza }}</span>
          <span class="toplamCeza">{{ this.scores.E.totalCeza }}</span>
          
          <span class="kozlar">{{ compCopy.positives }}</span>
          <span class="kozlar">{{ this.scores.S.totalKoz }}</span>
          <span class="kozlar">{{ this.scores.W.totalKoz }}</span>
          <span class="kozlar">{{ this.scores.N.totalKoz }}</span>
          <span class="kozlar">{{ this.scores.E.totalKoz }}</span>
          
          <span class="fark">{{ compCopy.result }}</span>
          <span class="fark">{{ this.scores.S.totalKoz - this.scores.S.totalCeza }}</span>
          <span class="fark">{{ this.scores.W.totalKoz - this.scores.W.totalCeza }}</span>
          <span class="fark">{{ this.scores.N.totalKoz - this.scores.N.totalCeza }}</span>
          <span class="fark">{{ this.scores.E.totalKoz - this.scores.E.totalCeza }}</span>
        </div>
        <div v-if="this.gameOver"
             style="display: flex;
                     flex-direction: row;
                     justify-content: center;
                     margin: 5px
                    ">
          <div
            class="btn-green"
            style="background: var(--sixthColor);"
            @click="quitGame">
            <b>{{ compCopy.mainPage }}</b>
          </div>
          <div
            class="btn-green"
            @click="restartGame"><b>{{ compCopy.newGame }}</b></div>
        </div>
        
        <!--                <button-->
        <!--                        type="button"-->
        <!--                        class="btn-green"-->
        <!--                        @click="close"-->
        <!--                        aria-label="Close modal"-->
        <!--                >-->
        <!--                    Kapat!-->
        <!--                </button>-->
      </div>
    </div>
  </transition>
</template>

<script>

import {kingCopy} from "@/js/king/kingCopy";

export default {
  name: 'ModalYazboz',
  props: ["yazboz", "table", "scores", "winners", "screenNames", "gameOver"],
  created() {
    this.compCopy = kingCopy.ModalYazBoz;
  },
  methods: {
    close() {
      this.$emit('close');
    },
    quitGame() {
      this.$emit('quitGame')
    },
    restartGame() {
      this.$emit('restartGame')
    },
    name_abbr() {
      let names = {};
      names['S'] = this.screenNames.S
      names['W'] = this.screenNames.W
      names['N'] = this.screenNames.N
      names['E'] = this.screenNames.E
      
      if (names['S'].substring(names['S'].length - 2, names['S'].length) === '🤖') {
        names['S'] = names['S'].substring(0, names['S'].length - 2)
      }
      if (names['W'].substring(names['W'].length - 2, names['W'].length) === '🤖') {
        names['W'] = names['W'].substring(0, names['W'].length - 2)
      }
      if (names['E'].substring(names['E'].length - 2, names['E'].length) === '🤖') {
        names['E'] = names['E'].substring(0, names['E'].length - 2)
      }
      if (names['N'].substring(names['N'].length - 2, names['N'].length) === '🤖') {
        names['N'] = names['N'].substring(0, names['N'].length - 2)
      }
      return names
    },
  },
  computed: {
    winnersParsed: function () {
      let resp = this.winners[0];
      for (let i = 0; i < this.winners.length - 1; i++) {
        resp += ' ve ' + this.winners[i + 1]
      }
      return resp
    },
    remains: function () {
      const yazboz = this.yazboz;
      const remains = {
        'E': {'koz': [], 'ceza': []},
        'S': {'koz': [], 'ceza': []},
        'N': {'koz': [], 'ceza': []},
        'W': {'koz': [], 'ceza': []}
      };
      for (const seat in remains) {
        if (yazboz.remains[seat]['koz'] === 2) {
          remains[seat]['koz'] = [false, false]
        } else if (yazboz.remains[seat]['koz'] === 1) {
          remains[seat]['koz'] = [true, false];
        } else {
          remains[seat]['koz'] = [true, true];
        }
        
        if (yazboz.remains[seat]['ceza'] === 3) {
          remains[seat]['ceza'] = [false, false, false];
        } else if (yazboz.remains[seat]['ceza'] === 2) {
          remains[seat]['ceza'] = [true, false, false];
        } else if (yazboz.remains[seat]['ceza'] === 1) {
          remains[seat]['ceza'] = [true, true, false];
        } else {
          remains[seat]['ceza'] = [true, true, true];
        }
        
      }
      return remains
    }
  }
  
};
</script>

<style>
#yazboz-table {
  display: grid;
  grid-template-rows: [remains]60px [player-names]30px [rifki]50px [kiz]50px
                            [erkek]50px [kupa]50px [el]50px [soniki]50px
                            [toplam-ceza]50px [kozlar]50px [fark]40px;
  grid-template-columns: [ceza-names] 24% [s]19% [w]19% [n]19% [e]19%[final];
  border-top: 2px solid;
  border-right: 2px solid;
  
  /*background-color: var(--mainColor);*/
  color: var(--tertiaryColor);
  
  margin: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  
}

.remains {
  /*border: 1px solid red;*/
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  
  justify-content: space-evenly;
  align-items: stretch;
  align-content: stretch;
  
}

.remains .col {
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  
  /*border: 1px solid red; */
  margin-top: 5px;
  min-width: 0;
  transform: scale(1.2, 1.2);
}

.remains .col i {
  margin-left: 1px;
  margin-right: 1px;
  margin-top: 3px;
}

#yazboz-table > span {
  display: flex;
  padding: 2px 4px;
  border-left: 1px solid;
  border-bottom: 1px solid;
  
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  
  min-width: 0;
  
  text-shadow: 0px 0px 1px black;
  /*border: red solid 1px;*/
}

#yazboz-table > .header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  /*padding: 8px 4px;*/
  border-left: 1px solid;
  border-bottom: 1px solid;
  
  background-color: rgba(36, 218, 126, 0.2);
  /*margin-top: -8px;*/
  /*border: red solid 1px;*/
}

.cezalar {
  background-color: rgba(36, 218, 126, 0.2);
}

.toplamCeza {
  grid-row-start: toplam-ceza;
  background-color: rgba(255, 30, 30, 0.6);
}

.kozlar {
  grid-row-start: kozlar;
  background-color: #29ffff;
}

.fark {
  display: flex;
  justify-content: space-between;
  
  border-top: 5px solid;
  /*border-bottom: 3px solid;*/
  border-color: crimson;
  
  background-color: var(--mainColor);
  
  text-shadow: 0px 0px 1px black;
}

.item-yazboz-header {
  grid-area: remains / ceza-names / rifki / s;
  /*background-color: #0d0d0d;*/
  
}


.btn-green {
  color: black;
  cursor: pointer;
  background: var(--mainColor);
  border: 1px solid var(--tertiaryColor);
  border-radius: 2px;
  width: auto;
  align-self: center;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.6);
  margin: 5px;
  padding: 10px;
}

</style>
