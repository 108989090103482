<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click=close>
      
      <div class="modal"
           role="dialog"
           aria-labelledby="modalTitle"
           aria-describedby="modalDescription"
      >
        <div id="round-end" :class="trumpUrl">
          <span></span>
          <span class="player-points-roundend points-roundend">{{ scores[relDir.N].round * trumpPts }}</span>
          <span></span>
          <span class="player-points-roundend points-roundend">{{ scores[relDir.W].round * trumpPts }}</span>
          <span></span>
          <span class="player-points-roundend points-roundend">{{ scores[relDir.E].round * trumpPts }}</span>
          <span></span>
          <span class="player-points-roundend points-roundend">{{ scores[relDir.S].round * trumpPts }}</span>
          <span></span>
        </div>
        <button
          type="button"
          class="btn-green"
          @click="close"
          aria-label="Close modal"
        >
          {{ compCopy.beginNewRound }}
        </button>
        <!--        <div>-->
        <!--          <div id="history">-->
        <!--            <div>-->
        <!--              <vue-playing-card style="z-index: 20"-->
        <!--                                v-if="!!this.history && !!this.history.lastCards"-->
        <!--                                :width="100"-->
        <!--                                :signature="cardWName"-->
        <!--                                :key="cardWName"-->
        <!--                                class="playing-card"-->
        <!--              >-->
        <!--              </vue-playing-card>-->
        <!--            </div>-->
        <!--            <div>-->
        <!--              <vue-playing-card style="z-index: 20"-->
        <!--                                v-if="!!this.history && !!this.history.lastCards"-->
        <!--                                :width="100"-->
        <!--                                :signature="cardNName"-->
        <!--                                :key="cardNName"-->
        <!--                                class="playing-card">-->
        <!--              </vue-playing-card>-->
        
        <!--              <vue-playing-card style="z-index: 20"-->
        <!--                                v-if="!!this.history && !!this.history.lastCards"-->
        <!--                                :width="100"-->
        <!--                                :signature="cardSName"-->
        <!--                                :key="cardSName"-->
        <!--                                class="playing-card">-->
        <!--              </vue-playing-card>-->
        <!--            </div>-->
        <!--            <div>-->
        <!--              <vue-playing-card style="z-index: 20"-->
        <!--                                v-if="!!this.history && !!this.history.lastCards"-->
        <!--                                :width="100"-->
        <!--                                :signature="cardEName"-->
        <!--                                :key="cardEName"-->
        <!--                                class="playing-card">-->
        <!--              </vue-playing-card>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </transition>
</template>

<script>
import {kingCopy} from "@/js/king/kingCopy";

export default {
  name: 'ModalRoundEnd',
  props: ["game", "gameMeta", "relDir"],
  methods: {
    close() {
      this.$emit('close');
    },
  },
  created() {
    this.compCopy = kingCopy.ModalRoundEnd;
  },
  computed: {
    trump: function () {
      return this.game.table.koz || this.game.table.ceza
    },
    scores: function () {
      return this.gameMeta.scores
    },
    trumpPts: function () {
      if (this.trump === 'rifki') {
        return -320
      } else if (this.trump === 'el') {
        return -50
      } else if (this.trump === 'kupa') {
        return -30
      } else if (this.trump === 'erkek') {
        return -60
      } else if (this.trump === 'kiz') {
        return -100
      } else if (this.trump === 'soniki') {
        return -180
      } else {
        return 50
      }
    },
    trumpUrl: function () {
      if (this.trump === 'rifki') {
        return "bg-rifki"
      } else if (this.trump === 'el') {
        return "bg-el"
      } else if (this.trump === 'kupa') {
        return "bg-kupa"
      } else if (this.trump === 'erkek') {
        return "bg-erkek"
      } else if (this.trump === 'kiz') {
        return "bg-kiz"
      } else if (this.trump === 'soniki') {
        return "bg-soniki"
      } else if (this.trump === 'hearts') {
        return "bg-hearts"
      } else if (this.trump === 'diamonds') {
        return "bg-diamonds"
      } else if (this.trump === 'clubs') {
        return "bg-clubs"
      } else if (this.trump === 'spades') {
        return "bg-spades"
      } else {
        return ''
      }
    },
    cardWName: function () {
      return this.deck[this.game.table.cards[this.relDir.W]].name
    },
    cardEName: function () {
      return this.deck[this.game.table.cards[this.relDir.E]].name
    },
    cardNName: function () {
      return this.deck[this.game.table.cards[this.relDir.N]].name
    },
    cardSName: function () {
      return this.deck[this.game.table.cards[this.relDir.S]].name
    },
  },
  // watch: {
  //     trumpUrl() {
  //         // document.documentElement.style
  //         //     .setProperty('--background-url', url('../assets/spades.png' )); //this.trumpUrl()
  //
  //     }
  // }
  
};
</script>

<style scoped>

#history {
  display: grid;
  grid-template-rows: [r] 330px [rfinal];
  grid-template-columns: [l] 110px [c] 110px [r] 110px [cfinal];
  
  color: var(--tertiaryColor);
  margin: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  
  border-radius: 2px;
  
  outline: inherit;
}

#history div {
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;
  
  
  min-width: 70px;
  margin: 5px;
  
  outline: inherit;
}

#round-end {
  display: grid;
  grid-template-rows: [t]100px [m]100px [b]100px [rfinal];
  grid-template-columns: [l]110px [c]110px [r]110px [cfinal];
  
  color: var(--tertiaryColor);
  
  margin: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  
  border-radius: 2px;
  
  outline: inherit;
  
}

#round-end span {
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;
  
  min-width: 0;
  margin: auto;
  
  outline: inherit;
  
}

.bg-rifki {
  background: url("../../../assets/rifki.png") center no-repeat;
  background-size: 35%;
}

.bg-soniki {
  background: url("../../../assets/soniki.png") center no-repeat;
  background-size: 35%;
}

.bg-kupa {
  background: url("../../../assets/kupaalmaz.png") center no-repeat;
  background-size: 35%;
}

.bg-el {
  background: url("../../../assets/elalmaz.png") center no-repeat;
  background-size: 35%;
}

.bg-erkek {
  background: url("../../../assets/erkekalmaz.png") center no-repeat;
  background-size: 35%;
}

.bg-kiz {
  background: url("../../../assets/kizalmaz.png") center no-repeat;
  background-size: 35%;
}

.bg-hearts {
  background: url("../../../assets/hearts.png") center no-repeat;
  background-size: 35%;
}

.bg-diamonds {
  background: url("../../../assets/diamond.png") center no-repeat;
  background-size: 35%;
}

.bg-clubs {
  background: url("../../../assets/clubs.png") center no-repeat;
  background-size: 35%;
}

.bg-spades {
  background: url("../../../assets/spades.png") center no-repeat;
  background-size: 35%;
}

.player-points-roundend {
  display: flex;
  /*justify-content: center;*/
  /*align-items: center;*/
  /*align-self: center;*/
  flex: 1 0;
  
  width: 40px;
  height: 40px;
  padding: 11px;
  background: var(--secondaryColor);
  border-radius: 50%;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  /*position: absolute;*/
  z-index: 120;
  /*/*left: -30px;*/
  /*text-align: center;*/
  /*flex: 0 1 auto;*/
  
  outline: inherit;
}

.points-roundend {
  /*border: 2px darkblue solid;*/
  font-size: 1.5rem;
  color: var(--tertiaryColor);
  text-shadow: 1px 1px 8px rgba(0, 0, 0, 1);
  cursor: default;
  outline: inherit;
}


.modal-backdrop {
  position: fixed;
  z-index: 300;
  
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  
  outline: inherit;
  
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;
  
  /*position: absolute;*/
  /*top: calc(100% - 410px);*/
  
  border-radius: 2px;
  
  outline: inherit;
}

.modal-header,
.modal-footer {
  justify-content: center;
  align-items: center;
  padding: 15px;
  display: flex;
  color: var(--tertiaryColor);
  
  outline: inherit;
}


.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
  
  outline: inherit;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  
  outline: inherit;
}

.btn-close {
  border: none;
  font-size: 20px;
  padding: 20px;
  cursor: pointer;
  font-weight: bold;
  color: #4AAE9B;
  background: transparent;
}

.btn-green {
  color: black;
  background: var(--mainColor);
  border: 1px solid var(--tertiaryColor);
  border-radius: 2px;
  width: auto;
  align-self: center;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.6);
  margin-bottom: 6px;
  margin-top: 6px;
}
</style>
