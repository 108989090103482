export const batakCopy = {
    'months': {
        "1": "Ocak",
        "2": "Şubat",
        "3": "Mart",
        "4": "Nisan",
        "5": "Mayıs",
        "6": "Haziran",
        "7": "Temmuz",
        "8": "Ağustos",
        "9": "Eylül",
        "10": "Ekim",
        "11": "Kasım",
        "12": "Aralık"
    },
    'suits': {
        "spades": "Maça",
        "diamonds": "Karo",
        "clubs": "Sinek",
        "hearts": "Kupa",
    },
    'validations': {
        'playBarbu': 'Rıfkı oynamalısınız.',
        'playHearts': 'Kupa oynamalısınız.',
        'playX': 'oynamalısınız.',
        'notYetHearts': 'Bu elde henüz kupa çıkmadı. Kupa çıkamazsınız.',
        'notYetTrump': 'Bu elde henüz koz çıkmadı. Koz çıkamazsınız.',
    },
    'App': {
        'defaultName': 'Sen',
        'roundCopy': '. Tur',
        'change': 'Değİştİr',
        'ok': 'Tamam',
        'cancel': 'Geri Dön',
        'quitWarning': 'Önceki oyununuzu terk edip linkteki oyuna gireceksiniz. Kabul ediyor musunuz?',
    },
    'Landing': {
        'flashMessage': '🚀 Batak (Koz Maça) yayında!',
        'flashMessage2': 'Tıkla ve dene.',
        'flashMessage3': '🏆 Kasım şampiyonları için tıkla.',
        'footerNote':'👉 Öneri ve istekleriniz için iletişime geçin',
        'newRibbon': 'Yeni',
        'noRegistrationRibbon': 'Kayıtsız',
        'landingCopy1': "🔥 Ücretsiz, kayıtsız, bilgisayara karşı veya arkadaşlarınızla Batak oynayın.",
        'landingCopy3': 'Liderlik tablosu ve puan sistemi çok yakında geliyor!',
        'landingCopy4': 'Bizi sosyal medya hesaplarımızdan takip edin.',
        'verifyEmail': 'E-posta adresinizi gönderdiğimiz E-posta ile doğrulayın.',
        'emailCopy': 'E-posta adresiniz: ',
        'playWithBots': 'Bilgisayarla',
        'playWithBotsDescription': 'Oyna',
        'playWithFriends': 'Arkadaşınla',
        'playWithFriendsDescription': 'Oyna',
        'playRated': 'Puanlı',
        'playRatedDescription': 'Oyna',
        'start': 'Başla',
        'back': 'Geri',
        'loginToStart': 'Başlamak için giriş yapın',
        'userName': 'Kullanıcı Adı',
        'singleRule1': 'Üye olmadan oyna. Çaylaklar için.',
        'singleRule2': 'Liderlik tablosunu ve KagitOyna puanını etkilemez.',
        'singleRule3': "Ücretsiz üye olarak Puanlı oyna",
        'ratedRule1': 'Liderlik tablosunda ustalığını göster.',
        'ratedRule2': 'Oyunu bitir, 200 KagitOyna puanı kazan.',
        'ratedRule3': 'Ayrıca oyun sonu puanın kadar KagitOyna puanı kazan.',
        'friendlyRule1': 'Oyun kodunu kopyala, arkadaşlarına yolla.',
        'friendlyRule2': 'Boş koltuklara botları ekle.',
        'friendlyRule3': 'KagitOyna puanını etkilemez.',

    },
    'Leaderboard': {
        'season': 'SEZONU',
        'allTime': 'Tüm Zamanlar',
        'username': 'GİRİŞ YAP',
        'leaderboard': 'PUAN TABLOSU',
        'played': 'Oynanan',
        'wins': 'Galibiyet',
        'streak': 'Güncel üst üste galibiyet',
        'streakGeneral': 'Üst üste galibiyet',
        'maxStreak': 'En fazla üst üste galibiyet',
        'koRating': 'KagitOyna Puanı',
        'bestTime': 'En hızlı galibiyet',
        'winRate': 'Galibiyet oranı',
        'maxScore': 'En yüksek puan',
        'scorePerPlayed': 'Ortalama puan',
        'maxPositives': 'En yüksek ÇIKAN',
        'minNegatives': 'En düşük BATAN',
        'minutes': 'dk.',
        'seconds': 'sn.',
        'leaderboardEnterCriteria': 'Galibiyet Oranı ve Ortalama Puan listelerine girebilmek için en az 10 oyun oynamış olmak gerekir.',
        'leaderboardCategories': {
            'topXKO': {
                'name': 'KağıtOyna Puanı',
                'name1': 'KağıtOyna',
                'name2': 'Puanı',
                'description': 'KagitOyna puanına göre yapılmış sıralamadır.'
            },
            'topXWinRate': {
                'name': 'Galibiyet Oranı',
                'name1': 'Galibiyet',
                'name2': 'Oranı',
                'description': 'En yüksek galibiyet yüzdesine göre yapılmış sıralamadır. Parantez içindeki sayı toplam oynanan oyunu gösterir. ' +
                  'Puan tablosuna girebilmek için en az 10 oyun oynamış olmak gerekir.'
            },
            'topXMaxStreak': {
                'name': 'Üst Üste Galibiyet',
                'name1': 'Üst Üste',
                'name2': 'Galibiyet',
                'description': 'En fazla üst üste galibiyet sayısına göre yapılmış sıralamadır.'
            },
            'topXBestTime': {
                'name': 'En Hızlı Galibiyet',
                'name1': 'En Hızlı',
                'name2': 'Galibiyet',
                'description': 'En kısa sürede alınmış galibiyete göre yapılmış sıralamadır.'
            },
            'topXMaxScore': {
                'name': 'En Yüksek Puan',
                'name1': 'En Yüksek',
                'name2': 'Puan',
                'description': 'En yüksek oyun sonu puanına göre yapılmış sıralamadır.'
            },
            'topXScorePerPlayed': {
                'name': 'Ortalama Puan',
                'name1': 'Ortalama',
                'name2': 'Puan',
                'description': 'Ortalama oyun sonu puanına göre yapılmış sıralamadır. Puan tablosuna girebilmek için en az 10 oyun oynamış olmak gerekir. ' +
                  'Yarım bırakılan oyunlar 0 puan sayılır.'
            },
            'topXMaxPositives': {
                'name': 'En Yüksek ÇIKAN',
                'name1': 'En Yüksek',
                'name2': 'ÇIKAN',
                'description': 'Bir oyunda çıkan ellerden alınmış en yüksek toplam puan sıralamasıdır.'
            },
            'topXMinNegatives': {
                'name': 'En Düşük BATAN',
                'name1': 'En Düşük',
                'name2': 'BATAN',
                'description': 'Bir oyunda batan ellerden kaybedilmiş en düşük toplam puan sıralamasıdır.'
            }
        },
    },
    'ModalCompetitionInfo': {
        'description': 'Batak oyununda aşağıdaki sekiz farklı puan kategorisi bulunmaktadır.',
        'description2': 'Bu kategoriler aylık ve tüm zamanlar penceresinde değerlendirilir.'
    },
    'ModalUserProfile': {
        'placeholderUsername': 'Kullanıcı Adı',
        'placeholderPassword': 'Şifre',
        'placeholderPasswordConfirm': 'Şifre Tekrar',
        'placeholderEmail': 'E-posta',
        'back': 'Geri',
        'signOutCopy': 'Çıkış',
        'signIn': 'Giriş',
        'signInTitle': 'Giriş Yap',
        'signUpTitle': 'Kayıt Ol',
        'newUserTitle': 'Yeni Hesap Oluştur',
        'enter': 'Devam',
        'or': 'veya',
        'newUserCopy': 'Ücretsiz üye ol',
        'oldUserCopy': 'Hesabınız varsa buradan giriş yapın.',
        'createCopy': 'Yeni hesap oluşturun.',
        'signInWithGoogle': 'Google ile devam et',
        'renameUser': 'Kullanıcı adını değiştir',
        'quitWarning': 'Siteden çıkmanız oyunu da terk etmenize neden olur. Siteden çıkmak ve oyunu terk etmek istediğinizden emin misiniz?',
        'signInAdvantage': 'Siteye giriş yapın, oyununuz bulutta kayıtlı kalsın.',
        'userNameMissingAlert': 'Kullanıcı adı girmelisiniz.',
        'passwordMissingAlert': 'Şifre girmelisiniz.',
        'emailMissingAlert': 'E-posta adresinizi girmelisiniz.',
        'badlyFormatedEmailAlert': 'Geçersiz bir E-posta adresi girdiniz.',
        'emailAlreadyInUseAlert': 'Bu E-posta adresi zaten kullanımdadır.',
        'weakPasswordAlert': 'Şifre en az 6 haneli olmalı.',
        'noPasswordMatchAlert': 'Aynı şifreyi tekrarlayınız.',
        'genericErrorAlert': 'Lütfen aşağıdaki hata kodunu kopyalayarak batak@kagitoyna.com adresine gönderin. ',
        'userNotFoundAlert': 'Bu E-posta adresi sistemimizde kayıtlı değil.'
    },
    'InvitePanel': {
        'copyShare': 'Kopyala paylaş',
        'addBot': 'Bot Ekle',
        'sit': 'Otur',
        'inviteCopy1': '👆 Arkadaşlarını davet et.',
        'inviteCopy2': 'Veya ',
        'inviteCopy3': '👇 Bot Ekle',
        'inviteCopy4': 'yerek bilgisayara karşı oyna.',
        'copyGameCodeAlert': 'Oyun adresini kopyaladınız. Arkadaşlarınızla paylaşın, hemen oyuna başlayın.'
    },
    'HandNumberChooser': {
    },
    'ModalMenu': {
        'gameCode': 'Masa kodu:',
        'gameHost': 'Masa sahibi:',
        'quitGame': 'Masayı terk et',
        'restartGame': 'Baştan başlat',
        'renameUser': 'Kullanıcı adını değiştir',
        'howToPlayBatak': 'Batak nasıl oynanır?',
        'contactUs': 'İletişime geç',
        'followUs': 'Takip et',
        'restart': 'Oyunu baştan başlatmak istediğinizden emin misiniz?',
        'restartOnlyHost': 'Yalnızca masayı açan oyuncu oyunu baştan başlatabilir.',
        'quitAlert': 'Oyunu terk etmek istediğinizden emin misiniz?'
    },
    'TablePanel': {
        'noBarbuChosen': 'Rıfkı Almaz seçti!',
        'noTricksChosen': 'El Almaz seçti!',
        'noLastTwoChosen': 'Son İki Almaz seçti!',
        'noMenChosen': 'Erkek Almaz seçti!',
        'noQueensChosen': 'Kız Almaz seçti!',
        'noHeartsChosen': 'Kupa Almaz seçti!',
        'heartsChosen': 'Koz Kupa seçti!',
        'clubsChosen': 'Koz Sinek seçti!',
        'spadesChosen': 'Koz Maça seçti!',
        'diamondsChosen': 'Koz Karo seçti!'
    },
    'ModalYazBoz': {
        'yazBoz': 'Yazboz',
        'xWon': 'kazandı!',
        'positives': 'Çıkanlar',
        'negatives': 'Batanlar',
        'result': 'Toplam',
        'mainPage': 'Ana Sayfa',
        'newGame': 'Yeni Oyun'
    },
    'ModalHistory': {
        'lastHand': 'Önceki El',
        'playedHearts': 'Çıkmış Kupalar',
        'playedMen': 'Çıkmış Erkekler',
        'playedQueens': 'Çıkmış Kızlar'

    },
    'ModalRoundEnd': {
        'beginNewRound': 'Yeni Eli Başlat!'
    },
    'ModalUserName': {
        'userName': 'Kullanıcı Adı',
        'back': 'Geri',
        'takeSeat': 'Otur',
        'userNameLengthAlert': 'Kullanıcı adınız en fazla on karakterden oluşabilir.',
        'userNameFirstLetterAlert': 'Kullanıcı adınızın ilk karakteri rakam ya da harf olmalıdır.',
        'userAlreadyExists': 'Seçtiğiniz kullanıcı adı kullanımda. Başka bir ad seçiniz.',
        'nameTooShort': 'Kullanıcı adınız en az üç haneli olmalıdır.'
    }
}