import { deck_template } from "../deck";
import { v4 as uuid } from 'uuid';
import { namePool } from "../namePool";
import { dealAllCardsToPlayersHigherThan10 } from "../utils";
import {nextSeat, suitsInHandFunction, mapRank2Id, orderHand, howMany, playedCards, } from "../games"; //prettyPrint
import {isInvalid} from "./validations"

const deck = JSON.parse(JSON.stringify(deck_template));

export const ratedLevels = {
  '1': {
    'name': 'Çaylak',
    'startPoints': 0,
    'endPoints': 5000
  },
  '2': {
    'name': 'Amatör',
    'startPoints': 5000,
    'endPoints': 15000
  },
  '3': {
    'name': 'Çırak',
    'startPoints': 15000,
    'endPoints': 30000
  },
  '4': {
    'name': 'Yancı',
    'startPoints': 30000,
    'endPoints': 50000
  },
  '5': {
    'name': 'Oyuncu',
    'startPoints': 50000,
    'endPoints': 100000
  },
  '6': {
    'name': 'Uzman',
    'startPoints': 100000,
    'endPoints': 175000
  },
  '7': {
    'name': 'Erbab',
    'startPoints': 175000,
    'endPoints': 250000
  },
  '8': {
    'name': 'Profesyonel',
    'startPoints': 250000,
    'endPoints': 500000
  },
  '9': {
    'name': 'Profesör',
    'startPoints': 500000,
    'endPoints': 1000000
  },
  '10': {
    'name': 'Maestro',
    'startPoints': 1000000,
    'endPoints': 10000000
  }
};

export function createGame(user, gameType) {
  const id = uuid().substring(0, 5);
  const usersInGame = {'S': {'name': user.name, 'id': user.id, 'gameCategory': 'batak' }}
  const gameCategory = user.gameCategory
  const round = 0;


  if (gameType === 'batakSingle') {
    usersInGame['W'] = addBot('W', id, 'batakSingle')
    usersInGame['N'] = addBot('N', id, 'batakSingle', [usersInGame['W'].name.substring(9)])
    usersInGame['E'] = addBot('E', id, 'batakSingle', [usersInGame['W'].name.substring(9), usersInGame['N'].name.substring(9)])
  }
  else if (gameType === 'batakRated') {
    usersInGame['W'] = addBot('W', id, 'batakRated')
    usersInGame['N'] = addBot('N', id, 'batakRated', [usersInGame['W'].name.substring(9)])
    usersInGame['E'] = addBot('E', id, 'batakRated', [usersInGame['W'].name.substring(9), usersInGame['N'].name.substring(9)])
  }

  const game = {
    id,
    round: round,
    dealer: 'S',
    usersInGame,
    table: {
      'tableSuit': null,
      'whoseTurn': 'S',
      'kozRevealed': false,
      'tableRound': 1,
      'gameOver': false,
      'cards': {},
      'roundEnd': false,
    },
    'contracts': {
      'S': null,
      'W': null,
      'N': null,
      'E': null
    }
  };

  const gameMeta = {
    id,
    gameType: gameType,
    gameCategory: gameCategory,
    createTs: {
      0: {
        'round': 0
      }
    },
    creator: user.name,
    owner: {
      'id': user.id,
      'name': user.name,
      'seat': 'S'
    },
    restartCounter: 0,
    scores: {
      'S': {'total': 0, 'round': 0, 'totalKoz': 0, 'totalCeza': 0},
      'W': {'total': 0, 'round': 0, 'totalKoz': 0, 'totalCeza': 0},
      'N': {'total': 0, 'round': 0, 'totalKoz': 0, 'totalCeza': 0},
      'E': {'total': 0, 'round': 0, 'totalKoz': 0, 'totalCeza': 0},
    },
    yazboz: {
    }
  }

  const newUser = {
    'id': user.id,
    'name': user.name,
    'gameType': gameType,
    'gameCategory': gameCategory,
    'gameId': game.id,
    'seat': 'S'
  }
  const hands = dealAllCardsToPlayersHigherThan10()

  return [game, gameMeta, hands, newUser]
}

export function restartGame(game, gameMeta, user) {
  const gameId = game.id;
  const usersInGame = game.usersInGame;
  const gameType = user.gameType;
  const gameCategory = user.gameCategory;

  const createTs = gameMeta.createTs
  let restartCounter = gameMeta.restartCounter;

  let newGame = {
    'id': gameId,
    'usersInGame': usersInGame,
    round: 1,
    'dealer': user.seat,
    table: {
      'tableSuit': null,
      'whoseTurn': user.seat,
      'kozRevealed': false,
      'tableRound': 1,
      'gameOver': false,
      'cards': {},
      'roundEnd': false,
    },
    'contracts': {
      'S': null,
      'W': null,
      'N': null,
      'E': null
    },
    'history': null
  };

  const newGameMeta = {
    'id': gameId,
    'gameType': gameType,
    'gameCategory': gameCategory,
    'creator': user.name,
    'owner': user,
    'restartCounter': 0,
    scores: {
      'S': {'total': 0, 'round': 0, 'totalKoz': 0, 'totalCeza': 0},
      'W': {'total': 0, 'round': 0, 'totalKoz': 0, 'totalCeza': 0},
      'N': {'total': 0, 'round': 0, 'totalKoz': 0, 'totalCeza': 0},
      'E': {'total': 0, 'round': 0, 'totalKoz': 0, 'totalCeza': 0},
    },
    yazboz: {
    }
  }

  const hands = dealAllCardsToPlayersHigherThan10()
  // [newGame, hands] = updateStateRoundEnd(newGame, newGameMeta);

  if(restartCounter) {
    restartCounter += 1
  }
  else {
    restartCounter = 1
  }
  newGameMeta['restartCounter'] = restartCounter;
  newGameMeta['createTs'] = createTs;

  return [newGame, newGameMeta, hands]
}

export function addBot(seat, gameId, gameType, namesExcluded = []) {
  const nameP = JSON.parse(JSON.stringify(namePool));
  if (!Date.now) {
    Date.now = function () {
      return new Date().getTime();
    }
  }
  let index = Math.floor(Math.random() * nameP.length);
  while (namesExcluded.includes(nameP[index])) {
    index = Math.floor(Math.random() * nameP.length);
  }
  const botName = '_bot' + JSON.stringify(Date.now()).substring(8) + nameP[index];

  return {
    'id': botName,
    'gameId': gameId,
    'gameCategory': 'batak',
    'gameType': gameType,
    'name': botName,
    'seat': seat
  }
}


export function botPlay(seat, game, hand) {
  return botKoz(game, hand)
}

function botKoz(game, hand) {
  const tableSuit = game.table.tableSuit;

  if (tableSuit) {
    if (Object.keys(game.table.cards).length === 3) {
      return play_4(game, hand)
    } else { //en son degilsen
      return play_2_3(game, hand)
    }
  }
  else { //Eli belirleyen ise - Olmuslari topla, yoksa en kisa elin kucugunu at (koz olmayan), sonra kozun en kucugu
    return play_1(game, hand)
  }
}

function play_1(game, hand) {
  let pCards = playedCards(game)

  if (ripeCards(game, hand).length > 0) {
    let ripeForTableSuit = ripeCards(game, hand)
    if (!game.table.kozRevealed) {
      ripeForTableSuit = ripeForTableSuit.filter(x => deck[x].suit !== game.table.koz)
    }
    for (const rcid of ripeForTableSuit) { // don't play if less than 5 cards are played for this suit
      if (howMany(pCards, 'suit', deck[rcid].suit) < 5) {
        return rcid;
      }
    }
  }
  const excludedSuits = ripeCards(game, hand).map(x => deck[x].suit).concat(game.table.koz)
  const possibleSuits = ['spades', 'hearts', 'clubs', 'diamonds'].filter(x => !excludedSuits.includes(x))

  if (orderSuitsByLength(hand, possibleSuits).length > 0) {
    for (const s of orderSuitsByLength(hand, possibleSuits)) {
      if (howMany(pCards, 'suit', s) < 9) {
        return orderHand(hand, s)[0]
      }
    }
    return orderHand(hand, orderSuitsByLength(hand, possibleSuits)[0])[0]
  }
  else if (ripeCards(game, hand).length > 0) {
    if (ripeCards(game, hand).filter(x => deck[x].suit !== game.table.koz).length > 0) {
      return ripeCards(game, hand).filter(x => deck[x].suit !== game.table.koz)[0]
    } else {
      return ripeCards(game, hand).filter(x => deck[x].suit === game.table.koz)[0]
    }
  }
  else {
    return orderHand(hand, game.table.koz)[0]
  }
}

function play_4(game, hand){
  const seat = game.table.whoseTurn
  const tableSuit = game.table.tableSuit
  const suitsInHand = suitsInHandFunction(hand)
  const orderedHand = orderHand(hand, tableSuit)

  if (suitsInHand.includes(tableSuit)) { // elde varsa en kucukten baslayarak, ilk winner karti at
    for (const myCardId of orderedHand) {
      if (isWinnerCard(seat, hand[myCardId], game.table)) {
        return hand[myCardId]
      }
    }
    return hand[orderedHand[0]]
  }
  else { // elde yoksa kozlardan en kucukten baslayarak ilk winneri at
    if (suitsInHand.includes(game.table.koz)){
      const orderedHand = orderHand(hand, game.table.koz)
      for (const myCardId of orderedHand) {
        if (isWinnerCard(seat, myCardId, game.table)) {
          return myCardId
        }
      }
      // yoksa en kucuk koz
      return orderHand(hand, game.table.koz)[0]
    }
    else { // koz da yoksa en kucuk karti at
      return orderHand(hand)[0]
    }
  }
}

function play_2_3(game, hand){
  const seat = game.table.whoseTurn
  const tableSuit = game.table.tableSuit;
  const suitsInHand = suitsInHandFunction(hand);

  if (suitsInHand.includes(tableSuit)) {
    const ripeForTableSuit = ripeCards(game, hand).filter((cardId) => deck[cardId].suit === tableSuit)
    if (ripeForTableSuit.length > 0) {
      return ripeForTableSuit[0]
    } else {
      const orderedSuitsInHand = orderHand(hand, tableSuit)
      for (const myCardId of orderedSuitsInHand) { // Yukseltmek mecbur
        if (isWinnerCard(seat, hand[myCardId], game.table)) {
          return hand[myCardId]
        }
      }
      return orderedSuitsInHand[0]
    }
  }
  else {// elde yoksa kozlardan en kucukten baslayarak ilk winneri at
    if (suitsInHand.includes(game.table.koz)){
      const orderedHand = orderHand(hand, game.table.koz)
      for (const myCardId of orderedHand) {
        if (isWinnerCard(seat, myCardId, game.table)) {
          return myCardId
        }
      }
      // yoksa en kucuk koz
      return orderHand(hand, game.table.koz)[0]
    }
    else { // koz da yoksa en kucuk karti at
      return orderHand(hand)[0]
    }
  }
}


export function tableWinner(table) {
  const deck = {...deck_template}
  const tableCards = table['cards'];
  const tableSuit = table['tableSuit'];
  let trump = table.koz
  if (table.ceza) {
    trump = table.tableSuit
  }
  const weightedCards = {};
  for (const seat in tableCards) {
    if (deck[tableCards[seat]].suit === trump) {
      weightedCards[deck[tableCards[seat]].rank * 100] = seat;
    } else if (deck[tableCards[seat]].suit === tableSuit) {
      weightedCards[deck[tableCards[seat]].rank * 10] = seat;
    } else {
      weightedCards[deck[tableCards[seat]].rank] = seat;
    }
  }
  const max_index = Object.keys(weightedCards).reduce((a, b) => {
    if (parseInt(a) > parseInt(b)) {
      return a
    } else {
      return b
    }
  })
  return weightedCards[max_index]
}

function isWinnerCard(seat, mycard, table) {
  const cards = JSON.parse(JSON.stringify(table.cards));
  cards[seat] = mycard

  const testTable = {'cards': cards, 'tableSuit': table.tableSuit, 'koz': table.koz}
  return tableWinner(testTable) === seat;
}

function orderSuitsByLength(hand, unFinishedS = ['spades', 'hearts', 'clubs', 'diamonds']) {
  const suits = []
  if (hand && unFinishedS.includes('spades')) {
    suits.push({'suit': 'spades', 'length': Object.keys(hand).filter(x => deck[x].suit === 'spades').length})
  }
  if (hand && unFinishedS.includes('hearts')) {
    suits.push({'suit': 'hearts', 'length': Object.keys(hand).filter(x => deck[x].suit === 'hearts').length})
  }
  if (hand && unFinishedS.includes('diamonds')) {
    suits.push({'suit': 'diamonds', 'length': Object.keys(hand).filter(x => deck[x].suit === 'diamonds').length})
  }
  if (hand && unFinishedS.includes('clubs')) {
    suits.push({'suit': 'clubs', 'length': Object.keys(hand).filter(x => deck[x].suit === 'clubs').length})
  }
  return suits.filter(x => x['length'] > 0).sort((x, y) => x['length'] - y['length']).map(x => x['suit'])
}

//olmuslar
export function ripeCards(game, hand) {
  const rcards = []
  let pCards = playedCards(game)
  for (let i = 14; i > 1; i--) {
    if (!pCards.includes(mapRank2Id('spades', i))) {
      if (hand[mapRank2Id('spades', i)] && !isInvalid(hand[mapRank2Id('spades', i)], game.table, hand)) {
        rcards.push(hand[mapRank2Id('spades', i)])
      }
      break
    }
  }
  for (let i = 14; i > 1; i--) {
    if (!pCards.includes(mapRank2Id('diamonds', i))) {
      if (hand[mapRank2Id('diamonds', i)] && !isInvalid(hand[mapRank2Id('diamonds', i)], game.table, hand)) {
        rcards.push(hand[mapRank2Id('diamonds', i)])
      }
      break
    }
  }

  for (let i = 14; i > 1; i--) {
    if (!pCards.includes(mapRank2Id('clubs', i))) {
      if (hand[mapRank2Id('clubs', i)] && !isInvalid(hand[mapRank2Id('clubs', i)], game.table, hand)) {
        rcards.push(hand[mapRank2Id('clubs', i)])
      }
      break
    }
  }
  for (let i = 14; i > 1; i--) {
    if (!pCards.includes(mapRank2Id('hearts', i))) {
      if (hand[mapRank2Id('hearts', i)] && !isInvalid(hand[mapRank2Id('hearts', i)], game.table, hand)) {
        rcards.push(hand[mapRank2Id('hearts', i)])
      }
      break
    }
  }
  return rcards.sort((a, b) => parseInt(b) - parseInt(a))
}

function weights(suitCards){
  if(suitCards.length < 2){
    return [1, 0, 0]
  }
  else if(suitCards.length === 2){
    return [1, 0.6, 0]
  }
  else if(suitCards.length === 3){
    return [1, 0.6, 0.2]
  }
  else if(suitCards.length === 4){
    return [1, 0.5, 0]
  }
  else if(suitCards.length === 5){
    return [1, 0.4, 0]
  }
  else if(suitCards.length === 6){
    return [1, 0.3, 0]
  }
  else {
    return [1, 0, 0]
  }
}

export function scoreHand(hand, trump='spades') {
  let spades;
  let hearts;
  let clubs;
  let diamonds;
  if(trump === 'hearts') {
    spades = Object.keys(hand).filter(x => deck[x].suit === 'hearts')
    hearts = Object.keys(hand).filter(x => deck[x].suit === 'spades')
    clubs = Object.keys(hand).filter(x => deck[x].suit === 'clubs')
    diamonds = Object.keys(hand).filter(x => deck[x].suit === 'diamonds')
  }
  else if(trump === 'clubs'){
    spades = Object.keys(hand).filter(x => deck[x].suit === 'clubs')
    hearts = Object.keys(hand).filter(x => deck[x].suit === 'hearts')
    clubs = Object.keys(hand).filter(x => deck[x].suit === 'spades')
    diamonds = Object.keys(hand).filter(x => deck[x].suit === 'diamonds')
  }
  else if(trump === 'diamonds'){
    spades = Object.keys(hand).filter(x => deck[x].suit === 'diamonds')
    hearts = Object.keys(hand).filter(x => deck[x].suit === 'hearts')
    clubs = Object.keys(hand).filter(x => deck[x].suit === 'clubs')
    diamonds = Object.keys(hand).filter(x => deck[x].suit === 'spades')
  }
  else {
    spades = Object.keys(hand).filter(x => deck[x].suit === 'spades')
    hearts = Object.keys(hand).filter(x => deck[x].suit === 'hearts')
    clubs = Object.keys(hand).filter(x => deck[x].suit === 'clubs')
    diamonds = Object.keys(hand).filter(x => deck[x].suit === 'diamonds')
  }

  let num = 0;
  // Spades
  // Koz sayisi - 3
  let numKoz = spades.length

  if (numKoz > 3) {
    num += numKoz - 3.5
  }
  let w = weights(spades)
  if (spades.filter(x => deck[x].rank === 14).length) {
    num += 1
    w[0] = 0
    if (spades.filter(x => deck[x].rank === 13).length){
      num += 1
      w[1] = 0
      if (spades.filter(x => deck[x].rank === 12).length){
        num += 1
        w[2] = 0
      }
    }
  }
  numKoz -= num
  if(numKoz > 0 && Object.keys(hearts).length < 3) {
    num += Math.min((3 - Object.keys(hearts).length)*0.4, numKoz)
    numKoz -= Math.min((3 - Object.keys(hearts).length), numKoz)
  }
  if(numKoz > 0 && Object.keys(clubs).length < 3) {
    num += Math.min((3 - Object.keys(clubs).length)*0.4, numKoz)
    numKoz -= Math.min((3 - Object.keys(clubs).length), numKoz)
  }
  if(numKoz > 0 && Object.keys(diamonds).length < 3) {
    num += Math.min((3 - Object.keys(diamonds).length < 3)*0.4, numKoz)
  }


  if(spades) {
    num += w[0] * spades.filter(x => deck[x].rank === 14).length
    num += w[1] * spades.filter(x => deck[x].rank === 13).length
    num += w[2] * spades.filter(x => deck[x].rank === 12).length
  }
  //Hearts
  w = weights(hearts)
  if(hearts){
    num += w[0] * hearts.filter(x => deck[x].rank === 14).length
    num += w[1] * hearts.filter(x => deck[x].rank === 13).length
    num += w[2] * hearts.filter(x => deck[x].rank === 12).length
  }

  //Clubs
  w = weights(clubs)
  if(clubs){
    num += w[0] * clubs.filter(x => deck[x].rank === 14).length
    num += w[1] * clubs.filter(x => deck[x].rank === 13).length
    num += w[2] * clubs.filter(x => deck[x].rank === 12).length
  }

  //Diamonds
  w = weights(diamonds)
  if(diamonds){
    num += w[0] * diamonds.filter(x => deck[x].rank === 14).length
    num += w[1] * diamonds.filter(x => deck[x].rank === 13).length
    num += w[2] * diamonds.filter(x => deck[x].rank === 12).length
  }

  return Math.round(num)
}

export function botChooseNumber(game, hand) {
  const seat = game.table.whoseTurn;
  const newGame = JSON.parse(JSON.stringify(game))

  newGame.contracts[seat] = scoreHand(hand)
  newGame.table.whoseTurn = nextSeat(seat)

  // prettyPrint(hand, seat, game.round)
  return newGame
}

export function contractsNotMade(game) {
  const contracts = game.contracts
  if ((contracts.S != null) && (contracts.W != null) && (contracts.E != null) && (contracts.N != null)){
    return false
  } else {
    if ((contracts.S == null) && (contracts.W == null) && (contracts.E == null) && (contracts.N == null)) {
      return "Clean contracts"
    }
    else {
      let anchor
      for (const s of ['S', 'W', 'N', 'E']){
        if(contracts[s] != null){
          anchor = s
        }
      }
      for (let i=0;i<3; i++){
        if(contracts[nextSeat(anchor)] == null){
          return nextSeat(anchor)
        }
        else {
          anchor = nextSeat(anchor)
        }
      }
    }
  }
}

function isPlayerThrough(game, gameMeta, yanBatar, seat) {
  const contracts = game.contracts

  if(contracts[seat]) {
    if((contracts[seat] > gameMeta.scores[seat].round) || (gameMeta.scores[seat].round >= contracts[seat]+yanBatar ) ) {
      return false
    }
    return true
  }
  else {
    if(gameMeta.scores[seat].round > 0) {
      return false
    }
    return true
  }
}

export function updateYazBoz(game, gameMeta, yanBatar) {
  const newGameMeta = JSON.parse(JSON.stringify(gameMeta))
  let contracts = game.contracts

  let s, e, w, n;
  if(contracts.S) {
    if (isPlayerThrough(game, gameMeta, yanBatar, 'S')){
      s = contracts.S * 9 + gameMeta.scores.S.round;
      newGameMeta.scores.S.totalKoz += s ;
      newGameMeta.scores.S.total += s ;
    }
    else {
      s = -contracts.S * 10
      newGameMeta.scores.S.totalCeza -= s ;
      newGameMeta.scores.S.total += s ;
    }
  }
  else {
    if (isPlayerThrough(game, gameMeta, yanBatar, 'S')){
      s = 50;
      newGameMeta.scores.S.totalKoz += s ;
      newGameMeta.scores.S.total += s ;
    }
    else {
      s = -50
      newGameMeta.scores.S.totalCeza -= s ;
      newGameMeta.scores.S.total += s ;
    }
  }

  if(contracts.W) {
    if (isPlayerThrough(game, gameMeta, yanBatar, 'W')){
      w = contracts.W * 9 + gameMeta.scores.W.round;
      newGameMeta.scores.W.totalKoz += w ;
      newGameMeta.scores.W.total += w ;
    }
    else {
      w = -contracts.W * 10
      newGameMeta.scores.W.totalCeza -= w ;
      newGameMeta.scores.W.total += w ;
    }
  }
  else {
    if (isPlayerThrough(game, gameMeta, yanBatar, 'W')){
      w = 50;
      newGameMeta.scores.W.totalKoz += w ;
      newGameMeta.scores.W.total += w ;
    }
    else {
      w = -50
      newGameMeta.scores.W.totalCeza -= w ;
      newGameMeta.scores.W.total += w ;
    }
  }
  if(contracts.N) {
    if (isPlayerThrough(game, gameMeta, yanBatar, 'N')){
      n = contracts.N * 9 + gameMeta.scores.N.round;
      newGameMeta.scores.N.totalKoz += n ;
      newGameMeta.scores.N.total += n ;
    }
    else {
      n = -contracts.N * 10
      newGameMeta.scores.N.totalCeza -= n ;
      newGameMeta.scores.N.total += n ;
    }
  }
  else {
    if (isPlayerThrough(game, gameMeta, yanBatar, 'N')){
      n = 50;
      newGameMeta.scores.N.totalKoz += n ;
      newGameMeta.scores.N.total += n ;
    }
    else {
      n = -50
      newGameMeta.scores.N.totalCeza -= n ;
      newGameMeta.scores.N.total += n ;
    }
  }
  if(contracts.E) {
    if (isPlayerThrough(game, gameMeta, yanBatar, 'E')){
      e = contracts.E * 9 + gameMeta.scores.E.round;
      newGameMeta.scores.E.totalKoz += e ;
      newGameMeta.scores.E.total += e ;
    }
    else {
      e = -contracts.E * 10
      newGameMeta.scores.E.totalCeza -= e ;
      newGameMeta.scores.E.total += e ;
    }
  }
  else {
    if (isPlayerThrough(game, gameMeta, yanBatar, 'E')){
      e = 50;
      newGameMeta.scores.E.totalKoz += e ;
      newGameMeta.scores.E.total += e ;
    }
    else {
      e = -50
      newGameMeta.scores.E.totalCeza -= e ;
      newGameMeta.scores.E.total += e ;
    }
  }

  newGameMeta.yazboz[game.round] = {'S': s, 'W':w, 'E':e, 'N':n}

  newGameMeta.scores.E.round = 0;
  newGameMeta.scores.S.round = 0;
  newGameMeta.scores.W.round = 0;
  newGameMeta.scores.N.round = 0;

  return newGameMeta
}

export function updateStateRoundEnd(game) {
  const newGame = JSON.parse(JSON.stringify(game))
  const table = JSON.parse(JSON.stringify(game.table))
  // const newReporting = JSON.parse(JSON.stringify(reporting))

  newGame.dealer = nextSeat(game.dealer)
  newGame.round = game.round + 1
  table.whoseTurn = newGame.dealer
  table.koz = null
  table.roundEnd = false
  table.cards = {}
  table.kozRevealed = false
  table.tableRound = 1
  table.tableSuit = null
  newGame.table = table
  newGame.history = null

  newGame.contracts.E = null
  newGame.contracts.W = null
  newGame.contracts.N = null
  newGame.contracts.S = null

  const newHands = dealAllCardsToPlayersHigherThan10()

  return [newGame, newHands]
}

export function isRoundFinished (hands) {
  if(hands) {
    return Object.keys(hands.S).length === 0
  }
  else {
    return true
  }
}

export function buildHistory(game) {
  const history = game.history || {};
  const table = game.table;

  const lastTableCards = {
    'S': table.cards.S, 'W': table.cards.W, 'N': table.cards.N, 'E': table.cards.E
  }

  const playedCards = history.playedCards || {};

  for (const s in lastTableCards) {
    const cardId = lastTableCards[s]
    playedCards[cardId] = table.tableRound
  }

  game.history = {'lastCards': lastTableCards, 'playedCards': playedCards, 'lastStarter': nextSeat(game.table.whoseTurn)}
  return game.history
}

