<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click=close>
      <div id="menu" class="modal"
           role="dialog"
           aria-labelledby="modalTitle"
           aria-describedby="modalDescription"
           @click.stop>
        <div id="menu-header">
          <code v-if="this.user && this.user['gameId']"
                style="display:flex; justify-content: flex-start">{{ this.compCopy.gameCode }} {{
              this.user['gameId']
            }}</code>
          <code v-if="this.user && this.user['gameId'] && gameMeta && gameMeta.owner && gameMeta.owner['name']"
                style="display:flex; justify-content: flex-start">{{ this.compCopy.gameHost }} {{
              gameMeta.owner.name
            }}</code>
        </div>
        <div id="menu-table">
          <div v-if="this.user"
               v-show="this.user"
               @click=quitGame
               class="pointer menu-button"
               style="display: flex; flex-direction: row; align-items: center;"
          >
            <i class="fa-duotone fa-sign-out fa-2x" style="margin-right: 10px"/>
            <span style="margin: 5px;" class="pointer">{{ this.compCopy.quitGame }}</span>
          </div>
          <div v-if="this.user"
               v-show="this.user && this.round > 0"
               @click=restart
               class="pointer menu-button"
               style="display: flex; flex-direction: row; align-items: center;"
          >
            <i class="fa-duotone fa-fast-backward fa-2x" style="margin-right: 10px"/>
            <span style="margin: 5px;" class="pointer">{{ this.compCopy.restartGame }}</span>
          </div>
          <div v-if="this.user"
               v-show="this.user && this.round > 0"
               class="pointer menu-button"
               @click="modalUserOpen"
               style="display: flex; flex-direction: row; align-items: center;"
          >
            <i class="fa-duotone fa-signature fa-2x"/>
            <span style="margin: 5px;" class="pointer">{{ this.compCopy.renameUser }}</span>
          </div>
        </div>
        <div id="menu-system">
          
          <div class="menu-button">
            <a href="https://twitter.com/kagitoyna" target="_blank"
               style="text-decoration: none; display: flex; align-items: center">
              <i class="fa-duotone fa-comment-alt-smile fa-2x"
                 style="margin-right: 6px; margin-top:3px; margin-bottom:2px"/>
              <span style="margin: 10px;" class="pointer">{{ this.compCopy.contactUs }}</span>
            </a>
          </div>
        </div>
        <div id="menu-takip">
          <div style="display: flex; justify-content: flex-start; align-items: center; margin: 5px; padding-left: 5px">
            <a href="https://www.facebook.com/kagitoyna" target="_blank">
              <div>
                <i class="fa-brands fa-facebook-square facebook fa-3x icenter follow-button"
                   style="display: flex; flex: 1 1 auto; margin-right: 10px"
                ></i>
              </div>
            </a>
            <a href="https://twitter.com/kagitoyna" target="_blank">
              <div>
                <i class="fa-brands fa-twitter-square twitter fa-3x icenter follow-button"
                   style="display: flex; flex: 1 1 auto; margin-right: 10px"
                ></i>
              </div>
            </a>
            <a href="https://instagram.com/kagitoyna" target="_blank">
              <div>
                <i class="fa-brands fa-instagram-square instagram fa-3x icenter follow-button"
                   style="display: flex; flex: 1 1 auto; margin-right: 5px"
                />
              </div>
            </a>
            <span style="margin: 5px;">{{ this.compCopy.followUs }}</span>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import app from "@/firebase.setup";
import { ihaleCopy } from "@/js/ihale/ihaleCopy";

export default {
  name: 'ModalMenu',
  props: ["game", "gameMeta", "user"],
  data: () => ({
    renameMenuFinger: true,
  }),
  created() {
    this.appCopy = ihaleCopy.App;
    this.compCopy = ihaleCopy.ModalMenu;
  },
  computed: {
    round: function () {
      if (this.game && this.game.round) {
        return this.game.round
      } else {
        return null
      }
    },
    isNameSen: function () {
      return this.user.name === this.appCopy.defaultName;
    }
  },
  methods: {
    modalUserOpen() {
      this.renameMenuFinger = false;
      this.$emit('modalUserOpen')
    },
    close() {
      this.$emit('close');
    },
    quitGame() {
      this.$confirm(this.compCopy.quitAlert).then(() => {
        this.$emit('quitGame');
      }).catch(() => {
      });
    },
    restart() {
      const ownerSeat = this.gameMeta.owner.seat;
      if (this.user.seat === ownerSeat) {
        this.$confirm(this.compCopy.restart).then(() => {
          this.$emit('restart');
        }).catch(() => {
        });
      } else {
        this.$alert(this.compCopy.restartOnlyHost)
      }
    },
  },
};
</script>

<style scoped>
@import url('../../../css/font-awesome-color-brand.css');


#menu {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  align-items: stretch;
  
  font-size: 20px;
  color: var(--tertiaryColor);
  
  margin: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  
  width: min(80%, 500px);
  
  overflow-y: hidden;
  
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  
  outline: inherit;
  
}

#menu-header {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  
  /*border: 1px solid var(--tertiaryColor);*/
  
  font-size: 20px;
  color: var(--tertiaryColor);
  
  margin: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  padding: 5px;
  
  background-color: var(--mainColor);
  
  outline: inherit;
  
}

#menu-header > div {
  margin: 5px;
}

#menu-table {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  
  justify-content: center;
  align-items: stretch;
  
  font-size: 20px;
  color: var(--tertiaryColor);
  
  margin: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  
  outline: inherit;
}

#menu-table > div {
  margin: 5px;
}

#menu-system {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  
  justify-content: center;
  align-items: stretch;
  
  font-size: 20px;
  color: var(--tertiaryColor);
  
  margin: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  
  outline: inherit;
}

#menu-takip {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  
  justify-content: center;
  align-items: stretch;
  
  font-size: 20px;
  color: var(--tertiaryColor);
  
  margin: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  
  outline: inherit;
}

#menu-system > div {
  margin: 5px;
}

.menu-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
  padding-left: 5px
}

.menu-button:hover {
  background-color: var(--secondaryColor);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
}

/*.social-button:hover {*/
/*    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);*/
/*}*/


.modal-backdrop {
  position: fixed;
  z-index: 300;
  
  
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  
  outline: inherit;
  
}

.modal {
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;
  
  /*background: #FFFFFF;*/
  box-shadow: 2px 2px 20px 1px;
  /*overflow-x: auto;*/
  
  /*background-color: #24CD63;*/
  
  z-index: 1040;
  
  border-radius: 2px;
  outline: inherit;
}


</style>
