<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click=close>
      <div class="modal"
           role="dialog"
           aria-labelledby="modalTitle"
           aria-describedby="modalDescription"
      >
        <div v-if="this.winners && this.gameOver && this.screenNames">
          <h1 style="margin:15px">🏆 {{ winnersParsed }} {{ compCopy.xWon }} 🏆</h1>
        </div>
        <div id="yazboz-table" v-if="this.yazboz" class="grid">
          
          <span class="item-yazboz-header"><strong>{{ compCopy.yazBoz }}</strong></span>
          <span><div style="margin: 10px"><b>{{ this.name_abbr().S }}</b></div></span>
          <span><div style="margin: 10px"><b>{{ this.name_abbr().W }}</b></div></span>
          <span><div style="margin: 10px"><b>{{ this.name_abbr().N }}</b></div></span>
          <span><div style="margin: 10px"><b>{{ this.name_abbr().E }}</b></div></span>

          <span v-for="(value, key) in this.yazbozFlattened" :class="{'green': evalColor(value), 'red': !evalColor(value)}" :key="key">{{value}}</span>
          <span v-for="(value, index) in this.yazbozFill" :key="index*100+100">{{value}}</span>

<!--          <span class="positives">{{ compCopy.positives }}</span>-->
<!--          <span class="positives">{{ this.scores.S.totalKoz }}</span>-->
<!--          <span class="positives">{{ this.scores.W.totalKoz }}</span>-->
<!--          <span class="positives">{{ this.scores.N.totalKoz }}</span>-->
<!--          <span class="positives">{{ this.scores.E.totalKoz }}</span>-->

<!--          <span class="negatives">{{ compCopy.negatives }}</span>-->
<!--          <span class="negatives">{{ this.scores.S.totalCeza }}</span>-->
<!--          <span class="negatives">{{ this.scores.W.totalCeza }}</span>-->
<!--          <span class="negatives">{{ this.scores.N.totalCeza }}</span>-->
<!--          <span class="negatives">{{ this.scores.E.totalCeza }}</span>-->

          <span class="totals">{{ compCopy.result }}</span>
          <span class="totals">{{ this.scores.S.total }}</span>
          <span class="totals">{{ this.scores.W.total}}</span>
          <span class="totals">{{ this.scores.N.total}}</span>
          <span class="totals">{{ this.scores.E.total}}</span>
        </div>
        <div v-if="this.gameOver"
             style="display: flex;
                     flex-direction: row;
                     justify-content: center;
                     margin: 5px
                    ">
          <div
            class="btn-green"
            style="background: var(--sixthColor);"
            @click="quitGame">
            <b>{{ compCopy.mainPage }}</b>
          </div>
          <div
            class="btn-green"
            @click="restartGame"><b>{{ compCopy.newGame }}</b></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

import {ihaleCopy} from "@/js/ihale/ihaleCopy";

export default {
  name: 'IhaleModalYazboz',
  props: ["yazboz", "table", "scores", "winners", "screenNames", "gameOver", "numberOfRounds"],
  created() {
    this.compCopy = ihaleCopy.ModalYazBoz;
  },
  methods: {
    evalColor(value){
      return value > 0;
    },
    close() {
      this.$emit('close');
    },
    quitGame() {
      this.$emit('quitGame')
    },
    restartGame() {
      this.$emit('restartGame')
    },
    name_abbr() {
      let names = {};
      names['S'] = this.screenNames.S
      names['W'] = this.screenNames.W
      names['N'] = this.screenNames.N
      names['E'] = this.screenNames.E
      
      if (names['S'].substring(names['S'].length - 2, names['S'].length) === '🤖') {
        names['S'] = names['S'].substring(0, names['S'].length - 2)
      }
      if (names['W'].substring(names['W'].length - 2, names['W'].length) === '🤖') {
        names['W'] = names['W'].substring(0, names['W'].length - 2)
      }
      if (names['E'].substring(names['E'].length - 2, names['E'].length) === '🤖') {
        names['E'] = names['E'].substring(0, names['E'].length - 2)
      }
      if (names['N'].substring(names['N'].length - 2, names['N'].length) === '🤖') {
        names['N'] = names['N'].substring(0, names['N'].length - 2)
      }
      return names
    },
  },
  computed: {
    yazbozFlattened: function () {
      let flattened = []
      for (const key in this.yazboz){
        flattened.push(key)
        flattened.push(this.yazboz[key]['S'])
        flattened.push(this.yazboz[key]['W'])
        flattened.push(this.yazboz[key]['N'])
        flattened.push(this.yazboz[key]['E'])
      }
      return flattened
    },
    yazbozFill: function () {
      const filler = []
      for(let i = Object.keys(this.yazboz).length + 1; i <= this.numberOfRounds; i++){
        filler.push(i)
        filler.push('')
        filler.push('')
        filler.push('')
        filler.push('')
      }
      return filler
    },
    winnersParsed: function () {
      let resp = this.winners[0];
      for (let i = 0; i < this.winners.length - 1; i++) {
        resp += ' ve ' + this.winners[i + 1]
      }
      return resp
    }
  }
};
</script>

<style scoped>
#yazboz-table {
  display: grid;
  /*grid-template-rows: [row0] 50px repeat(11, [row] 30px ) [pos] 40px [negs] 40px [totals] 50px;*/
  grid-template-rows: [row0] 50px repeat(11, [row] 30px ) [totals] 50px;
  grid-template-columns: [round] 80px [s] 80px [w] 80px [n] 80px [e] 80px [final];
  border-top: 2px solid;
  border-right: 2px solid;
  
  /*background-color: var(--mainColor);*/
  color: var(--tertiaryColor);
  
  margin: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
}

@media (max-width:450px) {
  #yazboz-table {
    display: grid;
    /*grid-template-rows: [row0] 50px repeat(11, [row] 30px ) [pos] 40px [negs] 40px [totals] 50px;*/
    grid-template-rows: [row0] 50px repeat(11, [row] 30px ) [totals] 50px;
    grid-template-columns: [round] 20% [s] 20% [w] 20%  [n] 20% [e] 20% [final];
    border-top: 2px solid;
    border-right: 2px solid;

    /*background-color: var(--mainColor);*/
    color: var(--tertiaryColor);

    margin: 5px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  }
}


#yazboz-table > span {
  display: flex;
  padding: 2px 4px;
  border-left: 1px solid;
  border-bottom: 1px solid;
  
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  
  min-width: 0;

  text-shadow: 0px 0px 1px black;
  /*border: red solid 1px;*/
}

.negatives {
  grid-row-start: negs;
  background-color: rgba(255, 30, 30, 0.6);
}

.positives {
  grid-row-start: pos;
  background-color: #29ffff;
  border-top: 2px solid;
  /*border-bottom: 3px solid;*/
  border-color: crimson;
}

.totals {
  display: flex;
  justify-content: space-between;

  grid-row-start: totals;
  
  border-top: 5px solid;
  /*border-bottom: 3px solid;*/
  border-color: crimson;
  
  background-color: var(--mainColor);
  
  text-shadow: 0px 0px 1px black;
}

.item-yazboz-header {
  grid-area: row0 / round ;
  /*background-color: #0d0d0d;*/
}

.modal-backdrop {
  position: fixed;
  z-index: 350;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  
  border-radius: 2px;
}

.btn-green {
  color: black;
  cursor: pointer;
  background: var(--mainColor);
  border: 1px solid var(--tertiaryColor);
  border-radius: 2px;
  width: auto;
  align-self: center;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.6);
  margin: 5px;
  padding: 10px;
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease
}

.green{
  background-color: var(--mainColor);
}
.red{
  background-color: rgba(255, 30, 30, 0.6);
}


</style>
