export const batakXXXTable = {
  methods: {
    thumbsUpDown(seat){
      if(this.contracts){
        if(this.contracts[seat] != null)  {
          if(this.contracts[seat] > 0){
            return {
              'up': this.contracts[seat] <= this.scores[this.relDir[seat]]['round'],
              'down': this.contracts[seat] + this.yanBatar <= this.scores[this.relDir[seat]]['round']
            }
          }
          else {
            return {
              'up': this.scores[this.relDir[seat]]['round'] === 0,
              'down': this.scores[this.relDir[seat]]['round'] > 0
            }
          }
        }
        else {
          return {
            'up': false,
            'down': false
          }
        }
      }
    }
  }
}