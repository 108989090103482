<template>
  <div id="room">
    <modal-menu
      style="z-index: 1000"
      v-show="menuOpen"
      @close="closeMenuModal"
      @restart="restart"
      @quitGame="quitGame"
      @modalUserOpen="modalUserOpen=true"
      
      :game="game"
      :game-meta="gameMeta"
      :user="user"
    />
    <modal-user-name
      v-if="relDir"
      style="z-index: 1000"
      :seat="relDir.S"
      :button-text=this.appCopy.change
      :user="user"
      v-show="modalUserOpen"
      @renameUser="renameUser"
      @modalUserNameClose="modalUserOpen=false"
    />
    <modal-history
      style="z-index: 1000"
      v-if="game && game.history"
      v-show="isHistoryModalVisible"
      @close="closeHistoryModal"
      :table="table"
      :scores=scores
      :history=game.history
      :rel-dir="relDir"
    />
    <ModalYazboz
      style="z-index: 1050"
      v-if="round > 0"
      v-show="isYazbozModalVisible"
      @close="closeYazbozModal"
      @quitGame="quitGame"
      @restartGame="restart"
      :yazboz="yazboz"
      :table="table"
      :scores="scores"
      :winners="winners"
      :screenNames="screenNames"
      :gameOver="game.table.gameOver"
    />
    <ModalRoundEnd
      style="z-index: 1000"
      v-if="this.game && this.game.table && this.game.table.roundEnd && this.game.table.roundEnd[user.seat]"
      @close="closeRoundEndModal"
      :game="game"
      :game-meta="gameMeta"
      :relDir="relDir"
    />
    <invite-panel
      v-if="invitePanelFlag"
      id="invite-panel"
      class="player-area"
      :game-meta="gameMeta"
      :screen-names="screenNames"
      :usersInGame="usersInGame"
      :user="user"
      :relDir="relDir"

      @registerUser="registerUser"
      @closeMenuModal="closeMenuModal"
      @setIsLoading="setIsLoading"
      @add-bot="addBot"
    />
    <div v-else style="display: flex; flex-direction: column; flex: 1 0 auto; justify-content: stretch;
            height: calc(100% - 75px); position: relative;outline: inherit;">
      <div id="top-area" class="player-area" style="">
      <div style="display: flex;   width: min(100px,10vw);align-items: center; justify-content: flex-start;outline: inherit">
        <div class="btn-info points">{{round}}</div>
      </div>
        <div style="display: flex; height: 100%; width:calc(100% - min(200px,20vw)); outline: inherit">
          <div v-if="this.relDir && usersInGame && Object.keys(usersInGame).length === 4 && this.remains"
               style="display: flex; flex: 1 0; flex-direction: row; justify-content: center; align-items: center; outline: inherit">
            <div v-if="table && (table.ceza || table.koz)"
                 class="player-points" style="z-index:90"
            >
                            <span class="points" style="z-index:90">
                                {{ scores[this.relDir.N]['round'] }}
                            </span>
            </div>
            <div v-else class="trumps" style="flex-direction: column; margin-top:5px">
              <div class="triangles">
                <div v-if="remains && remains[relDir.N]['ceza'] === 3">
                  <i class="fa-light fa-circle"></i>
                  <i class="fa-light fa-circle"></i>
                  <i class="fa-light fa-circle"></i>
                </div>
                <div v-else-if="remains && remains[relDir.N]['ceza'] === 2">
                  <i class="fa-solid fa-circle"></i>
                  <i class="fa-light fa-circle"></i>
                  <i class="fa-light fa-circle"></i>
                </div>
                <div v-else-if="remains && remains[relDir.N]['ceza'] === 1">
                  <i class="fa-solid fa-circle"></i>
                  <i class="fa-solid fa-circle"></i>
                  <i class="fa-light fa-circle"></i>
                </div>
                <div v-else>
                  <i class="fa-solid fa-circle"></i>
                  <i class="fa-solid fa-circle"></i>
                  <i class="fa-solid fa-circle"></i>
                </div>
              </div>
              <div class="circles ">
                <div v-if="remains && remains[relDir.N]['koz'] === 2">
                  <i class="fa-light fa-triangle"></i>
                  <i class="fa-light fa-triangle"></i>
                </div>
                <div v-else-if="remains && remains[relDir.N]['koz'] === 1">
                  <i class="fa-solid fa-triangle"></i>
                  <i class="fa-light fa-triangle"></i>
                </div>
                <div v-else>
                  <i class="fa-solid fa-triangle"></i>
                  <i class="fa-solid fa-triangle"></i>
                </div>
              </div>
            </div>
            <b v-if="table.whoseTurn !== this.relDir.N" class="playername text" style="z-index:90">
              {{ screenNames[this.relDir.N] }}</b>
            <b v-else class="playername text nameTurn" style="z-index:90">
              {{ screenNames[this.relDir.N] }}</b>
            <div v-if="table && (table.ceza || table.koz)"
                 class="player-points" style="z-index:90; flex: 0 1; visibility: hidden"
            >
                            <span class="points" style="z-index:90; flex: 0 1;">
                                {{ scores[this.relDir.N]['round'] }}
                            </span>
            </div>
            <div v-else class="trumps"
                 style="flex-direction: column; flex: 0 1; margin-top:5px; visibility: hidden">
              <div class="triangles">
                <div v-if="remains && remains[relDir.N]['ceza'] === 3">
                  <i class="fa-light fa-circle"></i>
                  <i class="fa-light fa-circle"></i>
                  <i class="fa-light fa-circle"></i>
                </div>
                <div v-else-if="remains && remains[relDir.N]['ceza'] === 2">
                  <i class="fa-solid fa-circle"></i>
                  <i class="fa-light fa-circle"></i>
                  <i class="fa-light fa-circle"></i>
                </div>
                <div v-else-if="remains && remains[relDir.N]['ceza'] === 1">
                  <i class="fa-solid fa-circle"></i>
                  <i class="fa-solid fa-circle"></i>
                  <i class="fa-light fa-circle"></i>
                </div>
                <div v-else>
                  <i class="fa-solid fa-circle"></i>
                  <i class="fa-solid fa-circle"></i>
                  <i class="fa-solid fa-circle"></i>
                </div>
              </div>
              <div class="circles">
                <div v-if="remains && remains[relDir.N]['koz'] === 2">
                  <i class="fa-light fa-triangle"></i>
                  <i class="fa-light fa-triangle"></i>
                </div>
                <div v-else-if="remains && remains[relDir.N]['koz'] === 1">
                  <i class="fa-solid fa-triangle"></i>
                  <i class="fa-light fa-triangle"></i>
                </div>
                <div v-else>
                  <i class="fa-solid fa-triangle"></i>
                  <i class="fa-solid fa-triangle"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style=" width: min(100px,10vw);display: flex; align-items: center; justify-content: flex-end; outline: inherit; ">
          <div v-if="trump === 'spades'" class="spades_button btn-info"></div>
          <div v-else-if="trump === 'hearts'" class="hearts_button btn-info"></div>
          <div v-else-if="trump === 'clubs'" class="clubs_button btn-info"></div>
          <div v-else-if="trump === 'diamonds'" class="diamonds_button btn-info"></div>
          <div v-if="trump === 'rifki'" class="rifki btn-info"></div>
          <div v-else-if="trump === 'el'" class="elalmaz btn-info"></div>
          <div v-else-if="trump === 'erkek'" class="erkekalmaz btn-info"></div>
          <div v-else-if="trump === 'kiz'" class="kizalmaz btn-info"></div>
          <div v-else-if="trump === 'kupa'" class="kupaalmaz2 btn-info"></div>
          <div v-else-if="trump === 'soniki'" class="soniki btn-info"></div>
        </div>
      </div>
      <div id="middle-area">
        <koz-ceza-panel
          style="z-index: 150"
          v-if="!invitePanelFlag && chooseTrump && table && table.whoseTurn && usersInGame"
          id="koz-ceza-panel"
          class="player-area"
          @registerKozIfAvailable="registerKozIfAvailable"
          @registerCezaIfAvailable="registerCezaIfAvailable"
          :game-meta="gameMeta"
          :round="round"
          :usersInGame="usersInGame"
          :hand="hand"
          :screen-names="screenNames"
          :table="table"
          :yazboz="yazboz"
          :user="user"
          :rel-dir="relDir"
        ></koz-ceza-panel>
        <tablePanel
          v-else-if="!invitePanelFlag && user && table"
          id="table-panel"
          :showChosenTrump="showChosenTrump"
          :screen-names="screenNames"
          :table="table"
          :usersInGame="usersInGame"
          :scores="scores"
          :user="user"
          :cardWidth="cardWidthTable"
          :relDir="relDir"
        ></tablePanel>
      </div>
      <div id="bottom-name-area" class="player-area">
        
        <div v-if="usersInGame && Object.keys(usersInGame).length === 4"
             style="display: flex; flex: 1 1; flex-direction: row; align-content:center; justify-content: center; outline: inherit">
          <div style="visibility:hidden; display: flex; margin-left: auto; width:min(100px,25vw); justify-content: flex-end; align-items: center; outline: inherit; "></div>
          <div v-if="table && (table.ceza || table.koz)"
               class="player-points" style="z-index:90"
          >
                        <span class="points" style="z-index:90">
                            {{ scores[this.relDir.S]['round'] }}
                        </span>
          </div>
          <div v-else class="trumps" style="flex-direction: column; margin-top:5px; justify-content: center">
            <div class="triangles">
              <div v-if="remains && remains[relDir.S]['ceza'] === 3">
                <i class="fa-light fa-circle"></i>
                <i class="fa-light fa-circle"></i>
                <i class="fa-light fa-circle"></i>
              </div>
              <div v-else-if="remains && remains[relDir.S]['ceza'] === 2">
                <i class="fa-solid fa-circle"></i>
                <i class="fa-light fa-circle"></i>
                <i class="fa-light fa-circle"></i>
              </div>
              <div v-else-if="remains && remains[relDir.S]['ceza'] === 1">
                <i class="fa-solid fa-circle"></i>
                <i class="fa-solid fa-circle"></i>
                <i class="fa-light fa-circle"></i>
              </div>
              <div v-else>
                <i class="fa-solid fa-circle"></i>
                <i class="fa-solid fa-circle"></i>
                <i class="fa-solid fa-circle"></i>
              </div>
            </div>
            <div class="circles">
              <div v-if="remains && remains[relDir.S]['koz'] === 2">
                <i class="fa-light fa-triangle"></i>
                <i class="fa-light fa-triangle"></i>
              </div>
              <div v-else-if="remains && remains[relDir.S]['koz'] === 1">
                <i class="fa-solid fa-triangle"></i>
                <i class="fa-light fa-triangle"></i>
              </div>
              <div v-else>
                <i class="fa-solid fa-triangle"></i>
                <i class="fa-solid fa-triangle"></i>
              </div>
            </div>
          </div>
          <b v-if="table.whoseTurn !== this.relDir.S" class="playername text" style="z-index:90">
            {{ screenNames[this.relDir.S] }}</b>
          <b v-else class="playername text nameTurn" style="z-index:90">
            {{ screenNames[this.relDir.S] }}</b>
          <div class="trumps" style="flex-direction: column; margin-top:5px; visibility: hidden">
            <div class="triangles">
              <div v-if="remains && remains[relDir.S]['ceza'] === 3">
                <i class="fa-light fa-circle"></i>
                <i class="fa-light fa-circle"></i>
                <i class="fa-light fa-circle"></i>
              </div>
              <div v-else-if="remains && remains[relDir.S]['ceza'] === 2">
                <i class="fa-solid fa-circle"></i>
                <i class="fa-light fa-circle"></i>
                <i class="fa-light fa-circle"></i>
              </div>
              <div v-else-if="remains && remains[relDir.S]['ceza'] === 1">
                <i class="fa-solid fa-circle"></i>
                <i class="fa-solid fa-circle"></i>
                <i class="fa-light fa-circle"></i>
              </div>
              <div v-else>
                <i class="fa-solid fa-circle"></i>
                <i class="fa-solid fa-circle"></i>
                <i class="fa-solid fa-circle"></i>
              </div>
            </div>
            <div class="circles">
              <div v-if="remains && remains[relDir.S]['koz'] === 2">
                <i class="fa-light fa-triangle"></i>
                <i class="fa-light fa-triangle"></i>
              </div>
              <div v-else-if="remains && remains[relDir.S]['koz'] === 1">
                <i class="fa-solid fa-triangle"></i>
                <i class="fa-light fa-triangle"></i>
              </div>
              <div v-else>
                <i class="fa-solid fa-triangle"></i>
                <i class="fa-solid fa-triangle"></i>
              </div>
            </div>
          </div>
          <div style="display: flex; margin-left: auto; width:min(100px,25vw); justify-content: flex-end; align-items: center; outline: inherit; ">
            <div
              v-if="game && game.history"
              type="button"
              class="btn-yazboz"
              style="margin-right:5px;"
              @click="showHistoryModal"
            >
              <i class="fa-duotone fa-history fa-2x" style="display:flex; margin-left:1px; align-self: center"></i>
            </div>
            <div
              v-if="round > 0"
              class="btn-yazboz"
              style="display:flex; align-content: center;"
              @click="showYazbozModal"
            >
              <i class="fa-light fa-clipboard-list fa-2x" style="display:flex;" />
            </div>
          </div>
        </div>
      </div>
      <div id="bottom-area">
        <transition-group
          v-if="usersInGame && Object.keys(usersInGame).length === 4"
          name="card-appear"
          mode="out-in"
          class="player-area-cards"
          style="z-index:120; align-items: flex-start; justify-content: space-evenly"
        >
          <vue-playing-card
            v-for="cardId of heartsSpadesCards"
            id="heartsSpadesCards"
            :width="100"
            :signature=deck[cardId].name
            :key="deck[cardId].id"
            @click.native="!chooseTrump && table && table.whoseTurn === user.seat ? { click:  throwCard(cardId) } : {}"
            :class="[(table.whoseTurn === relDir.S && !touchDevice ) ? 'hover playing-card' : 'playing-card']"
          >
          </vue-playing-card>
          <vue-playing-card
            v-for="cardId of clubsDiamondsCards"
            id="clubsDiamondsCards"
            :width="100"
            :signature=deck[cardId].name
            :key="deck[cardId].id"
            @click.native="!chooseTrump && table && table.whoseTurn === user.seat ? { click:  throwCard(cardId) } : {}"
            :class="[(table.whoseTurn === relDir.S && !touchDevice ) ? 'hover playing-card' : 'playing-card']"
          >
          </vue-playing-card>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import app from '@/firebase.setup'

import InvitePanel from "./KingInvitePanel";
import KozCezaPanel from "./KingKozCezaPanel";
import TablePanel from "./KingTablePanel";
import ModalHistory from "./KingModalHistory";
import ModalYazboz from './KingModalYazboz';
import ModalRoundEnd from "./KingModalRoundEnd";
import ModalUserName from "@/components/games/king/user/ModalUserName";
import ModalMenu from './KingModalMenu.vue';

// import BaseTimer from "./BaseTimer";
// import CryptoJS from 'crypto-js';

// import deckMixin from '@/mixins/deckMixin'
import {isInvalid} from "@/js/king/validations";
import { nextSeat } from "@/js/games";

import { deck_template } from "@/js/deck";
import { namePool } from "@/js/namePool";
import {kingCopy} from '@/js/king/kingCopy'

// import config from '@/config.json'

// const TIME_LIMIT = config.TIME_LIMIT;
// const WARNING_THRESHOLD = config.WARNING_THRESHOLD;
// const ALERT_THRESHOLD = config.ALERT_THRESHOLD;

export default {
  name: 'KingFriendly',
  props: ["user", "menuOpen", "relDir", "touchDevice", "renameFinger"],
  components: {
    InvitePanel,
    KozCezaPanel,
    TablePanel,
    ModalHistory,
    ModalYazboz,
    ModalRoundEnd,
    ModalMenu,
    ModalUserName,
  },
  data: () => ({
    game: null,
    gameMeta: null,
    hand: null,
    
    modalUserOpen: false,
    cardThrowLock: false,
    namePool: namePool,
    windowWidth: window.innerWidth,
    environment: process.env.VUE_APP_ENV,
    isHistoryModalVisible: false,
    isYazbozModalVisible: false,
    // timeLimit: TIME_LIMIT,
    // warning_threshold: WARNING_THRESHOLD,
    // alert_threshold: ALERT_THRESHOLD,
    timePassedValueEncrypted: sessionStorage.getItem('tp'),
    refreshed: false,
    showChosenTrump: false,
    deck: null
  }),
  async created() {
    this.$emit('setIsLoading', true);
    this.contractsCopy = kingCopy.contracts;
    this.appCopy = kingCopy.App;
    this.deck = { ...deck_template }
    
    await this.setListeners(this.user);
    if (this.hand) {
      const l = Math.max(this.heartsSpadesCards.length, this.clubsDiamondsCards.length, 7)
      document.documentElement.style.setProperty("--colNumber", l);
    }
    this.$emit('setIsLoading', false);
  },
  methods: {
    async addBot(seat, game = {'owner': this.gameMeta.owner, 'id': this.user.gameId}, userSeat = this.user.seat) {
      const ownerSeat = game.owner.seat;
      if (userSeat === ownerSeat) {
        if (!Date.now) {
          Date.now = function () {
            return new Date().getTime();
          }
        }
        const index = Math.floor(Math.random() * this.namePool.length);
        const botName = '_bot' + JSON.stringify(Date.now()).substring(8) + this.namePool[index];
        if (index > -1) {
          this.namePool.splice(index, 1);
        }
        
        const botObj = {'name': botName, 'id': botName}
        await app.database().ref(`/games/${this.gameMeta.gameType}/${game.id}/usersInGame/${seat}`).set(botObj)
        await app.database().ref(`/users/${botName}`).set({
          'id': botName,
          'gameId': game.id,
          'gameType': this.gameMeta.gameType,
          'gameCategory': 'king',
          'name': botName,
          'seat': seat
        });
      } else {
        await this.$alert('Sadece masa sahibi bot ekleyebilir.', '', 'error')
      }
      return 'ok'
    },
    registerUser(user) {
      this.$emit('registerUser', user);
    },
    renameUser: async function (data) {
      await app.database().ref(`/users/${this.user.id}/name`).set(data.name);
      await app.database().ref(`/games/${this.user.gameType}/${this.user.gameId}/usersInGame/${data.seat}/name`).set(data.name);
      if (data.seat === 'S') {
        await app.database().ref(`/gamesMeta/${this.user.gameId}/creator`).set(data.name);
      }
      if (this.user.id === this.gameMeta.owner.id) {
        await app.database().ref(`/gamesMeta/${this.user.gameId}/owner/name`).set(data.name);
      }
      
      this.modalUserOpen = false;
    },
    async restart() {
      this.$emit('setIsLoading', true);
      this.closeMenuModal();
      
      if (this.gameOver) {
        app.analytics().logEvent('kingFriendly-restart-gameOver');
      } else {
        app.analytics().logEvent('kingFriendly-restart-premature');
      }
      
      let restartGame = app.functions().httpsCallable('kingFriendlyRestartGame');
      await restartGame({
        'gameId': this.user.gameId, 'user': this.user, 'usersInGame': this.usersInGame,
        'restartCounter': this.gameMeta.restartCounter
      })
      
      this.$emit('setIsLoading', false);
      // window.location.reload();
      return 'all good'
    },
    async quitGame() {
      this.$emit('setIsLoading', true);
      this.closeMenuModal();

      if (this.gameOver) {
        app.analytics().logEvent('kingFriendly-quitGame-gameOver');
      } else {
        app.analytics().logEvent('kingFriendly-quitGame-premature');
      }
      
      // switch game owner to left if game owner is quitting
      try{
        if (this.user.seat === this.gameMeta.owner.seat) {
          let newOwnerSeat = nextSeat(this.user.seat);
          for (let i = 0; i < 3; i++) {
            if (!this.usersInGame[newOwnerSeat] || this.isBot(newOwnerSeat)) {
              newOwnerSeat = nextSeat(newOwnerSeat);
            } else {
              const newOwner = {
                'id': this.usersInGame[newOwnerSeat].id,
                'name': this.usersInGame[newOwnerSeat].name,
                'seat': newOwnerSeat
              }
              await app.database().ref(`/gamesMeta/${this.user.gameId}/owner`).set(newOwner)
              break;
            }
            if (i === 2) {
              await app.database().ref(`/gamesMeta/${this.user.gameId}/owner`).remove()
              break;
            }
          }
        }
      }
      catch (e) {
        console.log(e)
      }

      await app.database().ref(`/games/kingFriendly/${this.user.gameId}/usersInGame/${this.user.seat}`).remove()
      
      await this.unsetListeners();
      this.game = null;
      this.gameMeta = null;
      this.hands = null;
      this.$emit('setIsLoading', false);
      await app.database().ref(`/users/${this.user.id}`).set({'id': this.user.id, 'name': this.user.name})
      return 'all good'
    },
    closeMenuModal() {
      this.$emit('closeMenu')
    },
    setIsLoading(val) {
      this.$emit('setIsLoading', val);
    },
    async setListeners(user) { // establishes .on() reactivity on game, gameMeta and hands
      const gameType = 'kingFriendly';
      const gameId = user.gameId;
      await this.listenGame(gameId, gameType);
      
      await app.database()
        .ref(`hands/${gameId}/${user.seat}`)
        .on("value", snapshot => {
          this.hand = snapshot.val()
        });
    },
    async listenGame(gameId, gameType) {
      await app.database()
        .ref(`gamesMeta/${gameId}`)
        .on("value", snapshot => {
          this.gameMeta = snapshot.val()
        });
      await app.database()
        .ref(`games/${gameType}/${gameId}`)
        .on("value", snapshot => {
          this.game = snapshot.val()
        });
      const gameSnap = await app.database().ref(`games/${gameType}/${gameId}`).once('value');
      this.game = gameSnap.val()
      const gameMetaSnap = await app.database().ref(`gamesMeta/${gameId}`).once('value');
      this.gameMeta = gameMetaSnap.val()
    },
    async unsetListeners() {
      // Unsets game, gameMeta and hands listeners
      const refHandFriendly = await app.database().ref(`hands/${this.user.gameId}/${this.user.seat}`)
      await refHandFriendly.off()
      
      const refGameMeta = app.database().ref(`gamesMeta/${this.user.gameId}`)
      await refGameMeta.off()
      
      const refGames = app.database().ref(`games/kingFriendly/${this.user.gameId}`)
      await refGames.off()
      
      return 'all good'
    },
    numberOfBots: function () {
      if (this.usersInGame) {
        return Object.keys(this.usersInGame).map(seat => this.isBot(seat)).reduce((a, b) => a + b, 0)
      } else {
        return 0
      }
    },
    isBot: function (seat) {
      const userName = this.usersInGame[seat].name;
      if (userName.substring(0, 4) === '_bot') {
        return 1
      } else {
        return 0
      }
    },
    async closeRoundEndModal() {
      await app.database().ref(`/games/${this.user.gameType}/${this.user.gameId}/table/roundEnd/${this.user.seat}`)
        .set(false)
      return 'all good'
    },
    throwCard: async function (cardId) {
      if (!this.cardThrowLock && !this.showChosenTrump) {
        this.cardThrowLock = true
        if (!isInvalid(cardId, this.table, this.hand)) {
          let tableCards = {...this.table.cards};
          tableCards[this.user.seat] = cardId
          await app.database().ref(`/hands/${this.user.gameId}/${this.user.seat}/${cardId}`).remove()
          await app.database().ref(`/games/${this.gameMeta.gameType}/${this.user.gameId}/table/cards/${this.user.seat}`).set(cardId)
        } else {
          await this.$alert(`${isInvalid(cardId, this.table, this.hand)}`)
          this.cardThrowLock = false
        }
      }
      return 'all good'
    },
    registerKozIfAvailable: function (kozName, user) {
      if (this.yazboz.remains[user.seat]['koz'] > 0) {
        app.database()
          .ref(`/games/${this.user.gameType}/${user.gameId}/table/koz`)
          .set(kozName)
        app.database()
          .ref(`/gamesMeta/${this.user.gameId}/yazboz/remains/${user.seat}/koz`)
          .set(this.yazboz.remains[this.user.seat]['koz'] - 1)
      } else {
        this.alertNoMorePlayerKoz();
      }
    },
    registerCezaIfAvailable: function (cezaName, user) {
      const remains = {
        "remainPlayer": this.yazboz.remains[user.seat]['ceza'],
        "remainCommon": this.yazboz.remains.cezalar[cezaName]
      }
      if (remains.remainPlayer < 1) {
        this.alertNoMorePlayerCeza()
      } else if (remains.remainCommon === 0) {
        this.alertNoMoreCommonCeza(this.contractsCopy[cezaName])
      } else {
        app.database()
          .ref(`/games/${user.gameType}/${user.gameId}/table/ceza`)
          .set(cezaName)
          .then(() => {
            app.database()
              .ref(`/gamesMeta/${user.gameId}/yazboz/remains/cezalar/${cezaName}`)
              .set(remains.remainCommon - 1)
              .then(() => {
                app.database()
                  .ref(`/gamesMeta/${user.gameId}/yazboz/remains/${user.seat}/ceza`)
                  .set(remains.remainPlayer - 1)
              })
          })
      }
    },
    showHistoryModal() {
      this.isHistoryModalVisible = true;
    },
    closeHistoryModal() {
      this.isHistoryModalVisible = false;
    },
    showYazbozModal() {
      this.isYazbozModalVisible = true;
    },
    closeYazbozModal() {
      this.isYazbozModalVisible = false;
    },
    increaseTimer() {
      this.refreshed = false;
      this.timePassed += 1;
    },
    alertNoMoreCommonCeza: function (ceza) {
      this.$alert(ceza + this.appCopy.noMoreCommonPenaltyAlert);
    },
    alertNoMorePlayerCeza: function () {
      this.$alert(this.appCopy.noMorePlayerPenaltyAlert);
    },
    alertNoMorePlayerKoz: function () {
      this.$alert(this.appCopy.noMorePlayerTrumpAlert);
    },
  },
  watch: {
    numberOfUsers: {
      immediate: true,
      handler(val, oldVal) {
        if (val && oldVal) {
          if ((val === 4) && (oldVal === 3)) {
            setTimeout(() => {
              if (!this.usersInGameIds.includes(this.user.id)) {
                this.$alert(`${this.game.id} numaralı masa doldu.`)
                this.unsetListeners();
                app.database().ref(`/users/${this.user.id}`).set({
                  'id': this.user.id,
                  'name': this.user.name,
                  'gameCategory': 'king'
                });
                this.hand = null;
                this.game = null;
                this.gameMeta = null;
              } else {
                app.database()
                  .ref(`hands/${this.user.gameId}/${this.user.seat}`)
                  .on("value", snapshot => {
                    this.hand = snapshot.val()
                  });
              }
            }, 500)
          }
        }
      }
    },
    owner(val, oldVal) {
      if (val && oldVal && val.name !== oldVal.name) {
        this.$alert(`Yeni masa sahibi: ${val.name}`)
      }
    },
    lock: {
      immediate: false,
      handler(val) {
        if (val === 1) {
          this.$emit('setIsLoading', true)
        } else if (val === 0) {
          this.$emit('setIsLoading', false)
        }
      }
    },
    chooseTrump(val) {
      if (val) {
        this.showChosenTrump = true;
        setTimeout(() => {
          this.showChosenTrump = false;
        }, 1500);
      }
    },
    gameOver() {
      if (this.gameOver === true) {
        this.isYazbozModalVisible = true;
        app.analytics().logEvent('kingFriendly-gameOver');
        if (this.numberOfBots() === 3) {
          app.analytics().logEvent('kingFriendly-gameOver-1');
        } else if (this.numberOfBots() === 2) {
          app.analytics().logEvent('kingFriendly-gameOver-2');
        } else if (this.numberOfBots() === 1) {
          app.analytics().logEvent('kingFriendly-gameOver-3');
        } else if (this.numberOfBots() === 0) {
          app.analytics().logEvent('kingFriendly-gameOver-4');
        } else {
          app.analytics().logEvent('kingFriendly-gameOver-0');
        }
      }
    },
    hand() {
      if (this.hand && Object.keys(this.hand).length === 13) {
        const l = Math.max(this.heartsSpadesCards.length, this.clubsDiamondsCards.length)
        document.documentElement.style.setProperty("--colNumber", l);
      }
    },
    // newTimer() {
    //     if (!this.refreshed) {
    //         this.timePassed = 0
    //     }
    // },
    whoseTurn() {
      this.cardThrowLock = false
    },
    tableCards() {
      if (!this.tableCards) {
        this.cardThrowLock = false
      }
    },
    round: {
      immediate: false,
      handler(newVal) {
        if (newVal === 2) {
          this.$emit('setFinger');
          app.analytics().logEvent('kingFriendly-R1');
        } else if (newVal === 3) {
          app.analytics().logEvent('kingFriendly-R2');
        } else if (newVal === 6) {
          app.analytics().logEvent('kingFriendly-R5');
        } else if (newVal === 11) {
          app.analytics().logEvent('kingFriendly-R10');
        } else if (newVal === 16) {
          app.analytics().logEvent('kingFriendly-R15');
        }
        
        if (this.table && this.gameOver) {
          const nextPlayer = this.screenNames[this.table.whoseTurn]
          this.$alert(newVal + '. Oyun: \r\n' + `${JSON.stringify(nextPlayer.name).replace(/['"]+/g, '')} koz ya da cezayı belirliyor.`)
        }
      }
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    });
    
  },
  computed: {
    trump() {
      if(this.game && this.game.table ) {
        if (!this.game.table.ceza && !this.game.table.koz) {
          return false
        } else if (this.game.table.ceza) {
          return this.game.table.ceza
        } else {
          return this.game.table.koz
        }
      }
      return true
    },
    owner: function () {
      if (this.gameMeta && this.gameMeta.owner) {
        return this.gameMeta.owner;
      } else {
        return null;
      }
    },
    lock: function () {
      if (this.game) {
        return this.game.lock;
      } else {
        return 0;
      }
    },
    invitePanelFlag: function () {
      if (this.user && this.game && this.gameMeta && this.game.usersInGame && Object.keys(this.game.usersInGame).length < 4) {
        return true
      } else {
        return false
      }
    },
    screenNames: function () {
      const sN = {}
      
      if (this.usersInGame && this.usersInGame.N && this.usersInGame.N['name'] && this.usersInGame.N['name'].substring(0, 4) === '_bot') {
        sN['N'] = this.usersInGame.N['name'].substring(9)
      } else {
        if (!this.usersInGame || !this.usersInGame.N) {
          sN['N'] = ''
        } else {
          sN['N'] = this.usersInGame.N['name']
        }
      }
      
      if (this.usersInGame && this.usersInGame.W && this.usersInGame.W['name'] && this.usersInGame.W['name'].substring(0, 4) === '_bot') {
        sN['W'] = this.usersInGame.W['name'].substring(9)
      } else {
        if (!this.usersInGame || !this.usersInGame.W) {
          sN['W'] = ''
        } else {
          sN['W'] = this.usersInGame.W['name']
        }
      }
      
      if (this.usersInGame && this.usersInGame.E && this.usersInGame.E['name'] && this.usersInGame.E['name'].substring(0, 4) === '_bot') {
        sN['E'] = this.usersInGame.E['name'].substring(9)
      } else {
        if (!this.usersInGame || !this.usersInGame.E) {
          sN['E'] = ''
        } else {
          sN['E'] = this.usersInGame.E['name']
        }
      }
      
      if (this.usersInGame && this.usersInGame.S && this.usersInGame.S['name'] && this.usersInGame.S['name'].substring(0, 4) === '_bot') {
        sN['S'] = this.usersInGame.S['name'].substring(9)
      } else {
        if (!this.usersInGame || !this.usersInGame.S) {
          sN['S'] = ''
        } else {
          sN['S'] = this.usersInGame.S['name']
        }
      }
      
      return sN
    },
    gameOver: function () {
      if (!this.game || (this.game && !this.game.table)) {
        return false
      } else {
        this.$emit('setGameOver');
        return this.game.table.gameOver
      }
    },
    round() {
      if (this.game && this.game.round) {
        return this.game.round
      } else {
        return null
      }
    },
    yazboz() {
      if (this.gameMeta && this.gameMeta.yazboz) {
        return this.gameMeta.yazboz
      } else {
        return null
      }
    },
    scores() {
      if (this.gameMeta && this.gameMeta.scores) {
        return this.gameMeta.scores
      } else {
        return null
      }
    },
    table() {
      if (this.game && this.game.table) {
        return this.game.table
      } else {
        return null
      }
    },
    usersInGame() {
      if (this.game && this.game.usersInGame) {
        return this.game.usersInGame
      } else {
        return null
      }
    },
    usersInGameIds() {
      if (this.game && this.game.usersInGame) {
        return Object.keys(this.game.usersInGame).map(x => this.game.usersInGame[x].id);
      } else {
        return []
      }
    },
    numberOfUsers() {
      if (this.usersInGame) {
        return Object.keys(this.usersInGame).length;
      } else {
        return 0
      }
    },
    isNameSen: function () {
      return this.user.name === this.appCopy.defaultName;
    },
    // timePassed: {
    //     get: function() {
    //         if(!this.timePassedValueEncrypted ) {
    //             return 0
    //         } else {
    //             const encryptionToken = this.user.id.substring(0, 6) ;
    //             const store = this.timePassedValueEncrypted
    //             const bytes = CryptoJS.AES.decrypt(store, encryptionToken);
    //
    //             return parseInt(bytes.toString(CryptoJS.enc.Utf8))
    //         }
    //     },
    //     set: function(timePassed) {
    //         const encryptionToken = this.user.id.substring(0, 6);
    //         const store = CryptoJS.AES.encrypt(timePassed.toString(), encryptionToken).toString();
    //         this.timePassedValueEncrypted = store;
    //         sessionStorage.setItem('tp', store)
    //     }
    // },
    // newTimer: function() {
    //     if (this.game) {
    //         return this.game.table.tableRound + this.game.table.whoseTurn + this.game.table.ceza + this.game.table.koz
    //     } else {
    //         return null
    //     }
    // },
    remains: function () {
      if (!this.yazboz) {
        return null
      } else {
        return this.yazboz.remains
      }
    },
    cardWidthTable: function () {
      return this.windowWidth / 3.7
    },
    
    cardWidthHand: function () {
      if (this.hand) {
        return Math.min(this.windowWidth / (Object.keys(this.hand).length) * 1.6, this.windowWidth / 5)
      } else {
        return 7
      }
    },
    
    whoseTurn: function () {
      if (this.table && this.table.whoseTurn) {
        return this.table.whoseTurn
      } else {
        return null
      }
    },
    tableCards: function () {
      if (this.table && this.table.cards) {
        return this.table.cards
      } else {
        return null
      }
    },
    chooseTrump: function () {
      return !(this.table && (this.table.koz || this.table.ceza));
    },
    orderedHand: function () {
      if (this.hand) {
        const hand = Object.keys(this.hand)
        
        hand.sort((a, b) => {
          return this.deck[a].rank - this.deck[b].rank;
        })
        return hand
      } else {
        return null
      }
    },
    
    strongestSuit: function () {
      // const deck = deckMixin.data().deck;
      if (this.hand) {
        const hearts = [];
        const spades = [];
        const diamonds = [];
        const clubs = [];
        
        for (const key of this.hand) {
          if (this.deck[key].suit === 'hearts') {
            hearts.push(key)
          } else if (this.deck[key].suit === 'spades') {
            spades.push(key)
          } else if (this.deck[key].suit === 'diamonds') {
            diamonds.push(key)
          } else if (this.deck[key].suit === 'clubs') {
            clubs.push(key)
          }
        }
        
        const suit_lengths = [{'name': 'hearts', 'len': hearts.length},
          {'name': 'spades', 'len': spades.length},
          {'name': 'diamonds', 'len': diamonds.length},
          {'name': 'clubs', 'len': clubs.length}]
        
        return suit_lengths.reduce((a, b) => a.len > b.len ? a : b)
        
      } else {
        return false
      }
      
    },
    winners: function () {
      if (this.scores) {
        const winner_score = Object.keys(this.scores).map((key) => {
          return [key, this.scores[key]['totalKoz'] - this.scores[key]['totalCeza']]
        })
          .reduce((a, b) => {
            if (a[1] > b[1]) {
              return a
            } else {
              return b
            }
          })[1]
        return Object.keys(this.scores)
          .filter((key) => (this.scores[key]['totalKoz'] - this.scores[key]['totalCeza']) === winner_score)
          .map((key) => this.screenNames[key])
      } else {
        return null
      }
    },
    heartsSpadesCards: function () {
      let hearts = [];
      if (this.hand) {
        let hand_list = Object.keys(this.hand)
        hand_list.sort((a, b) => {
          return this.deck[b].id - this.deck[a].id;
        })
        
        for (const key of hand_list) {
          if (this.deck[key].suit === 'hearts' || this.deck[key].suit === 'spades') {
            hearts.push(key)
          }
        }
      }
      return hearts
    },
    clubsDiamondsCards: function () {
      let clubs = []
      if (this.hand) {
        let hand_list = Object.keys(this.hand)
        hand_list.sort((a, b) => {
          return this.deck[b].id - this.deck[a].id;
        })
        
        for (const key of hand_list) {
          if (this.deck[key].suit === 'clubs' || this.deck[key].suit === 'diamonds') {
            clubs.push(key)
          }
        }
      }
      return clubs
    },
    contract: function () {
      if (this.game && this.game.table && this.game.table.koz) {
        return `Koz ${this.contractsCopy[this.game.table.koz]}`
      } else if (this.game && this.game.table && this.game.table.ceza) {
        return `${this.contractsCopy[this.game.table.ceza]}`
      } else {
        return null
      }
    },
  },
  
}
</script>

<style scoped>
.trumps {
  display: flex;
  justify-content: center;
  align-items: center;
  
  padding: 0.1rem;
  background: var(--secondaryColor);
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  
  flex: 0 1 auto;
  z-index: 120;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  
  outline: inherit;
}

.triangles {
  display: flex;
  flex-direction: row;
  /*margin-bottom:-4px;*/
  font-size: min(15px, 3vw);
  
  outline: inherit;
}

.circles {
  display: flex;
  align-items: stretch;
  /*margin-top: -4px;*/
  font-size: min(15px, 3vw);
  
  outline: inherit;
}

.player-area-cards {
  display: grid;
  
  /*grid-template-columns: 15% 15% 15% 15% 15% 15%;*/
  /*grid-template-columns: repeat(auto-fill, var(--colSize));*/
  grid-template-columns: repeat(var(--colNumber), calc(90% / calc(var(--colNumber))));
  grid-template-rows: 50px 50px;
  column-gap: 0;
  
  padding-left: 2px;
  padding-right: 30px;
  
  flex: 1 0;
  justify-content: flex-end;
  justify-self: stretch;
  align-self: stretch;
  align-items: center;
  
  margin-top: 6px;
  
  outline: inherit;
  
}

.player-area-cards #heartsSpadesCards {
  grid-row-start: 1;
  
  justify-content: center;
  align-items: center;
  justify-self: flex-start;
  
  outline: inherit;
}

.player-area-cards #clubsDiamondsCards {
  grid-row-start: 2;
  
  justify-content: center;
  align-items: center;
  justify-self: flex-start;
  
  outline: inherit;
}


#room {
  /*margin-left: 20px;*/
  display: flex;
  
  flex: 1 0 100%;
  flex-direction: column;
  justify-content: space-between;
  
  outline: inherit;
  
  /*	position: relative;*/
}

#room #top-area {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  flex: 0 0 3.5rem;
  height: 3.5rem;

  z-index: 0;
  
  outline: inherit;
}

#room #middle-area {
  display: flex;
  flex-direction: row;
  flex: 0 0 max(40vh, 320px);
  
  justify-content: center;
  align-items: stretch;

  outline: inherit;
}

#koz-ceza-panel, #table-panel {
  z-index: 300;
  height: max(360px, 45vh);
}

#room #bottom-name-area {
  display: flex;
  flex: 0 0 3.5rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;


  z-index: 100;
  
  outline: inherit;
}

#room #bottom-area {
  display: flex;
  flex: 1 0 200px;

  flex-direction: column;
  justify-content: center;
  align-items: center;


  /*height: calc(50% - 90px);*/
  /*margin-right: 4%;*/
  /*margin-left: 4%;*/
  
  z-index: 130;
  /*height: 100px;*/
  
  /*overflow: hidden;*/
  
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  
  outline: inherit;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#bottom-area::-webkit-scrollbar {
  display: none;
}


/*.showChosenTrump-enter-active, .showChosenTrump-leave-active {*/
/*  opacity: 0;*/
/*}*/
/*.showChosenTrump-enter, .showChosenTrump-leave-to {*/
/*  transition: opacity 2s;*/
/*}*/


</style>
