<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div id="menu" class="modal">
        <p id="menu-table">
          This website uses cookies to ensure you get the best experience on our website.<a href="/cookie_policy.html"
                                                                                            target="_blank">Learn
          more.</a>
        </p>
        <div class="btn-accept" @click="accept">
          Accept
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import app from "@/firebase.setup";

export default {
  name: 'ModalCookie',
  props: ["cookieAccepted"],
  methods: {
    accept() {
      this.$emit('close');
      this.$cookies.set('cookieFlag', true)
    }
  }
};
</script>

<style scoped>

.btn-accept {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background-color: var(--tertiaryColor);
  color: var(--mainColor);
  width: 96px;
  height: 35px;
  margin-bottom: 10px;
  margin-top: 5px;
  /*border-radius: 10%;*/
  /*box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);*/
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
}

#menu {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  align-items: center;
  
  font-size: 20px;
  color: var(--tertiaryColor);
  
  margin: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  /*border-radius: 10%;*/
  
  width: min(80%, 500px);
  
  overflow-y: hidden;
  
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  
  outline: inherit;
  
}

#menu-header {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  
  /*border: 1px solid var(--tertiaryColor);*/
  
  font-size: 20px;
  color: var(--tertiaryColor);
  
  margin: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  padding: 5px;
  
  background-color: var(--mainColor);
  
  outline: inherit;
  
}

#menu-header > div {
  margin: 5px;
}

#menu-table {
  /*display: flex;*/
  /*flex-direction: column;*/
  flex: 1 0 auto;
  
  /*justify-content: center;*/
  /*align-items: stretch;*/
  
  font-size: 20px;
  color: var(--tertiaryColor);
  
  margin: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  
  outline: inherit;
}

#menu-table > div {
  margin: 5px;
}

#menu-system {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  
  justify-content: center;
  align-items: stretch;
  
  font-size: 20px;
  color: var(--tertiaryColor);
  
  margin: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  
  outline: inherit;
  
}

#menu-system > div {
  margin: 5px;
}

.menu-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
  padding-left: 5px
}

.menu-button:hover {
  background-color: var(--secondaryColor);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
}

.modal-header,
.modal-footer {
  justify-content: center;
  align-items: center;
  padding: 15px;
  display: flex;
  color: var(--tertiaryColor);
  
  outline: inherit;
}


.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
  
  outline: inherit;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  
  outline: inherit;
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease
}


</style>
