<template>
  <div id="room">
    <modal-menu
      style="z-index: 1000"
      v-show="menuOpen"
      @close="closeMenuModal"
      @restart="restart"
      @quitGame="quitGame"
      @modalUserOpen="modalUserOpen=true"
      
      :game="game"
      :game-meta="gameMeta"
      :user="user"
    />
    <modal-user-name
      v-if="relDir"
      style="z-index: 1000"
      :seat="relDir.S"
      :button-text=this.appCopy.change
      :user="user"
      v-show="modalUserOpen"
      @renameUser="renameUser"
      @modalUserNameClose="modalUserOpen=false"
    />
    <modal-history
      style="z-index: 1000"
      v-if="game && game.history"
      v-show="isHistoryModalVisible"
      @close="closeHistoryModal"
      :table="table"
      :scores=scores
      :history=game.history
      :rel-dir="relDir"
    />
    <ModalYazboz
      style="z-index: 1050"
      v-if="round > 0"
      v-show="isYazbozModalVisible"
      @close="closeYazbozModal"
      @quitGame="quitGame"
      @restartGame="restart"
      :yazboz="yazboz"
      :table="table"
      :scores="scores"
      :winners="winners"
      :screenNames="screenNames"
      :gameOver="gameOver"
    />
    <ModalRoundEnd
      style="z-index: 1000"
      v-if="this.table && this.table.roundEnd"
      @close="closeRoundEndModal"
      :game="game"
      :game-meta="gameMeta"
      :relDir="relDir"
    />
    
    <div id="top-area" class="player-area">
      <div style="display: flex;   width: min(100px,10vw);align-items: center; justify-content: flex-start;outline: inherit">
        <div class="btn-info points">{{round}}</div>
      </div>
      <div style="display: flex; height: 100%; width:calc(100% - min(200px,20vw)); outline: inherit">
        <div v-if="this.relDir && this.remains"
             style="display: flex; flex: 1 0; flex-direction: row; justify-content: center; align-items: center; outline: inherit">
          <div v-if="table && (table.ceza || table.koz)"
               class="player-points" style="z-index:90"
          >
                        <span class="points" style="z-index:90">
                            {{ scores[this.relDir.N]['round'] }}
                        </span>
          </div>
          <div v-else class="trumps" style="flex-direction: column; margin-top:5px">
            <div class="triangles">
              <div v-if="remains && remains[relDir.N]['ceza'] === 3">
                <i class="fa-light fa-circle"></i>
                <i class="fa-light fa-circle"></i>
                <i class="fa-light fa-circle"></i>
              </div>
              <div v-else-if="remains && remains[relDir.N]['ceza'] === 2">
                <i class="fa-solid fa-circle"></i>
                <i class="fa-light fa-circle"></i>
                <i class="fa-light fa-circle"></i>
              </div>
              <div v-else-if="remains && remains[relDir.N]['ceza'] === 1">
                <i class="fa-solid fa-circle"></i>
                <i class="fa-solid fa-circle"></i>
                <i class="fa-light fa-circle"></i>
              </div>
              <div v-else>
                <i class="fa-solid fa-circle"></i>
                <i class="fa-solid fa-circle"></i>
                <i class="fa-solid fa-circle"></i>
              </div>
            </div>
            <div class="circles ">
              <div v-if="remains && remains[relDir.N]['koz'] === 2">
                <i class="fa-light fa-triangle"></i>
                <i class="fa-light fa-triangle"></i>
              </div>
              <div v-else-if="remains && remains[relDir.N]['koz'] === 1">
                <i class="fa-solid fa-triangle"></i>
                <i class="fa-light fa-triangle"></i>
              </div>
              <div v-else>
                <i class="fa-solid fa-triangle"></i>
                <i class="fa-solid fa-triangle"></i>
              </div>
            </div>
          </div>
          <b v-if="table.whoseTurn !== this.relDir.N" class="playername text" style="z-index:90">
            {{ screenNames[this.relDir.N] }}</b>
          <b v-else class="playername text nameTurn" style="z-index:90">
            {{ screenNames[this.relDir.N] }}</b>
          <div v-if="table && (table.ceza || table.koz)"
               class="player-points" style="z-index:90; flex: 0 1; visibility: hidden"
          >
                        <span class="points" style="z-index:90; flex: 0 1;">
                            {{ scores[this.relDir.N]['round'] }}
                        </span>
          </div>
          <div v-else class="trumps"
               style="flex-direction: column; flex: 0 1; margin-top:5px; visibility: hidden">
            <div class="triangles">
              <div v-if="remains && remains[relDir.N]['ceza'] === 3">
                <i class="fa-light fa-circle"></i>
                <i class="fa-light fa-circle"></i>
                <i class="fa-light fa-circle"></i>
              </div>
              <div v-else-if="remains && remains[relDir.N]['ceza'] === 2">
                <i class="fa-solid fa-circle"></i>
                <i class="fa-light fa-circle"></i>
                <i class="fa-light fa-circle"></i>
              </div>
              <div v-else-if="remains && remains[relDir.N]['ceza'] === 1">
                <i class="fa-solid fa-circle"></i>
                <i class="fa-solid fa-circle"></i>
                <i class="fa-light fa-circle"></i>
              </div>
              <div v-else>
                <i class="fa-solid fa-circle"></i>
                <i class="fa-solid fa-circle"></i>
                <i class="fa-solid fa-circle"></i>
              </div>
            </div>
            <div class="circles">
              <div v-if="remains && remains[relDir.N]['koz'] === 2">
                <i class="fa-light fa-triangle"></i>
                <i class="fa-light fa-triangle"></i>
              </div>
              <div v-else-if="remains && remains[relDir.N]['koz'] === 1">
                <i class="fa-solid fa-triangle"></i>
                <i class="fa-light fa-triangle"></i>
              </div>
              <div v-else>
                <i class="fa-solid fa-triangle"></i>
                <i class="fa-solid fa-triangle"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style=" width: min(100px,10vw);display: flex; align-items: center; justify-content: flex-end; outline: inherit; ">
        <div v-if="trump === 'spades'" class="spades_button btn-info"></div>
        <div v-else-if="trump === 'hearts'" class="hearts_button btn-info"></div>
        <div v-else-if="trump === 'clubs'" class="clubs_button btn-info"></div>
        <div v-else-if="trump === 'diamonds'" class="diamonds_button btn-info"></div>
        <div v-if="trump === 'rifki'" class="rifki btn-info"></div>
        <div v-else-if="trump === 'el'" class="elalmaz btn-info"></div>
        <div v-else-if="trump === 'erkek'" class="erkekalmaz btn-info"></div>
        <div v-else-if="trump === 'kiz'" class="kizalmaz btn-info"></div>
        <div v-else-if="trump === 'kupa'" class="kupaalmaz2 btn-info"></div>
        <div v-else-if="trump === 'soniki'" class="soniki btn-info"></div>
      </div>
    </div>
    
    <div id="middle-area">
      <koz-ceza-panel
        style="z-index: 150"
        v-if="chooseTrumpFlag && !!table && !!table.whoseTurn"
        id="koz-ceza-panel"
        class="player-area"
        @registerKozIfAvailable="registerKozIfAvailable"
        @registerCezaIfAvailable="registerCezaIfAvailable"
        :game-meta="gameMeta"
        :round="round"
        :usersInGame="usersInGame"
        :hand="hands && hands['S']"
        :screen-names="screenNames"
        :table="table"
        :yazboz="yazboz"
        :user="user"
        :rel-dir="relDir"
      ></koz-ceza-panel>
      <tablePanel
        v-if="user && table && !chooseTrumpFlag"
        id="table-panel"
        :showChosenTrump="showChosenTrump"
        :screen-names="screenNames"
        :table="table"
        :usersInGame="usersInGame"
        :scores="scores"
        :user="user"
        :cardWidth="cardWidthTable"
        :relDir="relDir"
      ></tablePanel>
    </div>
    
    <div id="bottom-name-area" class="player-area">
      <!--            <div v-if="showChosenTrump" style="display: flex; flex: 1 1 auto;">-->
      <!--                <div style="display: flex; flex:  1 1;" v-if="table.ceza==='rifki'">-->
      <!--                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">-->
      <!--                        <div class="header">-->
      <!--                            <b class="trumpHeader text">Rıfkı Almaz</b>-->
      <!--                        </div>-->
      <!--                    </div>-->
      <!--                </div>-->
      <!--            </div>-->
      
      <div v-if="usersInGame && Object.keys(usersInGame).length === 4"
           style="display: flex; flex: 1 1; flex-direction: row; align-content: center; justify-content: center; outline: inherit">
        <div style="visibility: hidden; display: flex; margin-left: auto; width:min(100px,25vw); justify-content: center; align-items: center;outline: inherit; ">
        </div>
        <div v-if="table && (table.ceza || table.koz)"
             class="player-points" style="z-index:90"
        >
                    <span class="points" style="z-index:90">
                        {{ scores[this.relDir.S]['round'] }}
                    </span>
        </div>
        <div v-else class="trumps" style="flex-direction: column; margin-top:5px; justify-content: center">
          <div class="triangles">
            <div v-if="remains && remains[relDir.S]['ceza'] === 3">
              <i class="fa-light fa-circle"></i>
              <i class="fa-light fa-circle"></i>
              <i class="fa-light fa-circle"></i>
            </div>
            <div v-else-if="remains && remains[relDir.S]['ceza'] === 2">
              <i class="fa-solid fa-circle"></i>
              <i class="fa-light fa-circle"></i>
              <i class="fa-light fa-circle"></i>
            </div>
            <div v-else-if="remains && remains[relDir.S]['ceza'] === 1">
              <i class="fa-solid fa-circle"></i>
              <i class="fa-solid fa-circle"></i>
              <i class="fa-light fa-circle"></i>
            </div>
            <div v-else>
              <i class="fa-solid fa-circle"></i>
              <i class="fa-solid fa-circle"></i>
              <i class="fa-solid fa-circle"></i>
            </div>
          </div>
          <div class="circles">
            <div v-if="remains && remains[relDir.S]['koz'] === 2">
              <i class="fa-light fa-triangle"></i>
              <i class="fa-light fa-triangle"></i>
            </div>
            <div v-else-if="remains && remains[relDir.S]['koz'] === 1">
              <i class="fa-solid fa-triangle"></i>
              <i class="fa-light fa-triangle"></i>
            </div>
            <div v-else>
              <i class="fa-solid fa-triangle"></i>
              <i class="fa-solid fa-triangle"></i>
            </div>
          </div>
        </div>
        <b v-if="table.whoseTurn !== this.relDir.S" class="playername text" style="z-index:90">
          {{ screenNames[this.relDir.S] }}</b>
        <b v-else class="playername text nameTurn" style="z-index:90">
          {{ screenNames[this.relDir.S] }}</b>
        <div class="trumps" style="flex-direction: column; margin-top:5px; visibility: hidden">
          <div class="triangles">
            <div v-if="remains && remains[relDir.S]['ceza'] === 3">
              <i class="fa-light fa-circle"></i>
              <i class="fa-light fa-circle"></i>
              <i class="fa-light fa-circle"></i>
            </div>
            <div v-else-if="remains && remains[relDir.S]['ceza'] === 2">
              <i class="fa-solid fa-circle"></i>
              <i class="fa-light fa-circle"></i>
              <i class="fa-light fa-circle"></i>
            </div>
            <div v-else-if="remains && remains[relDir.S]['ceza'] === 1">
              <i class="fa-solid fa-circle"></i>
              <i class="fa-solid fa-circle"></i>
              <i class="fa-light fa-circle"></i>
            </div>
            <div v-else>
              <i class="fa-solid fa-circle"></i>
              <i class="fa-solid fa-circle"></i>
              <i class="fa-solid fa-circle"></i>
            </div>
          </div>
          <div class="circles">
            <div v-if="remains && remains[relDir.S]['koz'] === 2">
              <i class="fa-light fa-triangle"></i>
              <i class="fa-light fa-triangle"></i>
            </div>
            <div v-else-if="remains && remains[relDir.S]['koz'] === 1">
              <i class="fa-solid fa-triangle"></i>
              <i class="fa-light fa-triangle"></i>
            </div>
            <div v-else>
              <i class="fa-solid fa-triangle"></i>
              <i class="fa-solid fa-triangle"></i>
            </div>
          </div>
        </div>
        <div style="display: flex; margin-left: auto; width:min(100px,25vw); justify-content: flex-end; align-items: center; outline: inherit; ">
          <div
            v-if="game && game.history"
            type="button"
            class="btn-yazboz"
            style="margin-right:5px;"
            @click="showHistoryModal"
          >
            <i class="fa-duotone fa-history fa-2x" style="display:flex; margin-left:1px; align-self: center"></i>
          </div>
          <div
            v-if="round > 0"
            class="btn-yazboz"
            style="display:flex; align-content: center;"
            @click="showYazbozModal"
          >
            <i class="fa-light fa-clipboard-list fa-2x" style="display:flex;"/>
          </div>
        </div>
      </div>
    
    </div>
    
    <div id="bottom-area">
      <transition-group v-if="usersInGame && Object.keys(usersInGame).length === 4" name="card-appear"
                        mode="out-in" class="player-area-cards"
                        style="z-index:120; align-items: flex-start; justify-content: space-evenly">
        <vue-playing-card
          v-for="cardId of heartsSpadesCards"
          id="heartsSpadesCards"
          :width="100"
          :signature=deck[cardId].name
          :key="deck[cardId].id"
          @click.native="!chooseTrumpFlag && table && table.whoseTurn === user.seat ? { click:  throwCard(cardId) } : {}"
          :class="[(table.whoseTurn === relDir.S && !touchDevice ) ? 'hover playing-card' : 'playing-card']"
        >
        </vue-playing-card>
        <vue-playing-card
          v-for="cardId of clubsDiamondsCards"
          id="clubsDiamondsCards"
          :width="100"
          :signature=deck[cardId].name
          :key="deck[cardId].id"
          @click.native="!chooseTrumpFlag && table && table.whoseTurn === user.seat ? { click:  throwCard(cardId) } : {}"
          :class="[(table.whoseTurn === relDir.S && !touchDevice ) ? 'hover playing-card' : 'playing-card']"
        >
        </vue-playing-card>
      </transition-group>
    </div>
  </div>
</template>

<script>
import app from '@/firebase.setup'

import KozCezaPanel from "./KingKozCezaPanel";
import TablePanel from "./KingTablePanel";
import ModalHistory from "./KingModalHistory";
import ModalYazboz from './KingModalYazboz';
import ModalRoundEnd from "./KingModalRoundEnd";
import ModalUserName from "./user/ModalUserName";
import ModalMenu from './KingModalMenu.vue';

import { nextSeat, isKozRevealed } from "@/js/games";
import { deck_template } from '@/js/deck';
import { kingCopy } from '@/js/king/kingCopy';

import {
  restartGame,
  createGame,
  tableWinner,
  isRoundFinished,
  buildHistory,
  botPlay,
  botChooseTrump,
  updateYazBoz,
  updateStateRoundEnd,
} from "@/js/king/king";

import {getTimestamp, isBot} from "@/js/utils";
import {debounce} from "debounce";
import {isInvalid} from "@/js/king/validations";

// import BaseTimer from "./BaseTimer";
// import CryptoJS from 'crypto-js';
// import deckMixin from '../mixins/deckMixin'
// import {sleep} from "@/utils";

const BOT_PLAY_TIMEOUT = 500; // Bot hamlesindeki delay
const BOT_CHOOSE_TRUMP_TIMEOUT = 2500;
const TABLE_ROUND_END_TIMEOUT = 1500;
const ROUND_END_FLAG_TIMEOUT = 1500; // Son round kagitlari yere dustukten sonra bekleme suresi
const ROUND_END_MODAL_TIMEOUT = 3500; // Son round kagitlari yere dustukten SOnu Modalinin kaybolmasi arasindaki sure ROUND_END_FLAG_TIMEOUT'den buyuk olmali
const CHOOSE_TRUMP_TIMEOUT = 490; // BOT_PLAY_TIMEOUT tan az olmali
// const TIME_LIMIT = config.TIME_LIMIT;
// const WARNING_THRESHOLD = config.WARNING_THRESHOLD;
// const ALERT_THRESHOLD = config.ALERT_THRESHOLD;
// const RESTART_TIMEOUT = BOT_CHOOSE_TRUMP_TIMEOUT + 5*BOT_PLAY_TIMEOUT;
const RESTART_TIMEOUT = 50;

export default {
  name: 'KingSingle',
  props: ["user", "menuOpen", "relDir", "touchDevice", "renameFinger"],
  components: {
    KozCezaPanel,
    TablePanel,
    ModalHistory,
    ModalYazboz,
    ModalRoundEnd,
    ModalMenu,
    ModalUserName,
  },
  data: () => ({
    game: null,
    gameMeta: null,
    hands: null,
    
    gameOver: false,
    
    modalUserOpen: false,
    cardThrowLock: false,
    windowWidth: window.innerWidth,
    environment: process.env.VUE_APP_ENV,
    isHistoryModalVisible: false,
    isYazbozModalVisible: false,
    // timeLimit: TIME_LIMIT,
    // warning_threshold: WARNING_THRESHOLD,
    // alert_threshold: ALERT_THRESHOLD,
    timePassedValueEncrypted: sessionStorage.getItem('tp'),
    refreshed: false,
    showChosenTrump: false,
    roundFinished: false,
    deck: null
  }),
  async created() {
    this.$emit('setIsLoading', true);
    this.contractsCopy = kingCopy.contracts;
    this.appCopy = kingCopy.App;
    this.deck = {...deck_template};

    this.processBotTable = debounce(this.processBotTable, BOT_PLAY_TIMEOUT)

    // Storage validation done in King.vue
    if(sessionStorage.gameMeta && sessionStorage.hands && sessionStorage.game) {
      this.gameMeta = JSON.parse(sessionStorage.gameMeta);
      this.hands = JSON.parse(sessionStorage.hands);
      this.game = JSON.parse(sessionStorage.game);
    }

    if (!this.gameOver && this.table && this.table.cards && Object.keys(this.table.cards).length === 4) {
      this.processTable();
    }

    if (this.hand) {
      const l = Math.max(this.heartsSpadesCards.length, this.clubsDiamondsCards.length, 7)
      document.documentElement.style.setProperty("--colNumber", l);
    }
    this.$emit('setIsLoading', false);
  },
  methods: {
    async renameUser(data) {
      await app.database().ref(`/users/${this.user.id}/name`).set(data.name);

      this.gameMeta.creator = data.name;
      this.gameMeta.owner.name = data.name;
      this.game.usersInGame.S.name = data.name;

      this.modalUserOpen = false;
      this.$emit('closeMenu');
    },
    closeMenuModal() {
      this.$emit('closeMenu');
    },
    closeRoundEndModal() {
      this.roundFinished = false;
      const gameMeta = updateYazBoz(this.game, this.gameMeta);
      let game = JSON.parse(JSON.stringify(this.game));

      if (this.game.round !== 20) {
        const resp = updateStateRoundEnd(this.game);
        game = resp[0]
        const hands = resp[1]
        for (const k in hands) {
          this.$set(this.hands, k, hands[k]);
        }
      } else {
        this.gameOver = true;
        game.table.roundEnd = false;
        gameMeta.createTs[gameMeta.restartCounter]['end'] = getTimestamp()
        gameMeta.createTs[gameMeta.restartCounter]['round'] = 20
      }
      this.updateGameMetaAndGame(game, gameMeta)
    },
    updateGameMetaAndGame: function (game, gameMeta) {
      for (const k in gameMeta) {
        this.$set(this.gameMeta, k, gameMeta[k]);
      }

      for (const k in game) {
        if (k !== 'table') {
          this.$set(this.game, k, game[k]);
        }
      }

      this.$set(this.game.table, 'ceza', game.table.ceza);
      this.$set(this.game.table, 'koz', game.table.koz);
      this.$set(this.game.table, 'kozRevealed', game.table.kozRevealed);
      this.$set(this.game.table, 'tableRound', game.table.tableRound);
      this.$set(this.game.table, 'tableSuit', game.table.tableSuit);
      this.$set(this.game.table, 'roundEnd', game.table.roundEnd);
      this.$set(this.game.table, 'whoseTurn', game.table.whoseTurn);
      this.$set(this.game.table, 'cards', game.table.cards);
    },
    updateRoundScore: function(tableW) {
      const cards = this.game.table.cards;
      const table = this.game.table;
      //update round score
      if (table.ceza === 'kupa') {
        let count = 0;
        for (const s in table.cards) {
          const cardId = table.cards[s]
          if (this.deck[cardId].suit === 'hearts') {
            count = count + 1
          }
        }
        this.gameMeta.scores[tableW].round += count;
      }
      else if (table.ceza === 'el' || (table.ceza === 'soniki' && table.tableRound > 11)) {
        this.gameMeta.scores[tableW].round += 1;
      }
      else if (table.ceza === 'rifki') {
        let rifkiThrown = false
        for (const s in table.cards) {
          if (cards[s] === '113') {
            rifkiThrown = true
          }
        }
        if (rifkiThrown) {
          this.gameMeta.scores[tableW].round += 1;
        }
      }
      else if (table.ceza === 'erkek') {
        let count = 0;
        for (const s in table.cards) {
          const cardId = table.cards[s]
          if (this.deck[cardId].rank === 11 || this.deck[cardId].rank === 13) {
            count = count + 1
          }
        }
        this.gameMeta.scores[tableW].round += count;
      }
      else if (table.ceza === 'kiz') {
        let count = 0;
        for (const s in table.cards) {
          const cardId = table.cards[s]
          if (this.deck[cardId].rank === 12) {
            count = count + 1
          }
        }
        this.gameMeta.scores[tableW].round += count;
      }
    },
    processTable: function () {
      const cards = this.game.table.cards;
      const table = this.game.table;

      if (!this.roundFinished) {
        if (table && (table.koz || table.ceza)) {
          if (cards && (Object.keys(cards).length === 4)) {
            const tableW = tableWinner(table)
            if (table.ceza) {
              if (isRoundFinished(this.hands, table.ceza)) {
                this.roundFinished = true;
                setTimeout(() => {
                  this.updateRoundScore(tableW)
                  this.$set(this.game.table, 'roundEnd', true)
                  this.cardThrowLock = false;
                }, ROUND_END_FLAG_TIMEOUT)
                if (isBot('S', this.game)) {
                  setTimeout(() => {
                    this.closeRoundEndModal();
                  }, ROUND_END_MODAL_TIMEOUT)
                }
              } else {
                //register history - Register last table, hearts, K/J, Q
                const newHistory = buildHistory(this.game);
                const newTable = {
                  'cards': {},
                  'ceza': table.ceza,
                  'whoseTurn': tableW,
                  'kozRevealed': isKozRevealed(table),
                  'tableRound': table.tableRound + 1
                }
                setTimeout(() => {
                  this.updateRoundScore(tableW)
                  this.$set(this.game, 'history', newHistory);
                  this.$set(this.game, 'table', newTable);
                  this.cardThrowLock = false;
                }, TABLE_ROUND_END_TIMEOUT)
              }
            }
            else { //koz
              if (isRoundFinished(this.hands, "koz")) {
                this.roundFinished = true;
                setTimeout(() => {
                  this.$set(this.gameMeta.scores[tableW], 'round', this.gameMeta.scores[tableW]['round'] + 1)
                  this.$set(this.game.table, 'roundEnd', true)
                  this.cardThrowLock = false;
                }, ROUND_END_FLAG_TIMEOUT)
                if (isBot('S', this.game)) {
                  setTimeout(() => {
                    this.closeRoundEndModal();
                  }, ROUND_END_MODAL_TIMEOUT)
                }
              } else {
                //register history - Register last table, hearts, K/J, Q
                const newHistory = buildHistory(this.game);
                this.$set(this.game, 'history', newHistory);

                // newtable
                const newTable = {
                  'cards': {},
                  'koz': table.koz,
                  'whoseTurn': tableW,
                  'kozRevealed': isKozRevealed(table),
                  'tableRound': table.tableRound + 1
                }
                setTimeout(() => {
                  this.$set(this.gameMeta.scores[tableW], 'round', this.gameMeta.scores[tableW]['round'] + 1)
                  this.$set(this.game, 'table', newTable);
                  this.cardThrowLock = false;
                }, TABLE_ROUND_END_TIMEOUT)
              }
            }
            return this.game.table.whoseTurn
          } else if (cards && Object.keys(cards).length === 1) {
            // @ts-ignore
            const tableSuit = this.deck[cards[Object.keys(cards)[0]]].suit

            this.$set(this.game.table, 'whoseTurn', nextSeat(table.whoseTurn));
            this.$set(this.game.table, 'tableSuit', tableSuit);
            this.cardThrowLock = false;
            return this.game.table.whoseTurn
          } else if ((cards && Object.keys(cards).length) === 2 || (cards && Object.keys(cards).length === 3)) {
            this.$set(this.game.table, 'whoseTurn', nextSeat(table.whoseTurn));
            this.cardThrowLock = false;
            return this.game.table.whoseTurn
          } else {
            console.log('BoSH Cek')
            return null
          }
        }
        else if (isBot(table.whoseTurn, this.game)) {
          // const BOT_TRUMP_CHOOSE_TIMEOUT = 2000;
          // sleep(BOT_TRUMP_CHOOSE_TIMEOUT);
          setTimeout(() => {
            const seat = table.whoseTurn;

            const botCT = botChooseTrump(this.game, this.gameMeta, this.hands[seat])
            const newGame = botCT[0]
            const newGameMeta = botCT[1]
            this.updateGameMetaAndGame(newGame, newGameMeta)
          }, BOT_CHOOSE_TRUMP_TIMEOUT)
        } else {
          console.log('Human player choosing trump')
        }
      }
    },
    numberOfBots: function () {
      if (this.usersInGame) {
        return Object.keys(this.usersInGame).map(seat => isBot(seat, this.game)).reduce((a, b) => a + b, 0)
      } else {
        return null
      }
    },
    throwCard: function (cardId, seat = 'S') {
      if (!this.cardThrowLock && !this.showChosenTrump && this.whoseTurn === seat) {
        this.cardThrowLock = true
        if (!isInvalid(cardId, this.table, this.hands && this.hands[seat])) {
          if (this.table.cards) {
            this.$set(this.game.table.cards, seat, cardId)
          } else {
            this.$set(this.game.table, 'cards', {seat: cardId})
          }
          this.$delete(this.hands[seat], cardId)

          this.processTable();
        } else {
          this.$alert(`${isInvalid(cardId, this.table, this.hands && this.hands[seat])}`)
          this.cardThrowLock = false
        }
      }
      return 'all good'
    },
    registerKozIfAvailable: function (kozName, user) {
      if (this.yazboz.remains[user.seat]['koz'] > 0) {
        this.$set(this.game.table, 'koz', kozName)
        this.$set(this.gameMeta.yazboz.remains[this.user.seat], 'koz', this.yazboz.remains[user.seat]['koz'] - 1)
      } else {
        this.alertNoMorePlayerKoz();
      }
    },
    registerCezaIfAvailable: function (cezaName, user) {
      const remains = {
        "remainPlayer": this.yazboz.remains[user.seat]['ceza'],
        "remainCommon": this.yazboz.remains.cezalar[cezaName]
      }
      if (remains.remainPlayer < 1) {
        this.alertNoMorePlayerCeza()
      } else if (remains.remainCommon === 0) {
        this.alertNoMoreCommonCeza(this.contractsCopy[cezaName])
      } else {
        this.$set(this.game.table, 'ceza', cezaName)
        this.$set(this.gameMeta.yazboz.remains.cezalar, cezaName, remains.remainCommon - 1)
        this.$set(this.gameMeta.yazboz.remains[user.seat], 'ceza', remains.remainPlayer - 1)
      }
    },
    showHistoryModal() {
      this.isHistoryModalVisible = true;
    },
    closeHistoryModal() {
      this.isHistoryModalVisible = false;
    },
    showYazbozModal() {
      this.isYazbozModalVisible = true;
    },
    closeYazbozModal() {
      this.isYazbozModalVisible = false;
    },
    increaseTimer() {
      this.refreshed = false;
      this.timePassed += 1;
    },
    alertNoMoreCommonCeza: function (ceza) {
      this.$alert(ceza + this.appCopy.noMoreCommonPenaltyAlert);
    },
    alertNoMorePlayerCeza: function () {
      this.$alert(this.appCopy.noMorePlayerPenaltyAlert);
    },
    alertNoMorePlayerKoz: function () {
      this.$alert(this.appCopy.noMorePlayerTrumpAlert);
    },
    processBotTable: function () {
      const nextPlayer = this.game.table.whoseTurn;
      const hand = this.hands[nextPlayer];
      const game = this.game;
      const cardId = botPlay(nextPlayer, game, hand);
      this.throwCard(cardId, nextPlayer)
    },
    async gameStart(user) {
      this.$emit('setIsLoading', true);
      this.gameOver = false;
      // let gameMeta;
      [this.game, this.gameMeta, this.hands, user] = createGame({'id': user.id, 'name': user.name, 'gameCategory': 'king'}, user.gameType);
      
      this.game.dealer = 'S';
      this.game['table'].tableSuit = null;
      this.game['table'].tableRound = 1;
      this.game['table']['kozRevealed'] = false;
      this.game['table']['whoseTurn'] = this.game.dealer;
      await app.database().ref(`/users/${user.id}`).set(user);
      
      const registerGameStart = app.functions().httpsCallable(`${this.gameTypePrefix}RegisterGameStart`);
      const resp = await registerGameStart(this.gameMeta);
      this.gameMeta = resp.data;
      
      this.$emit('setIsLoading', false);
    },
    async quitGame() {
      this.$emit('setIsLoading', true);
      this.closeMenuModal();
      //clears all setTimeout functions

      //clear all timeouts
      let i = setTimeout(function () {
      });
      while (i--) clearTimeout(i);
      
      if (this.gameOver) {
        app.analytics().logEvent(`${this.user.gameType}-quitGame-gameOver`);
      } else {
        const registerGameResult = app.functions().httpsCallable(`${this.gameTypePrefix}RegisterGameResult`);
        app.analytics().logEvent(`${this.user.gameType}-quitGame-premature`);
        try {
          await registerGameResult({'gameMeta': this.gameMeta, 'round': this.game.round});
        }
        catch(e) {
          console.warn(e)
        }
      }

      sessionStorage.clear()
      
      this.game = null;
      this.gameMeta = null;
      this.hands = null;
      
      this.$emit('setIsLoading', false);
      await app.database().ref(`/users/${this.user.id}`).set({'id': this.user.id, 'name': this.user.name})
    },
    async restart() {
      this.$emit('setIsLoading', true);
      this.closeMenuModal();
      setTimeout(async () => {
        //clears all setTimeout functions
        let i = setTimeout(function () {
        });
        while (i--) clearTimeout(i);
        
        if (this.gameOver) {
          app.analytics().logEvent(`${this.user.gameType}-restart-gameOver`);
        } else {
          app.analytics().logEvent(`${this.user.gameType}-restart-premature`);
        }
        
        const registerRestart = app.functions().httpsCallable(`${this.gameTypePrefix}RegisterRestart`);
        const resp = await registerRestart({'gameMeta': this.gameMeta, 'round': this.game.round});
        const gameMetaUpdated = resp.data;
        
        let [gameL, gameMetaL, handsL] = restartGame(this.game, gameMetaUpdated, this.user)

        this.updateGameMetaAndGame(gameL, gameMetaL);
        
        for (const k in handsL) {
          this.$set(this.hands, k, handsL[k]);
        }
        
        this.$emit('setIsLoading', false);
      }, RESTART_TIMEOUT)
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    });
  },
  computed: {
    gameType() {
      if(this.user) {
        return this.user.gameType
      }
      return null
    },
    gameTypePrefix() {
      if(this.gameType) {
        if (this.gameType === 'kingSingle') {
          return 'single'
        }
        else if(this.gameType === 'kingRated') {
          return 'rated'
        }
      }
      return null
    },
    trump() {
      if(this.game && this.game.table ) {
        if (!this.game.table.ceza && !this.game.table.koz) {
          return false
        } else if (this.game.table.ceza) {
          return this.game.table.ceza
        } else {
          return this.game.table.koz
        }
      }
      return true
    },
    hand() {
      if (!this.hands) {
        return null
      } else {
        return this.hands.S
      }
    },
    round() {
      if (this.game && this.game.round) {
        return this.game.round
      } else {
        return null
      }
    },
    yazboz() {
      if (this.gameMeta && this.gameMeta.yazboz) {
        return this.gameMeta.yazboz
      } else {
        return null
      }
    },
    scores() {
      if (this.gameMeta && this.gameMeta.scores) {
        return this.gameMeta.scores
      } else {
        return null
      }
    },
    table() {
      if (this.game && this.game.table) {
        return this.game.table
      } else {
        return null
      }
    },
    usersInGame() {
      if (this.game && this.game.usersInGame) {
        return this.game.usersInGame
      } else {
        return null
      }
    },
    isNameSen: function () {
      if (this.user && this.user.name) {
        return this.user.name === this.appCopy.defaultName;
      } else {
        return null
      }
    },
    remains: function () {
      if (!this.yazboz) {
        return null
      } else {
        return this.yazboz.remains
      }
    },
    cardWidthTable: function () {
      return this.windowWidth / 3.7
    },
    cardWidthHand: function () {
      if (this.hands && this.hands['S']) {
        return Math.min(this.windowWidth / (Object.keys(this.hands['S']).length) * 1.6, this.windowWidth / 5)
      } else {
        return null
      }
    },
    whoseTurn: function () {
      if (this.table && this.table.whoseTurn) {
        return this.table.whoseTurn
      } else {
        return null
      }
    },
    tableCards: function () {
      if (this.table && this.table.cards) {
        return this.table.cards
      } else {
        return null
      }
    },
    chooseTrumpFlag: function () {
      return !!this.table && !(this.table && (this.table.koz || this.table.ceza));
    },
    orderedHand: function () {
      if (this.hands && this.hands['S']) {
        const hand = Object.keys(this.hands['S'])

        hand.sort((a, b) => {
          return this.deck[a].rank - this.deck[b].rank;
        })
        return hand
      } else {
        return null
      }
    },
    strongestSuit: function () {
      if (this.hands && this.hands['S']) {
        const hearts = [];
        const spades = [];
        const diamonds = [];
        const clubs = [];

        for (const key of this.hands['S']) {
          if (this.deck[key].suit === 'hearts') {
            hearts.push(key)
          } else if (this.deck[key].suit === 'spades') {
            spades.push(key)
          } else if (this.deck[key].suit === 'diamonds') {
            diamonds.push(key)
          } else if (this.deck[key].suit === 'clubs') {
            clubs.push(key)
          }
        }

        const suit_lengths = [{'name': 'hearts', 'len': hearts.length},
          {'name': 'spades', 'len': spades.length},
          {'name': 'diamonds', 'len': diamonds.length},
          {'name': 'clubs', 'len': clubs.length}]

        return suit_lengths.reduce((a, b) => a.len > b.len ? a : b)

      } else {
        return false
      }

    },
    winners: function () {
      if (this.scores) {
        const winner_score = Object.keys(this.scores).map((key) => {
          return [key, this.scores[key]['totalKoz'] - this.scores[key]['totalCeza']]
        })
          .reduce((a, b) => {
            if (a[1] > b[1]) {
              return a
            } else {
              return b
            }
          })[1]
        return Object.keys(this.scores)
          .filter((key) => (this.scores[key]['totalKoz'] - this.scores[key]['totalCeza']) === winner_score)
          .map((key) => this.screenNames[key])
      } else {
        return null
      }

    },
    screenNames: function () {
      const sN = {}

      if (this.usersInGame && this.usersInGame.N && this.usersInGame.N['name'] && this.usersInGame.N['name'].substring(0, 4) === '_bot') {
        sN['N'] = this.usersInGame.N['name'].substring(9)
      } else {
        if (!this.usersInGame || !this.usersInGame.N) {
          sN['N'] = ''
        } else {
          sN['N'] = this.usersInGame.N['name']
        }
      }

      if (this.usersInGame && this.usersInGame.W && this.usersInGame.W['name'] && this.usersInGame.W['name'].substring(0, 4) === '_bot') {
        sN['W'] = this.usersInGame.W['name'].substring(9)
      } else {
        if (!this.usersInGame || !this.usersInGame.W) {
          sN['W'] = ''
        } else {
          sN['W'] = this.usersInGame.W['name']
        }
      }

      if (this.usersInGame && this.usersInGame.E && this.usersInGame.E['name'] && this.usersInGame.E['name'].substring(0, 4) === '_bot') {
        sN['E'] = this.usersInGame.E['name'].substring(9)
      } else {
        if (!this.usersInGame || !this.usersInGame.E) {
          sN['E'] = ''
        } else {
          sN['E'] = this.usersInGame.E['name']
        }
      }

      if (this.usersInGame && this.usersInGame.S && this.usersInGame.S['name'] && this.usersInGame.S['name'].substring(0, 4) === '_bot') {
        sN['S'] = this.usersInGame.S['name'].substring(9)
      } else {
        if (!this.usersInGame || !this.usersInGame.S) {
          sN['S'] = ''
        } else {
          sN['S'] = this.usersInGame.S['name']
        }
      }
      return sN
    },
    heartsSpadesCards: function () {
      let hearts = [];
      if (this.hand && this.deck) {
        let hand_list = Object.keys(this.hand)
        hand_list.sort((a, b) => {
          return this.deck[b].id - this.deck[a].id;
        })

        for (const key of hand_list) {
          if (this.deck[key].suit === 'hearts' || this.deck[key].suit === 'spades') {
            hearts.push(key)
          }
        }
      }
      return hearts
    },
    clubsDiamondsCards: function () {
      let clubs = []
      if (this.hand && this.deck) {
        let hand_list = Object.keys(this.hand)
        hand_list.sort((a, b) => {
          return this.deck[b].id - this.deck[a].id;
        })

        for (const key of hand_list) {
          if (this.deck[key].suit === 'clubs' || this.deck[key].suit === 'diamonds') {
            clubs.push(key)
          }
        }
      }
      return clubs
    },
    contract: function () {
      if (this.game && this.game.table && this.game.table.koz) {
        return `Koz ${this.contractsCopy[this.game.table.koz]}`
      } else if (this.game && this.game.table && this.game.table.ceza) {
        return `${this.contractsCopy[this.game.table.ceza]}`
      } else {
        return null
      }
    },
  },
  watch: {
    gameType: {
      immediate: true,
      handler(val) {
        if (val) {
          let gTFromStorage;
          if(sessionStorage.gameMeta) {
            gTFromStorage = JSON.parse(sessionStorage.gameMeta).gameType
          }
          if (!gTFromStorage || gTFromStorage !== val){
            this.gameStart({'id': this.user.id, 'name': this.user.name, 'gameCategory': 'king', 'gameType': val})

            setTimeout(() => {
              this.$set(this.game, 'round', 1);
            }, 50)
          }
        }
      }
    },
    trump: {
      immediate: true,
      handler() {
        if (!this.trump && this.game && this.game.table && this.game.table.whoseTurn && isBot(this.game.table.whoseTurn, this.game)) {
          this.processTable()
        }
      }
    },
    tableCards: {
      immediate: true,
      handler() {
        if (this.hands && this.game && this.game.table && this.game.table.cards
          && (this.game.table.ceza || this.game.table.koz) && Object.keys(this.game.table.cards).length < 4) {

          const nextPlayer = this.game.table.whoseTurn
          if (isBot(nextPlayer, this.game)) {
            this.processBotTable();
          }
        }
      }
    },
    game: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          sessionStorage.game = JSON.stringify(val);
          sessionStorage.gameMeta = JSON.stringify(this.gameMeta);
          this.$emit('setGameGameMetaHands', [val, this.gameMeta, this.hands])
        }
      }
    },
    gameMeta: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          sessionStorage.gameMeta = JSON.stringify(val);
        }
      }
    },
    hands: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          sessionStorage.hands = JSON.stringify(val);
          if (val['S'] && Object.keys(val['S']).length === 13) {
            const l = Math.max(this.heartsSpadesCards.length, this.clubsDiamondsCards.length)
            document.documentElement.style.setProperty("--colNumber", l);
          }
        }
      }
    },
    chooseTrumpFlag(val) {
      if (val) {
        this.showChosenTrump = true;
        setTimeout(() => {
          this.showChosenTrump = false;
        }, CHOOSE_TRUMP_TIMEOUT);
      }
    },
    whoseTurn() {
      this.cardThrowLock = false
    },
    async gameOver() {
      if (this.gameOver === true) {
        this.isYazbozModalVisible = true;
        app.analytics().logEvent(`${this.gameType}-gameOver`);
        const registerGameResult = app.functions().httpsCallable(`${this.gameTypePrefix}RegisterGameResult`);
        try {
          await registerGameResult({'gameMeta': this.gameMeta, 'round': this.game.round});
        }
        catch(e) {
          console.warn(e)
        }
      }
    },
    round(newVal) {
      if (newVal === 2) {
        this.$emit('setFinger');
        app.analytics().logEvent(`${this.gameType}-R1`);
      } else if (newVal === 3) {
        app.analytics().logEvent(`${this.gameType}-R2`);
      } else if (newVal === 6) {
        app.analytics().logEvent(`${this.gameType}-R5`);
      } else if (newVal === 11) {
        app.analytics().logEvent(`${this.gameType}-R10`);
      } else if (newVal === 16) {
        app.analytics().logEvent(`${this.gameType}-R15`);
      }
    },
  }
}
</script>

<style scoped>
.trumps {
  display: flex;
  justify-content: center;
  align-items: center;
  
  padding: 0.1rem;
  background: var(--secondaryColor);
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  
  flex: 0 1 auto;
  z-index: 120;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  
  outline: inherit;
}

.triangles {
  display: flex;
  flex-direction: row;
  /*margin-bottom:-4px;*/
  font-size: min(15px, 3vw);
  
  outline: inherit;
}

.circles {
  display: flex;
  align-items: stretch;
  /*margin-top: -4px;*/
  font-size: min(15px, 3vw);
  
  outline: inherit;
}

.player-area-cards {
  display: grid;
  
  /*grid-template-columns: 15% 15% 15% 15% 15% 15%;*/
  /*grid-template-columns: repeat(auto-fill, var(--colSize));*/
  grid-template-columns: repeat(var(--colNumber), calc(90% / calc(var(--colNumber))));
  grid-template-rows: 50px 50px;
  column-gap: 0;
  
  padding-left: 2px;
  padding-right: 30px;
  
  flex: 1 0;
  justify-content: flex-end;
  justify-self: stretch;
  align-self: stretch;
  align-items: center;
  
  margin-top: 6px;
  
  outline: inherit;
  
}

.player-area-cards #heartsSpadesCards {
  grid-row-start: 1;
  
  justify-content: center;
  align-items: center;
  justify-self: flex-start;
  
  outline: inherit;
}

.player-area-cards #clubsDiamondsCards {
  grid-row-start: 2;
  
  justify-content: center;
  align-items: center;
  justify-self: flex-start;
  
  outline: inherit;
}


#room {
  /*margin-left: 20px;*/
  display: flex;
  
  flex: 1 0 100%;
  flex-direction: column;
  justify-content: space-between;
  
  outline: inherit;
  
  /*	position: relative;*/
}

#room #top-area {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  flex: 0 0 3.5rem;
  height: 3.5rem;
  z-index: 0;
  
  outline: inherit;
}

#room #middle-area {
  display: flex;
  flex-direction: row;
  flex: 0 0 max(40vh, 320px);
  
  justify-content: center;
  align-items: stretch;

  outline: inherit;
}

#koz-ceza-panel, #table-panel {
  z-index: 300;
  height: max(360px, 45vh);
}

#room #bottom-name-area {
  display: flex;
  flex: 0 0 3.5rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;


  z-index: 100;
  
  outline: inherit;
}

#room #bottom-area {
  display: flex;
  flex: 1 0 200px;

  flex-direction: column;
  justify-content: center;
  align-items: center;


  /*height: calc(50% - 90px);*/
  /*margin-right: 4%;*/
  /*margin-left: 4%;*/
  
  z-index: 130;
  /*height: 100px;*/
  
  /*overflow: hidden;*/
  
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  
  outline: inherit;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#bottom-area::-webkit-scrollbar {
  display: none;
}

/*.showChosenTrump-enter-active, .showChosenTrump-leave-active {*/
/*  opacity: 0;*/
/*}*/
/*.showChosenTrump-enter, .showChosenTrump-leave-to {*/
/*  transition: opacity 2s;*/
/*}*/


</style>
