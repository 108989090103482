export const namePool = [
    "Sertaç🤖",
    "Çağla🤖",
    "Çağlar🤖",
    "Sercan🤖",
    "Burcu🤖",
    "Özcan🤖",
    "Can🤖",
    "Özgün🤖",
    "Kıvılcım🤖",
    "Sezgi🤖",
    "Sabri🤖",
    "Türker🤖",
    "Yusuf🤖",
    "Ayla🤖",
    "Duygu🤖",
    "Ediz🤖",
    "Ergin🤖",
    "Fatih🤖",
    "Şiraz🤖",
    "Hikmet🤖",
]
