<template>
  <div id="app">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      loader="dots"
      color="#ffc107"
      :height=100
      :width=100
      style="z-index: 900"
    />
    <modal-cookie
      style="z-index: 1100"
      v-show="!cookieAccepted"
      @close="cookieAccepted=true"
    />
    <King
      v-if="this.gameCategory === 'king' && this.user"
      :user="user"
      :userMeta="userMetaKing"
      :displayName="displayName"
      :appURL="appURL"
      :touchDevice="touchDevice"
      :email="email"
      :emailVerified="emailVerified"
      :authProvider="authProvider"
      
      @setDisplayName="setDisplayName"
    ></King>
    <Batak
      v-if="this.gameCategory === 'batak' && this.user"
      :user="user"
      :userMeta="userMetaBatak"
      :displayName="displayName"
      :appURL="appURL"
      :touchDevice="touchDevice"
      :email="email"
      :emailVerified="emailVerified"
      :authProvider="authProvider"

      @setDisplayName="setDisplayName"
    ></Batak>
    <Ihale
      v-if="this.gameCategory === 'ihale' && this.user"
      :user="user"
      :userMeta="userMetaIhale"
      :displayName="displayName"
      :appURL="appURL"
      :touchDevice="touchDevice"
      :email="email"
      :emailVerified="emailVerified"
      :authProvider="authProvider"

      @setDisplayName="setDisplayName"
    ></Ihale>
  </div>
</template>

<script>

import app from '@/firebase.setup'

import { appCopy } from "@/js/appCopy"

import ModalCookie from '@/components/ModalCookie.vue';
// import DevComponent from '@/components/DevComponent'
import King from '@/components/games/king/King'
import Ihale from '@/components/games/ihale/Ihale'
import Batak from '@/components/games/batak/Batak'
import Loading from "vue-loading-overlay";

import 'firebase/database';
import 'firebase/auth';
import 'firebase/functions';
import {parseUrlReturnGameCategory} from "@/js/app";
// import uuid from "uuid";

export default {
  name: 'App',
  components: {
    Loading,
    King,
    Batak,
    Ihale,
    ModalCookie,
  },
  data: () => ({
    gameCategory: null,
    
    user: null,
    userMeta: null,
    displayName: null,
    
    cookieAccepted: false,
    touchDevice: false,
    authProvider: null,
    authUser: null,
    emailVerified: false,
    email: null,
    
    isLoading: false,
    
    vh: 0,
    appURL: process.env.VUE_APP_URL,
    environment: process.env.VUE_APP_ENV,
    gameCategories: ['king', 'batak', 'ihale']
  }),
  methods: {
    alert(alertCopy) {
      this.$alert(alertCopy)
    },
    setDisplayName(val) {
      this.displayName = val
    },
    async getUsersInGame(gameId, gameType) {
      const usersInGameSnap = await app.database().ref(`games/${gameType}/${gameId}/usersInGame`).once('value')
      if (usersInGameSnap.val()) {
        return usersInGameSnap.val()
      } else {
        return null
      }
    },
    async getGameType(gameId) {
      const gameMetaRef = await app.database().ref(`gamesMeta/${gameId}`).once('value')
      const gameMeta = gameMetaRef.val();
      if (gameMeta && gameMeta.owner && gameMeta.gameType) {
        return gameMeta.gameType
      } else {
        return null
      }
    },
    async getGameCategory(gameId) {
      const gameMetaRef = await app.database().ref(`gamesMeta/${gameId}`).once('value')
      const gameMeta = gameMetaRef.val();
      if (gameMeta && gameMeta.owner && gameMeta.gameCategory) {
        return gameMeta.gameCategory
      } else {
        return null
      }
    },
    is_touch_device3() {
      return !!('ontouchstart' in window        // works on most browsers
        || navigator.maxTouchPoints);       // works on IE10/11 and Surface
    },
    async asyncAuthHandler(curUser) {
      if (!curUser) {
        await app.auth().signInAnonymously().catch((error) => {
          // Handle Errors here.
          let errorCode = error.code;
          let errorMessage = error.message;
          console.error("Error: " + errorCode + errorMessage)
        });
      }
      else {
        // 1- Auth flow is done first. If not signed in earlier, sign in anon as above.
        this.authUser = curUser;
        if (curUser.isAnonymous) {
          this.authProvider = 'anonymous';
          this.emailVerified = false;
        } else {
          this.authProvider = curUser.providerData[0].providerId;
          this.emailVerified = curUser.emailVerified;
          this.email = curUser.email;
        }

        // 2- set listeners on user
        const userSnap = await app.database()
          .ref(`users/${curUser.uid}`)
          .once('value')
        this.user = userSnap.val()

        if(!this.gameCategory && this.user && this.user.gameId) {
          this.gameCategory = this.user.gameCategory
        }

        app.database()
          .ref(`users/${curUser.uid}`)
          .on("value", snapshot => {
            this.user = snapshot.val()
          });

        app.database()
          .ref(`usersMeta/${curUser.uid}`)
          .on('value', snapshot => {
            this.userMeta = snapshot.val()
          })

        // 3- assign displayName and register the user
        if (this.user && this.user.name) {
          this.displayName = this.user.name;
        } else if (this.authUser.displayName) {
          this.displayName = this.authUser.displayName.trim().split(/\s+/)[0];
          await app.database()
            .ref(`users/${curUser.uid}`)
            .set({'name': this.displayName, 'id': curUser.uid});
        } else {
          this.displayName = this.appCopy.defaultName;
          this.user = {'name': this.displayName, 'id': curUser.uid};
          await app.database()
            .ref(`users/${curUser.uid}`)
            .set(this.user);
        }
      }

      // When there is gameid added to uri
      let separator = this.appURL
      if(separator[separator.length - 1] !== '/'){
        separator = separator + '/'
      }
      let uri = window.location.href.split(separator);
      if (uri[uri.length - 1] !== ''){
        const gameId = uri[uri.length - 1];
        if (
          !gameId.includes('#')
          && !gameId.includes('/')
          && !gameId.includes('.')
          && !gameId.includes('$')
          && !gameId.includes('[')
          && !gameId.includes(']')
        ) {
          const gameType = await this.getGameType(gameId)
          if (gameType) {
            const usersInGame = await this.getUsersInGame(gameId, gameType)
            let usersInGameIds;
            if (usersInGame) {
              usersInGameIds = Object.keys(usersInGame).map(x => usersInGame[x].id);
            } else {
              usersInGameIds = [];
            }
            if (Object.keys(usersInGame).length < 4 || usersInGameIds.includes(this.user.id)) {
              this.gameCategory = await this.getGameCategory(gameId)

              if (this.user && this.user.gameId && this.user.gameId !== gameId) {
                this.$confirm(appCopy.App.quitWarning).then(async () => {
                  this.user = {
                    'id': curUser.uid,
                    'gameId': gameId,
                    'gameType': gameType,
                    'name': this.user.name,
                    'gameCategory': this.gameCategory
                  }
                  await app.database()
                    .ref(`users/${this.user.id}`)
                    .set(this.user)
                  window.location.href = this.appURL;
                }).catch(() => {
                });
              } else {
                this.user = {
                  'id': curUser.uid,
                  'gameId': gameId,
                  'gameType': gameType,
                  'name': this.user.name,
                  'gameCategory': this.gameCategory
                }
                await app.database()
                  .ref(`users/${this.user.id}`)
                  .set(this.user)
              }
            }
          }
          window.history.replaceState({}, document.title, "/app");
        }
      }


      // if gameCategory is still not populated, direct to landing page
      if(!this.gameCategory) {
        window.location.href =  process.env.VUE_APP_LANDING;
      }

      return 'all good'
    }
  },
  computed: {
    userMetaKing() {
      if(!this.userMeta){
        return null
      }
      else {
        return this.userMeta['kingRated']
      }
    },
    userMetaBatak() {
      if(!this.userMeta){
        return null
      }
      else {
        return this.userMeta['batakRated']
      }
    },
    userMetaIhale() {
      if (!this.userMeta) {
        return null
      } else {
        return this.userMeta['ihaleRated']
      }
    }
  },
  watch: {
    user(val) {
      if (val && val.name) {
        this.displayName = val.name;
      }
    }
  },
  async created() {
    this.isLoading = true;
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    this.appCopy = appCopy.App;
    this.userProfileCopy = appCopy.ModalUserProfile;
    
    this.displayName = this.appCopy.defaultName;
    
    if (this.$cookies.get('cookieFlag')) {
      this.cookieAccepted = true
    }

    // parse url for gameCategory
    let uri = window.location.href.split('?');
    this.gameCategory =  parseUrlReturnGameCategory(uri, this.gameCategories)

    app.auth().onAuthStateChanged(this.asyncAuthHandler);
    this.touchDevice = this.is_touch_device3();
    this.isLoading = false;

    // const game = {"id":"e274c","round":1,"dealer":"S","usersInGame":{"S":{"name":"Sertaç","id":"0oqMumCnbdUKTpIKQ4eI3Ln7gYm1","gameCategory":"ihale"},"W":{"id":"_bot48538Şiraz🤖","gameId":"e274c","gameCategory":"ihale","gameType":"ihaleRated","name":"_bot48538Şiraz🤖","seat":"W"},"N":{"id":"_bot48538Sercan🤖","gameId":"e274c","gameCategory":"ihale","gameType":"ihaleRated","name":"_bot48538Sercan🤖","seat":"N"},"E":{"id":"_bot48538Ediz🤖","gameId":"e274c","gameCategory":"ihale","gameType":"ihaleRated","name":"_bot48538Ediz🤖","seat":"E"}},"table":{"cards":{},"koz":"hearts","whoseTurn":"N","kozRevealed":true,"tableRound":12},"contracts":{"S":10,"W":0,"N":0,"E":0},"history":{"lastCards":{"S":"111","W":"107","N":"405","E":"413"},"playedCards":{"102":7,"103":7,"105":10,"106":7,"107":11,"108":10,"110":6,"111":11,"112":10,"114":7,"202":8,"203":5,"204":5,"205":10,"206":8,"207":9,"208":9,"209":8,"210":5,"211":5,"212":9,"213":8,"214":9,"302":3,"303":6,"304":3,"305":4,"306":4,"308":3,"309":4,"311":6,"312":6,"313":4,"314":3,"402":1,"403":2,"404":2,"405":11,"406":1,"407":1,"409":2,"410":2,"413":11,"414":1}}}
    // const gameMeta = {"id":"e274c","gameType":"ihaleRated","gameCategory":"ihale","createTs":{"0":{"round":2,"start":"20211214100411","end":"20211214162323","scores":{"S":{"total":-5,"round":0,"totalKoz":0,"totalCeza":5},"W":{"total":5,"round":0,"totalKoz":5,"totalCeza":0},"N":{"total":1,"round":0,"totalKoz":1,"totalCeza":0},"E":{"total":4,"round":0,"totalKoz":4,"totalCeza":0}}},"1":{"start":"20211214162323","round":2,"end":"20211214162609","scores":{"S":{"total":-13,"round":1,"totalKoz":0,"totalCeza":13},"W":{"total":5,"round":6,"totalKoz":5,"totalCeza":0},"N":{"total":5,"round":1,"totalKoz":5,"totalCeza":0},"E":{"total":2,"round":2,"totalKoz":2,"totalCeza":0}}},"2":{"start":"20211214162609","round":0}},"creator":"Sertaç","owner":{"gameCategory":"ihale","gameId":"f5a2c","gameType":"ihaleRated","id":"0oqMumCnbdUKTpIKQ4eI3Ln7gYm1","name":"Sertaç","seat":"S"},"restartCounter":2,"scores":{"S":{"total":0,"round":4,"totalKoz":0,"totalCeza":0},"W":{"total":0,"round":2,"totalKoz":0,"totalCeza":0},"N":{"total":0,"round":0,"totalKoz":0,"totalCeza":0},"E":{"total":0,"round":5,"totalKoz":0,"totalCeza":0}},"yazboz":{}}
    // const hands= {"S":{"106":"106","114":"114"},"E":{"109":"109"},"N":{"110":"110"},"W":{"108":"108"}}
    //
    // sessionStorage.setItem('game', JSON.stringify(game))
    // sessionStorage.setItem('gameMeta', JSON.stringify(gameMeta))
    // sessionStorage.setItem('hands', JSON.stringify(hands))

  }
}
</script>

<style lang="scss">

:root {
  --colSize: 15%;
  --colNumber: 13;
  --mainColor: #24da7e;
  --secondaryColor: #aaffc7;
  --tertiaryColor: navy;
  --fourthColor: #ffc145;
  --fifthColor: darkorange;
  --sixthColor: #ff3a00;
  --overlay: rgba(255, 0, 0, 0.3);
  
  --leader1: var(--tertiaryColor);
  --leader2: var(--secondaryColor);
  --leader3: var(--fourthColor);
  --leader4: var(--tertiaryColor);
}



#header-inner {
  display: flex;
  flex: 1 1 auto;
  height: 55px;
  
  justify-content: stretch;
  outline: inherit;
}

#header-inner .itemcenter {
  display: flex;
  flex-direction: column;
  flex: 1 1 40%;
  
  justify-content: center;
  align-items: center;
  
  outline: inherit;
}

#header-inner .itemleft {
  display: flex;
  flex-direction: column;
  flex: 1 1 30%;
  
  justify-content: center;
  align-content: center;
  align-items: flex-start;
  
  /*padding-left: 0%;*/
  
  outline: inherit;
}

#header-inner .itemright {
  display: flex;
  flex-direction: row;
  flex: 1 1 30%;
  
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  
  /*padding-right: 1%;*/
  
  outline: inherit;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#app::-webkit-scrollbar {
  display: none;
}

#app {
  display: flex;
  
  background-image: radial-gradient(var(--secondaryColor));
  background-repeat: no-repeat;
  
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-self: center;
  
  flex: 1 1;
  
  /*width: 100%;*/
  
  height: 100%;
  margin: -8px !important;
  
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  /*height: 100vh;*/
  
  text-align: center;
  color: var(--tertiaryColor);
  
  overflow: hidden;
  
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  
}



body {
  padding-right: 0 !important;
}

/*work around for shifting modal screen with alert check https://github.com/sweetalert2/sweetalert2/issues/678*/
html {
  overflow-y: auto;
}


#body {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  
  flex: 1 1 auto;
  height: calc(100% - 55px);
  
  position: relative;
  
  margin: 55px 0;
  
  outline: inherit;
}


.btn-loginout {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background-color: var(--tertiaryColor);
  color: var(--mainColor);
  width: 60px;
  height: 40px;
  border-radius: 5px;
  /*box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);*/
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  
  outline: inherit;
}

.authUser {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 5px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  width: 10px;
  /*height: 40px;*/
  /*min-height: 25px;*/
  
  background-color: var(--mainColor);
  /*height: 40px;*/
  border-radius: 20px;
  line-height: 36px;
  text-align: center;
  color: var(--tertiaryColor);
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 700;
  /*padding: 0 34px;*/
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, .3);
  
  outline: inherit;
}

.baseShadow {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

.playing-card {
  display: flex;
  flex: 1 1 content;
  
  /*min-width: 50px;*/
  max-width: 100px;
  background-size: auto;
  overflow: hidden;
  
  z-index: 100;
  
  height: auto;
  /*max-height: 100%;*/
  margin: 0.2vh;
  /*border: 0px solid black;*/
  /*border-radius: 5px;*/
  /*box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.6);*/
  transition-property: transform, box-shadow;
  transition-duration: 0.8s;
  transition-timing-function: ease-out;
  
  outline: inherit;
}

p, h3, h4 {
  font-size: 1rem;
  color: var(--tertiaryColor);
  text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3);
  
  outline: inherit;
}


.bm-burger-button {
  
  position: fixed;
  width: 36px;
  height: 30px;
  left: 30px;
  top: 32px;
  cursor: pointer;
  
  outline: inherit;
}

.bm-item-list {
  color: var(--mainColor);
  margin-left: 10%;
  font-size: 20px;
  font-weight: 700;
  
  outline: inherit;
}

.bm-item-list > * {
  display: flex;
  text-decoration: none;
  padding: 0.7em;
}

.bm-item-list > * > span {
  margin-left: 10px;
  font-weight: 700;
  color: white;
}

.fade-enter-active, .fade-leave-active {
  transition: all 2s;
}

.fade-enter, .fade-leave, .fade-leave-to {
  opacity: 0;
}

.card-appear-enter-active, .card-appear-leave-active {
  transition: all .5s cubic-bezier(.16, .11, .29, .96);
}

.card-appear-leave-to {
  opacity: 0;
  /*transform: translateY(-1000px);*/
}

.card-appear-enter {
  transform: translateY(-1000px) rotate(200deg);
}

.card-appear-move {
  transition: transform .3s;
}

.pointer {
  cursor: pointer;
}

.player-area {
  
  border: 2px solid rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  /*	overflow: hidden;*/
  
  z-index: 90;
  
  outline: inherit;
}

.text {
  color: var(--tertiaryColor);
  /*text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3);*/
  
  z-index: 100;
  
  outline: inherit;
}

.nameTurnStaticRed {
  background-color: var(--sixthColor);
  border-radius: 5px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  padding-right: 5px;
  padding-left: 5px;
}

.nameTurn {
  background-color: var(--mainColor);
  animation: image 3s infinite alternate;
  border-radius: 5px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  padding-right: 5px;
  padding-left: 5px;
}

@keyframes image {
  0% {
    background-color: var(--mainColor);
  }
  50% {
    background-color: var(--mainColor);
  }
  75% {
    
    background-color: var(--mainColor);
  }
  100% {
    background-color: var(--sixthColor);
  }
}

.playername {
  display: flex;
  justify-content: center;
  align-items: center;

  flex: 0 1 auto;
  /*width: 100px;*/
  /*height: 100px;*/

  z-index: 110;
  /*position: relative;*/

  margin: 5px;
  font-size: calc(min(25px, 5vw));

  padding-right: 5px;
  padding-left: 5px;

  cursor: default;

  outline: inherit;
}

.points {
  /*border: 2px darkblue solid;*/
  font-size: 2rem;
  color: var(--tertiaryColor);
  /*text-shadow: 1px 1px 8px rgba(0, 0, 0, 1);*/
  cursor: default;
  outline: inherit;
}

.player-points {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  width: 2.5rem;
  height: 2.5rem;
  padding: 0.3rem;
  background: var(--mainColor);
  border-radius: 50%;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  /*position: absolute;*/
  z-index: 120;
  /*left: -30px;*/
  text-align: center;
  flex: 0 1 auto;
  /*align-self: flex-start;*/
  /*margin: 3px;*/
  /*border: 3px hidden red;*/

  outline: inherit;
}

.turn-token {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  min-width: 20px;
  min-height: 20px;
  padding: 8px;
  background: var(--secondaryColor);
  border-radius: 50%;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  /*position: absolute;*/
  z-index: 120;
  /*left: -30px;*/
  text-align: center;
  flex: 0 1 auto;
  /*align-self: flex-start;*/

  border: 3px ridge red;

  outline: inherit;
}


.animated {
  display: inline-block;
}

.animated.v-enter {
  animation: fadein .5s;
}

.animated.v-leave {
  animation: fadeout .5s;
}

@keyframes fadein {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeout {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(0);
  }
}

.hover {
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
}


.hover:hover {
  /*transform: scale(1.1);*/
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 1);
  z-index: 300;
  cursor: pointer;
}

.outline {
  outline: 1px solid blue;
  border: 1px solid blue;
}

.overlay {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  left: 50%;
  background: var(--overlay);
  transform: skew(135deg);
  
  outline: inherit;
}

.overlay2 {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  left: 0;
  background: var(--overlay);
  transform: skew(0deg);
  
  outline: inherit;
}

.cancel {
    font-size: 12px;
    text-align: center;
    //margin-top: 1em;
    
    span {
      cursor: pointer;
      
      &:hover {
        text-decoration: underline;
      }
    }
    
    outline: inherit
  }

.rifki {
  background: transparent url('./assets/rifki.png') no-repeat center;
  background-size: 90%;
}

.erkekalmaz {
  background: transparent url('./assets/erkekalmaz.png') no-repeat center;
  background-size: 90%;
}

.kizalmaz {
  background: transparent url('./assets/kizalmaz.png') no-repeat center;
  background-size: 90%;
}

.kupaalmaz {
  background: transparent url('./assets/kupaalmaz.png') no-repeat center;
  background-size: 90%;
}

.kupaalmaz2 {
  background: transparent url('./assets/kupaalmaz2.png') no-repeat center;
  background-size: 90%;
}

.soniki {
  background: transparent url('./assets/soniki.png') no-repeat center;
  background-size: 90%;
}

.elalmaz {
  background: transparent url('./assets/elalmaz.png') no-repeat center;
  background-size: 90%;
}

.ceza-button {
  display: flex;
  flex: 0 1 auto;
  justify-content: space-evenly;

  /*align-items: stretch;*/
  /*padding-bottom: 15vw;*/
  /*height: 15vw;*/
  width: 15vw;
  height: 15vw;

  /*border: 1px black solid;*/
  border-radius: 50%;

  max-width: 90px;
  max-height: 90px;

  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.5);

  cursor: pointer;

  margin: 2px;
  overflow: hidden;

  /*clip-path: polygon(0 0, 0 100px, 100px 80px, 100px 0);*/
  position: relative;
  outline: inherit;
}

.ceza-button-inner {
  display: flex;
  height: 100%;
  width: 100%;

  border-radius: 50%;
  outline: inherit;
}
.koz-button {
  height: 13vw;
  width: 13vw;

  border-radius: 5px;

  max-width: 100px;
  max-height: 100px;

  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.5);

  cursor: pointer;
  /*color: white;*/
  /*font-weight: bold;*/
  /*background-color: #bdbcbc;*/
}

.spades_button {
  background: transparent url('./assets/spades.png') no-repeat center;
  background-size: 80%;
}

.hearts_button {
  background: transparent url('./assets/hearts.png') no-repeat center;
  background-size: 80%;
}

.clubs_button {
  background: transparent url('./assets/clubs.png') no-repeat center;
  background-size: 80%;
}

.diamonds_button {
  background: transparent url('./assets/diamond.png') no-repeat center;
  background-size: 80%;
}

.btn-yazboz {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background-color: var(--tertiaryColor);
  color: var(--mainColor);
  width: 46px;
  height: 45px;
  margin-right: 5px;
  border-radius: 5px;
  /*box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);*/
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.8);
}

.btn-info {
  display: flex;
  cursor: default;
  justify-content: center;
  align-items: center;
  /*background-color: var(--tertiaryColor);*/
  background-color: var(--mainColor);
  width: 46px;
  height: 45px;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 5px;
  /*box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);*/
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.8);
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease
}

.modal-backdrop {
  position: fixed;
  z-index: 350;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;

  border-radius: 2px;
}


.ribbon-wrapper-green {
  width: 85px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: -4px;
  right: -4px;
}

.ribbon-green {
  font: bold 15px Sans-Serif;
  color: #333;
  text-align: center;
  text-shadow: rgba(255,255,255,0.5) 0px 0px 0px;
  -webkit-transform: rotate(55deg);
  -moz-transform:    rotate(55deg);
  -ms-transform:     rotate(55deg);
  -o-transform:      rotate(55deg);
  position: relative;
  padding: 2px 1px;
  left: 4px;
  top: 15px;
  width: 120px;
  background-color: var(--mainColor);
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--secondaryColor)), to(var(--mainColor)));
  background-image: -webkit-linear-gradient(top, var(--secondaryColor), var(--mainColor));
  background-image:    -moz-linear-gradient(top, var(--secondaryColor), var(--mainColor));
  background-image:     -ms-linear-gradient(top, var(--secondaryColor), var(--mainColor));
  background-image:      -o-linear-gradient(top, var(--secondaryColor), var(--mainColor));
  color: var(--tertiaryColor);
  -webkit-box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
  -moz-box-shadow:    0px 0px 3px rgba(0,0,0,0.3);
  box-shadow:         0px 0px 3px rgba(0,0,0,0.3);
}

.ribbon-wrapper-red {
  width: 85px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: -4px;
  right: -4px;
}

.ribbon-red {
  font: bold 15px Sans-Serif;
  text-align: center;
  text-shadow: rgba(255,255,255,0.5) 0px 0px 0px;
  -webkit-transform: rotate(55deg);
  -moz-transform:    rotate(55deg);
  -ms-transform:     rotate(55deg);
  -o-transform:      rotate(55deg);
  position: relative;
  padding: 2px 1px;
  left: 4px;
  top: 15px;
  width: 120px;
  background-color: var(--sixthColor);
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--secondaryColor)), to(var(--mainColor)));
  background-image: -webkit-linear-gradient(top, var(--secondaryColor), var(--mainColor));
  background-image:    -moz-linear-gradient(top, var(--secondaryColor), var(--mainColor));
  background-image:     -ms-linear-gradient(top, var(--secondaryColor), var(--mainColor));
  background-image:      -o-linear-gradient(top, var(--secondaryColor), var(--mainColor));
  color: var(--tertiaryColor);
  -webkit-box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
  -moz-box-shadow:    0px 0px 3px rgba(0,0,0,0.3);
  box-shadow:         0px 0px 3px rgba(0,0,0,0.3);
}

.ribbon-red:before, .ribbon-red:after {
  content: "";
  border-top:   3px solid var(--tertiaryColor);
  border-left:  3px solid transparent;
  border-right: 3px solid transparent;
  position:absolute;
  bottom: -3px;
}

.ribbon-red:before {
  left: 0;
}
.ribbon-red:after {
  right: 0;
}

.flash-message {
  display: flex;
  align-items: center;
  justify-items: center;

  flex: 1 0 100%;

  padding: 20px;
  margin-top: 5px;
  background-color: var(--sixthColor);
  color: white;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.8);
  border-radius: 5px;

  //width: calc(min(600px, 100vw));

  z-index: 900;
}

//.swal2-container {
//  z-index: 10000;
//}

</style>
