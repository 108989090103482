<template>
  <div id="container">
    <div class="Header">
      <h1 class="authUser" style="width:auto; align-self: center; font-size: 18px; border: 2px solid #ff0000;" @click="openUserProfile">
        {{ userL.name }} </h1>
      <div style="display:flex; flex-direction: row; justify-content: center; margin-bottom: 15px;">
        <div @click="openUserProfile" class="pointer" style="display:flex; flex: 1 0; flex-direction: row; justify-content: flex-end;">
          <div class="scoreCard baseShadow">
            <div class="Header__stat">
              <h1 style="margin-bottom: -25px"> 🏅 </h1>
              <h2> {{ userMetaL.scoreAllTime.points }}</h2>
              <h4> {{ compCopy.koRating }} </h4>
            </div>
          </div>
        </div>
        <div @click="openUserProfile" class="pointer" style="display: flex; flex: 1  0; justify-content: center;">
          <UserBatakProgress
            :userMeta="userMeta"
            :diameter="diameter"
          >
          </UserBatakProgress>
        </div>
        <div @click="openUserProfile" class="pointer" style="display:flex; flex: 1  0; flex-direction: row; justify-content: flex-start;">
          <div class="scoreCard baseShadow">
            <div class="Header__stat">
              <h3> 👟 {{ userMetaL.scoreAllTime.played }} </h3>
              <h4>{{ compCopy.played }} </h4>
            </div>
            <div class="Header__stat">
              <h3> 💪 {{ userMetaL.scoreAllTime.wins }} </h3>
              <h4> {{ compCopy.wins }} </h4>
            </div>
            <div class="Header__stat" style="margin-bottom: -10px">
              <h3> 🔥 {{ userMetaL.scoreAllTime.streak }}/{{ userMetaL.scoreAllTime.maxStreak }} </h3>
              <h4> {{ compCopy.streakGeneral }} </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="pad2">
    <div class="Header">
      <h1 class="leaderBoardTitleMain" @click="openCompetitionInfo">
        <div> {{ this.selectedLeaderboardTitle }}</div>
      </h1>
      <i class="fa-duotone fa-medal fa-5x"
         @click="openCompetitionInfo"
         style="justify-content: center; align-self: center; margin-bottom: 5px; cursor: pointer"/>
      <div style="display: flex; justify-content: center; margin-bottom: 5px;">
        <toggle-button
          @change="switchLeaderBoardTimeWindow"
          :width=100
          :height=40
          :font-size=18
          :color="{checked: 'var(--mainColor)', unchecked: 'var(--fifthColor)', disabled: '#CCCCCC'}"
          :value="true"
          :labels="{checked: 'Sezon', unchecked: 'Genel'}"
        />
      </div>
      
      <div style="display: flex; flex-direction: column; justify-content: space-between; align-items: stretch; margin-bottom: 5px"
        >
        <div style="display: flex; flex-direction: row; justify-content: center">
          <div class="leaderBoardTitle" :class="this.leaderboardSwitch === 'topXKO' ? 'leaderBoardTitleChosen' : ''" @click="chooseLeaderboard('topXKO')">
            {{this.leaderboardCategories['topXKO']['name1']}} {{this.leaderboardCategories['topXKO']['name2']}}
          </div>
          <div class="leaderBoardTitle" :class="this.leaderboardSwitch === 'topXWinRate' ? 'leaderBoardTitleChosen' : ''" @click="chooseLeaderboard('topXWinRate')">
            {{this.leaderboardCategories['topXWinRate']['name1']}} {{this.leaderboardCategories['topXWinRate']['name2']}}
          </div>
        </div>
        <div style="display: flex; flex-direction: row;justify-content: center">
          <div class="leaderBoardTitle" :class="this.leaderboardSwitch === 'topXMaxStreak' ? 'leaderBoardTitleChosen' : ''" @click="chooseLeaderboard('topXMaxStreak')">
            {{this.leaderboardCategories['topXMaxStreak']['name1']}} {{this.leaderboardCategories['topXMaxStreak']['name2']}}
          </div>
          <div class="leaderBoardTitle" :class="this.leaderboardSwitch === 'topXBestTime' ? 'leaderBoardTitleChosen' : ''" @click="chooseLeaderboard('topXBestTime')">
            {{this.leaderboardCategories['topXBestTime']['name1']}} {{this.leaderboardCategories['topXBestTime']['name2']}}
          </div>
        </div>
        <div style="display: flex; flex-direction: row;justify-content: center">
          <div class="leaderBoardTitle" :class="this.leaderboardSwitch === 'topXMaxScore' ? 'leaderBoardTitleChosen' : ''" @click="chooseLeaderboard('topXMaxScore')">
            {{this.leaderboardCategories['topXMaxScore']['name1']}} {{this.leaderboardCategories['topXMaxScore']['name2']}}
          </div>
          <div class="leaderBoardTitle" :class="this.leaderboardSwitch === 'topXScorePerPlayed' ? 'leaderBoardTitleChosen' : ''" @click="chooseLeaderboard('topXScorePerPlayed')">
            {{this.leaderboardCategories['topXScorePerPlayed']['name1']}} {{this.leaderboardCategories['topXScorePerPlayed']['name2']}}
          </div>
        </div>
<!--        <div style="display: flex; flex-direction: row;justify-content: center">-->
<!--          <div class="leaderBoardTitle" :class="this.leaderboardSwitch === 'topXMaxPositives' ? 'leaderBoardTitleChosen' : ''" @click="chooseLeaderboard('topXMaxPositives')">-->
<!--            {{this.leaderboardCategories['topXMaxPositives']['name1']}} {{this.leaderboardCategories['topXMaxPositives']['name2']}}-->
<!--          </div>-->
<!--          <div class="leaderBoardTitle" :class="this.leaderboardSwitch === 'topXMinNegatives' ? 'leaderBoardTitleChosen' : ''" @click="chooseLeaderboard('topXMinNegatives')">-->
<!--            {{this.leaderboardCategories['topXMinNegatives']['name1']}} {{this.leaderboardCategories['topXMinNegatives']['name2']}}-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <div style="margin: 10px; display: flex; flex-direction: row; align-items: center">
        <i class="fa-duotone fa-info-circle fa-2x"
           style="outline: inherit"
        />
        <div style="text-align: left; margin-left: 5px">
          {{ this.compCopy.leaderboardEnterCriteria }}
        </div>
      </div>
    </div>
    <div @click="openCompetitionInfo" class="pointer">
      <transition :duration="{ enter: 500, leave: 500 }"
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="animate__animated animate__fadeOut"
                mode="out-in"
                >
        <competitors
          style="margin-bottom: 10px"
          v-show="showFlag"
          :list="selectedLeaderboard"
          id="topXMonthly">
        </competitors>
      </transition>
    </div>
  </div>
</template>

<script>
// import app from '@/firebase.setup'
import Competitors from "@/components/leaderboard/Competitors"
import UserBatakProgress from "@/components/games/batak/leaderboard/UserBatakProgress"
import app from '@/firebase.setup'
import { batakCopy } from "@/js/batak/batakCopy";
import { initScores } from "@/js/ratedInits"
import { formatTimeInSecToMinSec } from "@/js/utils"

Date.prototype.addHours = function(h) {
  this.setTime(this.getTime() + (h*60*60*1000));
  return this;
}

export default {
  name: 'Leaderboard',
  components: {
    Competitors,
    UserBatakProgress
  },
  data: () => ({
    usersMeta: null,
    seasonName: null,
    gameType: 'batakRated',
    
    leaderboardTimeWindow: 'monthly',
    
    leaderboardSwitch: 'topXKO',
    leaderboardCategories: null,
    
    leaderboards: [
      'topXKO', 'topXWinRate', 'topXMaxStreak', 'topXBestTime',
      'topXMaxScore', 'topXScorePerPlayed'],
    
    leadKO: null,
    leadBestTime: null,
    leadWinRate: null,
    leadMaxStreak: null,
    leadMaxScore: null,
    leadScorePerPlayed: null,
    leadMaxPositives: null,
    leadMinNegatives: null,
    
    
    leadKOMonthly: null,
    leadBestTimeMonthly: null,
    leadWinRateMonthly: null,
    leadMaxStreakMonthly: null,
    leadMaxScoreMonthly: null,
    leadScorePerPlayedMonthly: null,
    leadMaxPositivesMonthly: null,
    leadMinNegativesMonthly: null,
    
    numTopPlayers: 5,
    leaderboardCycleCounter: 0,
    showFlag: true,
    pauseFlag: false,
    
    t1: null,
    t2: null,
  }),
  props: ['user', 'userMeta', 'authProvider'],
  created() {
    this.compCopy = batakCopy.Leaderboard;
    const monthsCopy = batakCopy.months;
    
    let options = {
      timeZone: 'Europe/Istanbul',
      month: 'numeric'
    },
    formatter = new Intl.DateTimeFormat([], options);
    const n = formatter.format(new Date());
    this.seasonName = monthsCopy[n] + ' ' + this.compCopy.season
    
    this.leaderboardCategories = this.compCopy.leaderboardCategories;
    
    let refLeaderboard = app.database().ref(`/usersMeta`)
    refLeaderboard
      .orderByChild(`${this.gameType}/scoreAllTime/points`)
      .limitToLast(this.numTopPlayers)
      .on("value", snapshot => {
        this.leadKO = snapshot.val()
      });
    refLeaderboard
      .orderByChild(`${this.gameType}/scoreMonth/points`)
      .limitToLast(this.numTopPlayers)
      .on("value", snapshot => {
        this.leadKOMonthly = snapshot.val()
      });
    
    refLeaderboard
      .orderByChild(`${this.gameType}/scoreAllTime/bestTime`)
      .limitToFirst(this.numTopPlayers)
      .on("value", snapshot => {
        this.leadBestTime = snapshot.val()
      });
    refLeaderboard
      .orderByChild(`${this.gameType}/scoreMonth/bestTime`)
      .limitToFirst(this.numTopPlayers)
      .on("value", snapshot => {
        this.leadBestTimeMonthly = snapshot.val()
      });
    
    refLeaderboard
      .orderByChild(`${this.gameType}/scoreAllTime/winRate`)
      .limitToLast(this.numTopPlayers)
      .on("value", snapshot => {
        this.leadWinRate = snapshot.val()
      });
    refLeaderboard
      .orderByChild(`${this.gameType}/scoreMonth/winRate`)
      .limitToLast(this.numTopPlayers)
      .on("value", snapshot => {
        this.leadWinRateMonthly = snapshot.val()
      });
    
    refLeaderboard
      .orderByChild(`${this.gameType}/scoreAllTime/maxStreak`)
      .limitToLast(this.numTopPlayers)
      .on("value", snapshot => {
        this.leadMaxStreak = snapshot.val()
      });
    refLeaderboard
      .orderByChild(`${this.gameType}/scoreMonth/maxStreak`)
      .limitToLast(this.numTopPlayers)
      .on("value", snapshot => {
        this.leadMaxStreakMonthly = snapshot.val()
      });
    
    refLeaderboard
      .orderByChild(`${this.gameType}/scoreAllTime/maxScore`)
      .limitToLast(this.numTopPlayers)
      .on("value", snapshot => {
        this.leadMaxScore = snapshot.val()
      });
    refLeaderboard
      .orderByChild(`${this.gameType}/scoreMonth/maxScore`)
      .limitToLast(this.numTopPlayers)
      .on("value", snapshot => {
        this.leadMaxScoreMonthly = snapshot.val()
      });
    
    refLeaderboard
      .orderByChild(`${this.gameType}/scoreAllTime/scorePerPlayed`)
      .limitToLast(this.numTopPlayers)
      .on("value", snapshot => {
        this.leadScorePerPlayed = snapshot.val()
      });
    refLeaderboard
      .orderByChild(`${this.gameType}/scoreMonth/scorePerPlayed`)
      .limitToLast(this.numTopPlayers)
      .on("value", snapshot => {
        this.leadScorePerPlayedMonthly = snapshot.val()
      });
    
    refLeaderboard
      .orderByChild(`${this.gameType}/scoreAllTime/maxPositives`)
      .limitToLast(this.numTopPlayers)
      .on("value", snapshot => {
        this.leadMaxPositives = snapshot.val()
      });
    refLeaderboard
      .orderByChild(`${this.gameType}/scoreMonth/maxPositives`)
      .limitToLast(this.numTopPlayers)
      .on("value", snapshot => {
        this.leadMaxPositivesMonthly = snapshot.val()
      });
    
    refLeaderboard
      .orderByChild(`${this.gameType}/scoreAllTime/minNegatives`)
      .limitToFirst(this.numTopPlayers)
      .on("value", snapshot => {
        this.leadMinNegatives = snapshot.val()
      });
    refLeaderboard
      .orderByChild(`${this.gameType}/scoreMonth/minNegatives`)
      .limitToFirst(this.numTopPlayers)
      .on("value", snapshot => {
        this.leadMinNegativesMonthly = snapshot.val()
      });
    
    
    this.cycleLeaderboard()
  },
  methods: {
    switchLeaderBoardTimeWindow: function () {
      if (this.leaderboardTimeWindow === 'monthly') {
        this.leaderboardTimeWindow = 'allTime'
      }
      else {
        this.leaderboardTimeWindow = 'monthly'
      }
    },
    togglePauseFlag: function() {
      clearTimeout(this.t1)
      clearTimeout(this.t2)
      this.pauseFlag=!this.pauseFlag;
      this.cycleLeaderboard();
    },
    cycleLeaderboard: function() {
      if (!this.pauseFlag) {
        const CYCLE_INTERVAL = 3000;
        // const FADE_IN_DURATION = 500;
        const FADE_OUT_DURATION = 500;
    
        this.leaderboardSwitch = this.leaderboards[this.leaderboardCycleCounter % this.leaderboards.length];
        this.leaderboardCycleCounter += 1;
    
        this.showFlag = true;
        this.t1 = setTimeout(() => {
          this.showFlag = false;
        }, CYCLE_INTERVAL - FADE_OUT_DURATION)
        this.t2 = setTimeout(this.cycleLeaderboard, CYCLE_INTERVAL)
      }
      else {
        this.showFlag = true;
      }
		},
    openUserProfile() {
      this.$emit('openUserProfile')
    },
    openCompetitionInfo() {
      this.$emit('openCompetitionInfo')
    },
    chooseLeaderboard(lb) {
      this.pauseFlag = true;
      clearTimeout(this.t1)
      clearTimeout(this.t2)
      
      this.showFlag = false;
      setTimeout(() => {
        this.showFlag = true;
        this.leaderboardSwitch = lb;
      }, 500)
    }
  },
  computed: {
    diameter() {
      return Math.min(180, window.innerWidth / 2.5)
    },
    userL() {
      if (this.user && this.authProvider !== 'anonymous') {
        return this.user
      } else {
        return {'name': this.compCopy.username }
      }
    },
    userMetaL() {
      if (this.userMeta) {
        return this.userMeta
      } else {
        return {'scoreAllTime': initScores[this.gameType]}
      }
    },
    topXKO() {
      let rank = 1;
      let topX = [];
      if (this.leadKO) {
        let leaderboardList = Object.keys(this.leadKO)
          .sort((x, y) => this.leadKO[y][`${this.gameType}`]['scoreAllTime']['points'] - this.leadKO[x][`${this.gameType}`]['scoreAllTime']['points'] )
          .map(x => this.leadKO[x])
        for (let player of leaderboardList) {
          topX.push({
            'rank': rank,
            'name': player[`${this.gameType}`]['name'],
            'points': player[`${this.gameType}`]['scoreAllTime']['points']
          })
          rank += 1
        }
      }
      return topX
    },
    topXKOMonthly() {
      let rank = 1;
      let topX = [];
      if (this.leadKOMonthly) {
        let leaderboardList = Object.keys(this.leadKOMonthly)
          .sort((x, y) => this.leadKOMonthly[y][`${this.gameType}`]['scoreMonth']['points'] - this.leadKOMonthly[x][`${this.gameType}`]['scoreMonth']['points'] )
          .map(x => this.leadKOMonthly[x])
        for (let player of leaderboardList) {
          topX.push({
            'rank': rank,
            'name': player[`${this.gameType}`]['name'],
            'points': player[`${this.gameType}`]['scoreMonth']['points']
          })
          rank += 1
        }
      }
      return topX
    },
    topXBestTime() {
      let rank = 1;
      let topX = [];
      if (this.leadBestTime) {
        let leaderboardList = Object.keys(this.leadBestTime)
          .sort((x, y) => this.leadBestTime[x][`${this.gameType}`]['scoreAllTime']['bestTime'] - this.leadBestTime[y][`${this.gameType}`]['scoreAllTime']['bestTime'] )
          .map(x => this.leadBestTime[x])
        for (let player of leaderboardList) {
          topX.push({
            'rank': rank,
            'name': player[`${this.gameType}`]['name'],
            'points': formatTimeInSecToMinSec(player[`${this.gameType}`]['scoreAllTime']['bestTime'])
          })
          rank += 1
        }
      }
      return topX
    },
    topXBestTimeMonthly(){
      let rank = 1;
      let topX = [];
      if (this.leadBestTimeMonthly) {
        let leaderboardList = Object.keys(this.leadBestTimeMonthly)
          .sort((x, y) => this.leadBestTimeMonthly[x][`${this.gameType}`]['scoreMonth']['bestTime'] - this.leadBestTimeMonthly[y][`${this.gameType}`]['scoreMonth']['bestTime'] )
          .map(x => this.leadBestTimeMonthly[x])
        for (let player of leaderboardList) {
          topX.push({
            'rank': rank,
            'name': player[`${this.gameType}`]['name'],
            'points': formatTimeInSecToMinSec(player[`${this.gameType}`]['scoreMonth']['bestTime'])
          })
          rank += 1
        }
      }
      return topX
    },
    topXWinRate() {
      let rank = 1;
      let topX = [];
      if (this.leadWinRate) {
        let leaderboardList = Object.keys(this.leadWinRate)
          .sort((x, y) => this.leadWinRate[y][`${this.gameType}`]['scoreAllTime']['winRate']  - this.leadWinRate[x][`${this.gameType}`]['scoreAllTime']['winRate'])
          .map(x => this.leadWinRate[x])
        for (let player of leaderboardList) {
          topX.push({
            'rank': rank,
            'name': player[`${this.gameType}`]['name'],
            'points': player[`${this.gameType}`]['scoreAllTime']['winRate'] + '%' + ' ('+player[`${this.gameType}`]['scoreAllTime']['played'] +')'
          })
          rank += 1
        }
      }
      return topX
    },
    topXWinRateMonthly() {
      let rank = 1;
      let topX = [];
      if (this.leadWinRateMonthly) {
        let leaderboardList = Object.keys(this.leadWinRateMonthly)
          .sort((x, y) => this.leadWinRateMonthly[y][`${this.gameType}`]['scoreMonth']['winRate']  - this.leadWinRateMonthly[x][`${this.gameType}`]['scoreMonth']['winRate'])
          .map(x => this.leadWinRateMonthly[x])
        for (let player of leaderboardList) {
          topX.push({
            'rank': rank,
            'name': player[`${this.gameType}`]['name'],
            'points': player[`${this.gameType}`]['scoreMonth']['winRate'] + '%' + ' ('+player[`${this.gameType}`]['scoreMonth']['played'] +')'
          })
          rank += 1
        }
      }
      return topX
    },
    topXMaxStreak() {
      let rank = 1;
      let topX = [];
      if (this.leadMaxStreak) {
        let leaderboardList = Object.keys(this.leadMaxStreak)
          .sort((x, y) => this.leadMaxStreak[y][`${this.gameType}`]['scoreAllTime']['maxStreak']  - this.leadMaxStreak[x][`${this.gameType}`]['scoreAllTime']['maxStreak'])
          .map(x => this.leadMaxStreak[x])
        for (let player of leaderboardList) {
          topX.push({
            'rank': rank,
            'name': player[`${this.gameType}`]['name'],
            'points': player[`${this.gameType}`]['scoreAllTime']['maxStreak']
          })
          rank += 1
        }
      }
      return topX
    },
    topXMaxStreakMonthly() {
      let rank = 1;
      let topX = [];
      if (this.leadMaxStreakMonthly) {
        let leaderboardList = Object.keys(this.leadMaxStreakMonthly)
          .sort((x, y) => this.leadMaxStreakMonthly[y][`${this.gameType}`]['scoreMonth']['maxStreak']  - this.leadMaxStreakMonthly[x][`${this.gameType}`]['scoreMonth']['maxStreak'])
          .map(x => this.leadMaxStreakMonthly[x])
        for (let player of leaderboardList) {
          topX.push({
            'rank': rank,
            'name': player[`${this.gameType}`]['name'],
            'points': player[`${this.gameType}`]['scoreMonth']['maxStreak']
          })
          rank += 1
        }
      }
      return topX
    },
    topXMaxScore() {
      let rank = 1;
      let topX = [];
      if (this.leadMaxScore) {
        let leaderboardList = Object.keys(this.leadMaxScore)
          .sort((x, y) => this.leadMaxScore[y][`${this.gameType}`]['scoreAllTime']['maxScore']  - this.leadMaxScore[x][`${this.gameType}`]['scoreAllTime']['maxScore'])
          .map(x => this.leadMaxScore[x])
        for (let player of leaderboardList) {
          topX.push({
            'rank': rank,
            'name': player[`${this.gameType}`]['name'],
            'points': player[`${this.gameType}`]['scoreAllTime']['maxScore']
          })
          rank += 1
        }
      }
      return topX
    },
    topXMaxScoreMonthly() {
      let rank = 1;
      let topX = [];
      if (this.leadMaxScoreMonthly) {
        let leaderboardList = Object.keys(this.leadMaxScoreMonthly)
          .sort((x, y) => this.leadMaxScoreMonthly[y][`${this.gameType}`]['scoreMonth']['maxScore']  - this.leadMaxScoreMonthly[x][`${this.gameType}`]['scoreMonth']['maxScore'])
          .map(x => this.leadMaxScoreMonthly[x])
        for (let player of leaderboardList) {
          topX.push({
            'rank': rank,
            'name': player[`${this.gameType}`]['name'],
            'points': player[`${this.gameType}`]['scoreMonth']['maxScore']
          })
          rank += 1
        }
      }
      return topX
    },
    topXScorePerPlayed() {
      let rank = 1;
      let topX = [];
      if (this.leadScorePerPlayed) {
        let leaderboardList = Object.keys(this.leadScorePerPlayed)
          .sort((x, y) => this.leadScorePerPlayed[y][`${this.gameType}`]['scoreAllTime']['scorePerPlayed']  - this.leadScorePerPlayed[x][`${this.gameType}`]['scoreAllTime']['scorePerPlayed'])
          .map(x => this.leadScorePerPlayed[x])
        for (let player of leaderboardList) {
          topX.push({
            'rank': rank,
            'name': player[`${this.gameType}`]['name'],
            'points': player[`${this.gameType}`]['scoreAllTime']['scorePerPlayed']
          })
          rank += 1
        }
      }
      return topX
    },
    topXScorePerPlayedMonthly() {
      let rank = 1;
      let topX = [];
      if (this.leadScorePerPlayedMonthly) {
        let leaderboardList = Object.keys(this.leadScorePerPlayedMonthly)
          .sort((x, y) => this.leadScorePerPlayedMonthly[y][`${this.gameType}`]['scoreMonth']['scorePerPlayed']  - this.leadScorePerPlayedMonthly[x][`${this.gameType}`]['scoreMonth']['scorePerPlayed'])
          .map(x => this.leadScorePerPlayedMonthly[x])
        for (let player of leaderboardList) {
          topX.push({
            'rank': rank,
            'name': player[`${this.gameType}`]['name'],
            'points': player[`${this.gameType}`]['scoreMonth']['scorePerPlayed']
          })
          rank += 1
        }
      }
      return topX
    },
    topXMaxPositives() {
      let rank = 1;
      let topX = [];
      if (this.leadMaxPositives) {
        let leaderboardList = Object.keys(this.leadMaxPositives)
          .sort((x, y) => this.leadMaxPositives[y][`${this.gameType}`]['scoreAllTime']['maxPositives']  - this.leadMaxPositives[x][`${this.gameType}`]['scoreAllTime']['maxPositives'])
          .map(x => this.leadMaxPositives[x])
        for (let player of leaderboardList) {
          topX.push({
            'rank': rank,
            'name': player[`${this.gameType}`]['name'],
            'points': player[`${this.gameType}`]['scoreAllTime']['maxPositives']
          })
          rank += 1
        }
      }
      return topX
    },
    topXMaxPositivesMonthly() {
      let rank = 1;
      let topX = [];
      if (this.leadMaxPositivesMonthly) {
        let leaderboardList = Object.keys(this.leadMaxPositivesMonthly)
          .sort((x, y) => this.leadMaxPositivesMonthly[y][`${this.gameType}`]['scoreMonth']['maxPositives']  - this.leadMaxPositivesMonthly[x][`${this.gameType}`]['scoreMonth']['maxPositives'])
          .map(x => this.leadMaxPositivesMonthly[x])
        for (let player of leaderboardList) {
          topX.push({
            'rank': rank,
            'name': player[`${this.gameType}`]['name'],
            'points': player[`${this.gameType}`]['scoreMonth']['maxPositives']
          })
          rank += 1
        }
      }
      return topX
    },
    topXMinNegatives() {
      let rank = 1;
      let topX = [];
      if (this.leadMinNegatives) {
        let leaderboardList = Object.keys(this.leadMinNegatives)
          .sort((x, y) => this.leadMinNegatives[x][`${this.gameType}`]['scoreAllTime']['minNegatives']  - this.leadMinNegatives[y][`${this.gameType}`]['scoreAllTime']['minNegatives'])
          .map(x => this.leadMinNegatives[x])
        for (let player of leaderboardList) {
          topX.push({
            'rank': rank,
            'name': player[`${this.gameType}`]['name'],
            'points': player[`${this.gameType}`]['scoreAllTime']['minNegatives']
          })
          rank += 1
        }
      }
      return topX
    },
    topXMinNegativesMonthly() {
      let rank = 1;
      let topX = [];
      if (this.leadMinNegativesMonthly) {
        let leaderboardList = Object.keys(this.leadMinNegativesMonthly)
          .sort((x, y) => this.leadMinNegativesMonthly[x][`${this.gameType}`]['scoreMonth']['minNegatives']  - this.leadMinNegativesMonthly[y][`${this.gameType}`]['scoreMonth']['minNegatives'])
          .map(x => this.leadMinNegativesMonthly[x])
        for (let player of leaderboardList) {
          topX.push({
            'rank': rank,
            'name': player[`${this.gameType}`]['name'],
            'points': player[`${this.gameType}`]['scoreMonth']['minNegatives']
          })
          rank += 1
        }
      }
      return topX
    },
    selectedLeaderboardTitle() {
      if (this.leaderboardTimeWindow === 'monthly') {
        return this.seasonName
      }
      else {
        return this.compCopy.allTime
      }
    },
    selectedLeaderboardCategory() {
      if(this.leaderboardCategories) {
        if (this.leaderboardTimeWindow === 'allTime') {
          return this.leaderboardCategories[this.leaderboardSwitch]['name']
        }
        else {
          return this.leaderboardCategories[this.leaderboardSwitch]['name'] + 'Monthly'
        }
      }
      return null
    },
    selectedLeaderboard(){
      if(this.leaderboardSwitch === 'topXBestTime' && this.leaderboardTimeWindow === 'allTime'){
        return this.topXBestTime
      }
      else if(this.leaderboardSwitch === 'topXWinRate' && this.leaderboardTimeWindow === 'allTime'){
        return this.topXWinRate
      }
      else if(this.leaderboardSwitch === 'topXMaxStreak' && this.leaderboardTimeWindow === 'allTime'){
        return this.topXMaxStreak
      }
      else if(this.leaderboardSwitch === 'topXMaxScore' && this.leaderboardTimeWindow === 'allTime'){
        return this.topXMaxScore
      }
      else if(this.leaderboardSwitch === 'topXScorePerPlayed' && this.leaderboardTimeWindow === 'allTime'){
        return this.topXScorePerPlayed
      }
      else if(this.leaderboardSwitch === 'topXMaxPositives' && this.leaderboardTimeWindow === 'allTime'){
        return this.topXMaxPositives
      }
      else if(this.leaderboardSwitch === 'topXMinNegatives' && this.leaderboardTimeWindow === 'allTime'){
        return this.topXMinNegatives
      }
      else if(this.leaderboardSwitch === 'topXKO' && this.leaderboardTimeWindow === 'allTime') {
        return this.topXKO
      }
      else if(this.leaderboardSwitch === 'topXBestTime' && this.leaderboardTimeWindow === 'monthly'){
        return this.topXBestTimeMonthly
      }
      else if(this.leaderboardSwitch === 'topXWinRate' && this.leaderboardTimeWindow === 'monthly'){
        return this.topXWinRateMonthly
      }
      else if(this.leaderboardSwitch === 'topXMaxStreak' && this.leaderboardTimeWindow === 'monthly'){
        return this.topXMaxStreakMonthly
      }
      else if(this.leaderboardSwitch === 'topXMaxScore' && this.leaderboardTimeWindow === 'monthly'){
        return this.topXMaxScoreMonthly
      }
      else if(this.leaderboardSwitch === 'topXScorePerPlayed' && this.leaderboardTimeWindow === 'monthly'){
        return this.topXScorePerPlayedMonthly
      }
      else if(this.leaderboardSwitch === 'topXMaxPositives' && this.leaderboardTimeWindow === 'monthly'){
        return this.topXMaxPositivesMonthly
      }
      else if(this.leaderboardSwitch === 'topXMinNegatives' && this.leaderboardTimeWindow === 'monthly'){
        return this.topXMinNegativesMonthly
      }
      else {
        return this.topXKOMonthly
      }
    }
  }
}
</script>


<style lang="css" scoped>


#container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

hr {
  outline: 0;
  border: 0;
  height: 0;
  margin: 0;
}

hr.pad2 {
  padding: 1% 0;
}

hr.pad5 {
  padding: 2.5% 0;
}

hr.pad10 {
  padding: 5% 0;
}

nav {
  width: 100%;
  background-color: #333333;
}

nav img {
  display: block;
  width: 150px;
  margin: 0 auto;
  padding: 33px 0;
}

.Header, .Athlete {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

.Header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  
  width: 90%;
  z-index: 1;
  max-width: 625px;
  margin: 0 auto;
  position: relative;
  border-top: 3px solid var(--leader1);
  background-color: var(--leader2);
  
  border-top-left-radius: min(1vh, 5);
  border-top-right-radius: min(1vh, 5);
}

.Header:nth-of-type(2) {
  border-top: 3px solid var(--leader1);
}

.Header .Header__title {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 24px;
  color: var(--leader1);
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  padding: 12px 0;
}

.Header .Header__image {
  width: 40px;
  height: auto;
  display: block;
  margin: 0 auto;
  padding: 0 0 23px 0;
}

.Header .Header__summary {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  height: 115px;
}

.Header .scoreCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*flex: 0 1;*/
  width: calc(min(80px, 15vw));
  align-items: stretch;
  background-color: var(--mainColor);
  border-radius: 5px;
  padding: 10px;
  
}

.Header .Header__stat {
  display: flex;
  /*flex-wrap: wrap;*/
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.Header .Header__stat h3 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 20px;
  width: 100%;
  margin-top: 0;
  padding: 0;
  display: block;
  text-align: center;
  align-self: center;
}

.Header .Header__stat h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: var(--leader1);
  text-transform: uppercase;
  margin-top: -20px;
  
}

::selection {
  background-color: #F5617A;
  color: #fff;
}

::-moz-selection {
  background-color: #F5617A;
  color: #fff;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder { /* Firefox 18- */
  text-align: center;
}

::-moz-placeholder { /* Firefox 19+ */
  text-align: center;
}

:-ms-input-placeholder {
  text-align: center;
}

.lb-cycle-enter-active {
  transition: all .3s ease;
}
.lb-cycle-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.lb-cycle-enter, .lb-cycle-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}



.leaderBoardTitle {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 2px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  width: min(180px, 90%);
  /*white-space: nowrap;*/
  /*word-spacing: 30000px;*/
  
  /*background-color: var(--mainColor);*/
  border: 1px solid var(--tertiaryColor);
  /*height: 40px;*/
  border-radius: 3px;
  line-height: 36px;
  text-align: center;
  color: var(--tertiaryColor);
  text-transform: uppercase;
  font-weight: 700;
  /*padding: 0 34px;*/
  /*box-shadow: 0 4px 16px 0 rgba(0, 0, 0, .3);*/
  
  outline: inherit;
  
  align-self: center;
  font-size: 16px
}

.leaderBoardTitleChosen {
  background-color: var(--mainColor);
}

.leaderBoardTitleMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  width:auto;
  align-self: center;
  font-size: 18px;
  
  
  background-color: var(--mainColor);
  /*height: 40px;*/
  border-radius: 3px;
  line-height: 36px;
  text-align: center;
  color: var(--tertiaryColor);
  text-transform: uppercase;
  font-weight: 700;
  /*padding: 0 34px;*/
  /*box-shadow: 0 4px 16px 0 rgba(0, 0, 0, .3);*/
  
  outline: inherit;
}

@media screen and (max-width: 420px) {
   .leaderBoardTitle {
      font-size: 13px;
     /*font-weight: 500;*/
     /*line-height: 36px;*/
   }
}

@media screen and (max-width: 362px) {
   .leaderBoardTitle {
      font-size: 16px;
     /*font-weight: 500;*/
     /*line-height: 36px;*/
   }
}

</style>
