<template>
  <div id="room">
    <ModalTrumpConfirm
      v-show="trumpToDisplay"
      :trump="trumpToDisplay"
      :chosen="screenNames[ihaleci]"
      @close="closeTrumpConfirmModal"
    ></ModalTrumpConfirm>
    <IhaleModalMenu
      style="z-index: 1000"
      v-show="menuOpen"
      @close="closeMenuModal"
      @restart="restart"
      @quitGame="quitGame"
      @modalUserOpen="modalUserOpen=true"

      :game="game"
      :game-meta="gameMeta"
      :user="user"
    />
    <ModalUserName
      v-if="relDir"
      style="z-index: 1000"
      :seat="relDir.S"
      :button-text=this.appCopy.change
      :user="user"
      v-show="modalUserOpen"
      @renameUser="renameUser"
      @modalUserNameClose="modalUserOpen=false"
    />
    <IhaleModalHistory
      style="z-index: 1000"
      v-if="game && game.history"
      v-show="isHistoryModalVisible"
      @close="closeHistoryModal"
      :table="table"
      :scores=scores
      :history=game.history
      :rel-dir="relDir"
    />
    <IhaleModalYazboz
      style="z-index: 1050"
      v-if="round > 0"
      v-show="isYazbozModalVisible"
      @close="closeYazbozModal"
      @quitGame="quitGame"
      @restartGame="restart"
      :yazboz="yazboz"
      :table="table"
      :scores="scores"
      :winners="winners"
      :screenNames="screenNames"
      :gameOver="gameOver"
      :numberOfRounds="numberOfRounds"
    />
    <IhaleModalRoundEnd
      style="z-index: 1000"
      v-if="this.table && this.table.roundEnd"
      @close="closeRoundEndModal"
      :game="game"
      :game-meta="gameMeta"
      :relDir="relDir"
      :ihaleci="ihaleci"
    />
    <div id="top-area" class="player-area">
      <div style="display: flex;   width: min(100px,10vw);align-items: center; justify-content: flex-start;outline: inherit">
        <div class="btn-info points">{{round}}</div>
      </div>
      <div style="display: flex; width: calc(100% - min(200px,20vw)); height: 100%; outline: inherit">
        <div v-if="this.relDir"
             style="display: flex; flex: 1 0; flex-direction: row; justify-content: center; align-items: center; outline: inherit">
          <div v-if="!ihaleci && contracts.N"
               class="player-points" :class="{'red-border':highestBid===contracts.N && !game.table.koz}" style="z-index:90"
          >
            <span class="points" style="z-index:90">{{ contracts.N }}</span>
          </div>
          <div v-else-if="!ihaleci && contracts.N === 0" class="player-points" style="z-index:90">
            <span class="points" style="z-index:90">Pas</span>
          </div>
          <div v-else-if="ihaleci === 'N'" class="player-points" style="z-index:90">
            <span class="points" style="z-index:90">{{ scores[this.relDir.N]['round'] }}/{{ contracts.N }}</span>
          </div>
          <div v-else-if="ihaleci" class="player-points" style="z-index:90">
            <span class="points" style="z-index:90">{{ scores[this.relDir.N]['round'] }}</span>
          </div>
          <b v-if="table && table.whoseTurn !== this.relDir.N" class="playername text" :class="{'nameTurnStaticRed': (this.game.dealer === this.relDir.N) && chooseTrumpFlag}" style="z-index:90;">
            {{ screenNames[this.relDir.N] }}
          </b>
          <b v-else class="playername text nameTurn" style="z-index:90">
            {{ screenNames[this.relDir.N] }}</b>
          <div v-if="contracts.N === 0"
               style="display: flex; align-items: center; justify-content: flex-start; text-align: left; font-size:25px;width: max(5vw, 60px);z-index:90; visibility: hidden"
          >
          </div>

          <div v-if="ihaleci === 'N' && contracts && scores[this.relDir.N]['round'] >= contracts.N" style="display: flex; align-items: center; justify-content: flex-start; text-align: left; font-size:30px;width: 60px;">
            👍
          </div>
        </div>
      </div>
      <div style=" width: min(100px,10vw);display: flex; align-items: center; justify-content: flex-end; outline: inherit; ">
        <div v-if="trump === 'spades'" class="spades_button btn-info"></div>
        <div v-else-if="trump === 'hearts'" class="hearts_button btn-info"></div>
        <div v-else-if="trump === 'clubs'" class="clubs_button btn-info"></div>
        <div v-else-if="trump === 'diamonds'" class="diamonds_button btn-info"></div>
      </div>
    </div>

    <div id="middle-area">
      <IhaleHandNumberChooser
        style="z-index: 150;"
        v-if="chooseTrumpFlag"
        class="player-area"
        :firstMover="firstMover"
        :game-meta="gameMeta"
        :round="round"
        :usersInGame="usersInGame"
        :hand="hands && hands['S']"
        :screen-names="screenNames"
        :game="game"
        :yazboz="yazboz"
        :user="user"
        :rel-dir="relDir"
        :numberOfRounds="numberOfRounds"
        @numberChosen="numberChosen"
      ></IhaleHandNumberChooser>

      <IhaleTablePanel
        v-if="user && table && !chooseTrumpFlag"
        id="table-panel"
        :showChosenTrump="showChosenTrump"
        :screen-names="screenNames"
        :table="table"
        :usersInGame="usersInGame"
        :scores="scores"
        :user="user"
        :contracts="contracts"
        :cardWidth="cardWidthTable"
        :relDir="relDir"
        :ihaleci="ihaleci"
      ></IhaleTablePanel>
    </div>

    <div id="bottom-name-area" class="player-area">
      <div v-if="usersInGame && Object.keys(usersInGame).length === 4"
           style="display: flex; flex: 1 1; flex-direction: row; justify-content: center; align-items: center; outline: inherit">
        <div style="visibility: hidden; display: flex; margin-left: auto; width:min(100px,15vw); justify-content: flex-end; align-items: center;outline: inherit; ">
        </div>
        <div v-if="!ihaleci && contracts.S"
             class="player-points" :class="{'red-border':highestBid===contracts.S && !game.table.koz}" style="z-index:90"
        >
          <span class="points" style="z-index:90">{{ contracts.S }}</span>
        </div>
        <div v-else-if="!ihaleci && contracts.S === 0"
             class="player-points" style="z-index:90"
        >
          <span class="points" style="z-index:90">Pas</span>
        </div>
        <div v-else-if="ihaleci === 'S'" class="player-points" style="z-index:90">
          <span class="points" style="z-index:90">{{ scores[this.relDir.S]['round'] }}/{{ contracts.S }}</span>
        </div>
        <div v-else-if="ihaleci" class="player-points" style="z-index:90">
          <span class="points" style="z-index:90">{{ scores[this.relDir.S]['round'] }}</span>
        </div>

        <b v-if="table && table.whoseTurn !== this.relDir.S" class="playername text" :class="{'nameTurnStaticRed': (this.game.dealer === this.relDir.S) && chooseTrumpFlag}" style="z-index:90">
          {{ screenNames[this.relDir.S] }}
        </b>
        <b v-else class="playername text nameTurn" style="z-index:90">
          {{ screenNames[this.relDir.S] }}</b>
        <div v-if="(contracts && contracts.S != null) && scores[this.relDir.S]['round'] < contracts.S"
             class="player-points" style="visibility: hidden; z-index:90"
        >
          <span class="points" style="z-index:90">{{ scores[this.relDir.S]['round'] }}/{{ contracts.S }}</span>
        </div>
        <div v-if="ihaleci === 'S' && contracts && scores[this.relDir.S]['round'] >= contracts.S"  style="display: flex; align-items: center; text-align: left; font-size:35px; width: 4.5rem; height: 2rem;">
          👍
        </div>
        <div style="display: flex; margin-left: auto; width:min(100px,15vw); justify-content: flex-end; align-items: center; outline: inherit; ">
          <div
            v-if="game && game.history"
            type="button"
            class="btn-yazboz"
            style="margin-right:5px; min-width: 35px"
            @click="showHistoryModal"
          >
            <i class="fa-duotone fa-history fa-2x"
               style="display:flex; margin-left:1px; align-self: center;"
            />
          </div>
          <div
            v-if="round > 0"
            class="btn-yazboz"
            style="display:flex; align-content: center; min-width: 35px"
            @click="showYazbozModal"
          >
            <i class="fa-light fa-clipboard-list fa-2x"
               style="display:flex;"
            />
          </div>
        </div>
      </div>

    </div>

    <div id="bottom-area">
      <transition-group v-if="usersInGame && Object.keys(usersInGame).length === 4" name="card-appear"
                        mode="out-in" class="player-area-cards"
                        style="z-index:120; align-items: flex-start; justify-content: space-evenly">
        <vue-playing-card
          v-for="cardId of heartsSpadesCards"
          id="heartsSpadesCards"
          :width="100"
          :signature=deck[cardId].name
          :key="deck[cardId].id"
          @click.native="!chooseTrumpFlag && table && table.whoseTurn === user.seat ? { click:  throwCard(cardId) } : {}"
          :class="[(table.whoseTurn === relDir.S && !touchDevice ) ? 'hover playing-card' : 'playing-card']"
        >
        </vue-playing-card>
        <vue-playing-card
          v-for="cardId of clubsDiamondsCards"
          id="clubsDiamondsCards"
          :width="100"
          :signature=deck[cardId].name
          :key="deck[cardId].id"
          @click.native="!chooseTrumpFlag && table && table.whoseTurn === user.seat ? { click:  throwCard(cardId) } : {}"
          :class="[(table.whoseTurn === relDir.S && !touchDevice ) ? 'hover playing-card' : 'playing-card']"
        >
        </vue-playing-card>
      </transition-group>
    </div>
  </div>
</template>

<script>
import app from '@/firebase.setup'

import IhaleHandNumberChooser from "./IhaleHandNumberChooser";
import IhaleTablePanel from "./IhaleTablePanel";
import IhaleModalHistory from "./IhaleModalHistory";
import IhaleModalYazboz from './IhaleModalYazboz';
import IhaleModalRoundEnd from "./IhaleModalRoundEnd";
import ModalUserName from "../ihale/user/ModalUserName";
import IhaleModalMenu from './IhaleModalMenu.vue';
import ModalTrumpConfirm from './IhaleModalTrumpConfirm'

import {debounce} from "debounce";

import {isKozRevealed, nextSeat} from "@/js/games";
import {getTimestamp, isBot} from "@/js/utils";
import {isInvalid} from "@/js/ihale/validations";
import {ihaleCopy} from "@/js/ihale/ihaleCopy";
import {deck_template} from "@/js/deck";
import {
  restartGame,
  createGame,
  tableWinner,
  isRoundFinished,
  buildHistory,
  botPlay,
  botChooseNumber,
  botChooseTrump,
  updateYazBoz,
  updateStateRoundEnd,
  contractsNotMade
} from "@/js/ihale/ihale";


// const TIME_LIMIT = config.TIME_LIMIT;
// const WARNING_THRESHOLD = config.WARNING_THRESHOLD;
// const ALERT_THRESHOLD = config.ALERT_THRESHOLD;
const BOT_PLAY_TIMEOUT = 500; // Bot hamlesindeki delay
const BOT_CHOOSE_NUMBER_TIMEOUT = 1000;
const TABLE_ROUND_END_TIMEOUT = 1500;
const ROUND_END_FLAG_TIMEOUT = 1500; // Son round kagitlari yere dustukten sonra bekleme suresi
const ROUND_END_MODAL_TIMEOUT = 3500; // Son round kagitlari yere dustukten SOnu Modalinin kaybolmasi arasindaki sure ROUND_END_FLAG_TIMEOUT'den buyuk olmali
// const CHOOSE_TRUMP_TIMEOUT = 490; // BOT_PLAY_TIMEOUT tan az olmali
const RESTART_TIMEOUT = 50;


export default {
  name: 'IhaleSingle',
  props: ["user", "menuOpen", "relDir", "touchDevice"],
  components: {
    IhaleHandNumberChooser,
    IhaleTablePanel,
    IhaleModalHistory,
    IhaleModalYazboz,
    IhaleModalRoundEnd,
    IhaleModalMenu,
    ModalUserName,
    ModalTrumpConfirm
  },
  data: () => ({
    game: null,
    gameMeta: null,
    hands: null,

    gameOver: false,

    modalUserOpen: false,
    cardThrowLock: false,
    windowWidth: window.innerWidth,
    environment: process.env.VUE_APP_ENV,
    isHistoryModalVisible: false,
    isYazbozModalVisible: false,
    // timeLimit: TIME_LIMIT,
    // warning_threshold: WARNING_THRESHOLD,
    // alert_threshold: ALERT_THRESHOLD,
    timePassedValueEncrypted: sessionStorage.getItem('tp'),
    refreshed: false,
    showChosenTrump: false,
    roundFinished: false,
    deck: null,
    numberOfRounds: 11,
    trumpToDisplay: null
  }),
  async created() {
    this.$emit('setIsLoading', true);
    this.appCopy = ihaleCopy.App;
    this.deck = {...deck_template};
    this.processBotTable = debounce(this.processBotTable, BOT_PLAY_TIMEOUT)
    if (sessionStorage.gameMeta) {
      const gM = JSON.parse(sessionStorage.gameMeta);
      const g = JSON.parse(sessionStorage.game);
      if (gM.gameCategory !== 'ihale') {
        if(gM.gameType === 'IhaleRated'){
          const registerGameResult = app.functions().httpsCallable('ratedRegisterGameResult');
          try {
            await registerGameResult({'gameMeta': gM, 'round': g.round});
          }
          catch(e) {
            console.warn(e)
          }
        }
        else if(gM.gameType === 'IhaleSingle')  {
          const registerGameResult = app.functions().httpsCallable('singleRegisterGameResult');
          try {
            await registerGameResult({'gameMeta': gM, 'round': g.round});
          }
          catch(e) {
            console.warn(e)
          }
        }
        console.log('failed at created')
        sessionStorage.clear();
      }
      else {
        this.gameMeta = gM;
      }
    }

    if (sessionStorage.hands) {
      this.hands = JSON.parse(sessionStorage.hands);
    }

    if (sessionStorage.game) {
      this.game = JSON.parse(sessionStorage.game);
    }


    if (!this.gameOver && this.table && this.table.cards && Object.keys(this.table.cards).length === 4) {
      this.processTable();
    }

    if (this.hand) {
      const l = Math.max(this.heartsSpadesCards.length, this.clubsDiamondsCards.length, 7)
      document.documentElement.style.setProperty("--colNumber", l);
    }
    this.$emit('setIsLoading', false);
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    });
  },
  methods: {
    closeTrumpConfirmModal() {
      this.trumpToDisplay = null;
    },
    async gameStart(user) {
      this.$emit('setIsLoading', true);
      this.gameOver = false;
      // let gameMeta;
      [this.game, this.gameMeta, this.hands, user] = createGame({'id': user.id, 'name': user.name, 'gameCategory': 'ihale'}, user.gameType);
      
      await app.database().ref(`/users/${user.id}`).set(user);

      const registerGameStart = app.functions().httpsCallable(`${this.gameTypePrefix}RegisterGameStart`);
      const resp = await registerGameStart(this.gameMeta);
      this.gameMeta = resp.data;

      this.$emit('setIsLoading', false);
    },
    async quitGame() {
      this.$emit('setIsLoading', true);
      this.closeMenuModal();
      //clears all setTimeout functions

      //clear all timeouts
      let i = setTimeout(function () {
      });
      while (i--) clearTimeout(i);

      if (this.gameOver) {
        app.analytics().logEvent(`${this.user.gameType}-quitGame-gameOver`);
      } else {
        const registerGameResult = app.functions().httpsCallable(`${this.gameTypePrefix}RegisterGameResult`);
        try {
          await registerGameResult({'gameMeta': this.gameMeta, 'round': this.game.round});
        }
        catch(e) {
          console.warn(e)
        }
        app.analytics().logEvent(`${this.user.gameType}-quitGame-premature`);
      }

      sessionStorage.clear()

      this.game = null;
      this.gameMeta = null;
      this.hands = null;

      this.$emit('setIsLoading', false);
      await app.database().ref(`/users/${this.user.id}`).set({'id': this.user.id, 'name': this.user.name})
    },
    async restart() {
      this.$emit('setIsLoading', true);
      this.closeMenuModal();
      setTimeout(async () => {
        //clears all setTimeout functions
        let i = setTimeout(function () {
        });
        while (i--) clearTimeout(i);

        if (this.gameOver) {
          app.analytics().logEvent(`${this.user.gameType}-restart-gameOver`);
        } else {
          app.analytics().logEvent(`${this.user.gameType}-restart-premature`);
        }
        
        const registerRestart = app.functions().httpsCallable(`${this.gameTypePrefix}RegisterRestart`);
        const resp = await registerRestart({'gameMeta': this.gameMeta, 'round': this.game.round});
        const gameMetaUpdated = resp.data;

        let [gameL, gameMetaL, handsL] = restartGame(this.game, gameMetaUpdated, this.user)


        this.updateGameMetaAndGame(gameL, gameMetaL);

        for (const k in handsL) {
          this.$set(this.hands, k, handsL[k]);
        }
        this.gameOver = false;
        this.$emit('setIsLoading', false);
      }, RESTART_TIMEOUT)
    },
    async renameUser(data) {
      await app.database().ref(`/users/${this.user.id}/name`).set(data.name);

      this.gameMeta.creator = data.name;
      this.gameMeta.owner.name = data.name;
      this.game.usersInGame.S.name = data.name;

      this.modalUserOpen = false;
      this.$emit('closeMenu');
    },
    numberChosen(seat, num) {
      const newGame = {...this.game}
      newGame.contracts[seat] = num

      let whoseTurnCandidate = nextSeat(seat)
      for (let i=0; i<3; i++) {
        if (this.game.contracts[whoseTurnCandidate] === 0) {
          whoseTurnCandidate = nextSeat(whoseTurnCandidate)
        } else {
          break
        }
      }

      newGame['table']['whoseTurn'] = whoseTurnCandidate
      this.updateGameMetaAndGame(newGame)

      // if (isBot(newGame.table.whoseTurn, this.game) ) {
      //   setTimeout(() => {
      //     this.processBotTable()
      //   }, BOT_PLAY_TIMEOUT)
      // }
    },
    closeMenuModal() {
      this.$emit('closeMenu');
    },
    closeRoundEndModal() {
      this.roundFinished = false;
      const gameMeta = updateYazBoz(this.game, this.gameMeta, this.ihaleci);
      let game = JSON.parse(JSON.stringify(this.game));

      if (this.game.round !== this.numberOfRounds) {
        const resp = updateStateRoundEnd(this.game);
        game = resp[0]
        const hands = resp[1]
        for (const k in hands) {
          this.$set(this.hands, k, hands[k]);
        }
      } else {
        this.gameOver = true;
        game.table.roundEnd = false;
        gameMeta.createTs[gameMeta.restartCounter]['end'] = getTimestamp()
        gameMeta.createTs[gameMeta.restartCounter]['round'] = this.numberOfRounds
      }
      this.updateGameMetaAndGame(game, gameMeta)
    },
    updateGameMetaAndGame: function (game, gameMeta) {
      for (const k in gameMeta) {
        this.$set(this.gameMeta, k, gameMeta[k]);
      }

      for (const k in game) {
        if (k !== 'table') {
          this.$set(this.game, k, game[k]);
        }
      }

      this.$set(this.game.table, 'koz', game.table.koz);
      this.$set(this.game.table, 'kozRevealed', game.table.kozRevealed);
      this.$set(this.game.table, 'tableRound', game.table.tableRound);
      this.$set(this.game.table, 'tableSuit', game.table.tableSuit);
      this.$set(this.game.table, 'roundEnd', game.table.roundEnd);
      this.$set(this.game.table, 'whoseTurn', game.table.whoseTurn);
      this.$set(this.game.table, 'cards', game.table.cards);

    },
    processTable: function () {
      const cards = this.game.table.cards;
      const table = this.game.table;

      if (!this.roundFinished) {
        if (table && table.koz) {
          if (cards && (Object.keys(cards).length === 4)) {
            // const kozRevealed = isKozRevealed(table)
            const tableW = tableWinner(table)

            if (isRoundFinished(this.hands)) {
              this.roundFinished = true;
              setTimeout(() => {
                this.$set(this.game.table, 'roundEnd', true)
                this.$set(this.gameMeta.scores[tableW], 'round', this.gameMeta.scores[tableW]['round'] + 1)
                this.cardThrowLock = false;
              }, ROUND_END_FLAG_TIMEOUT)
              if (isBot('S', this.game)) {
                setTimeout(() => {
                  this.closeRoundEndModal();
                }, ROUND_END_MODAL_TIMEOUT)
              }
            }
            else {
              //register history - Register last table, hearts, K/J, Q
              const newHistory = buildHistory(this.game);

              // newtable
              const newTable = {
                'cards': {},
                'koz': table.koz,
                'whoseTurn': tableW,
                'kozRevealed': isKozRevealed(table),
                'tableRound': table.tableRound + 1
              }
              setTimeout(() => {
                this.$set(this.game, 'history', newHistory);
                this.$set(this.game, 'table', newTable);
                this.$set(this.gameMeta.scores[tableW], 'round', this.gameMeta.scores[tableW]['round'] + 1)
                this.cardThrowLock = false;
              }, TABLE_ROUND_END_TIMEOUT)
            }

            return this.game.table.whoseTurn
          }
          else if (cards && Object.keys(cards).length === 1) {
            // @ts-ignore
            const tableSuit = this.deck[cards[Object.keys(cards)[0]]].suit

            this.$set(this.game.table, 'whoseTurn', nextSeat(table.whoseTurn));
            this.$set(this.game.table, 'tableSuit', tableSuit);
            this.cardThrowLock = false;
            return this.game.table.whoseTurn
          }
          else if ((cards && Object.keys(cards).length) === 2 || (cards && Object.keys(cards).length === 3)) {
            this.$set(this.game.table, 'whoseTurn', nextSeat(table.whoseTurn));
            this.cardThrowLock = false;
            return this.game.table.whoseTurn
          } else {
            console.log('BoSH Cek')
            return null
          }
        }
        else {
          console.log('Human player choosing trump')
        }
      }
    },
    numberOfBots: function () {
      if (this.usersInGame) {
        return Object.keys(this.usersInGame).map(seat => isBot(seat, this.game)).reduce((a, b) => a + b, 0)
      } else {
        return null
      }
    },
    throwCard: function (cardId, seat = 'S') {
      if (!this.cardThrowLock && !this.showChosenTrump && this.whoseTurn === seat && this.hands) {
        this.cardThrowLock = true
        if (!isInvalid(cardId, this.table,  this.hands[seat])) {
          if (this.table.cards) {
            this.$set(this.game.table.cards, seat, cardId)
          } else {
            this.$set(this.game.table, 'cards', {seat: cardId})
          }
          this.$delete(this.hands[seat], cardId)

          this.processTable();
        } else {
          this.$alert(`${isInvalid(cardId, this.table, this.hands[seat])}`)
          this.cardThrowLock = false
        }
      }
      return 'all good'
    },
    showHistoryModal() {
      this.isHistoryModalVisible = true;
    },
    closeHistoryModal() {
      this.isHistoryModalVisible = false;
    },
    showYazbozModal() {
      this.isYazbozModalVisible = true;
    },
    closeYazbozModal() {
      this.isYazbozModalVisible = false;
    },
    increaseTimer() {
      this.refreshed = false;
      this.timePassed += 1;
    },
    processBotTable: function () {
      const nextPlayer = this.game.table.whoseTurn;
      const hand = this.hands[nextPlayer];
      const game = this.game;
      if(!contractsNotMade(game) && !game.table.koz){
        const botCT = botChooseTrump(game, this.gameMeta, hand)
        const newGame = botCT[0]
        const newGameMeta = botCT[1]

        setTimeout(() => {
          this.updateGameMetaAndGame(newGame, newGameMeta)
          this.trumpToDisplay = newGame.table.koz
        }, BOT_CHOOSE_NUMBER_TIMEOUT)
        setTimeout(() => {
          this.trumpToDisplay = null
        }, BOT_CHOOSE_NUMBER_TIMEOUT*2.5)
      }
      else if(!contractsNotMade(game) && game.table.koz) {
        const cardId = botPlay(nextPlayer, game, hand);
        this.throwCard(cardId, nextPlayer)
      }
      else {
        if(contractsNotMade(game)){
          const newGame = botChooseNumber(game, hand)
          setTimeout(() => {
            this.updateGameMetaAndGame(newGame, this.gameMeta)
          }, BOT_CHOOSE_NUMBER_TIMEOUT)
        }
      }
    }
  },
  computed: {
    trump: function (){
      if(this.game && this.game.table && this.game.table.koz){
        return this.game && this.game.table && this.game.table.koz
      }
      return null
    },
    ihaleci: function (){
      if(this.game && this.game.table && this.game.table.koz){
        return Object.keys(this.contracts).filter(x => this.contracts[x] === this.highestBid)[0]
      }
      return null
    },
    highestBid: function(){
      if (this.game && this.game.contracts) {
        const h = Math.max(...Object.keys(this.game.contracts).map(x => this.game.contracts[x]))
        if (h > 0) {
          return h
        } else {
          return null
        }
      }
      else {
        return null
      }
    },
    gameType() {
      if(this.user) {
        return this.user.gameType
      }
      return null
    },
    gameTypePrefix() {
      if(this.gameType) {
        if (this.gameType === 'ihaleSingle') {
          return 'single'
        }
        else if(this.gameType === 'ihaleRated') {
          return 'rated'
        }
      }
      return null
    },
    contracts() {
      if (this.game && this.game.contracts) {
        return this.game.contracts
      } else {
        return null
      }
    },
    firstMover(){
      if (this.game && this.game.round) {
        const rem = this.game.round % 4
        if (rem === 1) {
          return 'S'
        }
        else if (rem === 2) {
          return 'W'
        }
        else if (rem === 3) {
          return 'N'
        }
        else {
          return 'E'
        }
      } else {
        return 'S'
      }
    },
    hand() {
      if (!this.hands) {
        return null
      } else {
        return this.hands.S
      }
    },
    round() {
      if (this.game && this.game.round) {
        return this.game.round
      } else {
        return null
      }
    },
    yazboz() {
      if (this.gameMeta && this.gameMeta.yazboz) {
        return this.gameMeta.yazboz
      } else {
        return null
      }
    },
    scores() {
      if (this.gameMeta && this.gameMeta.scores) {
        return this.gameMeta.scores
      } else {
        return null
      }
    },
    table() {
      if (this.game && this.game.table) {
        return this.game.table
      } else {
        return null
      }
    },
    usersInGame() {
      if (this.game && this.game.usersInGame) {
        return this.game.usersInGame
      } else {
        return null
      }
    },
    isNameSen: function () {
      if (this.user && this.user.name) {
        return this.user.name === this.appCopy.defaultName;
      } else {
        return null
      }
    },
    cardWidthTable: function () {
      return this.windowWidth / 3.7
    },
    cardWidthHand: function () {
      if (this.hands && this.hands['S']) {
        return Math.min(this.windowWidth / (Object.keys(this.hands['S']).length) * 1.6, this.windowWidth / 5)
      } else {
        return null
      }
    },
    whoseTurn: function () {
      if (this.table && this.table.whoseTurn) {
        return this.table.whoseTurn
      } else {
        return null
      }
    },
    tableCards: function () {
      if (this.table && this.table.cards) {
        return this.table.cards
      } else {
        return null
      }
    },
    chooseTrumpFlag: function () {
      return !!this.table && !(this.table && this.table.koz) && !!this.table.whoseTurn;
    },
    orderedHand: function () {
      if (this.hands && this.hands && this.hands['S']) {
        const hand = Object.keys(this.hands['S'])

        hand.sort((a, b) => {
          return this.deck[a].rank - this.deck[b].rank;
        })
        return hand
      } else {
        return null
      }
    },
    winners: function () {
      if (this.scores) {
        const winner_score = Object.keys(this.scores).map((key) => {
          return [key, this.scores[key]['totalKoz'] - this.scores[key]['totalCeza']]
        })
          .reduce((a, b) => {
            if (a[1] > b[1]) {
              return a
            } else {
              return b
            }
          })[1]
        return Object.keys(this.scores)
          .filter((key) => (this.scores[key]['totalKoz'] - this.scores[key]['totalCeza']) === winner_score)
          .map((key) => this.screenNames[key])
      } else {
        return null
      }

    },
    screenNames: function () {
      const sN = {}

      if (this.usersInGame && this.usersInGame.N && this.usersInGame.N['name'] && this.usersInGame.N['name'].substring(0, 4) === '_bot') {
        sN['N'] = this.usersInGame.N['name'].substring(9)
      } else {
        if (!this.usersInGame || !this.usersInGame.N) {
          sN['N'] = ''
        } else {
          sN['N'] = this.usersInGame.N['name']
        }
      }

      if (this.usersInGame && this.usersInGame.W && this.usersInGame.W['name'] && this.usersInGame.W['name'].substring(0, 4) === '_bot') {
        sN['W'] = this.usersInGame.W['name'].substring(9)
      } else {
        if (!this.usersInGame || !this.usersInGame.W) {
          sN['W'] = ''
        } else {
          sN['W'] = this.usersInGame.W['name']
        }
      }

      if (this.usersInGame && this.usersInGame.E && this.usersInGame.E['name'] && this.usersInGame.E['name'].substring(0, 4) === '_bot') {
        sN['E'] = this.usersInGame.E['name'].substring(9)
      } else {
        if (!this.usersInGame || !this.usersInGame.E) {
          sN['E'] = ''
        } else {
          sN['E'] = this.usersInGame.E['name']
        }
      }

      if (this.usersInGame && this.usersInGame.S && this.usersInGame.S['name'] && this.usersInGame.S['name'].substring(0, 4) === '_bot') {
        sN['S'] = this.usersInGame.S['name'].substring(9)
      } else {
        if (!this.usersInGame || !this.usersInGame.S) {
          sN['S'] = ''
        } else {
          sN['S'] = this.usersInGame.S['name']
        }
      }
      return sN
    },
    heartsSpadesCards: function () {
      let hearts = [];
      if (this.hand && this.deck) {
        let hand_list = Object.keys(this.hand)
        hand_list.sort((a, b) => {
          return this.deck[b].id - this.deck[a].id;
        })

        for (const key of hand_list) {
          if (this.deck[key].suit === 'hearts' || this.deck[key].suit === 'spades') {
            hearts.push(key)
          }
        }
      }
      return hearts
    },
    clubsDiamondsCards: function () {
      let clubs = []
      if (this.hand && this.deck) {
        let hand_list = Object.keys(this.hand)
        hand_list.sort((a, b) => {
          return this.deck[b].id - this.deck[a].id;
        })

        for (const key of hand_list) {
          if (this.deck[key].suit === 'clubs' || this.deck[key].suit === 'diamonds') {
            clubs.push(key)
          }
        }
      }
      return clubs
    },
  },
  watch: {
    gameType: {
      immediate: true,
      handler(val) {
        if (val) {
          let gTFromStorage;
          if(sessionStorage.gameMeta) {
            gTFromStorage = JSON.parse(sessionStorage.gameMeta).gameType
          }
          if (!gTFromStorage || gTFromStorage !== val){
            this.gameStart({'id': this.user.id, 'name': this.user.name, 'gameCategory': 'ihale', 'gameType': val})

            setTimeout(() => {
              this.$set(this.game, 'round', 1);
            }, 50)
          }
        }
      }
    },
    async gameOver() {
      if (this.gameOver === true) {
        this.isYazbozModalVisible = true;
        app.analytics().logEvent(`${this.user.gameType}-gameOver`);
        const registerGameResult = app.functions().httpsCallable(`${this.gameTypePrefix}RegisterGameResult`);
        try {
          await registerGameResult({'gameMeta': this.gameMeta, 'round': this.game.round});
        }
        catch(e) {
          console.warn(e)
        }
      }
    },
    round(newVal) {
      if (newVal === 2) {
        app.analytics().logEvent(`${this.gameTypePrefix}-R1`);
      } else if (newVal === 3) {
        app.analytics().logEvent(`${this.gameTypePrefix}-R2`);
      } else if (newVal === 6) {
        app.analytics().logEvent(`${this.gameTypePrefix}-R5`);
      } else if (newVal === 9) {
        app.analytics().logEvent(`${this.gameTypePrefix}-R9`);
      } else if (newVal === 10) {
        app.analytics().logEvent(`${this.gameTypePrefix}-R10`);
      }
    },
    contracts: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          if(this.contracts.S === 0 &&
            this.contracts.W === 0 &&
            this.contracts.N === 0 &&
            this.contracts.E === 0) {
            this.contracts[this.game.dealer] = 4
            this.game.table.whoseTurn = this.game.dealer
          }
          else {
            if (isBot(this.game.table.whoseTurn, this.game)) {
              this.processBotTable();
            }
          }
        }
      }
    },
    tableCards: {
      immediate: true,
      handler() {
        if (this.hands && this.game && this.game.table && this.game.table.cards
          && this.game.table.koz && Object.keys(this.game.table.cards).length < 4) {

          const nextPlayer = this.game.table.whoseTurn
          if (isBot(nextPlayer, this.game)) {
            this.processBotTable();
          }
        }
      }
    },
    game: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          sessionStorage.game = JSON.stringify(val);
          sessionStorage.gameMeta = JSON.stringify(this.gameMeta);
          this.$emit('setGameGameMetaHands', [val, this.gameMeta, this.hands])
        }
      }
    },
    gameMeta: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          sessionStorage.gameMeta = JSON.stringify(val);
        }
      }
    },
    hands: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          sessionStorage.hands = JSON.stringify(val);
          if (val['S'] && Object.keys(val['S']).length === 13) {
            const l = Math.max(this.heartsSpadesCards.length, this.clubsDiamondsCards.length)
            document.documentElement.style.setProperty("--colNumber", l);
          }
        }
      }
    },
    whoseTurn() {
      this.cardThrowLock = false
    },
  }
}
</script>

<style scoped>
.player-area-cards {
  display: grid;

  /*grid-template-columns: 15% 15% 15% 15% 15% 15%;*/
  /*grid-template-columns: repeat(auto-fill, var(--colSize));*/
  grid-template-columns: repeat(var(--colNumber), calc(90% / calc(var(--colNumber))));
  grid-template-rows: 50px 50px;
  column-gap: 0;

  padding-left: 2px;
  padding-right: 30px;

  flex: 1 0;
  justify-content: flex-end;
  justify-self: stretch;
  align-self: stretch;
  align-items: center;

  margin-top: 6px;

  outline: inherit;

}

.player-area-cards #heartsSpadesCards {
  grid-row-start: 1;

  justify-content: center;
  align-items: center;
  justify-self: flex-start;

  outline: inherit;
}

.player-area-cards #clubsDiamondsCards {
  grid-row-start: 2;

  justify-content: center;
  align-items: center;
  justify-self: flex-start;

  outline: inherit;
}


#room {
  /*margin-left: 20px;*/
  display: flex;
  
  flex: 1 0 100%;
  flex-direction: column;
  justify-content: space-between;
  
  outline: inherit;
  
  /*	position: relative;*/
}

#room #top-area {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  flex: 0 0 3.5rem;
  
  z-index: 0;
  
  outline: inherit;
}

#room #middle-area {
  display: flex;
  flex-direction: row;
  flex: 0 0 max(40vh, 320px);
  
  justify-content: center;
  align-items: stretch;

  outline: inherit;
}

#room #bottom-name-area {
  display: flex;
  flex: 0 0 3.5rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;


  z-index: 100;
  
  outline: inherit;
}

#room #bottom-area {
  display: flex;
  /*flex: 0 0 calc(100% - 7rem - max(40vh, 320px));*/
  flex: 1 0 200px;

  flex-direction: column;
  justify-content: center;
  align-items: center;


  /*height: calc(50% - 90px);*/
  /*margin-right: 4%;*/
  /*margin-left: 4%;*/
  
  z-index: 130;
  /*height: 100px;*/
  
  /*overflow: hidden;*/
  
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  
  outline: inherit;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#bottom-area::-webkit-scrollbar {
  display: none;
}

#table-panel {
  z-index: 300;
  height: max(360px, 45vh);
}


/*.showChosenTrump-enter-active, .showChosenTrump-leave-active {*/
/*  opacity: 0;*/
/*}*/
/*.showChosenTrump-enter, .showChosenTrump-leave-to {*/
/*  transition: opacity 2s;*/
/*}*/

.points {
  /*border: 2px darkblue solid;*/
  margin: 5px;
  font-size: 25px;

  /*font-size: 1.75rem;*/
  color: var(--tertiaryColor);
  /*text-shadow: 1px 1px 8px rgba(0, 0, 0, 1);*/
  cursor: default;
  outline: inherit;
}

.player-points {
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 50px;
  height: 2rem;
  /*padding: 0.1rem;*/
  /*margin: 5px;*/
  /*padding: 5px;*/
  background: var(--mainColor);
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  /*position: absolute;*/
  z-index: 120;
  /*left: -30px;*/
  text-align: center;
  flex: 0 1 0;
  /*align-self: flex-start;*/
  /*margin: 3px;*/
  /*border: 3px hidden red;*/

  outline: inherit;
}

.red-border {
  border: 2px solid red;
}

@media (max-width:450px){
  .points {
    /*border: 2px darkblue solid;*/
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 20px;

    /*font-size: 1.75rem;*/
    color: var(--tertiaryColor);
    /*text-shadow: 1px 1px 8px rgba(0, 0, 0, 1);*/
    cursor: default;
    outline: inherit;
  }
  .player-points {
    display: flex;
    justify-content: center;
    align-items: center;

    width: max(5vw, 55px);

    height: 1.5rem;
    /*padding: 0.1rem;*/
    /*margin: 5px;*/
    /*padding: 5px;*/
    background: var(--mainColor);
    border-radius: 10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    /*position: absolute;*/
    z-index: 120;
    /*left: -30px;*/
    text-align: center;
    flex: 0 1 auto;
    /*align-self: flex-start;*/
    /*margin: 3px;*/
    /*border: 3px hidden red;*/

    outline: inherit;
  }
  .koz-button2 {
    height: 5vw;
    width: 5vw;

    border-radius: 5px;

    max-width: 10px;
    max-height: 10px;

    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.5);

    cursor: pointer;
    /*color: white;*/
    /*font-weight: bold;*/
    /*background-color: #bdbcbc;*/
  }
}

</style>
