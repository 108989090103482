<template>
  <div id="koz-ceza-panel" v-if="usersInGame[relDir.W] && yazboz">
    <modal-trump-confirm
      v-show="trumpToConfirm"
      :trump="trumpToConfirm"
      :cezalar="cezalar"
      @close="closeTrumpConfirmModal"
      @confirmTrump="confirmTrump"
    ></modal-trump-confirm>
    <div id="nameW">
      <div id="nameWBorder" class="player-area">
        <b v-if="table.whoseTurn !== this.relDir.W" class="playername text">
          {{ screenNames[this.relDir.W] }}</b>
        <b v-else class="playername text nameTurn">
          {{ screenNames[this.relDir.W] }}</b>
        <div class="trumps" style="flex-direction: column; ">
          <div class="triangles" style="display:flex; flex-direction: row; outline: inherit">
            <div v-if="remains[relDir.W]['ceza'] === 3">
              <i class="fa-light fa-circle"></i>
              <i class="fa-light fa-circle"></i>
              <i class="fa-light fa-circle"></i>
            </div>
            <div v-else-if="remains[relDir.W]['ceza'] === 2">
              <i class="fa-solid fa-circle"></i>
              <i class="fa-light fa-circle"></i>
              <i class="fa-light fa-circle"></i>
            </div>
            <div v-else-if="remains[relDir.W]['ceza'] === 1">
              <i class="fa-solid fa-circle"></i>
              <i class="fa-solid fa-circle"></i>
              <i class="fa-light fa-circle"></i>
            </div>
            <div v-else>
              <i class="fa-solid fa-circle"></i>
              <i class="fa-solid fa-circle"></i>
              <i class="fa-solid fa-circle"></i>
            </div>
          </div>
          <div class="circles" style="display:flex; align-items: stretch; margin-left:5px; outline: inherit">
            <div v-if="remains[relDir.W]['koz'] === 2">
              <i class="fa-light fa-triangle"></i>
              <i class="fa-light fa-triangle"></i>
            </div>
            <div v-else-if="remains[relDir.W]['koz'] === 1">
              <i class="fa-solid fa-triangle"></i>
              <i class="fa-light fa-triangle"></i>
            </div>
            <div v-else>
              <i class="fa-solid fa-triangle"></i>
              <i class="fa-solid fa-triangle"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div id="cezalar-heading" v-if="round > 4">
      <!--            <b style="display:flex; flex-direction: column; justify-content: flex-end; font-size: 25px; outline: inherit">Kozlar</b>-->
      <div class="btn-ceza-koz"
           style="cursor: default; flex-direction: column; justify-content: flex-end; align-self: center">
        {{ compCopy.trumps }}
      </div>
    </div>
    
    <div id="kozceza" v-if="this.hand">
      <div id="koz-panel" v-if="round > 4">
        <!--            <div id="koz-panel">-->
        <div id="koz-parent">
          <div class="spades_button koz-button hover" @click="chosenTrump('spades')"></div>
          <div class="hearts_button koz-button hover" @click="chosenTrump('hearts')"></div>
          <div class="clubs_button koz-button hover" @click="chosenTrump('clubs')"></div>
          <div class="diamonds_button koz-button hover" @click="chosenTrump('diamonds')"></div>
        </div>
      </div>
      <div id="ceza-panel">
        <div class="btn-ceza-koz" style="cursor: default;"> {{ compCopy.penalties }}</div>
        <div id="ceza-parent">
          <div class="ceza-button hover" v-if="cezalar.rifki >= 0" @click="chosenTrump('rifki')">
            <div class="rifki ceza-button-inner"></div>
            <div class="overlay" v-if="cezalar.rifki === 1"></div>
            <div class="overlay2" v-if="cezalar.rifki === 0"></div>
          </div>
          <div class="ceza-button hover" v-if="cezalar.erkek >= 0" @click="chosenTrump('erkek')">
            <div class="erkekalmaz ceza-button-inner"></div>
            <div class="overlay" v-if="cezalar.erkek === 1"></div>
            <div class="overlay2" v-if="cezalar.erkek === 0"></div>
          </div>
          <div class="ceza-button hover" v-if="cezalar.kiz >= 0" @click="chosenTrump('kiz')">
            <div class="kizalmaz ceza-button-inner"></div>
            <div class="overlay" v-if="cezalar.kiz === 1"></div>
            <div class="overlay2" v-if="cezalar.kiz === 0"></div>
          </div>
          <div class="ceza-button hover" v-if="cezalar.soniki >= 0" @click="chosenTrump('soniki')">
            <div class="soniki ceza-button-inner"></div>
            <div class="overlay" v-if="cezalar.soniki === 1"></div>
            <div class="overlay2" v-if="cezalar.soniki === 0"></div>
          </div>
          <div class="ceza-button hover" v-if="cezalar.kupa >= 0" @click="chosenTrump('kupa')">
            <div class="kupaalmaz ceza-button-inner"></div>
            <div class="overlay" v-if="cezalar.kupa === 1"></div>
            <div class="overlay2" v-if="cezalar.kupa === 0"></div>
          </div>
          <div class="ceza-button hover" v-if="cezalar.el >= 0" @click="chosenTrump('el')">
            <div class="elalmaz ceza-button-inner"></div>
            <div class="overlay" v-if="cezalar.el === 1"></div>
            <div class="overlay2" v-if="cezalar.el === 0"></div>
          </div>
        </div>
      </div>
    </div>
    
    <div id="nameE" v-if="usersInGame[relDir.E]">
      <div id="nameEBorder" class="player-area">
        <b v-if="table.whoseTurn !== this.relDir.E" class="playername text">
          {{ screenNames[this.relDir.E] }}</b>
        <b v-else class="playername text nameTurn">
          {{ screenNames[this.relDir.E] }}</b>
        <div class="trumps" style="flex-direction: column; ">
          <div class="triangles" style="display:flex; flex-direction: row; outline: inherit">
            <div v-if="remains[relDir.E]['ceza'] === 3">
              <i class="fa-light fa-circle"></i>
              <i class="fa-light fa-circle"></i>
              <i class="fa-light fa-circle"></i>
            </div>
            <div v-else-if="remains[relDir.E]['ceza'] === 2">
              <i class="fa-solid fa-circle"></i>
              <i class="fa-light fa-circle"></i>
              <i class="fa-light fa-circle"></i>
            </div>
            <div v-else-if="remains[relDir.E]['ceza'] === 1">
              <i class="fa-solid fa-circle"></i>
              <i class="fa-solid fa-circle"></i>
              <i class="fa-light fa-circle"></i>
            </div>
            <div v-else>
              <i class="fa-solid fa-circle"></i>
              <i class="fa-solid fa-circle"></i>
              <i class="fa-solid fa-circle"></i>
            </div>
          </div>
          <div class="circles" style="display:flex; align-items: stretch; margin-left:5px; outline: inherit">
            <div v-if="remains[relDir.E]['koz'] === 2">
              <i class="fa-light fa-triangle"></i>
              <i class="fa-light fa-triangle"></i>
            </div>
            <div v-else-if="remains[relDir.E]['koz'] === 1">
              <i class="fa-solid fa-triangle"></i>
              <i class="fa-light fa-triangle"></i>
            </div>
            <div v-else>
              <i class="fa-solid fa-triangle"></i>
              <i class="fa-solid fa-triangle"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import ModalTrumpConfirm from "./KingModalTrumpConfirm";
import {kingCopy} from "@/js/king/kingCopy";

export default {
  name: "KozCezaPanel",
  components: {ModalTrumpConfirm},
  props: ["gameMeta", "round", "usersInGame", "table", "yazboz", "user", "hand", "relDir", "screenNames"],
  data: () => ({
    trumpToConfirm: null
  }),
  created() {
    this.compCopy = kingCopy.KozCezaPanel;
  },
  methods: {
    closeTrumpConfirmModal() {
      this.trumpToConfirm = null;
    },
    confirmTrump: function (trump) {
      if (this.table.whoseTurn === this.user.seat) {
        if (['spades', 'diamonds', 'clubs', 'hearts'].includes(trump)) {
          this.$emit('registerKozIfAvailable', trump, this.user)
        } else {
          this.$emit('registerCezaIfAvailable', trump, this.user)
        }
      } else {
        this.alertPleaseWait();
      }
    },
    chosenTrump: function (trump) {
      this.trumpToConfirm = trump
    },
    alertPleaseWait: function () {
      if (this.table && this.table.whoseTurn) {
        this.$alert(JSON.stringify(this.screenNames[this.table.whoseTurn])
          .replace(/['"]+/g, '') + ' eli belirliyor. Lütfen bekleyin.')
      }
    },
  },
  computed: {
    cezalar: function () {
      return !!this.yazboz && this.yazboz.remains.cezalar
    },
    remains: function () {
      return !!this.yazboz && this.yazboz.remains
    }
  }
}
</script>

<style scoped>
.trumps {
  display: flex;
  justify-content: center;
  align-items: center;
  
  padding: 0.1rem;
  background: var(--secondaryColor);
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  
  flex: 0 1 auto;
  z-index: 120;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  
  outline: inherit;
}

.triangles {
  display: flex;
  flex-direction: row;
  /*margin-bottom:-4px;*/
  font-size: min(15px, 3vw);
  
  outline: inherit;
}

.circles {
  display: flex;
  align-items: stretch;
  /*margin-top: -4px;*/
  font-size: min(14px, 3vw);
  
  outline: inherit;
}

.btn-ceza-koz {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 5px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  width: 60px;
  /*height: 40px;*/
  /*min-height: 25px;*/
  
  background-color: var(--mainColor);
  /*height: 40px;*/
  border-radius: 20px;
  line-height: 36px;
  text-align: center;
  color: var(--tertiaryColor);
  text-transform: uppercase;
  font-size: 0.875em;
  font-weight: 700;
  /*padding: 0 34px;*/
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, .3);
  
  outline: inherit;
}

#koz-ceza-panel {
  display: grid;
  grid-template-columns: calc(33% ) calc(34% ) calc(33% );
  grid-template-rows: 100px calc(100% - 100px);
  grid-template-areas:
            "nameW      cezalar-heading       nameE"
            "kozceza     kozceza     kozceza";
  flex: 1 0;
  
  /*min-height: min(350px, 44vh);*/
  
  outline: inherit;
  
}


#koz-ceza-panel #nameW {
  grid-area: nameW;
  
  display: flex;
  flex-direction: row;
  flex: 1 0;
  
  justify-content: flex-start;
  align-items: stretch;
  
  
  z-index: 200;
  /*background-color: #24CD63;*/
  /*border-bottom-right-radius: 90%;*/
  
  outline: inherit;
}

#nameWBorder {
  display: flex;
  
  flex: 1 0;
  
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  
  outline: inherit;
}

#koz-ceza-panel #nameE {
  grid-area: nameE;
  
  display: flex;
  flex-direction: row;
  
  justify-content: flex-end;
  align-items: stretch;
  
  z-index: 200;
  
  outline: inherit;
}

#nameEBorder {
  outline: inherit;
  display: flex;
  flex: 1 0;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

#cezalar-heading {
  grid-area: cezalar-heading;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  
  outline: inherit;
}

#kozceza {
  grid-area: kozceza;
  
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
  
  padding: 5px;
  
  outline: inherit;
}

#ceza-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*align-content: stretch;*/
  align-items: center;
  
  margin-top: 5px;
  
  flex: 1 1 auto;
  
  outline: inherit;
}

#koz-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: stretch;
  
  flex: 0 1 auto;
  
  outline: inherit;
}

#ceza-parent {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  align-content: space-around;
  /*flex-wrap: wrap;*/
  
  flex: 0 1 auto;
  
  outline: inherit;
}

#koz-parent {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: space-around;
  
  flex: 0 1 auto;
  
  outline: inherit;
}


</style>
