<template>
  <div id="number-pad">
    <div id="no0" class="number-key bg-green" @click="chosenNumberHands(0)">Pas</div>
    <div id="no13" class="number-key" :class="{'bg-grey': highestBid === 13, 'bg-green': highestBid === null || highestBid < 13}" @click="chosenNumberHands(13)">13</div>
    <div id="no12" class="number-key" :class="{'bg-grey': highestBid >= 12, 'bg-green': highestBid === null || highestBid < 12}" @click="chosenNumberHands(12)">12</div>
    <div id="no11" class="number-key" :class="{'bg-grey': highestBid >= 11, 'bg-green': highestBid === null || highestBid < 11}" @click="chosenNumberHands(11)">11</div>
    <div id="no10" class="number-key" :class="{'bg-grey': highestBid >= 10, 'bg-green': highestBid === null || highestBid < 10}" @click="chosenNumberHands(10)">10</div>
    <div id="no9" class="number-key" :class="{'bg-grey': highestBid >= 9, 'bg-green': highestBid === null || highestBid < 9}" @click="chosenNumberHands(9)">9</div>
    <div id="no8" class="number-key" :class="{'bg-grey': highestBid >= 8, 'bg-green': highestBid === null || highestBid < 8}" @click="chosenNumberHands(8)">8</div>
    <div id="no7" class="number-key" :class="{'bg-grey': highestBid >= 7, 'bg-green': highestBid === null || highestBid < 7}" @click="chosenNumberHands(7)">7</div>
    <div id="no6" class="number-key" :class="{'bg-grey': highestBid >= 6, 'bg-green': highestBid === null || highestBid < 6}" @click="chosenNumberHands(6)">6</div>
    <div id="no5" class="number-key " :class="{'bg-grey': highestBid >= 5, 'bg-green': highestBid === null || highestBid < 5}" @click="chosenNumberHands(5)">5</div>
  </div>
</template>

<script>

export default {
  name: 'NumberPad',
  props: ['highestBid'],
  data: () => ({
    chosenNumber: null
  }),
  created() {
  },
  computed: {
  },
  methods: {
    chosenNumberHands(num){
      if (num > 0 && num <= this.highestBid) {
        this.alertIncreaseBid()
      }
      else {
        this.$emit('chosenNumber', num);
      }
    },
    alertIncreaseBid: function () {
      this.$alert('İhaleyi yükseltmek mecburidir.')
    },
  },
};
</script>

<style scoped>

#number-pad {
  display: grid;
  grid-template-columns: calc(25% ) calc(25% ) calc(25% ) calc(25% );
  grid-template-rows: calc(33% ) calc(33% ) calc(33% );
  grid-template-areas:
            "no10     no11     no12  no13"
            "no6     no7    no8  no9"
            "no0     no0     no5   no5";
  flex: 1 0;

  outline: inherit;
}

#no0 {
  font-size: 4vmin;
}

.number-key {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 2px;
  padding: 10px;

  cursor: pointer;


  /*background-color: var(--mainColor)+'7f';*/
  text-align: center;

  font-size: 4vmin;

  border-radius: 5px;
}

.bg-grey {
  background-color: #757575;
}

.bg-green {
  background-color: #24da7e7f;
}

.bg-green:hover {
  background-color: #24da7e;
}

#number-pad #no0 {
  grid-area: no0;
}
#number-pad #no5 {
  grid-area: no5;
}
#number-pad #no6 {
  grid-area: no6;
}
#number-pad #no7 {
  grid-area: no7;
}
#number-pad #no8 {
  grid-area: no8;
}
#number-pad #no9 {
  grid-area: no9;
}
#number-pad #no10 {
  grid-area: no10;
}
#number-pad #no11 {
  grid-area: no11;
}
#number-pad #no12 {
  grid-area: no12;
}
#number-pad #no13 {
  grid-area: no13;
}



</style>
