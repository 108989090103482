<template>
  <div v-if="userMetaLocal"
       style="display:flex; flex: 1 1; flex-direction: column; justify-content: center; align-items: stretch; outline: inherit">
    <h1 class="authUser" style="align-self: center; font-size: 18px; width: auto"
        @click="close"
    >
      {{ userNameLocal }}
    </h1>
    <div style="display:flex; flex-direction: column; justify-content: space-between;" >
      <div @click="close" style="display: flex; flex-direction: column; flex: 1 0 1px; justify-content: center;">
        <UserBatakProgress
          :userMeta="userMetaLocal"
          :diameter=diameter
        ></UserBatakProgress>
      </div>
      <div @click="close"
        style="display: flex; flex: 1 0 1px; flex-direction: row; justify-content: center; align-items: center"
        >
        <span class="Header__stat " style="justify-content: center; margin-top: 10px;">
          <div class="header_bg" style="justify-content: center; flex: 0 1">
            <h4 style="white-space: nowrap;"> {{ leaderboardCopy.koRating }}: </h4>
            <h4 v-if="isSeason"> {{ userMetaLocal.scoreMonth.points }}</h4>
            <h4 v-else> {{ userMetaLocal.scoreAllTime.points }}</h4>
          </div>
        </span>
      </div>
      <div @click="close" class="metrics_row">
        <span class="Header__stat">
          <div class="header_bg">
            <h4>{{ leaderboardCopy.played }}: </h4>
            <h4 v-if="isSeason"> {{ userMetaLocal.scoreMonth.played }} </h4>
            <h4 v-else> {{ userMetaLocal.scoreAllTime.played }} </h4>
          </div>
        </span>
        <span class="Header__stat">
          <div class="header_bg">
            <h4> {{ leaderboardCopy.bestTime }}: </h4>
            <h4 v-if="isSeason"> {{ seasonBestTime }} </h4>
            <h4 v-else> {{ allTimeBestTime }} </h4>
          </div>
        </span>
      </div>
      <div @click="close" class="metrics_row">
        <span class="Header__stat ">
          <div class="header_bg">
            <h4> {{ leaderboardCopy.wins }}: </h4>
            <h4 v-if="isSeason"> {{ userMetaLocal.scoreMonth.wins }} </h4>
            <h4 v-else> {{ userMetaLocal.scoreAllTime.wins }} </h4>
          </div>
        </span>
        <span class="Header__stat">
          <div class="header_bg">
            <h4> {{ leaderboardCopy.winRate }}: </h4>
            <h4 v-if="isSeason"> {{ userMetaLocal.scoreMonth.winRate }} </h4>
            <h4 v-else> {{ userMetaLocal.scoreAllTime.winRate }} </h4>
          </div>
        </span>
      </div>
      <div @click="close" class="metrics_row">
        <span class="Header__stat">
          <div class="header_bg">
            <h4> {{ leaderboardCopy.streak }}: </h4>
            <h4 v-if="isSeason"> {{ userMetaLocal.scoreMonth.streak }} </h4>
            <h4 v-else> {{ userMetaLocal.scoreAllTime.streak }} </h4>
          </div>
        </span>
        <span class="Header__stat">
          <div class="header_bg">
            <h4> {{ leaderboardCopy.maxStreak }}: </h4>
            <h4 v-if="isSeason"> {{ userMetaLocal.scoreMonth.maxStreak }} </h4>
            <h4 v-else> {{ userMetaLocal.scoreAllTime.maxStreak }} </h4>
          </div>
        </span>
        
      </div>
      <div @click="close" class="metrics_row">
        <span class="Header__stat">
          <div class="header_bg">
            <h4> {{ leaderboardCopy.maxScore }}: </h4>
            <h4 v-if="isSeason"> {{ userMetaLocal.scoreMonth.maxScore }} </h4>
            <h4 v-else> {{ userMetaLocal.scoreAllTime.maxScore }} </h4>
          </div>
        </span>
        <span class="Header__stat">
          <div class="header_bg">
            <h4> {{ leaderboardCopy.scorePerPlayed }}: </h4>
            <h4 v-if="isSeason"> {{ userMetaLocal.scoreMonth.scorePerPlayed }} </h4>
            <h4 v-else> {{ userMetaLocal.scoreAllTime.scorePerPlayed }} </h4>
          </div>
        </span>
      </div>
      <div @click="close" class="metrics_row">
        <span class="Header__stat">
          <div class="header_bg">
            <h4> {{ leaderboardCopy.maxPositives }}: </h4>
            <h4 v-if="isSeason"> {{ userMetaLocal.scoreMonth.maxPositives }} </h4>
            <h4 v-else> {{ userMetaLocal.scoreAllTime.maxPositives }} </h4>
          </div>
        </span>
        <span class="Header__stat">
          <div class="header_bg">
            <h4> {{ leaderboardCopy.minNegatives }}: </h4>
            <h4 v-if="isSeason"> {{ userMetaLocal.scoreMonth.minNegatives }} </h4>
            <h4 v-else> {{ userMetaLocal.scoreAllTime.minNegatives }} </h4>
          </div>
        </span>
      </div>
      <div style="display: flex; justify-content: center; align-items: center">
        <toggle-button
          style="align-self: center; justify-self: center;"
          @change="switchTimeWindow"
          :width=90
          :height=30
          :font-size=16
          :color="{checked: 'var(--mainColor)', unchecked: 'var(--fifthColor)', disabled: '#CCCCCC'}"
          :value="false"
          :labels="{checked: 'Sezon', unchecked: 'Genel'}"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UserBatakProgress from "@/components/games/batak/leaderboard/UserBatakProgress";
import {batakCopy} from "@/js/batak/batakCopy";
import {formatTimeInSecToMinSec} from "@/js/utils";
import {initScores} from "@/js/ratedInits";


export default {
  name: 'UserProfile',
  components: {
    UserBatakProgress
  },
  data: () => ({
    isSeason: false
  }),
  props: ['userMeta', 'dummyName'],
  created() {
    this.leaderboardCopy = batakCopy.Leaderboard;
  },
  methods: {
    close() {
      this.$emit('close')
    },
    switchTimeWindow() {
      this.isSeason = !this.isSeason
    },
  },
  computed: {
    seasonBestTime() {
      return formatTimeInSecToMinSec(this.userMetaLocal.scoreMonth.bestTime)
    },
    allTimeBestTime() {
      return formatTimeInSecToMinSec(this.userMetaLocal.scoreAllTime.bestTime)
    },
    diameter() {
      return Math.min(180, window.innerWidth / 2.5)
    },
    userMetaLocal() {
      if (this.userMeta) {
        return this.userMeta
      } else {
        return {'scoreAllTime': initScores['batakRated'], 'scoreMonth': initScores['batakRated']}
      }
    },
    userNameLocal() {
      if(this.userMeta) {
        return this.userMeta.name
      }
      else {
        return this.dummyName
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.Header__stat {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  //margin-bottom: 1px;
  //margin-top: 1px;
  
  margin-left: 5px;
  margin-right: 5px;
  
  //border: 1px solid red;
  
  outline: inherit
}

.Header__stat .header_bg {
  display: flex;
  
  flex: 1 1;
  
  margin-top: 5px;
  margin-bottom: 5px;
  
  justify-content: space-between;
  align-items: center;
  
  //border: 1px solid red;
  
  background-color: var(--secondaryColor);
  border-radius: 10px;
  
}


.Header__stat h4 {
  margin-right: 8px;
  margin-left: 8px;
  
  margin-top: 5px;
  margin-bottom: 5px;
  
  text-align: left;
  
  outline: inherit
}

.Header__stat h3 {
  text-align: left;
  outline: inherit
}

.metrics_row {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}

</style>
