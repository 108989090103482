import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/database';    // for realtime database
import 'firebase/firestore';    // for firestore
import 'firebase/functions';   // for cloud functions
import 'firebase/analytics';   // for analytics

// import { initializeApp, analytics } from 'firebase';
import firebaseConfig from './firebase.config';

// const firebaseConfig = {
//     databaseURL: "http://localhost:9000?ns=gdsvueking"
// };

if(location.hostname === "localhost") {
    firebaseConfig.databaseURL = "http://localhost:9000?ns=" + firebaseConfig.projectId;
}

const app = firebase.initializeApp(firebaseConfig);

firebase.analytics();


if(location.hostname === "localhost") {
    app.functions().useFunctionsEmulator('http://localhost:5001');
}

export default app;
