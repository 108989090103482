<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click=close>
      <modalUserName
        style="z-index: 1030"
        :button-text=this.appCopy.change
        :user="user"
        seat="S"
        v-show="modalUserFlag"
        @renameUser="renameUser"
        @modalUserNameClose="modalUserFlag=false"
      />
      <div id="menu" class="modal"
           role="dialog"
           aria-labelledby="modalTitle"
           aria-describedby="modalDescription"
           @click.stop>
        <div v-if="!this.authProvider || this.authProvider && this.authProvider === 'anonymous'"
             style="display: flex; flex-direction: column; align-items: stretch; outline: inherit"
        >
          <div class="menu-section">
            <div class="signin-signup" v-if="signUpFlag">
              <h2>{{ compCopy.signUpTitle }}</h2>
              <input type="text" class="form--element" v-model="username" :placeholder=placeholderUsername><br>
              <input type="text" class="form--element" v-model="email" :placeholder=placeholderEmail><br>
              <input type="password" class="form--element" v-model="password" :placeholder=placeholderPassword><br>
              <input type="password" class="form--element" v-model="passwordConfirm"
                     :placeholder=placeholderPasswordConfirm><br>
              <div @click="signUp" class="authUser" style="width:auto; margin-top: -5px; "> {{
                  compCopy.signUpTitle
                }}
              </div>
              <div class="cancel" @click.stop><span @click="close">{{ compCopy.back }}</span></div>
              <p @click="signUpToggle" class="pointer" style="text-decoration: underline;"> {{
                  compCopy.oldUserCopy
                }} </p>
            </div>
            <div v-else class="signin-signup">
              <h2>{{ compCopy.signInTitle }}</h2>
              <input type="text" class="form--element" v-model="email" :placeholder=placeholderEmail><br>
              <input type="password" class="form--element" v-model="password" :placeholder=placeholderPassword><br>
              <div @click="signIn" class="authUser" style="width:auto; margin-top: -5px; "> {{ compCopy.enter }}</div>
              <div class="cancel" @click.stop><span @click="close">{{ compCopy.back }}</span></div>
              <p @click="signUpToggle" class="pointer" style="text-decoration: underline;"> {{ compCopy.newUserCopy }} </p>
            </div>
          </div>
          <div> {{ compCopy.or }}</div>
          <div class="menu-section">
            <div class="google-btn pointer" @click=googleSignIn>
              <div class="google-icon-wrapper">
                <img class="google-icon"
                     src="@/assets/Google__G__Logo.svg"/>
              </div>
              <p class="btn-text"><b>{{ compCopy.signInWithGoogle }}</b></p>
            </div>
          </div>
        </div>
        <div v-else-if="!emailVerified">
          <div class="menu-section">
            <h1 class="authUser" v-if="user" style="align-self: center; font-size: 18px; width: auto"
                @click="close"
            >
              {{ user.name }}
            </h1>
            <div style="outline: inherit">{{ landingCopy.verifyEmail }}</div>
            <div style="outline: inherit; margin-top:10px">{{ landingCopy.emailCopy }} <b>{{ emailRegistered }}</b>
            </div>
          </div>
          <div class="menu-section">
            <div
              @click=authLogout
              class="pointer menu-button"
              style="display: flex; flex-direction: row; justify-content: center; align-items: center;"
            >
              <i class="fa-duotone fa-sign-out fa-2x" style="margin-right: 6px"/>
              <span style="margin: 5px;" class="pointer">{{ compCopy.signOutCopy }}</span>
            </div>
          </div>
        </div>
        <div v-else style="outline: inherit">
          <div class="menu-section">
            <UserKingProfile
              :userMeta="userMeta"
              :dummy-name="username"
              @close="close"
            />
          </div>
          <div class="menu-section" v-show="user && !user.gameType">
            <div
              class="pointer menu-button"
              @click="modalUserOpen"
              style="display: flex; flex-direction: row; justify-content: center; align-items: center;"
            >
              <i class="fa-duotone fa-signature fa-2x"/>
              <span style="margin: 5px;" class="pointer">{{ compCopy.renameUser }}</span>
            </div>
            <div
              @click=authLogout
              class="pointer menu-button"
              style="display: flex; flex-direction: row; justify-content: center; align-items: center;"
            >
              <i class="fa-duotone fa-sign-out fa-2x" style="margin-right: 6px"/>
              <span style="margin: 5px;" class="pointer">{{ compCopy.signOutCopy }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { kingCopy } from '@/js/king/kingCopy'
import app from "@/firebase.setup";

import {find_player_by_name} from '@/js/database'
import ModalUserName from './ModalUserName';
import UserKingProfile from '@/components/games/king/leaderboard/UserKingProfile';

export default {
  name: 'ModalUserProfile',
  props: ["user", "userMeta", "authProvider", "emailRegistered", "emailVerified", "displayName"],
  data: () => ({
    modalUserFlag: false,
    signUpFlag: false,
    email: null,
    password: null,
    passwordConfirm: null,
    username: null,
    isSeason: false
  }),
  components: {
    ModalUserName,
    UserKingProfile
  },
  methods: {
    switchTimeWindow() {
      this.isSeason = !this.isSeason
    },
    signUpToggle() {
      this.signUpFlag = !this.signUpFlag;
    },
    signIn() {
      if (this.validateSignIn(this.email, this.password)) {
        this.$emit('passwordSignIn', this.email, this.password)
      }
    },
    async signUp() {
      const isValid = await this.validateSignUp(this.email, this.password, this.username)
      if (isValid) {
        this.$emit('passwordSignUp', this.email, this.password, this.username)
      }
    },
    validateSignIn(email, password) {
      if (!email) {
        this.$alert(this.compCopy.emailMissingAlert)
        return false
      } else if (!password) {
        this.$alert(this.compCopy.noPasswordMatchAlert)
        return false
      } else {
        return true
      }
    },
    async validateSignUp(email, password, username) {
      if (!username) {
        this.$alert(this.compCopy.userNameMissingAlert)
        return false
      } else if (!password) {
        this.$alert(this.compCopy.passwordMissingAlert)
        return false
      } else if (!email) {
        this.$alert(this.compCopy.emailMissingAlert)
        return false
      } else if (this.username.length > 10) {
        this.$alert(this.userNameCopy.userNameLengthAlert)
        return false
      } else if (password !== this.passwordConfirm) {
        this.$alert(this.compCopy.noPasswordMatchAlert)
      } else if (!this.username.substring(0, 1).match(/[a-zA-Z0-9_ıİöÖçÇüÜşŞ]/)) {
        this.$alert(this.userNameCopy.userNameFirstLetterAlert)
        return false
      } else if(await this.existsUser(this.username, this.user.id) || this.username.toLowerCase() === 'sen'){
        this.$alert(this.userNameCopy.userAlreadyExists)
        return false
      } else if(this.username.length < 3){
        this.$alert(this.userNameCopy.nameTooShort)
        return false
      } else {
        return true
      }
    },
    close() {
      this.$emit('close');
    },
    modalUserOpen() {
      this.modalUserFlag = true
    },
    async renameUser(data) {
      await app.database().ref(`/users/${this.user.id}/name`).set(data.name);
      this.modalUserFlag = false;
    },
    async existsUser(username, user_id) {
      const foundUsers = await find_player_by_name(username)
      foundUsers.filter(user => user.id !== user_id)
      return foundUsers.length > 0;
    },
    authLogout() {
      this.$emit('authLogout');
    },
    googleSignIn() {
      this.$emit('googleSignIn');
    },
  },
  computed: {
    placeholderEmail() {
      return this.compCopy.placeholderEmail
    },
    placeholderUsername() {
      return this.compCopy.placeholderUsername
    },
    placeholderPassword() {
      return this.compCopy.placeholderPassword
    },
    placeholderPasswordConfirm() {
      return this.compCopy.placeholderPasswordConfirm
    },
  },
  created() {
    this.appCopy = kingCopy.App;
    this.userNameCopy = kingCopy.ModalUserName;
    this.compCopy = kingCopy.ModalUserProfile;
    this.landingCopy = kingCopy.Landing;
    this.leaderboardCopy = kingCopy.Leaderboard;
    if(this.user) {
      this.username = this.user.name;
    }
  },
};
</script>

<style lang="scss" scoped>
$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669F2;

.form--element {
  background-color: #fff;
  border: 2px solid #ECECEC;
  border-radius: 3px;
  font-size: 16px;
  line-height: 28px;
  padding: 0 4px;
  color: #3D3D3D;
  width: 70%;
  margin: -10px 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  outline: inherit;
  
  &:focus {
    border: 1px solid #FFC145;
    border-radius: 2px;
  }
  
  &:not(.texteare) {
    height: 30px;
  }
  
  &.textarea {
    height: 80px;
    resize: none;
  }
}

.google-btn {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 190px;
  height: 42px;
  background-color: $google-blue;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .25);
  
  align-self: center;
  
  .google-icon-wrapper {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: $white;
  }
  
  .google-icon {
    position: absolute;
    margin-top: 11px;
    margin-left: -8px;
    width: 18px;
    height: 18px;
  }
  
  .btn-text {
    float: right;
    margin: 11px 11px 0 0;
    color: $white;
    font-size: 13px;
    letter-spacing: 0.2px;
    //font-family: "Roboto";
  }
  
  &:hover {
    box-shadow: 0 0 6px $google-blue;
  }
  
  &:active {
    background: $button-active-blue;
  }
}

@import url(https://fonts.googleapis.com/css?family=Roboto:500);

#menu {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  align-items: stretch;
  
  //z-index: 310;
  
  font-size: 20px;
  color: var(--tertiaryColor);
  
  margin: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  
  width: calc(min(90%, 500px));
  
  overflow-y: auto;
  
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  
  outline: inherit;
  
}

#menu-header {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  
  /*border: 1px solid var(--tertiaryColor);*/
  
  font-size: 20px;
  color: var(--tertiaryColor);
  
  margin: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  padding: 5px;
  
  background-color: var(--mainColor);
  
  outline: inherit;
  
}

.menu-section {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  
  justify-content: center;
  align-items: stretch;
  
  font-size: 20px;
  color: var(--tertiaryColor);
  
  margin: 5px;
  padding: 5px;
  /*margin-bottom: 20px;*/
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  
  outline: inherit;
}

.signin-signup {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  outline: inherit
}

#menu-system > div {
  margin: 5px;
  outline: inherit
}

.menu-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  border-radius: 2px;
  
  outline: inherit
}

.menu-button:hover {
  background-color: var(--secondaryColor);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  
  outline: inherit
}

.modal-backdrop {
  position: fixed;
  z-index: 300;
  
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  
  outline: inherit;
  
}

.modal {
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;
  
  /*background: #FFFFFF;*/
  box-shadow: 2px 2px 20px 1px;
  /*overflow-x: auto;*/
  
  /*background-color: #24CD63;*/
  
  border-radius: 2px;
  outline: inherit;
}

</style>
