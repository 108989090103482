export const deck_template =
	{
		402: { id: '402', name: '2s',		rank: 2, 		value: 2, 	suit: 'spades'},
		403: { id: '403', name: '3s',		rank: 3, 		value: 3, 	suit: 'spades'},
		404: { id: '404', name: '4s',		rank: 4, 		value: 4, 	suit: 'spades'},
		405: { id: '405', name: '5s',		rank: 5, 		value: 5, 	suit: 'spades'},
		406: { id: '406', name: '6s',		rank: 6, 		value: 6, 	suit: 'spades'},
		407: { id: '407', name: '7s',		rank: 7, 		value: 7, 	suit: 'spades'},
		408: { id: '408', name: '8s',		rank: 8, 		value: 8, 	suit: 'spades'},
		409: { id: '409', name: '9s',		rank: 9, 		value: 9, 	suit: 'spades'},
		410: { id: '410', name: 'Ts',		rank: 10,		value: 10, 	suit: 'spades'},
		411: { id: '411', name: 'js',		rank: 11, 	value: 10, 	suit: 'spades'},
		412: { id: '412', name: 'qs',		rank: 12, 	value: 10, 	suit: 'spades'},
		413: { id: '413', name: 'ks',		rank: 13, 	value: 10, 	suit: 'spades'},
		414: { id: '414', name: 'as',		rank: 14, 	value: 11, 	suit: 'spades'},
		302: { id: '302', name: '2d',		rank: 2, 		value: 2, 	suit: 'diamonds'},
		303: { id: '303', name: '3d',		rank: 3, 		value: 3, 	suit: 'diamonds'},
		304: { id: '304', name: '4d',		rank: 4, 		value: 4, 	suit: 'diamonds'},
		305: { id: '305', name: '5d',		rank: 5, 		value: 5, 	suit: 'diamonds'},
		306: { id: '306', name: '6d',		rank: 6, 		value: 6, 	suit: 'diamonds'},
		307: { id: '307', name: '7d',		rank: 7, 		value: 7, 	suit: 'diamonds'},
		308: { id: '308', name: '8d',		rank: 8, 		value: 8, 	suit: 'diamonds'},
		309: { id: '309', name: '9d',		rank: 9, 		value: 9, 	suit: 'diamonds'},
		310: { id: '310', name: 'Td',		rank: 10, 	value: 10, 	suit: 'diamonds'},
		311: { id: '311', name: 'jd',		rank: 11, 	value: 10, 	suit: 'diamonds'},
		312: { id: '312', name: 'qd',		rank: 12, 	value: 10, 	suit: 'diamonds'},
		313: { id: '313', name: 'kd',		rank: 13, 	value: 10, 	suit: 'diamonds'},
		314: { id: '314', name: 'ad',		rank: 14, 	value: 11, 	suit: 'diamonds'},
		202: { id: '202', name: '2c',		rank: 2, 		value: 2, 	suit: 'clubs'},
		203: { id: '203', name: '3c',		rank: 3, 		value: 3, 	suit: 'clubs'},
		204: { id: '204', name: '4c',		rank: 4, 		value: 4, 	suit: 'clubs'},
		205: { id: '205', name: '5c',		rank: 5, 		value: 5, 	suit: 'clubs'},
		206: { id: '206', name: '6c',		rank: 6, 		value: 6, 	suit: 'clubs'},
		207: { id: '207', name: '7c',		rank: 7, 		value: 7, 	suit: 'clubs'},
		208: { id: '208', name: '8c',		rank: 8, 		value: 8, 	suit: 'clubs'},
		209: { id: '209', name: '9c',		rank: 9, 		value: 9, 	suit: 'clubs'},
		210: { id: '210', name: 'Tc',		rank: 10, 	value: 10, 	suit: 'clubs'},
		211: { id: '211', name: 'jc',		rank: 11, 	value: 10, 	suit: 'clubs'},
		212: { id: '212', name: 'qc',		rank: 12, 	value: 10, 	suit: 'clubs'},
		213: { id: '213', name: 'kc',		rank: 13, 	value: 10, 	suit: 'clubs'},
		214: { id: '214', name: 'ac',		rank: 14, 	value: 11, 	suit: 'clubs'},
		102: { id: '102', name: '2h',		rank: 2, 		value: 2, 	suit: 'hearts'},
		103: { id: '103', name: '3h',		rank: 3, 		value: 3, 	suit: 'hearts'},
		104: { id: '104', name: '4h',		rank: 4, 		value: 4, 	suit: 'hearts'},
		105: { id: '105', name: '5h',		rank: 5, 		value: 5, 	suit: 'hearts'},
		106: { id: '106', name: '6h',		rank: 6, 		value: 6, 	suit: 'hearts'},
		107: { id: '107', name: '7h',		rank: 7, 		value: 7, 	suit: 'hearts'},
		108: { id: '108', name: '8h',		rank: 8, 		value: 8, 	suit: 'hearts'},
		109: { id: '109', name: '9h',		rank: 9, 		value: 9, 	suit: 'hearts'},
		110: { id: '110', name: 'Th',		rank: 10, 	value: 10, 	suit: 'hearts'},
		111: { id: '111', name: 'jh',		rank: 11, 	value: 10, 	suit: 'hearts'},
		112: { id: '112', name: 'qh',		rank: 12, 	value: 10, 	suit: 'hearts'},
		113: { id: '113', name: 'kh',		rank: 13, 	value: 10, 	suit: 'hearts'},
		114: { id: '114', name: 'ah',		rank: 14, 	value: 11, 	suit: 'hearts'}
	}

