<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click=close>
      <div class="modal"
           role="dialog"
           aria-labelledby="modalTitle"
           aria-describedby="modalDescription"
      >
        <div id="history-header">
          <b class="histHeader text">{{ compCopy.lastHand }}</b>
          <div id="history">
            <div style="display: flex; flex-direction: row" >
              <vue-playing-card style="z-index: 20;"
                                v-if="!!this.history && !!this.history.lastCards"
                                :class="{'border': lastStarter==='W'}"
                                class="playing-card lastcards"
                                :width="100"
                                :signature="cardWname"
                                :key="cardWname"
              >
              </vue-playing-card>
            </div>
            <div style="display: flex; ">
              <vue-playing-card style="z-index: 20;"
                                v-if="!!this.history && !!this.history.lastCards"
                                :class="{'border': lastStarter==='N'}"
                                :width="100"
                                :signature="cardNname"
                                :key="cardNname"
                                class="playing-card lastcards">
              </vue-playing-card>
              
              <vue-playing-card style="z-index: 20"
                                v-if="!!this.history && !!this.history.lastCards"
                                :class="{'border': lastStarter==='S'}"
                                :width="100"
                                :signature="cardSname"
                                :key="cardSname"
                                class="playing-card lastcards">
              </vue-playing-card>
            </div>
            <div style="display: flex; flex-direction: row;">
              <vue-playing-card style="z-index: 20"
                                :class="{'border': lastStarter==='E'}"
                                v-if="!!this.history && !!this.history.lastCards"
                                :width="100"
                                :signature="cardEname"
                                :key="cardEname"
                                class="playing-card lastcards">
              </vue-playing-card>
            </div>
          </div>
        </div>
        <div style="display:flex; flex-direction: column; font-size: 20px; justify-content: flex-start; height: 230px;
                    margin: 5px; box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6); border-radius: 2px; outline: inherit"
             v-if="!!this.history && !!this.history.hearts && ceza && ceza === 'kupa'">
          <b class="text histHeader" style="margin-bottom: 5px">{{ compCopy.playedHearts }}</b>
          <div id="hearts">
            <vue-playing-card
              v-for="cardId in orderedHearts"
              style="z-index: 20"
              :width="79"
              :signature="deck[cardId].name"
              :key="cardId"
              class="playing-card hearts-cards">
            </vue-playing-card>
          </div>
        </div>
        <div style="display:flex; flex-direction: column; font-size: 20px; justify-content: flex-start; height: 190px;
                    margin: 5px; box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6); border-radius: 2px; outline: inherit"
             v-if="!!this.history && !!this.history.kj && ceza && ceza === 'erkek'">
          <b class="histHeader text" style="margin-bottom: 5px">{{ compCopy.playedMen }}</b>
          <div id="kj">
            <vue-playing-card
              v-for="card in cardKJhearts"
              style="z-index: 20"
              :width="79"
              :signature="card.name"
              :key="card.id"
              class="playing-card kj-hearts">
            </vue-playing-card>
            <vue-playing-card
              v-for="card in cardKJspades"
              style="z-index: 20"
              :width="79"
              :signature="card.name"
              :key="card.id"
              class="playing-card kj-spades">
            </vue-playing-card>
            <vue-playing-card
              v-for="card in cardKJdiamonds"
              style="z-index: 20"
              :width="79"
              :signature="card.name"
              :key="card.id"
              class="playing-card kj-diamonds">
            </vue-playing-card>
            <vue-playing-card
              v-for="card in cardKJclubs"
              style="z-index: 20"
              :width="79"
              :signature="card.name"
              :key="card.id"
              class="playing-card kj-clubs">
            </vue-playing-card>
          
          </div>
        </div>
        <div style="display:flex; flex-direction: column; font-size: 20px; justify-content: flex-start; height: 155px;
                     margin: 5px; box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6); border-radius: 2px; outline: inherit;"
             v-if="!!this.history && !!this.history.q && ceza && ceza === 'kiz'">
          <b class="text histHeader" style="margin-bottom: 5px">{{ compCopy.playedQueens }}</b>
          <div id="q" v-if="!!this.history && !!this.history.q">
            <vue-playing-card
              v-for="cardId in orderedQs"
              style="z-index: 20"
              :width="79"
              :signature="deck[cardId].name"
              :key="cardId"
              class="playing-card q-cards">
            </vue-playing-card>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { deck_template } from "@/js/deck";
import { kingCopy } from "@/js/king/kingCopy";

export default {
  name: 'ModalHistory',
  props: ["scores", "history", "table", "relDir"],
  data: () => ({
  }),
  methods: {
    close() {
      this.$emit('close');
    },
  },
  created() {
    this.compCopy = kingCopy.ModalHistory;
    this.deck = {...deck_template};

  },
  computed: {
    lastStarter: function (){
      return this.history.lastStarter
    },
    ceza: function () {
      return this.table.ceza
    },
    cardWname: function () {
      return this.deck[this.history.lastCards[this.relDir.W]].name
    },
    cardEname: function () {
      return this.deck[this.history.lastCards[this.relDir.E]].name
    },
    cardNname: function () {
      return this.deck[this.history.lastCards[this.relDir.N]].name
    },
    cardSname: function () {
      return this.deck[this.history.lastCards[this.relDir.S]].name
    },
    cardKJhearts: function () {
      const newCards = {}
      for (const cardId of this.history.kj) {
        if (this.deck[cardId].suit === 'hearts') {
          const newCardId = 500 - parseInt(cardId)
          newCards[newCardId] = this.deck[cardId]
        }
      }
      return newCards
    },
    cardKJspades: function () {
      const newCards = {}
      for (const cardId of this.history.kj) {
        if (this.deck[cardId].suit === 'spades') {
          const newCardId = 500 - parseInt(cardId)
          newCards[newCardId] = this.deck[cardId]
        }
      }
      return newCards
    },
    cardKJdiamonds: function () {
      const newCards = {}
      for (const cardId of this.history.kj) {
        if (this.deck[cardId].suit === 'diamonds') {
          const newCardId = 500 - parseInt(cardId)
          newCards[newCardId] = this.deck[cardId]
        }
      }
      return newCards
    },
    cardKJclubs: function () {
      const newCards = {}
      for (const cardId of this.history.kj) {
        if (this.deck[cardId].suit === 'clubs') {
          const newCardId = 500 - parseInt(cardId)
          newCards[newCardId] = this.deck[cardId]
        }
      }
      return newCards
    },
    orderedHearts: function () {
      const q = [...this.history.hearts]
      q.sort((a, b) => {
        return parseInt(a) - parseInt(b)
      })
      return q
    },
    orderedQs: function () {
      const q = [...this.history.q]
      q.sort((a, b) => {
        return parseInt(a) - parseInt(b)
      })
      return q
    }
    
  },
  
  
};
</script>

<style scoped>

#history-header {
  display: grid;
  
  font-size: 20px;
  color: var(--tertiaryColor);
  
  margin: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  
  outline: inherit;
  
}

#history {
  display: grid;
  grid-template-rows: [r] 300px [rfinal];
  grid-template-columns: [l] 110px [c] 110px [r] 110px [cfinal];
  
  color: var(--tertiaryColor);
  
  outline: inherit;
  
}

#history div {
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;
  
  
  min-width: 70px;
  margin: 5px;
  
  outline: inherit;
  
}

#hearts {
  display: grid;
  grid-template-rows: [r] 30px [r1] 30px [r1] 30px [rfinal];
  grid-template-columns: [l] 82px [cl] 82px [cr] 82px [r] 82px [cfinal];
  
  color: var(--tertiaryColor);
  
  outline: inherit;
  
}

#hearts .hearts-cards {
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;
  
  outline: inherit;
  
}

#kj {
  display: grid;
  grid-template-rows: [r] 40px [r1] 40px [rfinal];
  grid-template-columns: [l] 82px [cl] 82px [cr] 82px [r] 82px [cfinal];
  grid-auto-flow: column;
  
  /*justify-items: center;*/
  /*align-items: center;*/
  
  color: var(--tertiaryColor);
  
  outline: inherit;
  
}

#kj .kj-hearts {
  grid-column-start: l;
  
  justify-content: center;
  align-items: center;
  
  outline: inherit;
  
}

#kj .kj-diamonds {
  grid-column-start: cr;
  
  justify-content: center;
  align-items: center;
  
  outline: inherit;
  
}

#kj .kj-spades {
  grid-column-start: cl;
  
  justify-content: center;
  align-items: center;
  
  outline: inherit;
  
}

#kj .kj-clubs {
  grid-column-start: r;
  
  justify-content: center;
  align-items: center;
  
  outline: inherit;
  
}

#kj .kj-cards {
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;
  
  outline: inherit;
  
}

#q {
  display: grid;
  grid-template-rows: [r] 120px [rfinal];
  grid-template-columns: [l] 82px [cl] 82px [cr] 82px [r] 82px [cfinal];
  
  color: var(--tertiaryColor);
  
  outline: inherit;
  
}

#q .q-cards {
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;
  
  outline: inherit;
  
}

.modal-backdrop {
  position: fixed;
  z-index: 300;
  
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  
  outline: inherit;
  
}

.modal {
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;
  
  /*background: #FFFFFF;*/
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  
  /*background-color: #24CD63;*/
  
  
  border-radius: 2px;
  
  outline: inherit;
}

.modal-header,
.modal-footer {
  justify-content: center;
  align-items: center;
  padding: 15px;
  display: flex;
  color: var(--tertiaryColor);
  
  outline: inherit;
}


.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
  
  outline: inherit;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  
  outline: inherit;
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease
}

.histHeader {
  margin: 3px;
  outline: inherit;
}

.lastcards {
  padding: 2px;
  border-radius: 7px;
}

.border {
  border: 2px solid red;
}

</style>
