<template>
  <div id="container" @click=formsClose>
    <ModalCompetitionInfo
      style="z-index: 1000"
      v-show="competitionInfoFlag"
      @close="closeCompetitionInfo"
    />
    <ModalHallOfFameInfo
      style="z-index: 1000"
      v-show="HOFInfoFlag"
      @close="closeHOFInfo"
    />
    <div id="buttons-meta">
      <div class="circle-button" @click.stop :class="{'open': botFormOpen}" v-if="!formOpen || botFormOpen"
           @click="botFormOpen = true">
        <div class="ribbon-wrapper-green"><div class="ribbon-green">{{ compCopy.noRegistrationRibbon }}</div></div>
        <div class="circle-button-inner">
          <div class="button-copy" v-show="!formOpen || botFormOpen"> {{ compCopy.playWithBots }}</div>
          <i class="fa-duotone fa-user-robot modeLogo"
             :class="{'fa-4x': !botFormOpen, 'fa-5x': botFormOpen}"
             style="outline: inherit"/>
          <div class="button-copy" v-show="!botFormOpen ">
            {{ compCopy.playWithBotsDescription }}
          </div>
          <form onsubmit="return false" class="circle-form">
            <div class="form--field" v-if="authProvider === 'anonymous'">
              <label>{{ compCopy.userName }}</label>
              <label>
                <input type="text" class="form--element" v-model="username"
                       :placeholder=this.displayName
                       style="text-align: center; margin: 3px">
              </label>
            </div>
            <button class="submit-button" @click="singleStart">{{ compCopy.start }}</button>
            <div class="cancel" style="margin-top: 5px" @click.stop><span @click="singleCancel">
              {{compCopy.back }}
            </span></div>
          </form>
          <div v-if="botFormOpen" class="circle-button__description">
            <div class="checkmark_item">
              <div class="checkmark">✅</div>
              <div class="text">{{ compCopy.singleRule1 }}</div>
            </div>
            <div class="checkmark_item">
              <div class="checkmark">🚨</div>
              <div class="text">{{ compCopy.singleRule2 }}</div>
            </div>
            <div class="checkmark_item">
              <div class="checkmark">👉️</div>
              <div class="text pointer" style="text-decoration: underline" @click="signIn">{{ compCopy.singleRule3 }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="circle-button" @click.stop :class="{'open': ratedFormOpen}" v-if="!formOpen || ratedFormOpen"
           @click="ratedFormOpen = true">
<!--        <div class="ribbon-wrapper-red"><div class="ribbon-red">{{ compCopy.newRibbon }}</div></div>-->
        <div class="circle-button-inner">
          <div class="button-copy" v-show="!formOpen || ratedFormOpen"> {{ compCopy.playRated }}</div>
          <i class="fa-duotone fa-trophy-alt modeLogo"
             :class="{'fa-4x': !ratedFormOpen, 'fa-5x': ratedFormOpen }"
             style="outline: inherit"
          />
          <div class="button-copy" v-if="!ratedFormOpen">
            {{ compCopy.playRatedDescription }}
          </div>
          <form onsubmit="return false" class="circle-form">
            <div v-if="authProvider === 'anonymous'"
                 style="display: flex; flex-direction: column; align-items: center; outline: inherit">
              <div class="form--field">
                <div style="outline: inherit">{{ compCopy.loginToStart }}</div>
                <div>👇</div>
              </div>
              <div
                class="btn-loginout baseShadow"
                @click="signIn"
              >
                <i class="fa-duotone fa-sign-in fa-2x"/>
              </div>
              <div class="cancel" style="margin-top: 5px" @click.stop><span @click="ratedCancel">{{
                  compCopy.back
                }}</span></div>
            </div>
            <div v-else-if="authProvider === 'password' && !emailVerified"
                 style="display: flex; flex-direction: column; align-items: center; outline: inherit">
              <div class="form--field" style="box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);">
                <div style="outline: inherit">{{ compCopy.verifyEmail }}</div>
                <div style="outline: inherit; margin-top:10px">{{ compCopy.emailCopy }} <b>{{ email }}</b></div>
              </div>
              <div class="cancel" style="margin-top: 5px" @click.stop><span @click="ratedCancel">{{ compCopy.back }}</span></div>
            </div>
            <div v-else>
              <button class="submit-button" @click="ratedStart">{{ compCopy.start }}</button>
              <div class="cancel" style="margin-top: 5px" @click.stop><span @click="ratedCancel">{{ compCopy.back }}</span></div>
            </div>
          </form>
          <div class="circle-button__description" v-if="ratedFormOpen">
            <div class="checkmark_item">
              <div class="checkmark">✅</div>
              <div class="text">{{ compCopy.ratedRule1 }}</div>

            </div>
            <div class="checkmark_item">
              <div class="checkmark">✅</div>
              <div class="text">{{ compCopy.ratedRule2 }}</div>
            </div>
            <div class="checkmark_item">
              <div class="checkmark">✅</div>
              <div class="text">{{ compCopy.ratedRule3 }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex; flex-direction: row; align-self: stretch; justify-content: center; align-items: center;">
      <div class="btn pop" v-scroll-to="{ el: '#hof', offset: -60 }">
        Onur Listesi
      </div>
      <div class="btn pop" v-scroll-to="{ el: '#previousSeasons', offset: -60 }">
        Geçmiş Sezonlar
      </div>
    </div>
    <leaderboard
      style="display: flex; flex-direction: column; align-items: stretch; align-self: stretch"
      @openUserProfile="openUserProfile"
      @openCompetitionInfo="openCompetitionInfo"
      :authProvider="authProvider"
      :user="user"
      :userMeta="userMeta"
    />
    <PreviousSeasons
      id="previousSeasons"
      style="display: flex; flex-direction: column; align-items: stretch; align-self: stretch"
    />
    <HallOfFame
      id="hof"
      @openHOFInfo="openHOFInfo"
      style="display: flex; flex-direction: column; align-items: stretch; align-self: stretch"
    />
    <div id="footer"
         class="baseShadow"
         style="display: flex; flex-direction: column; align-self: stretch"
    >
      <div>
        <strong><a href="https://www.facebook.com/kagitoyna" target="_blank"> {{ compCopy.footerNote }} </a></strong>
      </div>

      <div id="social" style="display: flex; flex-direction: row; justify-content: center">
        <a href="https://www.facebook.com/kagitoyna/" target="_blank">
          <div>
            <i class="fa-brands fa-facebook-square facebook fa-4x icenter follow-button"
               style="display: flex; flex: 1 1 auto; margin-right: 10px"
            ></i>
          </div>
        </a>
        <a href="https://twitter.com/kagitoyna" target="_blank">
          <div>
            <i class="fa-brands fa-twitter-square twitter fa-4x icenter follow-button"
               style="display: flex; flex: 1 1 auto; margin-right: 10px"
            />
          </div>
        </a>
        <a href="https://instagram.com/kagitoyna" target="_blank">
          <div>
            <i class="fa-brands fa-instagram-square instagram fa-4x icenter follow-button"
               style="display: flex; flex: 1 1 auto; margin-right: 5px"
            ></i>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import app from '@/firebase.setup'
import {batakCopy} from '@/js/batak/batakCopy'
import Leaderboard from "./leaderboard/Leaderboard";
import ModalCompetitionInfo from "./leaderboard/ModalCompetitionInfo";
import ModalHallOfFameInfo from "./leaderboard/ModalHallOfFameInfo";
import PreviousSeasons from "./leaderboard/PreviousSeasons";
import HallOfFame from "./leaderboard/HallOfFame";


export default {
  name: 'BatakLanding',
  components: {HallOfFame, PreviousSeasons, Leaderboard, ModalCompetitionInfo, ModalHallOfFameInfo},
  data: () => ({
    username: null,
    friendsFormOpen: false,
    botFormOpen: false,
    ratedFormOpen: false,
    competitionInfoFlag: false,
    HOFInfoFlag: false
  }),
  props: ['flashMessageFlag','displayName', 'userMeta', 'user', 'authProvider', 'emailVerified', 'email'],
  created() {
    this.compCopy = batakCopy.Landing;
  },
  methods: {
    sendKingLandingHofAnalytics() {
      app.analytics().logEvent('batakLanding-hof');
    },
    sendKingLandingArchiveAnalytics() {
      app.analytics().logEvent('batakLanding-archive');
    },
    closeCompetitionInfo() {
      this.competitionInfoFlag = false;
    },
    closeHOFInfo() {
      this.HOFInfoFlag = false;
    },
    flashMessageClose() {
      this.$emit('flashMessageClose')
    },
    openUserProfile() {
      this.$emit('openUserProfile')
    },
    openCompetitionInfo() {
      this.competitionInfoFlag = true;
    },
    openHOFInfo() {
      this.HOFInfoFlag = true;
    },
    signIn() {
      this.$emit('signIn')
    },
    formsClose() {
      this.botFormOpen = false;
      this.ratedFormOpen = false;
      this.friendsFormOpen = false;
    },
    singleCancel() {
      this.botFormOpen = false;
      // this.resetForm();
    },
    ratedCancel() {
      this.ratedFormOpen = false;
      // this.resetForm();
    },
    friendsCancel() {
      this.friendsFormOpen = false;
      // this.resetForm();
    },
    // captureKey: function (e) {
    //     const key = e.key || e.keyCode;
    //     this.username = this.username.trim()
    //     if (key === 'Enter' || key === 27) {
    //         this.validateInput()
    //     }
    // },
    singleStart: async function () {
      if (this.validateInput()) {
        app.analytics().logEvent('batakSingle-start');
        this.$emit('singleGameStart', this.username);
      }
      return 'all good'
    },
    friendlyStart: function () {
      if (this.validateInput()) {
        app.analytics().logEvent('batakFriendly-start');
        this.$emit('friendlyGameStart', this.username);
      }
    },
    ratedStart: function () {
      if (this.validateInput()) {
        app.analytics().logEvent('batakRated-start');
        this.$emit('ratedGameStart', this.username);
      }
    },
    validateInput: function () {
      if (!this.username) {
        this.username = this.displayName
      }
      else {
        this.username = this.username.trim()
      }
      if (this.username.length > 10) {
        this.$alert('Kullanıcı adınız en fazla on karakterden oluşabilir.')
      } else if (!this.username.substring(0, 1).match(/[a-zA-Z0-9_ıİöÖçÇüÜşŞ]/)) {
        this.$alert('Kullanıcı adınızın ilk karakteri rakam ya da harf olmalıdır.')
      } else {
        return true
      }
      return false
    },
    
  },
  computed: {
    formOpen() {
      return this.friendsFormOpen || this.botFormOpen || this.ratedFormOpen
    }
  }
}
</script>


<style lang="scss" scoped>

.modeLogo {
  margin-bottom: 5px;
  
}

#social {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
  padding-left: 5px
}

#container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  outline: inherit
}

#buttons-meta {
  display: flex;
  flex: 1 1 auto;
  margin-top: 5px;
  //margin-bottom: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  outline: inherit
}

.text-meta {
  display: flex;
  flex: 0 0;
  
  margin: 10px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  transition: all 0.3s ease;
  background-color: var(--secondaryColor);
  
  height: 50px;
  
  //width: min(100%, 180);
  border-radius: min(1vh, 5);
  
  outline: inherit
}

h2.adjust-whitespace {
  margin: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 0;
  line-height: 30px;
  height: 25px;
  
  outline: inherit
}

h3.adjust-whitespace {
  margin: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 0;
  //line-height: 30px;
  //height: 25px;
  
  outline: inherit
}

.circle-button {
  &.open {
    background-color: #FAFAFA;
    padding: 10px 15px;
    border-radius: 5px;
    width: 50%;
    min-width: 300px;
    //max-width: 100%;
    height: 440px;
    margin-top: 10px;
    cursor: default;
    
    form {
      opacity: 1;
      transition: opacity 0.1s ease;
      transition-delay: 0.1s;
      height: auto;
    }
    
    outline: inherit;
  }
  position: relative;
  margin: 5px;
  display: flex;
  flex: 0 0;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: stretch;
  transition: all 0.3s ease;
  background-color: var(--fourthColor);
  
  height: 150px;
  //width: calc(min(30vw, 150px));
  border-radius: min(1vw, 5);
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, .5);
  
  outline: inherit;
  
  .circle-button-inner {
    //border: 1px red solid;
    min-width: calc(min(30vw, 150px));
    display: flex;
    
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    outline: inherit;
  }
  
  .circle-button__description {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 10px;
    //width: 70%;
    
    outline: inherit;
  }
  
  .circle-button__description .checkmark_item {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    margin-top: 20px;
    
    outline: inherit;
  }
  
  .circle-button__description .checkmark_item .checkmark {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
  }
  
  .circle-button__description .checkmark_item .text {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  cursor: pointer;
  
  .button-copy {
    width: 100px;
    /*margin: 5px;*/
    display: flex;
    justify-content: center;
    text-align: center;
    line-height: 30px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
    color: var(--tertiaryColor);
    //margin-left: -100%;
    //margin-right: -100%;
    
    outline: inherit
  }
  
  form {
    transition: none;
    opacity: 0;
    height: 0;
    overflow: hidden;
    
    outline: inherit
  }
}

.circle-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  
  outline: inherit;
}

.submit-button {
  display: block;
  background-color: var(--mainColor);
  height: 40px;
  border-radius: 20px;
  line-height: 36px;
  text-align: center;
  color: var(--tertiaryColor);
  text-transform: uppercase;
  font-size: 0.875em;
  border: none;
  font-weight: 700;
  padding: 0 34px;
  margin: 0 auto;
  
  img {
    position: relative;
    top: 3px;
    right: 6px;
  }
  
  &:hover {
    background-color: darken(#3498DB, 10%);
    cursor: pointer;
  }
  
  outline: inherit
}

.featured-note {
  color: #949494;
  font-size: 12px;
  margin: 4px 0px;
  line-height: 18px;
  font-style: italic;
}

form * {
  outline: none;
}

label {
  display: block;
  font-size: 14px;
  font-weight: bold;
  user-select: none;
  
  &.emoji {
    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      height: 21px;
      margin: 0;
      display: none;
      
      & + span:after {
        content: "😶";
        font-size: 18px;
        top: 2px;
        left: 2px;
        position: relative;
        height: 18px;
        display: inline-block;
      }
      
      &:checked + span:after {
        content: "😀";
      }
    }
  }
  
  outline: inherit
}

.form--field {
  margin: 10px 0;
  
  &.-short {
    width: 120px;
  }
  
  outline: inherit
}

.form--price {
  position: absolute;
  line-height: 38px;
  width: 16px;
  color: #C7C7C7;
  text-align: center;
  
  & + input {
    padding-left: 14px;
  }
}

.form--container {
  width: 420px;
  
  &.-inline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: -12px;
  }
  
  outline: inherit;
}

.form--element {
  background-color: #fff;
  border: 1px solid #ECECEC;
  border-radius: 3px;
  font-size: 16px;
  line-height: 28px;
  padding: 0 4px;
  color: #3D3D3D;
  width: 80%;
  margin: 4px 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  
  &:focus {
    border: 1px solid #FFC145;
    border-radius: 2px;
  }
  
  &:not(.texteare) {
    height: 30px;
  }
  
  &.textarea {
    height: 80px;
    resize: none;
  }
  
  outline: inherit;
}


html,
body {
  height: 100%;
  margin: 0;
  
  outline: inherit;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  background-color: #F3F4F5;
  cursor: default;
  
  outline: inherit;
}

#footer {
  padding: 5px;
  border-radius: 5px;
}

a {
  text-decoration: none;
  cursor: pointer;
}

::selection {
  background-color: #F5617A;
  color: #fff;
}

::-moz-selection {
  background-color: #F5617A;
  color: #fff;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder { /* Firefox 18- */
  text-align: center;
}

::-moz-placeholder { /* Firefox 19+ */
  text-align: center;
}

:-ms-input-placeholder {
  text-align: center;
}


.closebtn {
  margin-left: 15px;
  color: var(--tertiaryColor);
  font-weight: bold;
  float: right;
  font-size: 30px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: var(--mainColor);
}

.btn {
  display: block;
  text-decoration: none;
  text-transform: capitalize;
  font-size: larger;
  color: var(--tertiaryColor);
  background: white;
  font-weight: bold;
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, .2);
  padding: 8px;
  margin: 5px;
  height: 25px;
  width: 150px;
  cursor: pointer;
}

.btn:hover {
  -webkit-box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.75);
}

.pop {
  color: var(--fourthColor);
}

.btn.pop {
  color: var(--tertiaryColor);
  background: var(--mainColor);
  align-self: center;
  justify-self: center
}

</style>
