<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click=close>
      <div class="modal"
           role="dialog"
           aria-labelledby="modalTitle"
           aria-describedby="modalDescription"
           @click.stop
      >
        <div id="login" class="form--field">
          <i class="fa-duotone fa-chair-office fa-6x chair" style="margin-bottom: 10px"/>
          <label>{{ compCopy.userName }}</label>
          <input type="text" class="form--element" v-model="username" style="text-align: center; margin: 3px"
                 value="Sen">
          <button class="submit-button" @click="validateInput">{{ this.btnText }}</button>
          <div class="cancel" @click.stop><span @click="close">{{ compCopy.back }}</span></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

import {kingCopy} from "@/js/king/kingCopy";
import {find_player_by_name} from "@/js/database";

export default {
  name: 'ModalUserName',
  data: () => ({
    newUserName: null
  }),
  props: ['seat', 'buttonText', 'user'],
  methods: {
    close() {
      this.$emit('modalUserNameClose');
    },
    async existsUser(username, user_id) {
      let foundUsers = await find_player_by_name(username)
      foundUsers = foundUsers.filter(el => el.id !== user_id)
      return foundUsers.length > 0;
    },
    async validateInput() {
      this.username = this.username.trim()
      if (this.username.length > 10) {
        this.$alert(this.compCopy.userNameLengthAlert)
      } else if (!this.username.substring(0, 1).match(/[a-zA-Z0-9_ıİöÖçÇüÜşŞ]/)) {
        this.$alert(this.compCopy.userNameFirstLetterAlert)
      } else if(this.username.length < 3){
        this.$alert(this.compCopy.nameTooShort)
      } else if(await this.existsUser(this.username, this.user.id) || this.username.toLowerCase() === 'sen'){
        this.$alert(this.compCopy.userAlreadyExists)
      }
      else {
        this.$emit('renameUser', {'seat': this.seat, 'name': this.username})
      }
    }
  },
  created() {
    this.appCopy = kingCopy.App;
    this.compCopy = kingCopy.ModalUserName;
  },
  computed: {
    username: {
      get: function () {
        if (this.newUserName) {
          if (this.newUserName === this.appCopy.defaultName) {
            return ''
          } else {
            return this.newUserName
          }
        } else if (this.user && this.user.name) {
          if (this.user.name === this.appCopy.defaultName) {
            return ''
          } else {
            return this.user.name
          }
        } else {
          return null
        }
      },
      set: function (newValue) {
        this.newUserName = newValue;
      }
    },
    btnText() {
      if (this.buttonText) {
        return this.buttonText
      } else {
        return this.compCopy.takeSeat
      }
    }
  }
  
};
</script>

<style lang="scss" scoped>
#login {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  background-color: #FAFAFA;
  padding: 18px 32px;
  border-radius: 5px;
  width: 300px;
  height: 300px;
  cursor: default;
}

.form--field {
  width: 420px;
  margin: 10px 0;
  
  &.-short {
    width: 120px;
  }
}

.form--element {
  background-color: #fff;
  border: 1px solid #ECECEC;
  border-radius: 3px;
  font-size: 16px;
  line-height: 28px;
  padding: 0 4px;
  color: #3D3D3D;
  width: 50%;
  margin: 4px 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  
  &:focus {
    border: 1px solid #FFC145;
    border-radius: 2px;
  }
  
  &:not(.texteare) {
    height: 30px;
  }
  
  &.textarea {
    height: 80px;
    resize: none;
  }
}

.button-copy {
  width: 100px;
  /*margin: 5px;*/
  display: flex;
  justify-content: center;
  text-align: center;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--tertiaryColor);
  font-size: 14px;
  margin-left: -100%;
  margin-right: -100%;
}

.cancel {
  font-size: 12px;
  text-align: center;
  margin-top: 1em;
  
  span {
    cursor: pointer;
    
    &:hover {
      text-decoration: underline;
    }
  }
}

.submit-button {
  display: block;
  background-color: var(--mainColor);
  height: 40px;
  border-radius: 20px;
  line-height: 36px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.875em;
  border: none;
  font-weight: 700;
  padding: 0 34px;
  margin: 10px auto;
  
  img {
    position: relative;
    top: 3px;
    right: 6px;
  }
  
  &:hover {
    background-color: darken(#3498DB, 10%);
    cursor: pointer;
  }
}


.modal-backdrop {
  position: fixed;
  
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  
  outline: inherit;
  
}

.modal {
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;
  
  /*background: #FFFFFF;*/
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  
  /*background-color: #24CD63;*/
  
  border-radius: 2px;
  
  outline: inherit;
}

.modal-header,
.modal-footer {
  justify-content: center;
  align-items: center;
  padding: 15px;
  display: flex;
  color: var(--tertiaryColor);
  
  outline: inherit;
}


.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
  
  outline: inherit;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  
  outline: inherit;
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease
}


</style>
