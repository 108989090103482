import app from "@/firebase.setup";

export const gameCat = {
  async created() {
    const validationFlag = await this.validateStorageGame()
    if (validationFlag === -1) {
      console.log('validation failed!')
      sessionStorage.clear()
    } else {
      this.gameMeta = JSON.parse(sessionStorage.gameMeta);
      this.hands = JSON.parse(sessionStorage.hands);
      this.game = JSON.parse(sessionStorage.game);
    }
  },
  methods: {
    validateStorageGame: async function (){
      if (sessionStorage.gameMeta && sessionStorage.game && sessionStorage.hands) {
        const gM = JSON.parse(sessionStorage.gameMeta);
        const g = JSON.parse(sessionStorage.game);

        if(!g || !gM) {
          console.warn('Storage invalidated: missing one of game or gameMeta objects')
          return -1
        }

        if (gM.gameCategory !== this.gameCategory) {
          if(gM.gameType === `${this.gameCategory}Rated`){
            const registerGameResult = app.functions().httpsCallable('ratedRegisterGameResult');
            try {
              await registerGameResult({'gameMeta': gM, 'round': g.round});
            }
            catch(e) {
              console.warn(e)
            }
          }
          else if(gM.gameType === `${this.gameCategory}Single`){
            const registerGameResult = app.functions().httpsCallable('singleRegisterGameResult');
            try {
              await registerGameResult({'gameMeta': gM, 'round': g.round});
            }
            catch(e) {
              console.warn(e)
            }
          }
          console.warn(`Storage invalidated: Storage game was other than ${this.gameCategory}`)
          return -1
        }

        if(!gM.owner || gM.owner.id !== this.user.id) {
          const registerGameResult = app.functions().httpsCallable('ratedRegisterGameResult');
          try {
            await registerGameResult({'gameMeta': gM, 'round': g.round});
          }
          catch(e) {
            console.warn(e)
          }
          console.warn('Storage invalidated: owner id in Storage gameMeta does not match the user id')
          return -1
        }

        if(g.id !== this.user.gameId) {
          const registerGameResult = app.functions().httpsCallable('ratedRegisterGameResult');
          try {
            await registerGameResult({'gameMeta': gM, 'round': g.round});
          }
          catch(e) {
            console.warn(e)
          }
          console.warn('Storage invalidated: gameId of the user does not match the Id of the Storage game')
          return -1
        }
        console.warn('Storage validated: Pass')
        return 1
      }
      console.warn('Storage invalidated: At least one of game, gameMeta and hands is not in Storage')
      return -1
    }
  }
}